import Salary from '../../Models/Salary';
import SalaryType from '../../Models/SalaryType';
import Shop from '../../Models/Shop';
import Spent from '../../Models/Spent';
import SpentType from '../../Models/SpentType';
import axiosInstance from '../../Utils/axios_manager';

export default class AnalyticService {
  
    constructor() { }

 
    public comptaDetail =  async (data: Record<string, any>) => {
        return await axiosInstance.post('/compta/vente/periode', data);
    }

    public supervizeSalary =  async (data: Record<string, any>) => {
        return await axiosInstance.post('/salaires/supervise', data);
    }

    public getSupervizeSalaries =  async () => {
        return await axiosInstance.get('/salaires/list/supervised');
    }

    public getAnalyticsOverviewData =  async (data: Record<string, any>) => {
        return await axiosInstance.post('/analystic/overview',  data);
    }

    public getAnalyticsProductsData =  async (data: Record<string, any>) => {
        return await axiosInstance.post('/analystic/product',  data);
    }

    public getAnalyticsRevenueData =  async (data: Record<string, any>) => {
        return await axiosInstance.post('/analystic/revenues',  data);
    }

    public getAnalyticsOrdersData =  async (data: Record<string, any>) => {
        return await axiosInstance.post('/analystic/orders',  data);
    }

    public getAnalyticsCategoriesData =  async (data: Record<string, any>) => {
        return await axiosInstance.post('/analystic/categories',  data);
    }

    public getAnalyticsStocksData =  async (data: Record<string, any>) => {
        return await axiosInstance.post('/analystic/stock',  data);
    }

    public getAnalyticsCouponsData =  async (data: Record<string, any>) => {
        return await axiosInstance.post('/analystic/coupons',  data);
    }


}