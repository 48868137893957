import { FC, useEffect, useRef, useState } from 'react'
import {
    Link, useNavigate
} from "react-router-dom";
import Breadcrumb from '../../Components/Breadcrumb';

//Alert
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';  

import AgentService from '../Agent/service';
import InterestRepartitionService from './service';


import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 

import ShopService from '../Shop/service';

import Alert from '../../Components/Alert';
import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
import { useTranslation } from 'react-i18next';

interface TaxeReportFormProps {
    id?: string | null; 
    datedeb: Date | null;
    datefin: Date | null; 
    idboutique: string;
    details: Boolean
}

const TaxeReportPage: FC = () => {  

    // const alert = useAppSelector((state: RootState ) => state.alerts );
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const formRef = useRef< FormikProps<TaxeReportFormProps>>(null);

    const navigate = useNavigate();

    const [
        loading, setLoading
    ] = useState(false);


    const [ shops, setShops ] = useState( [] );

    let [shopsLoading, setShopsLoading] = useState(false);

    const agentService  = new AgentService();
    const shopService = new ShopService();

    const interestRepartitionService  = new InterestRepartitionService();

    const [
        associates,
        setAssociates
    ] = useState([]);

    const [
        etatFactureFile,
        setEtatFactureFile
    ] = useState('');

    const [
        prestationFactureFile,
        setPrestationFactureFile
    ] = useState('');

    const readThis = (inputValue: any): void => {
        var file: File = inputValue.files[0];
        var lecteur: FileReader = new FileReader();
    
        lecteur.onloadend = (e) => {
            setEtatFactureFile( lecteur.result as string );
            // if (Object.prototype.toString.call(lecteur.result) === "[object String]") {
                
            // }
            
            console.log(lecteur.result);
        }
        lecteur.readAsDataURL(file);
    }

    const readThis2 = (inputValue: any): void => {
        var file: File = inputValue.files[0];
        var lecteur: FileReader = new FileReader();
    
        lecteur.onloadend = (e) => {
            setPrestationFactureFile( lecteur.result as string );
            // if (Object.prototype.toString.call(lecteur.result) === "[object String]") {
                
            // }
            
            console.log(lecteur.result);
        }
        lecteur.readAsDataURL(file);
    }

    const changeListener = (event: any)  => {
        readThis(event.target);
    }

    const changeListener2 = (event: any)  => {
        readThis2(event.target);
    }

    const initialSelectedGamme: any = null;

    const [
        selectedType,
        setSelectedType
    ] = useState(initialSelectedGamme);

    const [
        deleteLoading, setDeleteLoading
    ] = useState(false);

   const id: Number = 0;
    const [
        deleteId, setDeleteId
    ] = useState(id);
 

    const deleteAssociate = async (salaryId: Number) => {
        setDeleteId(salaryId);
        setDeleteLoading(true);

        confirmAlert({
            overlayClassName: "alert-overlay",
            customUI: ({ onClose }) => {
              return (
                    <Alert 
                        alert={{
                            title: 'Confirmer',
                            message: 'Vous confirmez la suppression de cet associé ?',
                            actionText: 'Valider',
                            action: async () => {
                                await interestRepartitionService.deleteAssociate(salaryId).then(async function (response: any) {
                                    console.log(response); 
                                   
                                    // getAssociates();
                                    setDeleteLoading(false); 

                                    confirmAlert({
                                        overlayClassName: "alert-overlay",
                                        customUI: ({ onClose }) => {
                                          return (
                                                <Alert 
                                                    alert={{
                                                        title: 'Succès',
                                                        message: 'Associé supprimé avec succès',
                                                        actionText: 'Ok',
                                                        action: () => {
                                                            onClose();
                                                        }
                                                    }}
                                                />
                                          );
                                        }
                                    });

                                })
                                  .catch(function (error: any) {
                                    console.log(error);
                                    setDeleteLoading(false); 
                                });
                                onClose();
                            },
                            cancelText: "Annuler",
                            cancelAction: () => {
                                setDeleteLoading(false); 
                                onClose();
                            }
                        }}
                    />
              );
            }
        });

    }

    const getShops = () => {
        setShopsLoading(true);
        shopService.getShops().then(async function (response: any) {
            console.log(response);
            setShops(response.data);
            setShopsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setShopsLoading(false); 
        });
    }

    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row.id
        },
        {
            name: 'Agent',
            selector: (row: any) => row.agent.prenomAgent + ' ' + row.agent.nomAgent ,
        },
        {
            name: t('Pourcentage'),
            selector: (row: any) => row.pourcentage,
        }, 
        {
            name: "Options", 
            selector: (row: any) => (
                <div className='btn-list'>
                    <button type="button" onClick={event => {
                        setSelectedType( row );
                        if ( formRef.current != null) {
                            formRef.current.values.id = null;
                            formRef.current.values.datedeb = null;
                            formRef.current.values.datefin = null; 
                            formRef.current.values.idboutique = ''; 
                            formRef.current.values.details  = false;
                        }  
                    }}
                        className="btn waves-effect waves-light btn-success">
                            <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-pencil"></i></span>
                    </button>
                    <button type="button" onClick={event => {  deleteAssociate(row.id)  }}
                        className="btn waves-effect waves-light btn-danger">
                            {
                                deleteId == row.id && deleteLoading ?
                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> :
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-trash"></i></span>
                            }
                    </button>
                </div>
            )
        }
    ];


    const cancel = () => {

        setSelectedType(null);

        if ( formRef.current != null) {
            formRef.current.values.id = null;
                            formRef.current.values.datedeb = null;
                            formRef.current.values.datefin = null; 
                            formRef.current.values.idboutique = ''; 
                            formRef.current.values.details  = false;
        } 
    }

    useEffect(() => {
        getShops();
        // getAssociates();
    }, []);



    return ( 
        <>  
            <Breadcrumb />

            <div className="container-fluid">

                <div className="row">

                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">

                                <h4 className="card-title">
                                    {
                                        selectedType == null ? 
                                        t("Rapport") :
                                        t("Mettre à jour associé")
                                    }
                                </h4>

                                <Formik
                                    initialValues={
                                        {
                                            id: null,
                                            datedeb: null,
                                            datefin: null, 
                                            idboutique: '',
                                            details: false
                                    }}
                                    validationSchema={
                                        yup.object().shape({
                                            datedeb: yup
                                                .date() 
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            datefin: yup
                                                .date() 
                                                .required(`${t('Ce champ est obligatoire')}`), 
                                            idboutique: yup
                                                .string()
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            details : yup
                                                .boolean()  
                                        })
                                    }
                                    onSubmit={async (
                                        values 
                                    ) => {  navigate('/impots/rapport-result');   }} 
                                    innerRef={formRef}
                                    >
                                        {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                                    <Form>
                                                        <div className="form-body">
                                                            

                                                            <div className="form-group">
                                                                <label>
                                                                    { t('Périodes') }
                                                                </label>
                                                                <div className="row"> 
                                                                    <div className="col-md-6"> 
                                                                        <Field type="date"
                                                                                className={`form-control ${ errors.datedeb
                                                                                     && touched.datedeb ? "is-invalid":""}`}
                                                                                id="datedeb" 
                                                                                name="datedeb"
                                                                                onChange={handleChange('datedeb')}
                                                                                onBlur={handleBlur('datedeb')}
                                                                                value={values.datedeb == null ? new Date()  : values.datedeb }
                                                                                placeholder={t("Date de début") ?? ''} />
                                                                                { errors.datedeb && touched.datedeb && errors.datedeb && 
                                                                                <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                    { errors.datedeb && touched.datedeb && errors.datedeb }
                                                                                </div> }
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <Field type="date"
                                                                                className={`form-control ${ errors.datefin
                                                                                     && touched.datefin ? "is-invalid":""}`}
                                                                                id="datefin" 
                                                                                name="datefin"
                                                                                onChange={handleChange('datefin')}
                                                                                onBlur={handleBlur('datefin')}
                                                                                value={values.datefin == null ? new Date()  : values.datefin }
                                                                                placeholder={ t("Date de fin") ?? ''} />
                                                                                { errors.datefin && touched.datefin && errors.datefin && 
                                                                                <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                    { errors.datefin && touched.datefin && errors.datefin }
                                                                                </div> }
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                            <div className="form-group">
                                                                <label> { t('Boutique') } </label>
                                                                    {
                                                                        shopsLoading ? 
                                                                        <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                                        <span className="sr-only">Loading...</span>
                                                                        </div> :
                                                                        <select  
                                                                            className={`form-control custom-select ${ errors.idboutique &&
                                                                                touched.idboutique ? "is-invalid" : "" }`}
                                                                            id="idboutique" 
                                                                            name="idboutique"
                                                                            onChange={event => { 
                                                                                handleChange('idboutique')(event); 
                                                                            }}
                                                                            onBlur={handleBlur('idboutique')}
                                                                            value={values.idboutique == null ? '' : values.idboutique}
                                                                            placeholder={ t('Boutique') ?? '' } >
                                                                            <option selected>{ t('Boutique') }..</option>
                                                                            {
                                                                                shops.map(
                                                                                    (shop: any, id) => <option 
                                                                                    key={id} value={shop.id}>
                                                                                        {
                                                                                            shop.libBoutique
                                                                                        }
                                                                                    </option>
                                                                                )
                                                                            }
                                                                        </select> 
                                                                    }
                                                            </div>

                                                            <div className="form-group">
                                                                <div className="form-check form-check-inline">
                                                                    <input  className="form-check-input" type="checkbox" id="inlineCheckbox1"
                                                                        value="option1" />
                                                                    <label className="form-check-label" htmlFor="inlineCheckbox1">
                                                                       { t('Rapport avec détails') }
                                                                    </label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <br />
                                                        <div className="form-actions">
                                                            <div className="text-right">
                                                                <button  disabled={!isValid} type="submit" className="btn btn-info">
                                                                    { t('Valider') }
                                                                    {
                                                                        loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    }
                                                                </button>
                                                                <button type="button" onClick={event => cancel()}
                                                                 className="btn btn-dark">
                                                                    { t('Annuler') }
                                                                 </button>
                                                            </div>
                                                        </div>

                                                    </Form>
                                              )}
                                    </Formik>

                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-7">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Liste des associés</h4>
                                <div className="btn-list">
                                    <button type="button" onClick={ event => getAssociates() }
                                        className="btn waves-effect waves-light btn-success">Actualiser</button>
                                </div> 
                                <div className="table-responsive">
                                    <DataTable
                                        responsive={true}
                                        className="table table-striped table-bordered"
                                        columns={columns}
                                        data={associates}
                                        progressPending={loading}
                                        pagination
                                    />
                                </div>
                             </div>
                        </div> 
                    </div>  */}
                </div>

            </div>   
        </>                   
    );
}

export default TaxeReportPage