import { FC, useEffect, useRef, useState } from 'react'
import {
    Link
} from "react-router-dom";
import Breadcrumb from '../../../Components/Breadcrumb';


import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup';
import ConfigurationService from '../service';

import Type from "../../../Models/Type"

import DataTable from 'react-data-table-component';

import Alert from '../../../Components/Alert';
import { useAppDispatch, useAppSelector } from '../../../Hooks/customSelector';


// type FormValues = Type;

//Alert
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { useTranslation } from 'react-i18next';

const TypePage: FC = () => {  

    // const alert = useAppSelector((state: RootState ) => state.alerts );
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const formRef = useRef< FormikProps<Type>>(null);

    const [
        loading, setLoading
    ] = useState(false);

    const [
        Types,
        setTypes
    ] = useState([]);

    const initialSelectedType: any = null

    const [
        selectedType,
        setSelectedType
    ] = useState(initialSelectedType);

    const [
        deleteLoading, setDeleteLoading
    ] = useState(false);

   const id: Number = 0;
    const [
        deleteId, setDeleteId
    ] = useState(id);


    const configService = new ConfigurationService();

    const deleteType = async (TypeId: Number) => {
        setDeleteId(TypeId);
        setDeleteLoading(true);

        confirmAlert({
            overlayClassName: "alert-overlay",
            customUI: ({ onClose }) => {
              return (
                    <Alert 
                        alert={{
                            title: t('Confirmer'),
                            message: t('Vous confirmez la suppression de ce type'),
                            actionText:  `${t('Valider')}`  ,
                            action: async () => {
                                await configService.deleteType(TypeId).then(async function (response: any) {
                                    console.log(response); 
                                    confirmAlert({
                                        overlayClassName: "alert-overlay",
                                        customUI: ({ onClose }) => {
                                          return (
                                                <Alert 
                                                    alert={{
                                                        title: t('Succès'),
                                                        message: t('Type supprimé avec succès'),
                                                        actionText: 'Ok',
                                                        action: () => {
                                                            onClose();
                                                        }
                                                    }}
                                                />
                                          );
                                        }
                                    });
                                    getTypes();
                                    setDeleteLoading(false); 
                                })
                                  .catch(function (error: any) {
                                    console.log(error);
                                    setDeleteLoading(false); 
                                });
                                onClose();
                            },
                            cancelText: `${t("Annuler")}`,
                            cancelAction: () => {
                                setDeleteLoading(false); 
                                onClose();
                            }
                        }}
                    />
              );
            }
        });

    }

    const getTypes = () => {
        setLoading(true);
        configService.getTypes().then(async function (response: any) {
            console.log(response); 
            setTypes(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row.id
        },
        {
            name: t('Libellé'),
            selector: (row: any) => row.libType,
        },
        {
            name: t('Description'),
            selector: (row: any) => row.descriptionType,
        }, 
        {
            name: "Options", 
            selector: (row: any) => (
                <div className='btn-list'>
                    <button type="button" onClick={event => {
                        setSelectedType( Type.fromJson(row) );
                        if ( formRef.current != null) {
                            formRef.current.values.id = row.id;
                            formRef.current.values.libType =  row.libType;
                            formRef.current.values.descriptionType = row.descriptionType;
                        }  
                    }}
                        className="btn waves-effect waves-light btn-success">
                            <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-pencil"></i></span>
                    </button>
                    <button type="button" onClick={event => {  deleteType(row.id)  }}
                        className="btn waves-effect waves-light btn-danger">
                            {
                                deleteId == row.id && deleteLoading ?
                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> :
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-trash"></i></span>
                            }
                    </button>
                </div>
            )
        }
    ];

    const cancel = () => {

        setSelectedType(null);

        if ( formRef.current != null) {
            formRef.current.values.id = null;
            formRef.current.values.libType =  '';
            formRef.current.values.descriptionType = '';
        } 
    }

    useEffect(() => {
        getTypes();
    }, []);



    return ( 
        <>  
            <Breadcrumb />

            <div className="container-fluid">

                <div className="row">
                    <div className="col-md-5">
                        <div className="card">
                            <div className="card-body">

                                <h4 className="card-title">
                                    {
                                        selectedType == null ? 
                                        t("Ajouter un Type") :
                                        t("Mettre à jour Type")
                                    }
                                </h4>

                                <Formik
                                    initialValues={
                                        {
                                        id: null,
                                        libType: '',
                                        descriptionType: ''
                                    }}
                                    validationSchema={
                                        yup.object().shape({
                                            libType: yup
                                                .string() 
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            descriptionType: yup
                                                .string()
                                        })
                                    }
                                    onSubmit={async (
                                        values 
                                    ) => {

                                        setLoading(true);

                                        if(selectedType == null) {

                                            await configService.postType( Type.fromJson(values) ).then(async function (response: any) {
                                                console.log(response);

                                                confirmAlert({
                                                    overlayClassName: "alert-overlay",
                                                    customUI: ({ onClose }) => {
                                                      return (
                                                            <Alert 
                                                                alert={{
                                                                    title: t('Succès'),
                                                                    message: t('Type créé avec succès'),
                                                                    actionText: 'Ok',
                                                                    action: () => {
                                                                        onClose();
                                                                    }
                                                                }}
                                                            />
                                                      );
                                                    }
                                                }); 
                                                 
                                                if ( formRef.current != null) {
                                                    formRef.current.values.id = null;
                                                    formRef.current.values.libType =  '';
                                                    formRef.current.values.descriptionType = '';
                                                } 
                                                getTypes();
                                                setLoading(false); 
                                            })
                                            .catch(function (error: any) {
                                                setLoading(false); 
                                            });

                                        }  else {

                                            await configService.updateType( Type.fromJson(values) ).then(async function (response: any) {
                                                console.log(response); 
                                                confirmAlert({
                                                    overlayClassName: "alert-overlay",
                                                    customUI: ({ onClose }) => {
                                                      return (
                                                            <Alert 
                                                                alert={{
                                                                    title: t('Succès'),
                                                                    message: t('Type mis à jour avec succès'),
                                                                    actionText: 'Ok',
                                                                    action: () => {
                                                                        onClose();
                                                                    }
                                                                }}
                                                            />
                                                      );
                                                    }
                                                }); 

                                                if ( formRef.current != null) {
                                                    formRef.current.values.id = null;
                                                    formRef.current.values.libType =  '';
                                                    formRef.current.values.descriptionType = '';
                                                } 

                                                getTypes();
                                                setLoading(false); 
                                            })
                                            .catch(function (error: any) {
                                                setLoading(false); 
                                            });

                                        }

                                        
          
                                    }} 
                                    innerRef={formRef}
                                    >
                                        {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                                    <Form>
                                                        <div className="form-body">
                                                            <label> { t('Libellé') } </label>
                                                            <div className="row">
                                                                
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <input type="text"
                                                                            className={`form-control ${ errors.libType && touched.libType ? "is-invalid":""}`}
                                                                            id="libType" 
                                                                            name="libType" 
                                                                            onChange={handleChange('libType')}
                                                                            onBlur={handleBlur('libType')}
                                                                            value={values.libType}
                                                                            placeholder={ t('Libellé') ?? '' } />
                                                                        { errors.libType && touched.libType && errors.libType && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                            { errors.libType && touched.libType && errors.libType }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                            <label> { t('Description') } </label>
                                                            <div className="row">
                                                                
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <textarea rows={6}
                                                                            className={`form-control ${ errors.descriptionType && touched.descriptionType ? "is-invalid":""}`}
                                                                            id="descriptionType" 
                                                                            name="descriptionType" 
                                                                            onChange={handleChange('descriptionType')}
                                                                            onBlur={handleBlur('descriptionType')}
                                                                            value={values.descriptionType}
                                                                            placeholder={ t('Description') ?? '' } />
                                                                        { errors.descriptionType && touched.descriptionType && errors.descriptionType && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                            { errors.descriptionType && touched.descriptionType && errors.descriptionType }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                        </div>

                                                        <div className="form-actions">
                                                            <div className="text-right">
                                                                <button  disabled={!isValid} type="submit" className="btn btn-info">
                                                                    { t('Valider') }
                                                                    {
                                                                        loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    }
                                                                </button>
                                                                <button type="button" onClick={ event => cancel() }
                                                                 className="btn btn-dark">
                                                                    { t('Annuler') }
                                                                 </button>
                                                            </div>
                                                        </div>

                                                    </Form>
                                              )}
                                    </Formik>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">
                                    { t('Liste Types') }
                                </h4>
                                <div className="btn-list">
                                    <button type="button" onClick={ event => getTypes() }
                                        className="btn waves-effect waves-light btn-success">
                                            { t('Actualiser') }
                                        </button>
                                </div> 
                                <div className="table-responsive">
                                    <DataTable
                                        responsive={true}
                                        className="table table-striped table-bordered"
                                        columns={columns}
                                        data={Types}
                                        progressPending={loading}
                                        pagination
                                    />
                                </div>
                             </div>
                        </div> 
                    </div> 
                </div>

            </div>   
        </>                   
    );
}

export default TypePage