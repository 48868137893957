export default class Salary {

    public id?: number | null;
    public idSal?: string;
    public idBoutique?: string;
    public idAgent?: string;
    public idPayeur?: number | null;
    public montantSal?: string;
    public description?: string;


    static fromJson(parsedJson: Record<string, any>): Salary {
        return new Salary(
            Number(parsedJson['id']),
            parsedJson['idSal'],
            parsedJson['idBoutique'],
            parsedJson['idAgent'],
            parsedJson['idPayeur'],
            parsedJson['montantSal'],
            parsedJson['description']
        );
    }

    static toJson(sal: Salary): Record<string, any>  {
        return  {
            "id": sal.id,
            "idSal": sal.idSal,
            "idBoutique": sal.idBoutique,
            "idAgent": sal.idAgent,
            "idPayeur": sal.idPayeur,
            "montantSal": sal.montantSal,
            "description": sal.description
        };
    }


    constructor(id: number, idSal: string, idBoutique: string, idAgent: string,
        idPayeur: number, montantSal: string, description: string) {
        this.id = id;
        this.idSal = idSal;
        this.idBoutique = idBoutique;
        this.idAgent = idAgent;
        this.idPayeur = idPayeur;
        this.montantSal = montantSal;
        this.description = description;
    }
}