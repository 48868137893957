import { FC, useEffect, useRef, useState } from 'react'
import {
    Link
} from "react-router-dom";
import Breadcrumb from '../../../Components/Breadcrumb';


import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup';
import ConfigurationService from '../service';

import Gamme from "../../../Models/Gamme"

import DataTable from 'react-data-table-component';

import Alert from '../../../Components/Alert';
import { useAppDispatch, useAppSelector } from '../../../Hooks/customSelector';


// type FormValues = Gamme;

//Alert
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { useTranslation } from 'react-i18next';

const GammePage: FC = () => {  

    // const alert = useAppSelector((state: RootState ) => state.alerts );
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const formRef = useRef< FormikProps<Gamme>>(null);

    const [
        loading, setLoading
    ] = useState(false);

    const [
        gammes,
        setGammes
    ] = useState([]);

    const initialSelectedGamme: any = null

    const [
        selectedGamme,
        setSelectedGamme
    ] = useState(initialSelectedGamme);

    const [
        deleteLoading, setDeleteLoading
    ] = useState(false);

   const id: Number = 0;
    const [
        deleteId, setDeleteId
    ] = useState(id);


    const configService = new ConfigurationService();

    const deleteGamme = async (gammeId: Number) => {
        setDeleteId(gammeId);
        setDeleteLoading(true);

        confirmAlert({
            overlayClassName: "alert-overlay",
            customUI: ({ onClose }) => {
              return (
                    <Alert 
                        alert={{
                            title: ` ${t('Confirmer') } ` ,
                            message: ` ${t('Vous confirmez la suppression de cette gamme') } `,
                            actionText: 'Valider',
                            action: async () => {
                                await configService.deleteGamme(gammeId).then(async function (response: any) {
                                    console.log(response); 
                                   
                                    getGammes();
                                    setDeleteLoading(false); 

                                    confirmAlert({
                                        overlayClassName: "alert-overlay",
                                        customUI: ({ onClose }) => {
                                          return (
                                                <Alert 
                                                    alert={{
                                                        title:  `${t('Succès') }`,
                                                        message: `${t('Gamme supprimée avec succès') }`,
                                                        actionText: 'Ok',
                                                        action: () => {
                                                            onClose();
                                                        }
                                                    }}
                                                />
                                          );
                                        }
                                    });

                                })
                                  .catch(function (error: any) {
                                    console.log(error);
                                    setDeleteLoading(false); 
                                });
                                onClose();
                            },
                            cancelText: "Annuler",
                            cancelAction: () => {
                                setDeleteLoading(false); 
                                onClose();
                            }
                        }}
                    />
              );
            }
        });

    }

    const getGammes = () => {
        setLoading(true);
        configService.getGammes().then(async function (response: any) {
            console.log(response); 
            setGammes(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    const columns = [
        {
            name: t('Id'),
            selector: (row: any) => row.id
        },
        {
            name: t('Libellé'),
            selector: (row: any) => row.libGamme,
        },
        {
            name: t('Description'),
            selector: (row: any) => row.descriptionGamme,
        }, 
        {
            name: t("Options"), 
            selector: (row: any) => (
                <div className='btn-list'>
                    <button type="button" onClick={event => {
                        setSelectedGamme( Gamme.fromJson(row) );
                        if ( formRef.current != null) {
                            formRef.current.values.id = row.id;
                            formRef.current.values.libGamme =  row.libGamme;
                            formRef.current.values.descriptionGamme = row.descriptionGamme;
                        }  
                    }}
                        className="btn waves-effect waves-light btn-success">
                            <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-pencil"></i></span>
                    </button>
                    <button type="button" onClick={event => {  deleteGamme(row.id)  }}
                        className="btn waves-effect waves-light btn-danger">
                            {
                                deleteId == row.id && deleteLoading ?
                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> :
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-trash"></i></span>
                            }
                    </button>
                </div>
            )
        }
    ];


    const cancel = () => {

        setSelectedGamme(null);

        if ( formRef.current != null) {
            formRef.current.values.id = null;
            formRef.current.values.libGamme =  '';
            formRef.current.values.descriptionGamme = '';
        } 
    }

    useEffect(() => {
        getGammes();
    }, []);



    return ( 
        <>  
            <Breadcrumb />

            <div className="container-fluid">

                <div className="row">
                    <div className="col-md-5">
                        <div className="card">
                            <div className="card-body">

                                <h4 className="card-title">
                                    {
                                        selectedGamme == null ? 
                                        t("Ajouter une gamme") :
                                        t("Mettre à jour gamme")
                                    }
                                </h4>

                                <Formik
                                    initialValues={
                                        {
                                        id: null,
                                        libGamme: '',
                                        descriptionGamme: ''
                                    }}
                                    validationSchema={
                                        yup.object().shape({
                                            libGamme: yup
                                                .string() 
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            descriptionGamme: yup
                                                .string()
                                        })
                                    }
                                    onSubmit={async (
                                        values 
                                    ) => {

                                        setLoading(true);

                                        if(selectedGamme == null) {

                                            await configService.postGamme( Gamme.fromJson(values) ).then(async function (response: any) {
                                                console.log(response);

                                                confirmAlert({
                                                    overlayClassName: "alert-overlay",
                                                    customUI: ({ onClose }) => {
                                                      return (
                                                            <Alert 
                                                                alert={{
                                                                    title: t('Succès'),
                                                                    message: t('Gamme créée avec succès'),
                                                                    actionText: 'Ok',
                                                                    action: () => {
                                                                        onClose();
                                                                    }
                                                                }}
                                                            />
                                                      );
                                                    }
                                                });
 
                                                if ( formRef.current != null) {
                                                    formRef.current.values.id = null;
                                                    formRef.current.values.libGamme =  '';
                                                    formRef.current.values.descriptionGamme = '';
                                                } 
                                                getGammes();
                                                setLoading(false); 
                                            })
                                            .catch(function (error: any) {
                                                setLoading(false); 
                                            });

                                        }  else {

                                            await configService.updateGamme( Gamme.fromJson(values) ).then(async function (response: any) {
                                                console.log(response); 
                                                confirmAlert({
                                                    overlayClassName: "alert-overlay",
                                                    customUI: ({ onClose }) => {
                                                      return (
                                                            <Alert 
                                                                alert={{
                                                                    title: t('Succès'),
                                                                    message: t('Gamme mis à jour avec succès'),
                                                                    actionText: 'Ok',
                                                                    action: () => {
                                                                        onClose();
                                                                    }
                                                                }}
                                                            />
                                                      );
                                                    }
                                                }); 

                                                if ( formRef.current != null) {
                                                    formRef.current.values.id = null;
                                                    formRef.current.values.libGamme =  '';
                                                    formRef.current.values.descriptionGamme = '';
                                                } 

                                                setSelectedGamme(null);

                                                getGammes();
                                                setLoading(false); 
                                            })
                                            .catch(function (error: any) {
                                                setLoading(false); 
                                            });

                                        }

                                        
          
                                    }} 
                                    innerRef={formRef}
                                    >
                                        {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                                    <Form>
                                                        <div className="form-body">
                                                            <label> { t('Libellé') } </label>
                                                            <div className="row">
                                                                
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <input type="text"
                                                                            className={`form-control ${ errors.libGamme && touched.libGamme ? "is-invalid":""}`}
                                                                            id="libGamme" 
                                                                            name="libGamme" 
                                                                            onChange={handleChange('libGamme')}
                                                                            onBlur={handleBlur('libGamme')}
                                                                            value={values.libGamme}
                                                                            placeholder={t("Libellé") ?? ''} />
                                                                        { errors.libGamme && touched.libGamme && errors.libGamme && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                            { errors.libGamme && touched.libGamme && errors.libGamme }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                            <label> { t('Description') }  </label>
                                                            <div className="row">
                                                                
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <textarea rows={6}
                                                                            className={`form-control ${ errors.descriptionGamme && touched.descriptionGamme ? "is-invalid":""}`}
                                                                            id="descriptionGamme" 
                                                                            name="descriptionGamme" 
                                                                            onChange={handleChange('descriptionGamme')}
                                                                            onBlur={handleBlur('descriptionGamme')}
                                                                            value={values.descriptionGamme}
                                                                            placeholder="Description" />
                                                                        { errors.descriptionGamme && touched.descriptionGamme && errors.descriptionGamme && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                            { errors.descriptionGamme && touched.descriptionGamme && errors.descriptionGamme }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                        </div>

                                                        <div className="form-actions">
                                                            <div className="text-right">
                                                                <button  disabled={!isValid} type="submit" className="btn btn-info">
                                                                    { t('Valider') }
                                                                    {
                                                                        loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    }
                                                                </button>
                                                                <button type="button" onClick={event => cancel()}
                                                                 className="btn btn-dark">
                                                                    { t('Annuler') }
                                                                 </button>
                                                            </div>
                                                        </div>

                                                    </Form>
                                              )}
                                    </Formik>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">
                                    { t('Liste gammes') }
                                </h4>
                                <div className="btn-list">
                                    <button type="button" onClick={ event => getGammes() }
                                        className="btn waves-effect waves-light btn-success">
                                            { t('Actualiser') }
                                        </button>
                                </div> 
                                <div className="table-responsive">
                                    <DataTable
                                        responsive={true}
                                        className="table table-striped table-bordered"
                                        columns={columns}
                                        data={gammes}
                                        progressPending={loading}
                                        pagination
                                    />
                                </div>
                             </div>
                        </div> 
                    </div> 
                </div>

            </div>   
        </>                   
    );
}

export default GammePage