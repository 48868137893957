import User from '../../Models/User';
import axiosInstance from '../../Utils/axios_manager';

export default class DashboardService {
  
    constructor( ) {
    }

    public getDashboardMonthSells =  async () => {
        return await axiosInstance.get('/dashboard/month/sells' );
    }

    public getDashboardInfo =  async () => {
        return await axiosInstance.get('/admin/dashboard/info');
    }

    public setDebutTravail = (data: Record<string, any>) => {
        return axiosInstance.post('/work/start', data);
    }

    public setDebutPause = (data: Record<string, any>) => {
        return axiosInstance.post('/break/start', data);
    }

    public setFinPause = (data: Record<string, any>) => {
        return axiosInstance.post('/break/end', data);
    }

    public setFinTravail = (data: Record<string, any>) => {
        return axiosInstance.post('/work/end', data);
    }
  

}