import { FC, useEffect, useState, useRef } from 'react'
import {
    Link, useNavigate 
} from "react-router-dom";


import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 


import InventaireService from './service';
import User from '../../Models/User';

// React - Bootstrap
import { Tabs, Tab } from 'react-bootstrap';
import ShopService from '../Shop/service';

//Alert
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 


import Alert from '../../Components/Alert';

//Redux 
import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
import { RootState } from '../../Redux/store';
import ConfigurationService from '../Configuration/service';
import Gamme from '../../Models/Gamme';
import Type from '../../Models/Type';
import Category from '../../Models/Category';
import Product from '../../Models/Product';

import MarketManForm from './market-man';
import MarketAutoForm from './market-auto';
import { useTranslation } from 'react-i18next';

interface InvFormProps {
    state: string;
    formType: string;
    inventaire?: any | null; 
}

interface ExemplaireForm {
    prodBarcodeValue: string | null,
    gamme:  string | null ,
    type: string | null,
    categorie: string | null,
    product: string | null,
    qteInventaire: string,
    datefabrication: Date | null,
    dateperemption: Date | null
}

const InventaireForm: FC<InvFormProps> = ( {  state, inventaire, formType } ) => {
   

    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const exemplaireFormRef = useRef< FormikProps< ExemplaireForm >>(null);

    const [key, setKey] = useState('inventaire');

    let [idappro, setIdappro] = useState();

    const [ shops, setShops ] = useState( [] );

    const [ types, setTypes ] = useState( [] );

    const [ products, setProducts ] = useState( [] );

    const [
        gammes,
        setGammes
    ] = useState([]);

    let [loading, setLoading] = useState(false);

    let [searchLoading, setSearchLoading] = useState(false);

    let [shopsLoading, setShopsLoading] = useState(false);
    let [gammesLoading, setGammesLoading] = useState(false);

    let [ typesLoading, setTypesLoading ] = useState(false);

    let [ productsLoading, setProductsLoading ] = useState(false)

    let [activeExpl, setActiveExpl] = useState(false);
    let [activeTab, setActiveTab] = useState(false);

    const user = useAppSelector((state: RootState) => state.users.user );

    const inventaireService = new InventaireService();
    const configService = new ConfigurationService();
    const shopService = new ShopService();
 

    const getShops = () => {
        setShopsLoading(true);
        shopService.getShops().then(async function (response: any) {
            console.log(response);
            setShops(response.data);
            setShopsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setShopsLoading(false); 
        });
    }

    const getGammes = () => {
        setGammesLoading(true);
        configService.getGammes().then(async function (response: any) {
            console.log(response); 
            setGammes(response.data);
            setGammesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setGammesLoading(false); 
        });
    }

    const getBoutiqueById = (id: number)  => {
        const filteredBoutique = shops.filter(
          (boutique: any) => {return boutique.id === id;}
        );
        return filteredBoutique[0];
      }

    const cancel = () => {  }

    const getProductByBarcode = async (barcode: string) => {
        // exemplaireFormRef.current?.
        setLoading(true);
        if (exemplaireFormRef.current !== null) {
            await inventaireService.getProductByBarcode({
                barcode: barcode
            }).then(async function (response: any) {
                console.log(response);
                if (response.data !== 'failure' && response.data.length) {
                    const produit = response.data[0];
                    // this.formExamplaire.enable();
                    // exemplaireFormRef.current?.values.gamme = Number(produit.gamme_id));
                    // this.formExamplaire.controls['_type'].setValue(Number(produit.type_id));
                    // this.formExamplaire.controls['produit'].setValue(produit.id);
                    // this.formExamplaire.controls['prodBarcodeValue'].setValue(this.formBarcode.value.barcode);
                    // this.formExamplaire.disable();
                    // this.formExamplaire.controls['qteInventaire'].enable();
                    // this.formExamplaire.controls['datefabrication'].enable();
                    // this.formExamplaire.controls['dateperemption'].enable();
                    // this.formBarcode.reset();
                  } else {
                    confirmAlert({
                        overlayClassName: "alert-overlay",
                        customUI: ({ onClose }) => {
                          return (
                                <Alert 
                                    alert={{
                                        type: 'error',
                                        title: 'Ouppss',
                                        message: 'Pas de produit correspondant.',
                                        actionText: 'Ok',
                                        action: () => {
                                            onClose();
                                        }
                                    }}
                                />
                          );
                        }
                    });
                    // this.formExamplaire.enable();
                  }
                setLoading(false); 
            })
              .catch(function (error: any) {
                console.log(error);
                setLoading(false); 
            });
        }
    }

    useEffect( function() { 
        getShops();
        getGammes();
         
    }, []);
    
    

    return (
        <Tabs  id="uncontrolled-tab-example" 
            activeKey={key}
            onSelect={(k: any) => setKey(k)} >
            
            <Tab style={{ padding: 20 }} eventKey="inventaire" title={ t("Création inventaire") ?? '' }>
                <Link type="button" to="/boutique/create"
                                        className="btn waves-effect waves-light btn-outline-primary">
                    { t('Ajouter une boutique') }
                </Link>
                <br />
                <br />
                <div className="card card-body">
                <Formik
                                    initialValues={
                                        {
                                            idBoutique: null,
                                            DteInventaire: null
                                    }}
                                    validationSchema={
                                        yup.object().shape({
                                            idBoutique: yup
                                                .string() 
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            DteInventaire: yup
                                                .date() 
                                                .required(`${t('Ce champ est obligatoire')}`),
                                        })
                                    }
                                    onSubmit={async (
                                        values 
                                    ) => { 

                                        setLoading(true); 

                                        const shop = getBoutiqueById(Number(values.idBoutique));

                                        console.log(shop);

                                        await inventaireService.saveInventaire ({
                                            ...values,
                                            idBoutique: shop,
                                            idAgent: user
                                        }).then(async function (response: any) {
                                            console.log(response);

                                            window.localStorage.setItem('idappro', response.data[0].id);

                                            setIdappro(response.data[0].id);

                                            setLoading(false); 

                                            setActiveExpl(false);

                                            setKey('expl');

                                            // confirmAlert({
                                            //     overlayClassName: "alert-overlay",
                                            //     customUI: ({ onClose }) => {
                                            //       return (
                                            //             <Alert 
                                            //                 alert={{
                                            //                     title: 'Succès',
                                            //                     message: 'Inventaireisonnement créée avec succès',
                                            //                     actionText: 'Ok',
                                            //                     action: () => {
                                            //                         onClose();
                                            //                     }
                                            //                 }}
                                            //             />
                                            //       );
                                            //     }
                                            // });

                                             
                                            
                                        })
                                        .catch(function (error: any) {
                                            setLoading(false); 
                                        });



                                    }}  
                                >

                                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                <Form>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    { t('Boutique') }
                                                </label>
                                                        {
                                                            shopsLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <select  
                                                                className={`form-control custom-select ${ errors.idBoutique &&
                                                                    touched.idBoutique ? "is-invalid" : "" }`}
                                                                id="idBoutique" 
                                                                name="idBoutique"
                                                                onChange={event => { 
                                                                    handleChange('idBoutique')(event); 
                                                                }}
                                                                onBlur={handleBlur('agent')}
                                                                value={values.idBoutique == null ? '' : values.idBoutique}
                                                                placeholder={ t('Boutique') ?? '' } >
                                                                <option selected>{ t('Boutique') }...</option>
                                                                {
                                                                    shops.map(
                                                                        (shop: any, id) => <option 
                                                                         key={id} value={shop.id}>
                                                                            {
                                                                                shop.libBoutique
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </select> 
                                                        }
                                            </div>

                                            <div className="form-group">
                                                <label>
                                                    {
                                                        t('Date inventaire')
                                                    }
                                                </label>
                                                    <Field type="date"
                                                        className={`form-control ${ errors.DteInventaire && touched.DteInventaire ? "is-invalid":""}`}
                                                        id="DteInventaire" 
                                                        name="DteInventaire"
                                                        onChange={handleChange('DteInventaire')}
                                                        onBlur={handleBlur('DteInventaire')}
                                                        value={values.DteInventaire == null ? new Date()  : values.DteInventaire }
                                                        placeholder={ t('Date inventaire') ?? '' } />
                                                    { errors.DteInventaire && touched.DteInventaire && errors.DteInventaire && 
                                                <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.DteInventaire && touched.DteInventaire && errors.DteInventaire }
                                                </div> }
                                            </div>

                                        </div>
                                    </div>

                                    <div className="form-actions">
                                        <div className="text-right">
                                            <button  disabled={!isValid} type="submit" 
                                                className="btn btn-info">
                                                { t('Valider') ?? '' }
                                                {
                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                            <button type="button"
                                                onClick={event => cancel() }
                                            className="btn btn-dark">
                                                { t('Annuler') }
                                            </button>
                                        </div>
                                    </div>

                                    </Form>
                                    )}
                </Formik>
                </div>
            </Tab>
            <Tab style={{ padding: 20 }}  eventKey="expl" title={ t("Choix des produits") ?? '' } disabled={activeExpl}>
 
                 {/* <div className='row'> 
                    <div className='col-md-12' > */}
                      
                            <div className='row'>
                                <div className='col-md-12' >
                                    <div className="card card-body">

                                        {
                                            formType == 'manuel' ? 
                                            <MarketManForm 
                                                onProductAdd={ () => { }}
                                                onProductDelete={ () => { }}  /> : <></>
                                        } 
                                        {
                                            formType == 'automatique' ? 
                                            <MarketAutoForm 
                                                onProductAdd={ () => { }}
                                                onProductDelete={ () => { }}  /> : <></>
                                        } 
                                       
                                    </div>
                                </div>
                            </div>
                    {/* </div>
                 </div> */}
            </Tab>
            <Tab eventKey="tabl" title={ t("Tableau de finalisation") ?? '' } disabled={activeTab}>
                 
            </Tab>
        </Tabs>
    );
}

export default InventaireForm