import { FC, useEffect, useState, useTransition } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb'; 

import {
    Link, useNavigate
} from "react-router-dom";

//Moment react
import Moment from 'react-moment';
import AmortizmentService from './service';

// React - Bootstrap
import { Tabs, Tab } from 'react-bootstrap';


// React - Bootstrap
import { Modal } from 'react-bootstrap';

//Alert
import Alert from '../../Components/Alert'; 
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { useTranslation } from 'react-i18next';
 

const AmortizmentList: FC = () => {  
    let idToModInit: any = null;

    const { t } = useTranslation();

    const [key, setKey] = useState('other');

    const [ carAmortizments, setCarAmortizments ] = useState( [] );

    const [ otherAmortizments, setOtherAmortizments ] = useState( [] );


    const [ idToMod, setIdToMod ] = useState(idToModInit);

    const [ contentLoading, setContentLoading ] = useState(false); 

    const [ contentModal, setContentModal ] = useState(false);

    const [ carModal, setCarModal ] = useState(false);

    const [ loading, setLoading ] = useState(false);

    const initialCurrentAmortContents: any = null;

    const [ currentAmortContents, setCurrentAmortContents ] = useState(initialCurrentAmortContents);

    const initialCarDetails: any = null;

    const [ carDetails, setCarDetails ] = useState(initialCarDetails);



    const [ carLoading, setCarLoading ] = useState(false);
    const [ otherLoading, setOtherLoading ] = useState(false);

    const amortizmentService = new AmortizmentService();

    const navigate = useNavigate();

    let editAgent = (id: Number): void => {
        setIdToMod(id);
        setLoading(true);
        navigate('/agent/edit/'+id.toString());
        setLoading(false);
    }

    const getAmortizments = () => {
        getOtherAmortizments();
        getCarAmortizments();
    }

    const getCarAmortizments = () => {
        setCarLoading(true);
        amortizmentService.getCarAmortizments().then(async function (response: any) {
            console.log(response);
            setCarAmortizments(response.data.amortizations);
            setCarLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setCarLoading(false); 
        });
    }

    const getOtherAmortizments = () => {
        setOtherLoading(true);
        amortizmentService.getOtherAmortizments().then(async function (response: any) {
            console.log(response);
            setOtherAmortizments(response.data.amortizations);
            setOtherLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setOtherLoading(false); 
        });
    }

    const getAmortizmentContents = (row: any) => {
        setIdToMod(Number(row.id));
        setContentLoading(true)
        amortizmentService.getAmortizmentContents(Number(row.id)).then(async function (response: any) {
            console.log(response);
            
            setCurrentAmortContents({

                "amort": row,
                "contents": response.data.contents

            });

            console.log(currentAmortContents);

            setContentModal(true);

            setContentLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setContentLoading(false); 
        });
    }

    const getCarDetails = (row: any) => {
        setIdToMod(Number(row.amortization.id));
        setContentLoading(true);
        setCarDetails(row?.car);
        setCarModal(true);
        setContentLoading(false);
    }


    const deleteAmortization = async (amortId: Number) => {
        setIdToMod(amortId);
        setContentLoading(true);

        confirmAlert({
            overlayClassName: "alert-overlay",
            customUI: ({ onClose }) => {
              return (
                    <Alert 
                        alert={{
                            title: 'Ok',
                            message: 'Vous confirmez la suppression de cette amortisation',
                            actionText: 'Valider',
                            action: async () => {
                                await amortizmentService.deleteAmortization(amortId).then(async function (response: any) {
                                    console.log(response); 
                                   
                                    getAmortizments();
                                    setContentLoading(false); 

                                    confirmAlert({
                                        overlayClassName: "alert-overlay",
                                        customUI: ({ onClose }) => {
                                          return (
                                                <Alert 
                                                    alert={{
                                                        title: 'Succès',
                                                        message: 'Amortisation supprimée avec succès',
                                                        actionText: 'Ok',
                                                        action: () => {
                                                            onClose();
                                                        }
                                                    }}
                                                />
                                          );
                                        }
                                    });

                                })
                                  .catch(function (error: any) {
                                    console.log(error);
                                    setContentLoading(false); 
                                });
                                onClose();
                            },
                            cancelText: "Annuler",
                            cancelAction: () => {
                                setContentLoading(false); 
                                onClose();
                            }
                        }}
                    />
              );
            }
        });

    }




    useEffect( function() { 
        getAmortizments();
    }, []);

    const columnsCar = [ 
        {
            name: t('Libellé'),
            selector: (row: any) => row.amortization.libAmort,
        },
        {
            name: t("Montant payé"),
            selector: (row: any) => row.amortization.montantPayed,
        }, 
        {
            name: t("Montant total"),
            selector: (row: any) => row.amortization.montantTotal,
        }, 
        {
            name: t("Pourcentage"),
            selector: (row: any) => row.amortization.pourcentage,
        }, 
        {
            name: t("Durée"),
            selector: (row: any) => row.amortization.duree,
        }, 
        {
            name: t("Etat"),
            selector: (row: any) => <div style={{ height: "25px", width: "25px", borderRadius: "50%" }}
            className={ row.amortization !== null && row.amortization !== '' && row.amortization !== undefined ? Number(row.amortization?.pourcentage) < 50 ? 'less50' : (
                (Number(row.amortization?.pourcentage) >= 51 && Number(row.amortization?.pourcentage) <= 80) ? 'between51_80' :
                  'between81_100' ) : '' }>
                        </div>,
        }, 
        {
            name: t("Voiture"),
            selector: (row: any) =>  <button type="button" onClick={event => {
                                            getCarDetails(row);
                                        }} className="btn waves-effect waves-light btn-success">
                                        {

                                            idToMod == row.id && contentLoading ?
                                            <div className="spinner-border text-light spinner-border-sm" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div> :
                                            <span className="" style={{ fontSize: "1em" }} >
                                            <i className="fas fa-truck"></i></span>

                                        }  
                                        
                                    </button>
        }, 
        {
            name: t("Date de création"),
            selector: (row: any) => <Moment format="YYYY/MM/DD">
                                        {row.amortization.dateCreation}
                                    </Moment>
        }, 
        {
            name: "Options", 
            selector: (row: any) => (
                <>
                    <button type="button" onClick={event => {
                             getAmortizmentContents(row);
                        }}  className="btn waves-effect waves-light btn-success">
                                
                            {

                                idToMod == row.amortization.id && contentLoading ?
                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> :
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="fas fa-clipboard-list"></i></span>

                            }        
                    </button>
                    <button type="button" onClick={event => { deleteAmortization(Number(row.amortization.id)) }}
                        className="btn waves-effect waves-light btn-danger"> 

                            {

                                idToMod == row.amortization.id && contentLoading ?
                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> :
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-trash"></i></span>

                            }     
                                
                            
                    </button> 
                </>
            )
        }
    ];


    const columnsOther = [ 
        {
            name: t('Libellé'),
            selector: (row: any) => row.libAmort,
        },
        {
            name: t("Montant payé"),
            selector: (row: any) => row.montantPayed,
        }, 
        {
            name: t("Montant total"),
            selector: (row: any) => row.montantTotal,
        }, 
        {
            name: t("Pourcentage"),
            selector: (row: any) => row.pourcentage,
        }, 
        {
            name: t("Durée"),
            selector: (row: any) => row.duree,
        }, 
        {
            name: t("Etat"),
            selector: (row: any) => <div style={{ height: "25px", width: "25px", borderRadius: "50%" }}
                            className={  Number(row?.pourcentage) < 50 ? 'less50' : (
                                (Number(row?.pourcentage) >= 51 && Number(row?.pourcentage) <= 80) ? 'between51_80' :
                                  'between81_100' ) }>
                    </div>
        }, 
        // {
        //     name: "voiture",
        //     selector: (row: any) =>  <button type="button" onClick={event => {
        //                                    // navigate('/reduction/edit/'+row.id.toString());
        //                                 }}
        //                                     className="btn waves-effect waves-light btn-success">
        //                                 <span className="" style={{ fontSize: "1em" }} >
        //                                     <i className="icon-truck"></i></span>
        //                             </button>
        // }, 
        {
            name: t("Date de création"),
            selector: (row: any) => <Moment format="YYYY/MM/DD">
                                        {row.dateCreation}
                                    </Moment>
        }, 
        {
            name: "Options", 
            selector: (row: any) => (
                <>
                    <button type="button" onClick={event => {
                             getAmortizmentContents(row);
                        }}
                            className="btn waves-effect waves-light btn-success">
                                
                            {

                                idToMod == row.id && contentLoading ?
                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> :
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="fas fa-clipboard-list"></i></span>

                            }        
                    </button>
                    <button type="button" onClick={event => {  deleteAmortization(Number(row.id))  }}
                        className="btn waves-effect waves-light btn-danger"> 
                               {

                                idToMod == row.id && contentLoading ?
                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> :
                                <span className="" style={{ fontSize: "1em" }} >
                                    <i className="icon-trash"></i> </span>

                                } 
                            
                    </button> 
                </>
            )
        }
    ];


    const contentListColumns  = [ 
        {
            name: t('Montant total'),
            selector: (row: any) => row.montantTotal,
        },
        {
            name: t("Période"),
            selector: (row: any) => row.periode,
        }, 
        {
            name: t("Payé"),
            selector: (row: any) => <div style={{ height: "25px", width: "25px", borderRadius: "50%" }}
                                            className={ row.value ? 'between81_100' :  'less50' }>
                                    </div>,
        }, 
        {
            name: t("Date de payement"),
            selector: (row: any) => <Moment format="YYYY/MM/DD">
                                        {row.datePayment}
                                    </Moment>
        }, 
    ];
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-list">
                                    <button type="button" onClick={ event => getAmortizments() }
                                        className="btn waves-effect waves-light btn-danger">
                                            { t('Actualiser') }
                                        </button>
                                        <Link type="button" to="/amortissement/create"
                                            className="btn waves-effect waves-light btn-primary">
                                            { t('Ajouter un amortissement') }
                                        </Link>
                                    </div>
                                    
                                        <br />
                                        <br />
                                        <Tabs  id="uncontrolled-tab-example" 
                                            activeKey={key}
                                            onSelect={(k: any) => setKey(k)} >

                                            <Tab style={{ padding: 20 }} eventKey="other" title={ t("Autre") }>
                                                <div className="table-responsive">
                                                    <DataTable
                                                        responsive={true}
                                                        className="table table-striped table-bordered"
                                                        columns={ columnsOther }
                                                        data={ otherAmortizments }
                                                        progressPending={ otherLoading  }
                                                        pagination
                                                    />
                                                </div>
                                            </Tab>
                                            
                                            <Tab style={{ padding: 20 }} eventKey="car" title={ t("Voiture") }>
                                                <div className="table-responsive">
                                                    <DataTable
                                                        responsive={true}
                                                        className="table table-striped table-bordered"
                                                        columns={ columnsCar }
                                                        data={ carAmortizments }
                                                        progressPending={ carLoading  }
                                                        pagination
                                                    />
                                                </div>
                                            </Tab>
                                        </Tabs>        
                                    </div>
                                </div> 
                        </div> 
                    </div>

                </div> 

            {/* Contents list Modal */}

            <Modal 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={ contentModal }
                onHide={() => setContentModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('Tableau de remboursement')} {">"} <b>{ currentAmortContents?.amort?.libAmort }</b>
                    </Modal.Title> 
                </Modal.Header>
                {/* <div style={{ width: "100%", paddingTop: "8px", paddingRight: "8px" }}>
                                    <button style={{float: 'right'}} type="button" onClick={ event => exportToPdf() }
                                        className="btn waves-effect waves-light btn-success">
                                            Imprimer
                                            {
                                                paymentLoading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            }
                                    </button>
                </div>  */}
                <Modal.Body>
                    {
                        !(currentAmortContents?.contents.length > 0) ? 
                        <p className="text-center" >
                            <b>
                                { t('Pas de remboursement trouvé') }
                            </b>
                        </p>
                        :

                        <div className="table-responsive">
                                        <DataTable
                                            responsive={true}
                                            className="table table-striped table-bordered"
                                            columns={contentListColumns}
                                            data={currentAmortContents?.contents}
                                            progressPending={loading}
                                            pagination
                                        />
                        </div>

                    }
                    
                </Modal.Body>
                <Modal.Footer>
                {/* <Button onClick={props.onHide}>Close</Button> */}
                </Modal.Footer>
            </Modal>


            {/* Car details Modal */}

            <Modal 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={ carModal }
                onHide={() => setCarModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        { t('Détails voiture') }
                    </Modal.Title> 
                </Modal.Header>
                <Modal.Body>
                <ul style={{ listStyleType: 'none' }} >
                    <li>
                        <h4><strong>
                        { t('Marque') }
                        </strong></h4>
                        {
                        carDetails?.brandCar
                        }
                    </li>
                    <li>
                        <h4><strong>
                        { t('Modèle') }  
                        </strong></h4>
                        {
                        carDetails?.modelCar
                        }
                    </li>
                    <li>
                        <h4><strong>
                        { t('Couleur') }  
                        </strong></h4>
                        {
                        carDetails?.colorCar
                        }
                    </li>
                    <li>
                        <h4><strong>
                        { t('VIN ou chassis') }
                        </strong></h4>
                        {
                        carDetails?.vinCar
                        }
                    </li>
                    <li>
                        <h4><strong>
                        { t('Année d\'achat') }
                        </strong></h4>
                        {
                        carDetails?.yearPurchase
                        }
                    </li>
                    <li>
                        <h4><strong>
                        { t('Année de conception') }
                        </strong></h4>
                        {
                        carDetails?.yearConcep
                        }
                    </li>
                    <li>
                        <h4><strong>
                        { t('Montant assurance') }
                        </strong></h4>
                        {
                        carDetails?.montantAssur
                        }
                    </li>
                    <li>
                        <h4><strong>
                        { t('Index Tableau de bord à l\'achat') }
                        </strong></h4>
                        {
                        carDetails?.indexPurchase
                        }
                    </li>
                    <li>
                        <h4><strong>
                        { t("Index Tableau de bord à la release") }
                        </strong></h4>
                        {
                        carDetails?.indexRelease
                        }
                    </li>
                </ul>
                </Modal.Body>
                <Modal.Footer>
                {/* <Button onClick={props.onHide}>Close</Button> */}
                </Modal.Footer>
            </Modal>

            </>                   

    );
}

export default AmortizmentList