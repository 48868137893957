import { FC, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb'; 

import {
    Link, useNavigate
} from "react-router-dom";

//Moment react
import Moment from 'react-moment';
import TemplateService from './service';

// React - Bootstrap
import { Tabs, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const TemplateList: FC = () => {  
    let idToModInit: any = null;
    const { t } = useTranslation();

    const [key, setKey] = useState('uncompleted');

    const [ completedTemplates, setCompletedTemplates ] = useState( [] );

    const [ uncompletedTemplates, setUncompletedTemplates ] = useState( [] );


    const [ idToMod, setIdToMod ] = useState(idToModInit);

    const [ loading, setLoading ] = useState(false);

    const [ completedLoading, setCompletedLoading ] = useState(false);
    const [ uncompletedLoading, setUncompletedLoading ] = useState(false);

    const templateService = new TemplateService();

    const navigate = useNavigate();

    let editAgent = (id: Number): void => {
        setIdToMod(id);
        setLoading(true);
        navigate('/agent/edit/'+id.toString());
        setLoading(false);
    }

    const getTemplates = () => {
        getUncompletedTemplates();
        getCompletedTemplates();
    }

    const getCompletedTemplates = () => {
        setCompletedLoading(true);
        templateService.getCompletedTemplates().then(async function (response: any) {
            console.log(response);
            setCompletedTemplates(response.data.templates);
            setCompletedLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setCompletedLoading(false); 
        });
    }

    const getUncompletedTemplates = () => {
        setUncompletedLoading(true);
        templateService.getUncompletedTemplates().then(async function (response: any) {
            console.log(response);
            setUncompletedLoading(response.data.templates);
            setUncompletedLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setUncompletedLoading(false); 
        });
    }

    useEffect( function() { 
        getTemplates();
    }, []);

    const columns1 = [ 
        {
            name: t('Libellé'),
            selector: (row: any) => row.libTemplate,
        },
        {
            name: t("Description"),
            selector: (row: any) => row.descriptionTemplate,
        }, 
        {
            name: t("Créateur"),
            selector: (row: any) => row.sender.prenomAgent + ' ' + row.sender.nomAgent,
        }, 
        {
            name: t("Editeur"),
            selector: (row: any) => row.editor.prenomAgent + ' ' + row.editor.nomAgent,
        },
        {
            name: t("Date création"),
            selector: (row: any) => <Moment format="YYYY/MM/DD">
                                        {row.dateCreation}
                                    </Moment>
        },
        {
            name: t("Date traitement"),
            selector: (row: any) => <Moment format="YYYY/MM/DD">
                                        {row.dateTraitement}
                                    </Moment>
        },
        {
            name: t("Date validation"),
            selector: (row: any) => <Moment format="YYYY/MM/DD">
                                        {row.dateValidation}
                                    </Moment>
        },
        {
            name: "Options", 
            selector: (row: any) => (
                <>
                    <button type="button" onClick={event => {
                            navigate('/reduction/edit/'+row.id.toString());
                        }}
                            className="btn waves-effect waves-light btn-success">
                                <span className="" style={{ fontSize: "1em" }} >
                                    <i className="icon-pencil"></i></span>
                    </button>
                    <button type="button" onClick={event => {  }}
                        className="btn waves-effect waves-light btn-danger"> 
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-trash"></i></span>
                            
                    </button> 
                </>
            )
        }
    ];


    const columns2 = [ 
        {
            name: t('Libellé'),
            selector: (row: any) => row.libTemplate,
        },
        {
            name: t("Description"),
            selector: (row: any) => row.descriptionTemplate,
        }, 
        {
            name: t("Créateur"),
            selector: (row: any) => row.sender.prenomAgent + ' ' + row.sender.nomAgent,
        }, 
        {
            name: t("Editeur"),
            selector: (row: any) => row.editor.prenomAgent + ' ' + row.editor.nomAgent,
        },
        {
            name: t("Date création"),
            selector: (row: any) => <Moment format="YYYY/MM/DD">
                                        {row.dateCreation}
                                    </Moment>
        },
        {
            name: t("Date traitement"),
            selector: (row: any) => <Moment format="YYYY/MM/DD">
                                        {row.dateTraitement}
                                    </Moment>
        },
        {
            name: t("Date validation"),
            selector: (row: any) => <Moment format="YYYY/MM/DD">
                                        {row.dateValidation}
                                    </Moment>
        },
        {
            name: "Options", 
            selector: (row: any) => (
                <>
                    <button type="button" onClick={event => {
                            navigate('/reduction/edit/'+row.id.toString());
                        }}
                            className="btn waves-effect waves-light btn-success">
                                <span className="" style={{ fontSize: "1em" }} >
                                    <i className="icon-pencil"></i></span>
                    </button>
                    <button type="button" onClick={event => {  }}
                        className="btn waves-effect waves-light btn-danger"> 
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-trash"></i></span>
                            
                    </button> 
                </>
            )
        }
    ];
    
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-list">
                                    <button type="button" onClick={ event => getTemplates() }
                                        className="btn waves-effect waves-light btn-danger">
                                            { t('Actualiser') }
                                        </button>
                                        <Link type="button" to="/template/create"
                                            className="btn waves-effect waves-light btn-primary">
                                                {
                                                    t('Ajouter un template')
                                                }
                                        </Link>
                                    </div>
                                    
                                    <br />
                                    <br />
                                    <Tabs  id="uncontrolled-tab-example" 
                                        activeKey={key}
                                        onSelect={(k: any) => setKey(k)} >

                                        <Tab style={{ padding: 20 }} eventKey="uncompleted" title={t("Incomplètes")}>
                                            <div className="table-responsive">
                                                <DataTable
                                                    responsive={true}
                                                    className="table table-striped table-bordered"
                                                    columns={columns1}
                                                    data={ uncompletedTemplates }
                                                    progressPending={ uncompletedLoading  }
                                                    pagination
                                                />
                                            </div>
                                        </Tab>
                                        
                                        <Tab style={{ padding: 20 }} eventKey="completed" title={t("Complètes") }>
                                            <div className="table-responsive">
                                                <DataTable
                                                    responsive={true}
                                                    className="table table-striped table-bordered"
                                                    columns={columns2}
                                                    data={ completedTemplates }
                                                    progressPending={ completedLoading  }
                                                    pagination
                                                />
                                            </div>
                                        </Tab>
                                    </Tabs>        
                                    </div>
                                </div> 
                        </div> 
                    </div>

                </div>   
            </>                   

    );
}

export default TemplateList