import { FC, useEffect, useState, useTransition } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import VenteService from './service'; 

//Moment react
import Moment from 'react-moment';

import {
    Link, useNavigate
} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const VenteList: FC = () => {  

    let idToModInit: any = null;
    const [ ventes, setVentes ] = useState( [] );
    const [ idToMod, setIdToMod ] = useState(idToModInit);
    const [ loading, setLoading ] = useState(false);
    const venteService = new VenteService();

    const navigate = useNavigate();

    const { t } = useTranslation();

    let editAgent = (id: Number): void => {
        setIdToMod(id);
        setLoading(true);
        navigate('/agent/edit/'+id.toString());
        setLoading(false);
    }

    const getVentes = () => {
        setLoading(true);
        venteService.getVentes().then(async function (response: any) {
            console.log(response);
            // setVentes(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    useEffect(function() { 
        getVentes();
    }, []);

    const columns = [
        {
            name: '#',
            selector: (row: any) => row.id,
        },
        {
            name: `${ t('Boutique') }` ,
            selector: (row: any) => row.boutique.libBoutique,
        },
        {
            name: `${ t('Agent') }` ,
            selector: (row: any) => row.agent.prenomAgent + ' '+  row.agent.nomAgent,
        },
        {
            name: 'Date',
            selector: (row: any) => <Moment format="YYYY/MM/DD hh:mm:ss">
                                        {row.dateVente}
                                    </Moment>
        },
        {
            name: `${ t('Montant total') }`,
            selector: (row: any) => row.montantVente
        },
        {
            name: "Options", 
            selector: (row: any) => (
                <>
                    {/* <button type="button" onClick={event => {
                            navigate('/client/edit/'+row.id.toString());
                        }}
                            className="btn waves-effect waves-light btn-success">
                                <span className="" style={{ fontSize: "1em" }} >
                                    <i className="icon-pencil"></i></span>
                    </button>
                    <button type="button" onClick={event => {  }}
                        className="btn waves-effect waves-light btn-danger"> 
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-trash"></i></span>
                            
                    </button>  */}
                </>
            )
        }
    ];
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-list">
                                        <button type="button" onClick={ event => getVentes() }
                                        className="btn waves-effect waves-light btn-danger">
                                            { t('Actualiser') }
                                        </button>
                                        <Link type="button" to="/vente/create/manuel"
                                            className="btn waves-effect waves-light btn-outline-primary">
                                            { t('Vente manuelle') }    
                                        </Link>
                                        <Link type="button" to="/vente/create/automatique"
                                            className="btn waves-effect waves-light btn-outline-success">
                                            { t('Vente automatique') }    
                                        </Link>
                                    </div>
                                    
                                    <br />
                                    <br /> 

                                        <div className="table-responsive">
                                            <DataTable
                                                responsive={true}
                                                className="table table-striped table-bordered"
                                                columns={columns}
                                                data={ ventes }
                                                progressPending={loading}
                                                pagination
                                            />
                                        </div>

                                    </div>
                                </div> 
                        </div> 
                    </div>

                </div>   
            </>                   

    );
}

export default VenteList