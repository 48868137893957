import { FC, useEffect, useRef, useState } from 'react'
import {
    Link
} from "react-router-dom";
import Breadcrumb from '../../../Components/Breadcrumb';


import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup';
import ConfigurationService from '../service';

import Category from "../../../Models/Category"

import DataTable from 'react-data-table-component';

import Alert from '../../../Components/Alert';
import { useAppDispatch, useAppSelector } from '../../../Hooks/customSelector';


// type FormValues = Category;

//Alert
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { useTranslation } from 'react-i18next';

const CategoryPage: FC = () => {  

    // const alert = useAppSelector((state: RootState ) => state.alerts );
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    
    const formRef = useRef< FormikProps<Category>>(null);

    const [
        loading, setLoading
    ] = useState(false);

    const [
        categories,
        setCategories
    ] = useState([]);

    const initialSelectedCategory: any = null

    const [
        selectedCategory,
        setSelectedCategory
    ] = useState(initialSelectedCategory);

    const [
        deleteLoading, setDeleteLoading
    ] = useState(false);

   const id: Number = 0;
    const [
        deleteId, setDeleteId
    ] = useState(id);


    const configService = new ConfigurationService();

    const deleteCategory = async (CategoryId: Number) => {
        setDeleteId(CategoryId);
        setDeleteLoading(true);

        confirmAlert({
            overlayClassName: "alert-overlay",
            customUI: ({ onClose }) => {
              return (
                    <Alert 
                        alert={{
                            title: t('Confirmer'),
                            message: `${t('Vous confirmez la suppression de cette catégorie ?')}`,
                            actionText: `${t('Valider')}`,
                            action: async () => {
                                await configService.deleteCategory(CategoryId).then(async function (response: any) {
                                    console.log(response); 
                                   
                                    getCategories();
                                    setDeleteLoading(false); 

                                    confirmAlert({
                                        overlayClassName: "alert-overlay",
                                        customUI: ({ onClose }) => {
                                          return (
                                                <Alert 
                                                    alert={{
                                                        title: `${t('Succès')}`,
                                                        message: `${t('Categorie supprimée avec succès')}`,
                                                        actionText: 'Ok',
                                                        action: () => {
                                                            onClose();
                                                        }
                                                    }}
                                                />
                                          );
                                        }
                                    });
                                    
                                })
                                  .catch(function (error: any) {
                                    console.log(error);
                                    setDeleteLoading(false); 
                                });
                                onClose();
                            },
                            cancelText: "Annuler",
                            cancelAction: () => {
                                setDeleteLoading(false); 
                                onClose();
                            }
                        }}
                    />
              );
            }
        });

    }

    const getCategories = () => {
        setLoading(true);
        configService.getCategories().then(async function (response: any) {
            console.log(response); 
            setCategories(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row.id
        },
        {
            name: t('Libellé'),
            selector: (row: any) => row.libcat,
        },
        {
            name: t('Description'),
            selector: (row: any) => row.desccat,
        }, 
        {
            name: "Options", 
            selector: (row: any) => (
                <div className='btn-list'>
                    <button type="button" onClick={event => {
                        setSelectedCategory( Category.fromJson(row) );
                        if ( formRef.current != null) {
                            formRef.current.values.id = row.id;
                            formRef.current.values.libcat =  row.libcat;
                            formRef.current.values.desccat = row.desccat;
                        }  
                    }}
                        className="btn waves-effect waves-light btn-success">
                            <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-pencil"></i></span>
                    </button>
                    <button type="button" onClick={event => {  deleteCategory(row.id)  }}
                        className="btn waves-effect waves-light btn-danger">
                            {
                                deleteId == row.id && deleteLoading ?
                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> :
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-trash"></i></span>
                            }
                    </button>
                </div>
            )
        }
    ];

    const cancel = () => {

        setSelectedCategory(null);

        if ( formRef.current != null) {
            formRef.current.values.id = null;
            formRef.current.values.libcat =  '';
            formRef.current.values.desccat = '';
        } 

    }

    useEffect(() => {
        getCategories();
    }, []);

    return ( 
        <>  
            <Breadcrumb />

            <div className="container-fluid">

                <div className="row">
                    <div className="col-md-5">
                        <div className="card">
                            <div className="card-body">

                                <h4 className="card-title">
                                    {
                                        selectedCategory == null ? 
                                        t("Ajouter une Catégorie") :
                                        t("Mettre à jour Catégorie")
                                    }
                                </h4>

                                <Formik
                                    initialValues={
                                        {
                                        id: null,
                                        libcat: '',
                                        desccat: ''
                                    }}
                                    validationSchema={
                                        yup.object().shape({
                                            libcat: yup
                                                .string() 
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            desccat: yup
                                                .string()
                                        })
                                    }
                                    onSubmit={async (
                                        values 
                                    ) => {

                                        setLoading(true);

                                        if(selectedCategory == null) {

                                            await configService.postCategory( Category.fromJson(values) ).then(async function (response: any) {
                                                console.log(response);

                                                confirmAlert({
                                                    overlayClassName: "alert-overlay",
                                                    customUI: ({ onClose }) => {
                                                      return (
                                                            <Alert 
                                                                alert={{
                                                                    title: t('Succès'),
                                                                    message: t('Catégorie créée avec succès'),
                                                                    actionText: 'Ok',
                                                                    action: () => {
                                                                        onClose();
                                                                    }
                                                                }}
                                                            />
                                                      );
                                                    }
                                                });
 
                                                if ( formRef.current != null) {
                                                    formRef.current.values.id = null;
                                                    formRef.current.values.libcat =  '';
                                                    formRef.current.values.desccat = '';
                                                } 
                                                getCategories();
                                                setLoading(false); 
                                            })
                                            .catch(function (error: any) {
                                                setLoading(false); 
                                            });

                                        }  else {

                                            await configService.updateCategory( Category.fromJson(values) ).then(async function (response: any) {
                                                console.log(response); 
                                                confirmAlert({
                                                    overlayClassName: "alert-overlay",
                                                    customUI: ({ onClose }) => {
                                                      return (
                                                            <Alert 
                                                                alert={{
                                                                    title: t('Succès'),
                                                                    message: t('Catégorie mis à jour avec succès'),
                                                                    actionText: 'Ok',
                                                                    action: () => {
                                                                        onClose();
                                                                    }
                                                                }}
                                                            />
                                                      );
                                                    }
                                                }); 

                                                if ( formRef.current != null) {
                                                    formRef.current.values.id = null;
                                                    formRef.current.values.libcat =  '';
                                                    formRef.current.values.desccat = '';
                                                } 

                                                setSelectedCategory(null);

                                                getCategories();
                                                setLoading(false); 
                                            })
                                            .catch(function (error: any) {
                                                setLoading(false); 
                                            });

                                        }

                                        
          
                                    }} 
                                    innerRef={formRef}
                                    >
                                        {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                                    <Form>
                                                        <div className="form-body">
                                                            <label> { t('Libellé') } </label>
                                                            <div className="row">
                                                                
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <input type="text"
                                                                            className={`form-control ${ errors.libcat && touched.libcat ? "is-invalid":""}`}
                                                                            id="libcat" 
                                                                            name="libcat" 
                                                                            onChange={handleChange('libcat')}
                                                                            onBlur={handleBlur('libcat')}
                                                                            value={values.libcat}
                                                                            placeholder={ t('Libellé') ?? '' } />
                                                                        { errors.libcat && touched.libcat && errors.libcat && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                            { errors.libcat && touched.libcat && errors.libcat }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                            <label> {
                                                                t('Description') } </label>
                                                            <div className="row">
                                                                
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <textarea rows={6}
                                                                            className={`form-control ${ errors.desccat && touched.desccat ? "is-invalid":""}`}
                                                                            id="desccat" 
                                                                            name="desccat" 
                                                                            onChange={handleChange('desccat')}
                                                                            onBlur={handleBlur('desccat')}
                                                                            value={values.desccat}
                                                                            placeholder={ t('Description') ?? ''} />
                                                                        { errors.desccat && touched.desccat && errors.desccat && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                            { errors.desccat && touched.desccat && errors.desccat }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                        </div>

                                                        <div className="form-actions">
                                                            <div className="text-right">
                                                                <button  disabled={!isValid} type="submit" className="btn btn-info">
                                                                    { t('Valider') }
                                                                    {
                                                                        loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    }
                                                                </button>
                                                                <button type="button" onClick={ event => cancel() }
                                                                 className="btn btn-dark">
                                                                    { t('Annuler') }
                                                                 </button>
                                                            </div>
                                                        </div>

                                                    </Form>
                                              )}
                                    </Formik>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">
                                    { t('Liste Catégories') }
                                </h4>
                                <div className="btn-list">
                                    <button type="button" onClick={ event => getCategories() }
                                        className="btn waves-effect waves-light btn-success">
                                            { t('Actualiser') }
                                        </button>
                                </div> 
                                <div className="table-responsive">
                                    <DataTable
                                        responsive={true}
                                        className="table table-striped table-bordered"
                                        columns={columns}
                                        data={categories}
                                        progressPending={loading}
                                        pagination
                                    />
                                </div>
                             </div>
                        </div> 
                    </div> 
                </div>

            </div>   
        </>                   
    );
}

export default CategoryPage