import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import {
    Link, useLocation, useNavigate, useParams
} from "react-router-dom";
import Breadcrumb from '../../Components/Breadcrumb';
import PromotionForm from './form';
import PromotionService from './service';
import ClientService from './service';


const PromotionEdit: FC = () => {  
    const location: any = useLocation();
    const navigate = useNavigate();

    const { t } = useTranslation();

    const promotionService = new PromotionService();

    let { id } = useParams();

    const [promotion, setPromotion] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        promotionService.getPromotion(Number(id)).then(async function (response: any) {
            console.log(response); 
            setPromotion(response.data.promo);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }, []);

    return ( 
        <> 
            <Breadcrumb />

            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">
                                    {t('Modifier une promotion')}
                                </h4>
                                <Link type="button" to="/promotion/list"
                                    className="btn waves-effect waves-light btn-primary">
                                        {
                                            t('Liste des promotions')
                                        }
                                </Link>
                                <br />
                                <br />

                                { !loading && promotion ?
                                    <PromotionForm state='edit' promotion={ promotion } />  
                                    :
                                    <div className="spinner-border text-primary spinner-border-lg" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }     
                                 
                                </div>
                            </div> 
                    </div> 
                </div>

            </div>   
        </>                   
    );
}

export default PromotionEdit