import Salary from '../../Models/Salary';
import SalaryType from '../../Models/SalaryType';
import Shop from '../../Models/Shop';
import axiosInstance from '../../Utils/axios_manager';

export default class SalaryService {
  
    constructor() { }

    public postSalaryType =  (salaryType: SalaryType ) => {
        return axiosInstance.post('/salaire/add',  SalaryType.toJson(salaryType) );
    }

    public getSalaryTypes =  async () => {
        return await axiosInstance.get('/salaire/list');
    }

    public getShop =  async (id: Number) => {
        return await axiosInstance.get('/boutique/details' + `?id=${id.toString()}`);
    }

    public putSalaryType =  (salaryType: SalaryType ) => {
        return axiosInstance.post('/salaire/update', SalaryType.toJson(salaryType));
    }

    public deleteSalaryType  = ( salaryId: Number ) => {
        return axiosInstance.delete('/salaire/delete' + `?id=${salaryId}`);
    }

    ///// 

    public getSalaries =  async () => {
        return await axiosInstance.get('/salaires/list/unsupervised');
    }

    public postSalaries =  async (salary: Salary ) => {
        return axiosInstance.post('/salaires/add',  Salary.toJson(salary) );
    }

    public deleteSalary  = ( salaryId: Number ) => {
        return axiosInstance.delete('/salaires/delete' + `?id=${salaryId}`);
    }

    public filterSalaries =  async (data: Record<string, any>) => {
        return await axiosInstance.post('/salaires/list/periode', data);
    }

    public supervizeSalary =  async (data: Record<string, any>) => {
        return await axiosInstance.post('/salaires/supervise', data);
    }

    public getSupervizeSalaries =  async () => {
        return await axiosInstance.get('/salaires/list/supervised');
    }

}