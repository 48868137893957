import { FC, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import { useTranslation } from 'react-i18next';
//Moment react
import Moment from 'react-moment';

import {
    Link, useNavigate
} from "react-router-dom";

import InterestRepartitionService from './service';

const SupervizeWithdrawalList: FC = () => {  
    const { t, i18n } = useTranslation();
    let idToModInit: any = null;
    const [ withdrawals, setWithdrawals ] = useState( [] );
    const [ idToMod, setIdToMod ] = useState(idToModInit);
    const [ loading, setLoading ] = useState(false);
    const interestRepartitionService = new InterestRepartitionService();

    const navigate = useNavigate();

    let editAgent = (id: Number): void => {
        setIdToMod(id);
        setLoading(true);
        navigate('/agent/edit/'+id.toString());
        setLoading(false);
    }

    const getSupervizeWithdrawalList = () => {
        setLoading(true);
        interestRepartitionService.getSupervizeWithdrawalList().then(async function (response: any) {
            console.log(response);
            setWithdrawals(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    useEffect( function() { 
        getSupervizeWithdrawalList();
    }, []);

    const columns = [
        {
            name: t('Associé'),
            selector: (row: any) => row.agent.prenomAgent + ' ' + row.agent.nomAgent,
        }, 
        {
            name: t("Pourcentage"),
            selector: (row: any) => row.pourcentage,
        },
        {
            name: t("Date de début"),
            selector: (row: any) => <Moment format="YYYY/MM/DD">
                                        {row.dateDeb}
                                    </Moment>
        },
        {
            name: t("Date de fin"),
            selector: (row: any) => <Moment format="YYYY/MM/DD">
                                        {row.dateFin}
                                    </Moment>
        }, 
        {
            name: t('Date retrait'),
            selector: (row: any) => <Moment format="YYYY/MM/DD">
                                        {row.dateRet}
                                    </Moment>
        },
        {
            name: t('Date de supervision'),
            selector: (row: any) => <Moment format="YYYY/MM/DD">
                                        {row.dateSup}
                                    </Moment>
        }
    ];
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-list">
                                    <button type="button" onClick={ event => getSupervizeWithdrawalList() }
                                        className="btn waves-effect waves-light btn-danger">
                                            { t('Actualiser') }
                                        </button>
                                        {/* <Link type="button" to="/approvisionnement/create"
                                            className="btn waves-effect waves-light btn-primary">
                                                Ajouter un approvisonnement
                                        </Link> */}
                                    </div>
                                    
                                    <br />
                                    <br />
                                    {/* <h4 className="card-title">Zero Configuration</h4>
                                    <h6 className="card-subtitle">DataTables has most features enabled by default, so all you
                                        need to do to use it with your own tables is to call the construction
                                        function:<code> $().DataTable();</code>. You can refer full documentation from here
                                        <a href="https://datatables.net/">Datatables</a></h6> */}
                                    <div className="table-responsive">
                                        <DataTable
                                            responsive={true}
                                            className="table table-striped table-bordered"
                                            columns={columns}
                                            data={withdrawals}
                                            progressPending={loading}
                                            pagination
                                        />
                                        </div>
                                    </div>
                                </div> 
                        </div> 
                    </div>

                </div>   
            </>                   

    );
}

export default SupervizeWithdrawalList