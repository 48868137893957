import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import {
    Link, useLocation, useNavigate, useParams
} from "react-router-dom";
import Breadcrumb from '../../Components/Breadcrumb';
import ProductForm from './form';
import ProductService from './service';


const ProductEdit: FC = () => {  
    const location: any = useLocation();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const productService = new ProductService();

    let { id } = useParams();

    const [product, setProduct] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        productService.getProduct(Number(id)).then(async function (response: any) {
            console.log(response); 
            setProduct(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }, []);

    return ( 
        <> 
            <Breadcrumb />

            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">
                                    {
                                        t('Modifier un produit')
                                    }
                                </h4>
                                <Link type="button" to="/product/list"
                                    className="btn waves-effect waves-light btn-primary">
                                        {
                                            t('Liste des produits')
                                        }
                                </Link>
                                <br />
                                <br />

                                {   
                                    !loading && product ?
                                    <ProductForm state='edit' product={ product } />  
                                    :
                                    <div className="spinner-border text-primary spinner-border-lg" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }     
                                 
                                </div>
                            </div> 
                    </div> 
                </div>

            </div>   
        </>                   
    );
}

export default ProductEdit