import { FC, useEffect, useState, useRef } from 'react'
import {
    Link, useNavigate 
} from "react-router-dom";


import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 


import ApprovService from './service';
import User from '../../Models/User';

// React - Bootstrap
import { Tabs, Tab } from 'react-bootstrap';
import ShopService from '../Shop/service';

//Alert
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 


import Alert from '../../Components/Alert';

//Redux 
import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
import { RootState } from '../../Redux/store';
import ConfigurationService from '../Configuration/service';
import Gamme from '../../Models/Gamme';
import Type from '../../Models/Type';
import Category from '../../Models/Category';
import Product from '../../Models/Product';
import { useTranslation } from 'react-i18next';

interface ClientFormProps {
    state: string;
    approv?: any | null; 
}

interface ExemplaireForm {
    prodBarcodeValue: string | null,
    gamme:  string | null ,
    type: string | null,
    categorie: string | null,
    product: string | null,
    qteApprov: string,
    datefabrication: Date | null,
    dateperemption: Date | null
}

const ApprovForm: FC<ClientFormProps> = ( {  state, approv } ) => {
   

    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const exemplaireFormRef = useRef< FormikProps< ExemplaireForm >>(null);

    const [key, setKey] = useState('approv');

    let [idappro, setIdappro] = useState();

    const [ shops, setShops ] = useState( [] );

    const [ types, setTypes ] = useState( [] );

    const [ products, setProducts ] = useState( [] );

    const [
        gammes,
        setGammes
    ] = useState([]);

    let [loading, setLoading] = useState(false);

    let [searchLoading, setSearchLoading] = useState(false);

    let [shopsLoading, setShopsLoading] = useState(false);
    let [gammesLoading, setGammesLoading] = useState(false);

    let [ typesLoading, setTypesLoading ] = useState(false);

    let [ productsLoading, setProductsLoading ] = useState(false)

    let [activeExpl, setActiveExpl] = useState(false);
    let [activeTab, setActiveTab] = useState(false);

    const user = useAppSelector((state: RootState) => state.users.user );

    const approvService = new ApprovService();
    const configService = new ConfigurationService();
    const shopService = new ShopService();
 

    const getShops = () => {
        setShopsLoading(true);
        shopService.getShops().then(async function (response: any) {
            console.log(response);
            setShops(response.data);
            setShopsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setShopsLoading(false); 
        });
    }

    const getGammes = () => {
        setGammesLoading(true);
        configService.getGammes().then(async function (response: any) {
            console.log(response); 
            setGammes(response.data);
            setGammesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setGammesLoading(false); 
        });
    }

    const getBoutiqueById = (id: number)  => {
        const filteredBoutique = shops.filter(
          (boutique: any) => {return boutique.id === id;}
        );
        return filteredBoutique[0];
      }

    const cancel = () => {  }

    const getProductByBarcode = async (barcode: string) => {
        // exemplaireFormRef.current?.
        setLoading(true);
        if (exemplaireFormRef.current !== null) {
            await approvService.getProductByBarcode({
                barcode: barcode
            }).then(async function (response: any) {
                console.log(response);
                if (response.data !== 'failure' && response.data.length) {
                    const produit = response.data[0];
                    // this.formExamplaire.enable();
                    // exemplaireFormRef.current?.values.gamme = Number(produit.gamme_id));
                    // this.formExamplaire.controls['_type'].setValue(Number(produit.type_id));
                    // this.formExamplaire.controls['produit'].setValue(produit.id);
                    // this.formExamplaire.controls['prodBarcodeValue'].setValue(this.formBarcode.value.barcode);
                    // this.formExamplaire.disable();
                    // this.formExamplaire.controls['qteApprov'].enable();
                    // this.formExamplaire.controls['datefabrication'].enable();
                    // this.formExamplaire.controls['dateperemption'].enable();
                    // this.formBarcode.reset();
                  } else {
                    confirmAlert({
                        overlayClassName: "alert-overlay",
                        customUI: ({ onClose }) => {
                          return (
                                <Alert 
                                    alert={{
                                        type: 'error',
                                        title: 'Ouppss',
                                        message:  `${t('Pas de produit correspondant')}`,
                                        actionText: 'Ok',
                                        action: () => {
                                            onClose();
                                        }
                                    }}
                                />
                          );
                        }
                    });
                    // this.formExamplaire.enable();
                  }
                setLoading(false); 
            })
              .catch(function (error: any) {
                console.log(error);
                setLoading(false); 
            });
        }
    }

    useEffect( function() { 
        getShops();
        getGammes();
         
    }, []);
    
    

    return (
        <Tabs   id="uncontrolled-tab-example" 
            activeKey={key}
            onSelect={(k: any) => setKey(k)} >
            
            <Tab style={{ padding: 20 }} eventKey="approv" title={ t("Approvisionnement") ?? ''} >
                <Link type="button" to="/boutique/create"
                                        className="btn waves-effect waves-light btn-outline-primary">
                    { t('Ajouter une boutique') }
                </Link>
                <br />
                <br />
                <div className="card card-body">
                <Formik
                                    initialValues={
                                        {
                                            idBoutique: null,
                                            DteApprov: null
                                    }}
                                    validationSchema={
                                        yup.object().shape({
                                            idBoutique: yup
                                                .string() 
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            DteApprov: yup
                                                .date() 
                                                .required(`${t('Ce champ est obligatoire')}`),
                                        })
                                    }
                                    onSubmit={async (
                                        values 
                                    ) => { 

                                        setLoading(true); 

                                        const shop = getBoutiqueById(Number(values.idBoutique));

                                        console.log(shop);

                                        await approvService.saveApprov ({
                                            ...values,
                                            idBoutique: shop,
                                            idAgent: user
                                        }).then(async function (response: any) {
                                            console.log(response);

                                            window.localStorage.setItem('idappro', response.data[0].id);

                                            setIdappro(response.data[0].id);

                                            setLoading(false); 

                                            setActiveExpl(false);

                                            setKey('expl');

                                            // confirmAlert({
                                            //     overlayClassName: "alert-overlay",
                                            //     customUI: ({ onClose }) => {
                                            //       return (
                                            //             <Alert 
                                            //                 alert={{
                                            //                     title: 'Succès',
                                            //                     message: 'Approvisonnement créée avec succès',
                                            //                     actionText: 'Ok',
                                            //                     action: () => {
                                            //                         onClose();
                                            //                     }
                                            //                 }}
                                            //             />
                                            //       );
                                            //     }
                                            // });

                                             
                                            
                                        })
                                        .catch(function (error: any) {
                                            setLoading(false); 
                                        });



                                    }}  
                                >

                                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                <Form>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label> { t('Boutique') } </label>
                                                        {
                                                            shopsLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <select  
                                                                className={`form-control custom-select ${ errors.idBoutique &&
                                                                    touched.idBoutique ? "is-invalid" : "" }`}
                                                                id="idBoutique" 
                                                                name="idBoutique"
                                                                onChange={event => { 
                                                                    handleChange('idBoutique')(event); 
                                                                }}
                                                                onBlur={handleBlur('agent')}
                                                                value={values.idBoutique == null ? '' : values.idBoutique}
                                                                placeholder={ t('Boutique') ?? ''} >
                                                                <option selected>{ t('Boutique') }..</option>
                                                                {
                                                                    shops.map(
                                                                        (shop: any, id) => <option 
                                                                         key={id} value={shop.id}>
                                                                            {
                                                                                shop.libBoutique
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </select> 
                                                        }
                                            </div>

                                            <div className="form-group">
                                                <label>
                                                    { t('Date approvisionnement') }
                                                </label>
                                                    <Field type="date"
                                                        className={`form-control ${ errors.DteApprov && touched.DteApprov ? "is-invalid":""}`}
                                                        id="DteApprov" 
                                                        name="DteApprov"
                                                        onChange={handleChange('DteApprov')}
                                                        onBlur={handleBlur('DteApprov')}
                                                        value={values.DteApprov == null ? new Date()  : values.DteApprov }
                                                        placeholder={ t('Date approvisionnement') } />
                                                    { errors.DteApprov && touched.DteApprov && errors.DteApprov && 
                                                <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.DteApprov && touched.DteApprov && errors.DteApprov }
                                                </div> }
                                            </div>

                                        </div>
                                    </div>

                                    <div className="form-actions">
                                        <div className="text-right">
                                            <button  disabled={!isValid} type="submit" 
                                                className="btn btn-info">
                                                { t('Valider') }
                                                {
                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                            <button type="button"
                                                onClick={event => cancel() }
                                            className="btn btn-dark">{ t('Annuler') }</button>
                                        </div>
                                    </div>

                                    </Form>
                                    )}
                </Formik>
                </div>
            </Tab>
            <Tab style={{ padding: 20 }}  eventKey="expl" title={ t("Exemplaires") ?? '' } disabled={activeExpl}>
                 <div className='btn-list'>
                    <Link type="button" to="/product/create"
                                            className="btn waves-effect waves-light btn-outline-primary ">
                        { t('Ajouter un produit') }
                    </Link>
                    <Link type="button" to="/configuration/gamme"
                                            className="btn waves-effect waves-light btn-outline-primary ">
                        { t('Ajouter une gamme') } 
                    </Link>
                    <Link type="button" to="/configuration/type"
                                            className="btn waves-effect waves-light btn-outline-primary">
                        { t('Ajouter un type') } 
                    </Link>
                 </div>
                 <br />
                 <div className='row'> 
                    <div className='col-md-12' >
                            <button style={{ float: 'right' }} className="btn btn-danger ml-1" type="button" data-toggle="collapse"
                                    data-target="#collapseExample" aria-expanded="false"
                                    aria-controls="collapseExample">
                                { t('Chercher un produit') }
                            </button> 
                            <br/><br/>
                            <hr/>
                            <div className="collapse hide" id="collapseExample">
                                <div className='row'>
                                    <div className='col-md-2'></div>
                                    <div className='col-md-8'>

                                    
                                            <div className="card card-body">
                                            
                                                <Formik
                                                    initialValues={
                                                        {
                                                            barcode: null
                                                    }}
                                                    validationSchema={
                                                        yup.object().shape({
                                                            barcode: yup
                                                                .string() 
                                                                .required(`${t('Ce champ est obligatoire')}`), 
                                                        })
                                                    }
                                                    onSubmit={async (
                                                        values 
                                                    ) => { 

                                                        getProductByBarcode(values.barcode == null ? '' : values.barcode);

                                                    }}  
                                                >

                                                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                                <Form> 

                                                            <div className="form-group">
                                                                <label>
                                                                    { t('Chercher le produit par barcode') }
                                                                </label>
                                                                    <input type="text"
                                                                        className={`form-control ${ errors.barcode && touched.barcode ? "is-invalid":""}`}
                                                                        id="barcode" 
                                                                        name="barcode"
                                                                        onChange={handleChange('barcode')}
                                                                        onBlur={handleBlur('barcode')}
                                                                        value={values.barcode == null ? ''  : values.barcode }
                                                                        placeholder="Barcode" />
                                                                    { errors.barcode && touched.barcode && errors.barcode && 
                                                                <div id="validationServer05Feedback" className="invalid-feedback">
                                                                        { errors.barcode && touched.barcode && errors.barcode }
                                                                </div> }
                                                            </div>

                                                         

                                                    <div className="form-actions">
                                                        <div className="text-right">
                                                            <button  disabled={!isValid} type="submit" 
                                                                className="btn btn-danger">
                                                                { t(' Valider') }
                                                                {
                                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                }
                                                            </button>
                                                            {/* <button type="button"
                                                                onClick={event => cancel() }
                                                            className="btn btn-dark">Annuler</button> */}
                                                        </div>
                                                    </div>

                                                    </Form>
                                                    )}
                                                </Formik>

                                            </div>
                                        </div>
                                    </div>    
                            </div>
                            <hr/>
                            <div className='row'>
                                <div className='col-md-2'></div>
                                <div className='col-md-8'>
                                    <div className="card card-body">
                                        <Formik
                                                    initialValues={
                                                        { 
                                                            prodBarcodeValue: null,
                                                            gamme: null,
                                                            type: null,
                                                            categorie: null,
                                                            product: null,
                                                            qteApprov: '',
                                                            datefabrication: null,
                                                            dateperemption: null
                                                    }}
                                                    validationSchema={
                                                        yup.object().shape({ 
                                                            prodBarcodeValue: yup
                                                                .string() 
                                                                .required(`${t('Ce champ est obligatoire')}`),
                                                            gamme: yup
                                                                .string() 
                                                                .required(`${t('Ce champ est obligatoire')}`),
                                                            type: yup
                                                                .string() 
                                                                .required(`${t('Ce champ est obligatoire')}`),
                                                            categorie: yup
                                                                .string() 
                                                                .required(`${t('Ce champ est obligatoire')}`),
                                                            product: yup
                                                                .string()  
                                                                .required(`${t('Ce champ est obligatoire')}`),
                                                            qteApprov: yup
                                                                .string()
                                                                .required(`${t('Ce champ est obligatoire')}`),
                                                            datefabrication: yup
                                                                .date()
                                                                .required(`${t('Ce champ est obligatoire')}`),
                                                            dateperemption: yup
                                                                .date()
                                                                .required(`${t('Ce champ est obligatoire')}`),
                                                        })
                                                    }
                                                    innerRef={exemplaireFormRef} 
                                                    onSubmit={async (
                                                        values 
                                                    ) => { 

                                                        

                                                    }}  
                                                >

                                                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                                <Form> 

                                                        <div className="form-group">
                                                                <label>
                                                                    { t('Barcode produit') }
                                                                </label>
                                                                    <input type="text"
                                                                        className={`form-control ${ 
                                                                            errors.prodBarcodeValue && touched.prodBarcodeValue ? "is-invalid":""}`}
                                                                        id="prodBarcodeValue" 
                                                                        name="prodBarcodeValue"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('prodBarcodeValue')(event);
                                                                            // getProductByBarcode();
                                                                        }}
                                                                        onBlur={handleBlur('prodBarcodeValue')}
                                                                        value={values.prodBarcodeValue == null ? ''  :
                                                                         values.prodBarcodeValue }
                                                                        placeholder={ t('Barcode produit') ?? '' } />
                                                                    { errors.prodBarcodeValue && touched.prodBarcodeValue &&
                                                                     errors.prodBarcodeValue && 
                                                                <div id="validationServer05Feedback" className="invalid-feedback">
                                                                        { errors.prodBarcodeValue && touched.prodBarcodeValue &&
                                                                         errors.prodBarcodeValue }
                                                                </div> }
                                                        </div>

                                                        <div className="form-group">
                                                        <label>
                                                            { t('Gamme') ?? '' }
                                                        </label>
                                                        {
                                                            gammesLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                             <Field as="select"  
                                                                className={`form-control custom-select ${ errors.gamme &&
                                                                    touched.gamme ? "is-invalid" : "" }`}
                                                                id="gamme" 
                                                                name="gamme"
                                                                onChange={(event: any) => {
                                                                    // console.log(event);
                                                                    handleChange('gamme')(event);
                                                                    // const agent = getAgentById(event.target.value);
                                                                    // console.log(agent);
                                                                    // setSelectedAgent( agent );
                                                                }}
                                                                onBlur={handleBlur('gamme')}
                                                                value={ values.gamme }
                                                                placeholder={ t('Gamme') ?? '' } >
                                                                <option selected>{ t('Gamme') ?? '' }...</option>
                                                                {
                                                                    gammes.map(
                                                                        (gamme: any, id) => <option 
                                                                         key={id} value={gamme.id}>
                                                                            {
                                                                                gamme.libGamme
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </Field> 
                                                        }
                                                        { errors.gamme && touched.gamme && errors.gamme && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.gamme && touched.gamme && errors.gamme }
                                                        </div> }
                                                    </div>



                                                    <div className="form-group">
                                                        <label>
                                                            { t('Type') }
                                                        </label>
                                                        {
                                                            typesLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm"
                                                             role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <Field disabled={ exemplaireFormRef.current?.values.gamme == null } as="select"  
                                                                className={`form-control custom-select ${ errors.type &&
                                                                    touched.type ? "is-invalid" : "" }`}
                                                                id="type" 
                                                                name="type"
                                                                onChange={(event: any) => {
                                                                    // console.log(event);
                                                                    handleChange('type')(event);
                                                                    // const agent = getAgentById(event.target.value);
                                                                    // console.log(agent);
                                                                    // setSelectedAgent( agent );
                                                                }}
                                                                onBlur={handleBlur('type')}
                                                                value={values.type}
                                                                placeholder={ t('Type') } >
                                                                <option selected>{ t('Type') }...</option>
                                                                {
                                                                    types.map(
                                                                        (type: any, id) => <option 
                                                                         key={id} value={type.id}>
                                                                            {
                                                                                type.libType
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </Field> 
                                                        }
                                                    { errors.type && touched.type && errors.type && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.type && touched.type && errors.type }
                                                    </div> }
                                                </div>


                                                <div className="form-group">
                                                        <label>
                                                            { t('Produit') }
                                                        </label>
                                                        {
                                                            typesLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm"
                                                             role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <Field disabled={ exemplaireFormRef.current?.values.type == null } as="select"  
                                                                className={`form-control custom-select ${ errors.product &&
                                                                    touched.product ? "is-invalid" : "" }`}
                                                                id="product" 
                                                                name="product"
                                                                onChange={(event: any) => {
                                                                    // console.log(event);
                                                                    handleChange('product')(event);
                                                                    // const agent = getAgentById(event.target.value);
                                                                    // console.log(agent);
                                                                    // setSelectedAgent( agent );
                                                                }}
                                                                onBlur={handleBlur('product')}
                                                                value={values.product}
                                                                placeholder={ t('Produit') } >
                                                                <option selected>{ t('Produit') }...</option>
                                                                {
                                                                    products.map(
                                                                        (product: any, id) => <option 
                                                                         key={id} value={product.id}>
                                                                            {
                                                                                product.libProduit
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </Field> 
                                                        }
                                                    { errors.product && touched.product && errors.product && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.product && touched.product && errors.product }
                                                    </div> }
                                                </div>

                                                <div className="form-group">
                                                    <label>
                                                        { t('Quantité') }
                                                    </label>
                                                    <input type="number"
                                                        className={`form-control 
                                                        ${ errors.qteApprov  && touched.qteApprov ? "is-invalid":""}`}
                                                        id="qteApprov" 
                                                        name="qteApprov"  
                                                        onChange={handleChange('qteApprov')}
                                                        onBlur={handleBlur('qteApprov')}
                                                        value={  values.qteApprov }
                                                        placeholder={ t('Quantité') ?? ''} />
                                                        { errors.qteApprov && touched.qteApprov && errors.qteApprov && 
                                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                                { errors.qteApprov && touched.qteApprov && errors.qteApprov }
                                                            </div> 
                                                        }
                                                </div>

                                                <div className="form-group">
                                                    <label>
                                                        { t('Date de fabrication') }
                                                    </label>
                                                        <Field type="date"
                                                            className={`form-control ${ errors.datefabrication &&
                                                                 touched.datefabrication ? "is-invalid":""}`}
                                                            id="datefabrication" 
                                                            name="datefabrication"
                                                            onChange={handleChange('datefabrication')}
                                                            onBlur={handleBlur('datefabrication')}
                                                            value={values.datefabrication == null ? new Date()  :
                                                                 values.datefabrication }
                                                            placeholder={ t('Date de fabrication') } />
                                                        { errors.datefabrication && touched.datefabrication && errors.datefabrication && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.datefabrication && touched.datefabrication && 
                                                            errors.datefabrication }
                                                    </div> }
                                                </div>

                                                <div className="form-group">
                                                    <label>
                                                        { t('Date de péremption') }
                                                    </label>
                                                        <Field type="date"
                                                            className={`form-control ${ errors.dateperemption &&
                                                                 touched.dateperemption ? "is-invalid":""}`}
                                                            id="dateperemption" 
                                                            name="dateperemption"
                                                            onChange={handleChange('dateperemption')}
                                                            onBlur={handleBlur('dateperemption')}
                                                            value={values.dateperemption == null ? new Date()  :
                                                                 values.dateperemption }
                                                            placeholder={ t('Date de péremption') } />
                                                        { errors.dateperemption && touched.dateperemption && errors.dateperemption && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.dateperemption && touched.dateperemption && 
                                                            errors.dateperemption }
                                                    </div> }
                                                </div>

                                                         

                                                    <div className="form-actions">
                                                        <div className="text-right">
                                                            <button  disabled={!isValid} type="submit" 
                                                                className="btn btn-info">
                                                                {
                                                                    t('Valider')
                                                                }
                                                                {
                                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                }
                                                            </button>
                                                            <button type="button"
                                                                onClick={event => cancel() }
                                                            className="btn btn-dark">
                                                                { t('Annuler') }
                                                            </button>
                                                        </div>
                                                    </div>

                                                    </Form>
                                                    )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                    </div>
                 </div>
            </Tab>
            <Tab eventKey="tabl" title={ t("Tableau de finalisation") ?? ''} disabled={activeTab}>
                 
            </Tab>
        </Tabs>
    );
}

export default ApprovForm