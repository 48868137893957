import uuid from 'react-uuid';
import { FC, useEffect, useState, useRef, useTransition } from 'react'
import {
    Link, useNavigate 
} from "react-router-dom";
import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup';
import { string } from 'yup/lib/locale';
import ProductService from './service';
import User from '../../Models/User';
import Product from '../../Models/Product';
import ConfigurationService from '../Configuration/service';
import Gamme from '../../Models/Gamme';
import Type from '../../Models/Type';
import Category from '../../Models/Category'; 
import Alert from '../../Components/Alert';

//Alert
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';

interface ProductFormProps {
    state: string;
    product?: Product | null; 
}

interface ProductFormProps {
    state: string;
    product?: Product | null; 
}

interface DescriptionElement {
    title: string;
    textContent: string
}

const ProductForm: FC< ProductFormProps > = ( {  state, product } ) => { 
       

    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const formRef = useRef< FormikProps< Product >>(null);

    let [image, setImage] = useState('');

    let [descriptionImage, setDescriptionImage] = useState('');

    let galeryFileInit: any;
    let [currentGaleryFile, setCurrentGaleryFile] = useState(galeryFileInit);

    let galeryIdInit: any = null;
    let [galId, setGalId] = useState(galeryIdInit);


    let productGalIdInit: any = null;
    let [productGalId, setProductGalId] = useState(productGalIdInit);

    let initialIngredNames: Array<string> = [];
    let [ingredNames, setIngredNames] = useState(initialIngredNames);
    let [ingredName, setIngredName] = useState('');


    let initialGalerie: Array<string> = [];
    let [galerie, setGalerie] = useState(initialGalerie);

    // Loading
    let [loading, setLoading] = useState(false);

    // Gal file loading
    let [galLoading, setGalLoading] = useState(false);

    // Types, Gammes, Categories loading
    let [typesLoading, setTypesLoading] = useState(false);
    let [gammesLoading, setGammesLoading] = useState(false);
    let [categoriesLoading, setCategoriesLoading] = useState(false);

    // Types, Gammes, Categories
    let [types, setTypes] = useState([]);
    let [gammes, setGammes] = useState([]);
    let [categories, setCategories] = useState([]);

    const initialTags: Array<any> = [];
    let [ tags, setTags ] = useState(initialTags);

    const initialContents: Array<{ id?: any, title: string, textContent: string }> = [];
    let [ contents, setContents ] = useState(initialContents);

    let contentToModInit: { id?: any, title: string, textContent: string } | null | any = null;
    let [ contentToMod, setContentToMod ] = useState(contentToModInit);

    // Services
    const productService = new ProductService();
    const configService = new ConfigurationService();
    
    const readThis = (inputValue: any): void => {
        var file: File = inputValue.files[0];
        var lecteur: FileReader = new FileReader();
    
        lecteur.onloadend = (e) => {
            setImage( lecteur.result as string );
            // if (Object.prototype.toString.call(lecteur.result) === "[object String]") {
                
            // }
            
            console.log(lecteur.result);
        }
        lecteur.readAsDataURL(file);
    }

    const changeListener = (event: any)  => {
        readThis(event.target);
    }

    const changeListenerDescriptionImage = (event: any)  => {
        readDescriptionImage(event.target);
    }

    const readDescriptionImage = (inputValue: any): void => {
        var file: File = inputValue.files[0];
        var lecteur: FileReader = new FileReader();
    
        lecteur.onloadend = (e) => {
            setDescriptionImage( lecteur.result as string );
            // if (Object.prototype.toString.call(lecteur.result) === "[object String]") {
                
            // }
            
            console.log(lecteur.result);
        }
        lecteur.readAsDataURL(file);
    }

    const readThisGalery = (inputValue: any): void => {
        var file: File = inputValue.files[0];
        var lecteur: FileReader = new FileReader();
    
        lecteur.onloadend = (e) => {

            let media  = lecteur.result as string;
            if (!(['png', 'jpg', 'PNG', 'JPG', 'jpeg', 'JPEG', 'mp4', 'MP4'].includes(fileType(media)))) {
                alert("Les types de fichiers autorisés sont 'png', 'jpg', 'PNG', 'JPG', 'jpeg', 'JPEG', 'mp4', 'MP4'");
            } else {
                setCurrentGaleryFile(media);
            }
             
            
            
            // if (Object.prototype.toString.call(lecteur.result) === "[object String]") {
                
            // }
            
            console.log(lecteur.result);
        }
        lecteur.readAsDataURL(file);
    }

    const isBase64 = (file: string) => {
        return file.includes('data');
        // if (file ==='' || file.trim() ===''){ return false; }
        // try {
        //     return window.btoa(window.atob(file)) == file;
        // } catch (err) {
        //     return false;
        // }
    }

    const fileType = (base64Data: any) => {
        const fileType = base64Data.split(';')[0].split('/')[1];
        console.log(fileType);
        return fileType;
    }

    const addGaleryFile = () => {
       if (currentGaleryFile !== null && currentGaleryFile !== undefined) {
            let galery: Array<any> = galerie;
            galery.push(currentGaleryFile); 
            setGalerie( galery ); 
            setCurrentGaleryFile(null);
       }
    }

    const removeGaleryFile = (fileIndex : number) => {
        let galery = galerie.filter((ingrd, index) => {
            return index !== fileIndex;
        });  
        console.log(galery);
        setGalerie(galery);
        console.log(galerie); 
        setGalLoading(false);
        setCurrentGaleryFile(null);
    }

    const onDeleteGaleryFile = (media: any, fileIndex : number) => {  
        setGalLoading(true);
        if (isBase64(media)) {
            removeGaleryFile(fileIndex);
        } else {
            const mediaSplitted = media.split("/");
            productService.deleteGaleryContent( mediaSplitted[ mediaSplitted.length - 1 ] ).then(async function (response: any) {
                console.log(response);  
                removeGaleryFile(fileIndex);
                setGalLoading(false);
            })
              .catch(function (error: any) {
                console.log(error); 
                setGalLoading(false);
            });
        }
       
    }

    const changeListeneralery = (event: any)  => {
        console.log('Gallery media selected');
        readThisGalery(event.target);
    }

    const getTypes = () => {
        setTypesLoading(true);
        configService.getTypes().then(async function (response: any) {
            console.log(response); 
            setTypes(response.data);
            setTypesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setTypesLoading(false); 
        });
    }


    const getGammes = () => {
        setGammesLoading(true);
        configService.getGammes().then(async function (response: any) {
            console.log(response); 
            setGammes(response.data);
            setGammesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setGammesLoading(false); 
        });
    }


    const getCategories = () => {
        setCategoriesLoading(true);
        configService.getCategories().then(async function (response: any) {
            console.log(response); 
            setCategories(response.data);
            setCategoriesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setCategoriesLoading(false); 
        });
    }

    const onAddIngredient = (event: any) => {  
            let ingrds = ingredNames;
            ingrds.push(ingredName == null ? '' : ingredName);
            console.log(ingrds);
            setIngredNames(ingrds);
            console.log(ingredNames); 
            setIngredName("");
    }

    const onDeleteIngredient = (ingredientIndex : number) => {  
        let ingrds = ingredNames.filter((ingrd, index) => {
            return index !== ingredientIndex;
        });  
        console.log(ingrds);
        setIngredNames(ingrds);
        console.log(ingredNames); 
        setIngredName("");
    }

    const uploadProductImage = async () => {
        return await new Promise(
          (resolve: any, reject) => {
            if ( image !== null && image !== undefined ) {
                productService.uploadProductFile(image).then(
                    (res: any) => {

                        console.log(res);
                        if (formRef.current !== null) {
                            formRef.current.values.photoProduit = res?.data?.mediaName;
                        }
                        
                        resolve();
                           
                    }, (error: any) => {
                    reject();
                    }
                );
            } else {
              resolve();
            }  
          }
        )
      }

    const uploadProductGaleryFiles = async () => {
        const galId = state === 'create' ? uuid() : product?.galerie?.creationKey;
        setGalId(galId);
        return await new Promise(
          (resolve: any, reject) => {
            if (galerie.length > 0) {
                galerie.map(
                    (file, id) => {

                        if (isBase64(file)) {
                    
                            productService.uploadProductGaleryFiles(file, 'loop', galId).then(
                                (res: any) => {
                                    console.log(res);
                                    
                                    if (formRef.current !== null) {

                                        let fileArray2: any =  formRef.current.values.galContent ?  formRef.current.values.galContent : [];
                                        fileArray2.push(res?.data?.uploadedFN);

                                        setProductGalId( (id: any)  => (res?.data?.galID));

                                        formRef.current.values.idGalerie = res?.data?.galID;

                                        formRef.current.values.galContent = fileArray2;
                                        
                                        if(id === (galerie.length - 1)) {
                                            resolve();
                                        }

                                    } 
                                    
                                }, (error: any) => {
                                    reject();
                                }
                            );
                        } else {
                            if (formRef.current !== null) {
                                let fileArray2: any =  formRef.current.values.galContent ?  formRef.current.values.galContent : [];
                                fileArray2.push(file);  
                                formRef.current.values.galContent = fileArray2;
                                if(id === (galerie.length - 1)) {
                                    resolve();
                                }

                            }
                        }
                    }
              );
            } else {
              resolve();
            }  
          }
        )
    }

    const getCorrespodantValue = (arr: Array<any>, id: any) => {
        const filt = arr.filter((val: any) =>  Number(val.id) ==  Number(id));
        return filt[0];
    }

    const handleTagsAdd = (e: any) => {
        if (formRef.current !== null ) { 
            let tags2: Array<any> = tags;
            tags2.push(formRef.current.values.tagCopy);
            setTags(tag => ([...tags2]));
            formRef.current.values.tagCopy = '';
        }
    }

    useEffect( () => {

        getTypes();
        getGammes();
        getCategories();

        if (product != null  && product != undefined) {

            console.log('Set ingreds values');
            console.log(product);
            setIngredNames(product.ingredNamesArray ?? []);
            setGalerie( product.galerie?.galerieContent ?? [] );
            setImage( product.fullImage ?? '' );

            if ( typeof product.tags === "string" ) {
                setTags( JSON.parse( product.tags ) );
            } 

            setContents(product.descriptionElements ?? []);
            setDescriptionImage(product.descriptionImage ?? '');

            if (formRef.current !== null ) {
                formRef.current.values.form_gamme = product.gamme?.id?.toString();
                formRef.current.values.form_categorie = product.categorie?.id?.toString();
                formRef.current.values.form_type = product.type?.id?.toString();
            }

        }


    }, []);

    const appProduct = ()  => {

    }

    const onTagInputPress = (e: any) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    }

    const columns = [
        {
            name: `${t('Titre')}`,
            selector: (row: any) => row.title,
        },
        {
            name: `${t('Contenu')}`,
            selector: (row: any) => <p className='description-content'>{ row.textContent }</p>,
        },
        {
            name: `${t("Options")}` ,
            selector: (row: any) => (
                <div className='btn-list'>
                    <button type="button" onClick={event => { 

                        if (formRef.current !== null ) {
                            setContentToMod(row);
                            formRef.current.values.title = row.title;
                            formRef.current.values.textContent  = row.textContent ;
                            
                        }
                       
                     }}
                        className="btn waves-effect waves-light btn-success">
                            <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-pencil"></i></span>
                    </button>
                    <button type="button" onClick={event => { 
                        
                        setContents(
                            [
                             ...contents.filter(
                                (c) => c.id !== row.id
                             )
                            ]
                        );

                     }}
                        className="btn waves-effect waves-light btn-danger"> 
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-trash"></i></span>
                             
                    </button>
                </div>
            )
        } 
    ];
    
    

    return (
        <Formik
        initialValues={
            product != null  && product != undefined ? 
            {
                ...product
            }:
            {
                id: null,
                idGalerie: null,
                gamme: null,
                type : null,
                categorie: null,
                form_gamme: null,
                form_type : null,
                form_categorie: null,
                libProduit: '',
                descriptionProduit: '',
                lidPrice: '',
                botorjarPrice: '',
                sealPrice: '',
                labelPrice: '',
                shippingPrice: '',
                protectPrice: '',
                coutFixe: '',
                coutVariant: '',
                laborPrice: '',
                overheadPrice: '',
                nbreJoursAlertAvPeremption: '',
                capitalUnitaireProduit: '',
                interetUnitaireProduit: '',
                qteStock: '',
                qteAvAlertFinStock: '',
                ingredPrice: '',
                ingredNames: null,
                productBoxPrice: 0,
                rappingBoxPrice : 0,
                commingSoon: false,
                masseProduit:  0,
                galContent  : null,
                ingredNamesArray: null,
                skinType: '',
                tags: null,
                tagCopy: null,
                descriptionImage: '',
                descriptionElements: null,
                title: null,
                textContent:null
        }}

        validationSchema={
            yup.object().shape({
                gamme: yup
                    .mixed<Gamme>(),
                type: yup
                    .mixed<Type>(),
                categorie: yup
                    .mixed<Category>(),
                form_gamme: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                form_type: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                form_categorie: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                libProduit: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                descriptionProduit: yup
                    .string(),
                lidPrice: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                botorjarPrice: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                sealPrice: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                labelPrice: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                shippingPrice: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                protectPrice:  yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                coutFixe:  yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                coutVariant:  yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                laborPrice:  yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                overheadPrice:  yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                nbreJoursAlertAvPeremption:  yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                capitalUnitaireProduit: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                interetUnitaireProduit: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                qteStock: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                qteAvAlertFinStock: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                ingredPrice: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                productBoxPrice: yup
                    .number()
                    .required(`${t('Ce champ est obligatoire')}`),
                rappingBoxPrice: yup
                    .number()
                    .required(`${t('Ce champ est obligatoire')}`),
                commingSoon: yup 
                    .boolean()
                    .required(`${t('Ce champ est obligatoire')}`),
                masseProduit: yup
                    .number()
                    .required(`${t('Ce champ est obligatoire')}`),
                galContent: yup
                    .mixed<string>(),
                skinType: yup
                    .string(),
                tagCopy: yup
                    .string(),
                tags: yup
                    .mixed<any>(),
                descriptionImage: yup
                    .string(),
                descriptionElements: yup
                    .mixed<DescriptionElement>(),
                descriptionTitle: yup
                    .string(),
                descriptionContent: yup
                    .string(),
            })
        }
        innerRef={formRef}
        onSubmit={async (
            values 
          ) => {

                setLoading(true);

                console.log(values);

                if (product != null  && product != undefined) {

                    await uploadProductGaleryFiles().then(
                        async () => {

                            if (formRef.current !== null ) {

                                formRef.current.values.photoProduit = image;
                                formRef.current.values.descriptionImage = descriptionImage;
                                formRef.current.values.gamme = getCorrespodantValue(gammes, values.form_gamme);
                                formRef.current.values.type = getCorrespodantValue(types, values.form_type);
                                formRef.current.values.categorie = getCorrespodantValue(categories, values.form_categorie);
                                formRef.current.values.ingredNames = ingredNames;
                                // formRef.current.values.idGalerie = productGalId;
                                formRef.current.values.descriptionElements = contents;
                                formRef.current.values.tags = tags;
                                
                            }

                            productService.updateProduct( values ).then(async function (response: any) {
                                console.log(response);
                                confirmAlert({
                                    overlayClassName: "alert-overlay",
                                    customUI: ({ onClose }) => {
                                      return (
                                        
                                            <Alert 
                                                alert={{
                                                    title: 'Succès',
                                                    message: 'Produit ajouté avec succès',
                                                    actionText: 'Ok',
                                                    action: () => {
                                                        onClose();
                                                    }
                                                }}
                                            />
                                      );
                                    }
                                }); 
                                navigate('/product/list');
                                setLoading(false); 
                            }).catch(function (error: any) {
                                console.log(error);
                                confirmAlert({
                                    overlayClassName: "alert-overlay",
                                    customUI: ({ onClose }) => {
                                      return (
                                        
                                            <Alert 
                                                alert={{
                                                    type: 'error',
                                                    title: 'Erreur',
                                                    message: 'Une erreur réesseyez',
                                                    actionText: 'Ok',
                                                    action: () => {
                                                        onClose();
                                                    }
                                                }}
                                            />
                                      );
                                    }
                                }); 
                                setLoading(false); 
                            });


                        }).catch(function(err) {
                            setLoading(false); 
                        }); 

                } else {

                    console.log(values);


                    // await uploadProductImage().then(
                    //     async () => {

                            await uploadProductGaleryFiles().then(
                                async () => {

                                    if (formRef.current !== null ) {
                                        formRef.current.values.photoProduit = image;
                                        formRef.current.values.descriptionImage = descriptionImage;
                                        formRef.current.values.gamme = getCorrespodantValue(gammes, values.form_gamme);
                                        formRef.current.values.type = getCorrespodantValue(types, values.form_type);
                                        formRef.current.values.categorie = getCorrespodantValue(categories, values.form_categorie);
                                        formRef.current.values.ingredNames = ingredNames;
                                        // formRef.current.values.idGalerie = productGalId;
                                        formRef.current.values.descriptionElements = contents;
                                        formRef.current.values.tags = tags;
                                    }

                                

                                    productService.addProduct( values ).then(async function (response: any) {
                                        console.log(response);
                                        confirmAlert({
                                            overlayClassName: "alert-overlay",
                                            customUI: ({ onClose }) => {
                                              return (
                                                
                                                    <Alert 
                                                        alert={{
                                                            title: t('Succès'),
                                                            message: t('Produit ajouté avec succès'),
                                                            actionText: 'Ok',
                                                            action: () => {
                                                                onClose();
                                                            }
                                                        }}
                                                    />
                                              );
                                            }
                                        }); 
                                        navigate('/product/list');
                                        setLoading(false); 
                                    }).catch(function (error: any) {
                                        console.log(error);
                                        confirmAlert({
                                            overlayClassName: "alert-overlay",
                                            customUI: ({ onClose }) => {
                                              return (
                                                
                                                    <Alert 
                                                        alert={{
                                                            type: 'error',
                                                            title: t('Erreur'),
                                                            message: t('Une erreur réesseyez'),
                                                            actionText: 'Ok',
                                                            action: () => {
                                                                onClose();
                                                            }
                                                        }}
                                                    />
                                              );
                                            }
                                        }); 
                                        setLoading(false); 
                                    });


                            }).catch(function(err) {
                                    setLoading(false); 
                                }); 
                    
                }

            }}
        >
                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                        <Form>
                            <div className="form-body">

                                <div className="row">
                                    <div className="col-md-6">

                                        <label>
                                            {
                                                t('Gamme')
                                            }
                                        </label>
                                        <div className="row">
                                        
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                        {
                                                            gammesLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                             <Field as="select"  
                                                                className={`form-control custom-select ${ errors.form_gamme &&
                                                                    touched.form_gamme ? "is-invalid" : "" }`}
                                                                id="form_gamme" 
                                                                name="form_gamme"
                                                                onChange={(event: any) => {
                                                                    // console.log(event);
                                                                    handleChange('form_gamme')(event);
                                                                    // const agent = getAgentById(event.target.value);
                                                                    // console.log(agent);
                                                                    // setSelectedAgent( agent );
                                                                }}
                                                                onBlur={handleBlur('form_gamme')}
                                                                value={ values.form_gamme }
                                                                placeholder="Gamme" >
                                                                <option selected>{t('Gamme')}...</option>
                                                                {
                                                                    gammes.map(
                                                                        (gamme: any, id) => <option 
                                                                         key={id} value={gamme.id}>
                                                                            {
                                                                                gamme.libGamme
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </Field> 
                                                        }
                                                        { errors.form_gamme && touched.form_gamme && errors.form_gamme && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.form_gamme && touched.form_gamme && errors.form_gamme }
                                                        </div> }
                                                </div>
                                            </div>

                                        </div>
                                </div>

                                <div className="col-md-6">

                                        <label>
                                            {
                                                t('Type')
                                            }
                                        </label>
                                        <div className="row">
                                        
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                {
                                                            typesLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm"
                                                             role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <Field as="select"  
                                                                className={`form-control custom-select ${ errors.form_type &&
                                                                    touched.form_type ? "is-invalid" : "" }`}
                                                                id="form_type" 
                                                                name="form_type"
                                                                onChange={(event: any) => {
                                                                    // console.log(event);
                                                                    handleChange('form_type')(event);
                                                                    // const agent = getAgentById(event.target.value);
                                                                    // console.log(agent);
                                                                    // setSelectedAgent( agent );
                                                                }}
                                                                onBlur={handleBlur('form_type')}
                                                                value={values.form_type}
                                                                placeholder="Type" >
                                                                <option selected>{t('Type')}...</option>
                                                                {
                                                                    types.map(
                                                                        (type: any, id) => <option 
                                                                         key={id} value={type.id}>
                                                                            {
                                                                                type.libType
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </Field> 
                                                        }
                                                    { errors.form_type && touched.form_type && errors.form_type && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.form_type && touched.form_type && errors.form_type }
                                                    </div> }
                                                </div>
                                            </div>

                                        </div>
                                </div>

                            </div>

                            <div className="row">
                                    <div className="col-md-6">

                                        <label>
                                            {t('Catégorie')}
                                        </label>
                                        <div className="row">
                                        
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                        {
                                                            gammesLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" 
                                                                role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <Field as="select"  
                                                                className={`form-control custom-select ${ errors.form_categorie &&
                                                                    touched.form_categorie ? "is-invalid" : "" }`}
                                                                id="form_categorie" 
                                                                name="form_categorie"
                                                                onChange={(event: any) => {
                                                                    // console.log(event);
                                                                    handleChange('form_categorie')(event);
                                                                    // const agent = getAgentById(event.target.value);
                                                                    // console.log(agent);
                                                                    // setSelectedAgent( agent );
                                                                }}
                                                                onBlur={handleBlur('form_categorie')}
                                                                value={values.form_categorie}
                                                                placeholder="Catégorie" >
                                                                <option selected>{t('Catégorie')}...</option>
                                                                {
                                                                    categories.map(
                                                                        (cat: any, id) => <option 
                                                                         key={id} value={cat.id}>
                                                                            {
                                                                                cat.libcat
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </Field> 
                                                        }
                                                        { errors.form_categorie && touched.form_categorie && errors.form_categorie && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.form_categorie && touched.form_categorie && errors.form_categorie }
                                                        </div> }
                                                </div>
                                            </div>

                                        </div>
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>
                                        { t('Libellé') } </label>
                                    <Field type="text"
                                        className={`form-control 
                                        ${ errors.libProduit && touched.libProduit ? "is-invalid":""}`}
                                        id="libProduit" 
                                        name="libProduit"  
                                        onChange={handleChange('libProduit')}
                                        onBlur={handleBlur('libProduit')}
                                        value={values.libProduit == null ? '' : values.libProduit }
                                        placeholder={ t('Libellé') } />
                                        { errors.libProduit && touched.libProduit && errors.libProduit && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.libProduit && touched.libProduit && errors.libProduit }
                                            </div> 
                                        }
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        { t('Description') }
                                    </label>
                                    <Field as="textarea" rows={2}
                                        className={`form-control 
                                        ${ errors.descriptionProduit && touched.descriptionProduit ? "is-invalid":""}`}
                                        id="descriptionProduit" 
                                        name="descriptionProduit"  
                                        onChange={handleChange('descriptionProduit')}
                                        onBlur={handleBlur('descriptionProduit')}
                                        value={values.descriptionProduit == null ? '' : values.descriptionProduit }
                                        placeholder={
                                            t('Description')
                                        } />
                                        { errors.descriptionProduit && touched.descriptionProduit && errors.descriptionProduit && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.descriptionProduit && touched.descriptionProduit && errors.descriptionProduit }
                                            </div> 
                                        }
                                </div>
                            </div>  
                            <br/>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>
                                        { t('Prix lid') }
                                    </label>
                                    <Field type="text"
                                        className={`form-control 
                                        ${ errors.lidPrice && touched.lidPrice ? "is-invalid":""}`}
                                        id="lidPrice" 
                                        name="lidPrice"  
                                        onChange={handleChange('lidPrice')}
                                        onBlur={handleBlur('lidPrice')}
                                        value={values.lidPrice == null ? '' : values.lidPrice }
                                        placeholder={ t('Prix lid') } />
                                        { errors.lidPrice && touched.lidPrice && errors.lidPrice && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.lidPrice && touched.lidPrice && errors.lidPrice }
                                            </div> 
                                        }
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        { t('Prix bouteille/pot') }
                                    </label>
                                    <Field type="text"
                                        className={`form-control 
                                        ${ errors.botorjarPrice && touched.botorjarPrice ? "is-invalid":""}`}
                                        id="botorjarPrice" 
                                        name="botorjarPrice"  
                                        onChange={handleChange('botorjarPrice')}
                                        onBlur={handleBlur('botorjarPrice')}
                                        value={values.botorjarPrice == null ? '' : values.botorjarPrice }
                                        placeholder={ t('Prix bouteille/pot') } />
                                        { errors.botorjarPrice && touched.botorjarPrice && errors.botorjarPrice && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.botorjarPrice && touched.botorjarPrice && errors.botorjarPrice }
                                            </div> 
                                        }
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>
                                        { t('Prix sceller') } </label>
                                    <Field type="text"
                                        className={`form-control 
                                        ${ errors.sealPrice && touched.sealPrice ? "is-invalid":""}`}
                                        id="sealPrice" 
                                        name="sealPrice"  
                                        onChange={handleChange('sealPrice')}
                                        onBlur={handleBlur('sealPrice')}
                                        value={ values.sealPrice }
                                        placeholder={ t('Prix sceller') } />
                                        { errors.sealPrice && touched.sealPrice && errors.sealPrice && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.sealPrice && touched.sealPrice && errors.sealPrice }
                                            </div> 
                                        }
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        { t('Prix étiqueter') }
                                    </label>
                                    <Field type="text"
                                        className={`form-control 
                                        ${ errors.labelPrice && touched.labelPrice ? "is-invalid":""}`}
                                        id="labelPrice" 
                                        name="labelPrice"  
                                        onChange={handleChange('labelPrice')}
                                        onBlur={handleBlur('labelPrice')}
                                        value={ values.labelPrice }
                                        placeholder={ t('Prix étiqueter') } />
                                        { errors.labelPrice && touched.labelPrice && errors.labelPrice && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.labelPrice && touched.labelPrice && errors.labelPrice }
                                            </div> 
                                        }
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>
                                        { t('Prix d\'expédition') }
                                    </label>
                                    <Field type="text"
                                        className={`form-control 
                                        ${ errors.shippingPrice && touched.shippingPrice ? "is-invalid":""}`}
                                        id="shippingPrice" 
                                        name="shippingPrice"  
                                        onChange={handleChange('shippingPrice')}
                                        onBlur={handleBlur('shippingPrice')}
                                        value={ values.shippingPrice }
                                        placeholder={ t('Prix d\'expédition') } />
                                        { errors.shippingPrice && touched.shippingPrice && errors.shippingPrice && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.shippingPrice && touched.shippingPrice && errors.shippingPrice }
                                            </div> 
                                        }
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        {t('Prix protection')}
                                    </label>
                                    <Field type="text"
                                        className={`form-control 
                                        ${ errors.protectPrice && touched.protectPrice ? "is-invalid":""}`}
                                        id="protectPrice" 
                                        name="protectPrice"  
                                        onChange={handleChange('protectPrice')}
                                        onBlur={handleBlur('protectPrice')}
                                        value={ values.protectPrice }
                                        placeholder={t('Prix protection')} />
                                        {   errors.protectPrice && touched.protectPrice && errors.protectPrice && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.protectPrice && touched.protectPrice && errors.protectPrice }
                                            </div> 
                                        }
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>
                                        {t('Coût fixe')}
                                    </label>
                                    <Field type="text"
                                        className={`form-control 
                                        ${ errors.coutFixe && touched.coutFixe ? "is-invalid":""}`}
                                        id="coutFixe" 
                                        name="coutFixe"  
                                        onChange={handleChange('coutFixe')}
                                        onBlur={handleBlur('coutFixe')}
                                        value={ values.coutFixe }
                                        placeholder={t('Coût fixe')} />
                                        { 
                                            errors.coutFixe && touched.coutFixe && errors.coutFixe && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.coutFixe && touched.coutFixe && errors.coutFixe }
                                            </div> 
                                        }
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        {t('Coût variant')}
                                    </label>
                                    <Field type="text"
                                        className={`form-control 
                                        ${ errors.coutVariant && touched.coutVariant ? "is-invalid":""}`}
                                        id="coutVariant" 
                                        name="coutVariant"  
                                        onChange={handleChange('coutVariant')}
                                        onBlur={handleBlur('coutVariant')}
                                        value={ values.coutVariant }
                                        placeholder={t('Coût variant')} />
                                        {   errors.coutVariant && touched.coutVariant && errors.coutVariant && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.coutVariant && touched.coutVariant && errors.coutVariant }
                                            </div> 
                                        }
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>
                                        {t('Prix du travail')}
                                    </label>
                                    <Field type="text"
                                        className={`form-control 
                                        ${ errors.laborPrice && touched.laborPrice ? "is-invalid":""}`}
                                        id="laborPrice" 
                                        name="laborPrice"  
                                        onChange={handleChange('laborPrice')}
                                        onBlur={handleBlur('laborPrice')}
                                        value={ values.laborPrice }
                                        placeholder={t('Prix du travail')} />
                                        { 
                                            errors.laborPrice && touched.laborPrice && errors.laborPrice && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.laborPrice && touched.laborPrice && errors.laborPrice }
                                            </div> 
                                        }
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        {t('Fraix généraux')}
                                    </label>
                                    <Field type="text"
                                        className={`form-control 
                                        ${ errors.overheadPrice && touched.overheadPrice ? "is-invalid":""}`}
                                        id="overheadPrice" 
                                        name="overheadPrice"  
                                        onChange={handleChange('overheadPrice')}
                                        onBlur={handleBlur('overheadPrice')}
                                        value={ values.overheadPrice }
                                        placeholder={t('Fraix généraux')} />
                                        {   errors.overheadPrice && touched.overheadPrice && errors.overheadPrice && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.overheadPrice && touched.overheadPrice && errors.overheadPrice }
                                            </div> 
                                        }
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>
                                        { t('Capital unitaire du produit') }
                                    </label>
                                    <Field type="text"
                                        className={`form-control 
                                        ${ errors.capitalUnitaireProduit && touched.capitalUnitaireProduit ? "is-invalid":""}`}
                                        id="capitalUnitaireProduit" 
                                        name="capitalUnitaireProduit"  
                                        onChange={handleChange('capitalUnitaireProduit')}
                                        onBlur={handleBlur('capitalUnitaireProduit')}
                                        value={ values.capitalUnitaireProduit }
                                        placeholder={ t('Capital unitaire du produit') } />
                                        { 
                                            errors.capitalUnitaireProduit && touched.capitalUnitaireProduit && errors.capitalUnitaireProduit && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.capitalUnitaireProduit && touched.capitalUnitaireProduit && errors.capitalUnitaireProduit }
                                            </div> 
                                        }
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        { t('Interet unitaire produit') }
                                    </label>
                                    <Field type="text"
                                        className={`form-control 
                                        ${ errors.interetUnitaireProduit && touched.interetUnitaireProduit ? "is-invalid":""}`}
                                        id="interetUnitaireProduit" 
                                        name="interetUnitaireProduit"  
                                        onChange={handleChange('interetUnitaireProduit')}
                                        onBlur={handleBlur('interetUnitaireProduit')}
                                        value={ values.interetUnitaireProduit }
                                        placeholder={ t('Interet unitaire produit') } />
                                        {   errors.interetUnitaireProduit && touched.interetUnitaireProduit && errors.interetUnitaireProduit && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.interetUnitaireProduit && touched.interetUnitaireProduit && errors.interetUnitaireProduit }
                                            </div> 
                                        }
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>
                                        {t('Quantité en stock')}
                                    </label>
                                    <Field type="text"
                                        className={`form-control 
                                        ${ errors.qteStock && touched.qteStock ? "is-invalid":""}`}
                                        id="qteStock" 
                                        name="qteStock"  
                                        onChange={handleChange('qteStock')}
                                        onBlur={handleBlur('qteStock')}
                                        value={ values.qteStock }
                                        placeholder={t('Quantité en stock')} />
                                        { 
                                            errors.qteStock && touched.qteStock && errors.qteStock && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.qteStock && touched.qteStock && errors.qteStock }
                                            </div> 
                                        }
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        {t('Quantité avant alert')}
                                    </label>
                                    
                                        <Field type="text"
                                            className={`form-control 
                                            ${ errors.qteAvAlertFinStock && touched.qteAvAlertFinStock ? "is-invalid":""}`}
                                            id="qteAvAlertFinStock" 
                                            name="qteAvAlertFinStock"  
                                            onChange={handleChange('qteAvAlertFinStock')}
                                            onBlur={handleBlur('qteAvAlertFinStock')}
                                            value={ values.qteAvAlertFinStock }
                                            placeholder={t('Quantité avant alert')} />
                                            {   errors.qteAvAlertFinStock && touched.qteAvAlertFinStock && errors.qteAvAlertFinStock && 
                                                <div id="validationServer05Feedback" className="invalid-feedback">
                                                    { errors.qteAvAlertFinStock && touched.qteAvAlertFinStock && errors.qteAvAlertFinStock }
                                                </div> 
                                            }
                                       
                                    
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>
                                        { t('Prix ingrédients') }
                                    </label>
                                    <Field type="text"
                                        className={`form-control 
                                        ${ errors.ingredPrice && touched.ingredPrice ? "is-invalid":""}`}
                                        id="ingredPrice" 
                                        name="ingredPrice"  
                                        onChange={handleChange('ingredPrice')}
                                        onBlur={handleBlur('ingredPrice')}
                                        value={ values.ingredPrice }
                                        placeholder={ t('Prix ingrédients') } />
                                        { 
                                            errors.ingredPrice && touched.ingredPrice && errors.ingredPrice && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.ingredPrice && touched.ingredPrice && errors.ingredPrice }
                                            </div> 
                                        }
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        {t('Noms des ingrédients')}
                                    </label>
                                    <div className='input-group'>
                                        <Field className='form-control' type="text"
                                            // className={`form-control 
                                            // ${ errors.qteAvAlertFinStock && touched.qteAvAlertFinStock ? "is-invalid":""}`}
                                            // id="qteAvAlertFinStock" 
                                            // name="qteAvAlertFinStock"  
                                            onChange={(event: any) => {
                                                setIngredName(event.target.value);
                                            }}
                                            // onBlur={handleBlur('qteAvAlertFinStock')}
                                            value={ ingredName == null ? '' : ingredName }
                                            //onKeyPress={onAddIngredient}
                                            placeholder={t('Noms des ingrédients')} />
                                        <div className="input-group-append">
                                                <button onClick={onAddIngredient} className="btn btn-outline-primary" type="button">+</button>
                                        </div>
                                    </div>
                                    {  ingredNames.length > 0 ? 
                                        <ul className="list-group mt-2">
                                            {
                                                ingredNames.map(
                                                    (ingred, index) =>
                                                        <li key={index} className="list-group-item">
                                                            {ingred}
                                                            <span onClick={event => onDeleteIngredient(index)} style={{ float: 'right', cursor: 'pointer' }}
                                                                className="badge badge-danger ml-auto">
                                                                <span className="" style={{ fontSize: "1em" }} >
                                                                x</span>
                                                            </span>
                                                        </li>
                                                )
                                            }
                                        </ul>
                                        : null
                                    }                
                                        
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>
                                        {t('Nbre Jours alert avant peremption')}
                                    </label>
                                    <Field type="text"
                                        className={`form-control 
                                        ${ errors.nbreJoursAlertAvPeremption && touched.nbreJoursAlertAvPeremption ? "is-invalid":""}`}
                                        id="nbreJoursAlertAvPeremption" 
                                        name="nbreJoursAlertAvPeremption"  
                                        onChange={handleChange('nbreJoursAlertAvPeremption')}
                                        onBlur={handleBlur('nbreJoursAlertAvPeremption')}
                                        value={ values.nbreJoursAlertAvPeremption }
                                        placeholder={t('Nbre Jours alert avant peremption')} />
                                        { 
                                            errors.nbreJoursAlertAvPeremption && touched.nbreJoursAlertAvPeremption &&
                                             errors.nbreJoursAlertAvPeremption && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.nbreJoursAlertAvPeremption && touched.nbreJoursAlertAvPeremption &&
                                                 errors.nbreJoursAlertAvPeremption }
                                            </div> 
                                        }
                                </div>

                                <div className="col-md-6">
                                    <label>
                                        {t('Type de teint de peau')}
                                    </label>
                                    <Field type="text"
                                        className={`form-control 
                                        ${ errors.skinType && touched.skinType ? "is-invalid":""}`}
                                        id="skinType" 
                                        name="skinType"  
                                        onChange={handleChange('skinType')}
                                        onBlur={handleBlur('skinType')}
                                        value={ values.skinType }
                                        placeholder={t('Type de teint de peau')} />
                                        { 
                                            errors.skinType && touched.skinType &&
                                             errors.skinType && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.skinType && touched.skinType &&
                                                 errors.skinType }
                                            </div> 
                                        }
                                </div>

                            </div>

                            <br />

                            <div className="row">
                                
                                <div className="col-md-6">
                                        <div className="form-group">
                                                    <label> { t('Mots clés') } </label>
                                                    <div className="input-group mb-3">
                                                        
                                                        <div className="custom-file">
                                                            {/* <label>
                                                                {t('Mots clés')}
                                                            </label> */}
                                                            <Field type="text"
                                                                className={`form-control 
                                                                ${ errors.tagCopy && touched.tagCopy ? "is-invalid":""}`}
                                                                id="tagCopy" 
                                                                name="tagCopy"   
                                                                onKeyPress={onTagInputPress}
                                                                onChange={handleChange('tagCopy')}
                                                                onBlur={handleBlur('tagCopy')}
                                                                value={ values.tagCopy }
                                                                placeholder={t('Mots clés')} />
                                                                { 
                                                                    errors.tagCopy && touched.tagCopy &&
                                                                    errors.tagCopy && 
                                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                                        { errors.tagCopy && touched.tagCopy &&
                                                                        errors.tagCopy }
                                                                    </div> 
                                                                }
                                                                            </div>

                                                                <div className=" input-group-prepend">
                                                                    <span onClick={ handleTagsAdd } className=" btn btn-primary input-group-text">
                                                                        { t('Ajouter') }
                                                                    </span>
                                                                </div>
                                                            </div> 
                                                     
                                                </div>

                                        
                                    </div>
                                    <div className="col-md-6"> 
                                        {
                                            tags.map(
                                                (tag, id) => <span onClick={ () => {
                                                    console.log('Item on remove');
                                                    setTags(
                                                        tg => (
                                                            [ ...tags.filter(t => t !== tag)  ]
                                                        )
                                                    )
                                                } } style={{cursor: 'pointer', marginLeft: '8px' }} key={id} className="tag tag-ios tag-lg">
                                                                {tag} <i className="fas fa-times-circle"></i>
                                                            </span> 
                                            )
                                        }
                                    </div>
                            </div>

                            <br/>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>
                                        { t('Prix boîte produit') }
                                    </label>
                                    <Field type="number"
                                        className={`form-control 
                                        ${ errors.productBoxPrice && touched.productBoxPrice ? "is-invalid":""}`}
                                        id="productBoxPrice" 
                                        name="productBoxPrice"  
                                        onChange={handleChange('productBoxPrice')}
                                        onBlur={handleBlur('productBoxPrice')}
                                        value={ values.productBoxPrice }
                                        placeholder={ t('Prix boîte produit') } />
                                        { errors.productBoxPrice && touched.productBoxPrice && errors.productBoxPrice && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.productBoxPrice && touched.productBoxPrice && errors.productBoxPrice }
                                            </div> 
                                        }
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        {t('Prix boîte rap')}
                                    </label>
                                    <Field type="number"
                                        className={`form-control 
                                        ${ errors.rappingBoxPrice && touched.rappingBoxPrice ? "is-invalid":""}`}
                                        id="rappingBoxPrice" 
                                        name="rappingBoxPrice"  
                                        onChange={handleChange('rappingBoxPrice')}
                                        onBlur={handleBlur('rappingBoxPrice')}
                                        value={ values.rappingBoxPrice }
                                        placeholder={t('Prix boîte rap')} />
                                        {   errors.rappingBoxPrice && touched.rappingBoxPrice && errors.rappingBoxPrice && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.rappingBoxPrice && touched.rappingBoxPrice && errors.rappingBoxPrice }
                                            </div> 
                                        }
                                </div>
                            </div>


                            <br/>
                            <div className="row">
                                <div className="col-md-6">
                                    <label> {t('A venir bientôt')}
                                    <Field type="checkbox"
                                        className={`form-control 
                                        ${ errors.commingSoon && touched.commingSoon ? "is-invalid":""}`}
                                        id="commingSoon" 
                                        name="commingSoon"  
                                        />
                                        </label>
                                        { errors.commingSoon && touched.commingSoon && errors.commingSoon && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.commingSoon && touched.commingSoon && errors.commingSoon }
                                            </div> 
                                        }
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        {t('Masse produit')}
                                    </label>
                                    <Field type="number"
                                        className={`form-control 
                                        ${ errors.masseProduit && touched.masseProduit ? "is-invalid":""}`}
                                        id="masseProduit" 
                                        name="masseProduit"  
                                        onChange={handleChange('masseProduit')}
                                        onBlur={handleBlur('masseProduit')}
                                        value={ values.masseProduit }
                                        placeholder={t('Masse produit')} />
                                        {   errors.masseProduit && touched.masseProduit && errors.masseProduit && 
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                { errors.masseProduit && touched.masseProduit && errors.masseProduit }
                                            </div> 
                                        }
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>
                                        {t('Photo du produit')}
                                    </label>
                                    <div className='form-group'>
                                        {/* <div className="input-group-prepend">
                                                            <span className="input-group-text">Upload</span>
                                                        </div> */}
                                                        <div className="custom-file">
                                                            <input type="file" 
                                                            className={`form-control custom-file-input `} 
                                                                id="photoProfil" 
                                                                onChange={changeListener} 
                                                                placeholder="Photo" />
                                                            <label className="custom-file-label" 
                                                            htmlFor="photoProfil">{ t('Choisir un fichier') }</label>
                                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <img src={image} 
                                                className="img-responsive" width="304" height="236" alt="" />
                                </div>
                            </div> 

                            <br/>

                            <div className="row">
                                <div className="col-md-6">
                                    <label>
                                        { t('Galerie') }
                                    </label>
                                    <div className='form-group'>
                                        {/* <div className="input-group-prepend">
                                                            <span className="input-group-text">Upload</span>
                                                        </div> */}
                                                        <div className='input-group'>
                                                            <input type="file" 
                                                            className={`form-control `} 
                                                                id="galProfil" 
                                                                onChange={changeListeneralery} 
                                                                placeholder="Photo" />
                                                           <label className="custom-file-label" 
                                                            htmlFor="galProfil">{ t('Choisir un fichier') }</label>
                                                           
                                                        </div>
                                                        <br />
                                                        <div  >
                                                            <button disabled={ currentGaleryFile == null || currentGaleryFile == undefined } onClick={addGaleryFile} className="btn btn-outline-primary" type="button">
                                                                {
                                                                    t('Ajouter')
                                                                } 
                                                            </button>
                                                        </div>
                                    </div>
                                    <div className='row'>
                                        {
                                            loading && 
                                            <div className="spinner-border text-danger spinner-border-sm" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        }
                                        {
                                            galerie.length > 0 ?
                                            galerie.map(
                                                (media, index) => {
                                                    return <div  onClick={ event => { setCurrentGaleryFile(media); } } 
                                                        className='col-md-3' >
                                                        <div style={{
                                                            width: '15px',
                                                            height: '15px',
                                                            borderRadius: '50%',
                                                            backgroundColor: 'red',
                                                            position: 'relative',
                                                            top: '-7px',
                                                            left: '89%',
                                                            textAlign: 'center',
                                                            
                                                        }}>

                                                            <span onClick={event => onDeleteGaleryFile(media, index)}  style={{ float: 'right', cursor: 'pointer' }}
                                                                className="badge badge-danger ml-auto">
                                                                <span className="" style={{ fontSize: "1em" }} >
                                                                x</span>
                                                            </span> 

                                                        </div>

                                                        {  isBase64(media) ?

                                                           ['png', 'jpg', 'PNG', 'JPG', 'jpeg', 'JPEG'].includes(fileType(media)) ? 
                                                           <img src={media} style={{
                                                            width: '100%', height: '100%'
                                                            }}
                                                                className="img-responsive"  alt="" /> :
                                                            <div  style={{ width: '100%', height: '100%', display: 'inline-block' }}>
                                                                <video  style={{ width: '100%', height: '100%' }} controls src={media} >
                                                                The “video” tag is not supported by your browser. Click [here] to download the video file.
                                                                </video>
                                                            </div>

                                                            :

                                                            ['png', 'jpg', 'PNG', 'JPG', 'jpeg', 'JPEG'].includes( media.split(".")[1] ) ? 
                                                            <img src={ 'https://lapotnewapi2files.nogdevhouse.com/images/products/' + media} 
                                                            style={{
                                                            width: '100%', height: '100%', maxHeight: '20px'
                                                            }}
                                                                className="img-responsive"  alt="" /> :
                                                            <div  style={{ width: '100%', height: '100%', display: 'inline-block' }}>
                                                                <video  style={{ width: '100%', height: '100%' }} controls src={'https://lapotnewapi2files.nogdevhouse.com/images/products/' + media} >
                                                                The “video” tag is not supported by your browser. Click [here] to download the video file.
                                                                </video>
                                                            </div>
                                                            
                                                        }
                                                        

                                                    </div>
                                                }
                                            ) : <></>
                                        }
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    {   currentGaleryFile !== null && currentGaleryFile !== undefined ?
                                        isBase64(currentGaleryFile) ?
                                        ['png', 'jpg', 'PNG', 'JPG', 'jpeg', 'JPEG'].includes(fileType(currentGaleryFile)) ? 
                                        <img src={currentGaleryFile} 
                                                className="img-responsive" width="304" height="236" alt="" /> :
                                        <video  style={{ width: '100%', height: '100%' }} controls src={currentGaleryFile} >
                                                The “video” tag is not supported by your browser. Click [here] to download the video file.
                                        </video> 
                                        : 
                                        ['png', 'jpg', 'PNG', 'JPG', 'jpeg', 'JPEG'].includes( currentGaleryFile.split(".")[1] ) ? 
                                        <img src={ 'https://lapotnewapi2files.nogdevhouse.com/images/products/' + currentGaleryFile} 
                                                className="img-responsive" width="304" height="236" alt="" /> :
                                        <video  style={{ width: '100%', height: '100%' }} controls src={'https://lapotnewapi2files.nogdevhouse.com/images/products/' + currentGaleryFile} >
                                                The “video” tag is not supported by your browser. Click [here] to download the video file.
                                        </video> : <></> 
                                    }
                                </div>
                            </div> 


                            <br/>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>
                                        {t('Image description')}
                                    </label>
                                    <div className='form-group'> 
                                                        <div className="custom-file">
                                                            <input type="file" 
                                                            className={`form-control custom-file-input `} 
                                                                id="imageDescription" 
                                                                onChange={changeListenerDescriptionImage} 
                                                                placeholder="Photo" />
                                                            <label className="custom-file-label" 
                                                            htmlFor="imageDescription">{ t('Choisir un fichier') }</label>
                                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <img src={descriptionImage} 
                                                className="img-responsive" width="304" height="236" alt="" />
                                </div>
                            </div>   
                            <br/>

                            <div className="row">
                                <div className="col-md-6">
                                    <label>
                                        {t('Eléments de description')}
                                    </label>
                                    <div className='form-group'>
                                            <Field type="text"
                                                                className={`form-control 
                                                                ${ errors.title && touched.title ? "is-invalid":""}`}
                                                                id="title" 
                                                                name="title"   
                                                                onKeyPress={onTagInputPress}
                                                                onChange={handleChange('title')}
                                                                onBlur={handleBlur('title')}
                                                                value={ values.title }
                                                                placeholder={t('Titre')} />
                                                                { 
                                                                    errors.title && touched.title &&
                                                                    errors.title && 
                                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                                        { errors.title && touched.title &&
                                                                        errors.title }
                                                                    </div> 
                                                                }
                                                        
                                    </div>
                                    <div className='form-group'>
                                            <textarea rows={6}
                                                                className={`form-control 
                                                                ${ errors.textContent  && touched.textContent  ? "is-invalid":""}`}
                                                                id="textContent " 
                                                                name="textContent "   
                                                                onKeyPress={onTagInputPress}
                                                                onChange={handleChange('textContent')}
                                                                onBlur={handleBlur('textContent ')}
                                                                value={ values.textContent  !== null ? values.textContent  : '' }
                                                                placeholder={t('Contenu') ?? ''} ></textarea>
                                                                { 
                                                                    errors.textContent  && touched.textContent  &&
                                                                    errors.textContent  && 
                                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                                        { errors.textContent  && touched.textContent  &&
                                                                        errors.textContent  }
                                                                    </div> 
                                                                }
                                                        
                                    </div>
                                    <button disabled={ formRef.current?.values?.textContent  === null || 
                                    formRef.current?.values?.textContent  ===  '' || formRef.current?.values?.title === null ||
                                    formRef.current?.values?.title === '' } className='btn btn-primary'  
                                    onClick={ () => {
                                        if (contentToMod !== null) {

                                            let cts: Array<{ id?: any, title: string, textContent: string }> = contents;
                                            let eIndex = 0;
                                            while(cts[eIndex].id !== contentToMod.id) {
                                                eIndex = eIndex + 1;
                                            } 
                                            console.log("Element in Mod "+eIndex);
                                            cts[eIndex].title = formRef.current?.values?.title ?? '';
                                            cts[eIndex].textContent  = formRef.current?.values?.textContent  ?? '';

                                            setContents(c =>([...cts]));

                                            if (formRef.current !== null) {
                                                formRef.current.values.title = '';
                                                formRef.current.values.textContent = '';
                                                setContentToMod(null);
                                            }


                                        } else {

                                            let cts: Array<{ id?: any, title: string, textContent: string }> = contents;
                                            cts.push({
                                                id: uuid(),
                                                title: formRef.current?.values?.title ?? '',
                                                textContent: formRef.current?.values?.textContent ?? ''
                                            });

                                            setContents(c =>([...cts]));

                                            if (formRef.current !== null) {
                                                formRef.current.values.title = '';
                                                formRef.current.values.textContent  = '';
                                            }

                                        }

                                        

                                    } } >
                                        Ajouter                        
                                     </button>
                                </div>
                                <div className='col-md-6'>
                                <DataTable
                                            responsive={true}
                                            className="table table-striped table-bordered"
                                            columns={columns}
                                            data={contents}
                                            progressPending={loading}
                                            pagination
                                        />
                                </div>
                            </div>

                        </div>
                        <br/> 
                        <div className="form-actions">
                            <div className="text-right">
                                <button  disabled={!dirty && !isValid } type="submit" className="btn btn-info">
                                    {
                                        t('Valider')
                                    }
                                    {
                                        loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    }
                                </button>
                                <button type="reset" className="btn btn-dark">
                                    {   
                                        t('Annuler')
                                    }
                                </button>
                            </div>
                        </div>
                        </Form>
                    )}
                </Formik>
    );
}


export default ProductForm