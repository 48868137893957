import { FC, useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../Components/Breadcrumb';

import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 

import Ressource from "../../Models/Ressource";

import DataTable from 'react-data-table-component';

import Alert from '../../Components/Alert';

// type FormValues = Gamme;

//Alert
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 

//Redux 
import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
 
import { RootState } from '../../Redux/store'; 
import RessourceService from './service';

// React - Bootstrap
import { Modal } from 'react-bootstrap';
 

//Moment react
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';

// PDF genaration
// import * as HTMLtoPDF from 'html2pdf.js';

import * as jsPDF from 'jspdf';
declare var html2pdf: any;



const RessourcePage: FC = () => {  
    // const alert = useAppSelector((state: RootState ) => state.alerts );
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const ressourceService = new RessourceService();
        
    const formRef = useRef< FormikProps<Ressource>>(null);

    const user = useAppSelector((state: RootState) => state.users.user );

    const [
        loading, setLoading
    ] = useState(false);

    const [
        ressources,
        setRessources
    ] = useState([]);


    const [
        payments,
        setPayments
    ] = useState([]);


    const initialSelectedRessource: any = null

    const [
        selectedRessource,
        setSelectedRessource
    ] = useState(initialSelectedRessource);

    const [
        ressourceLoading, setRessourceLoading
    ] = useState(false);

    const id: Number = 0;
    const [
        selectedRessourceId, setSelectedRessourceId
    ] = useState(id);

    const [paymentsModal, setPaymentsModal] =  useState(false);

    const [
        selectedPaymentId, setSelectedPaymentId
    ] = useState(id);

    const [
        paymentLoading, setPaymentLoading
    ] = useState(false);

    
   

    const deleteGamme = async (gammeId: Number) => {
        setSelectedRessourceId(gammeId);
        setRessourceLoading(true);

        confirmAlert({
            overlayClassName: "alert-overlay",
            customUI: ({ onClose }) => {
            return (
                    <Alert 
                        alert={{
                            title: 'Confirmer',
                            message: 'Vous confirmez la suppression de cette gamme',
                            actionText: 'Valider',
                            action: async () => { },
                            cancelText: "Annuler",
                            cancelAction: () => {
                                setRessourceLoading(false); 
                                onClose();
                            }
                        }}
                    />
            );
            }
        });

    }

    const getRessources = () => {
        setLoading(true);
        ressourceService.getRessources().then(async function (response: any) {
            console.log(response); 
            setRessources(response.data.ressources);
            setLoading(false); 
        })
        .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    const getRessourceAmount = (qte: any = null , prix: any = null) => {
        if (formRef.current != null) {
            if ( formRef.current.values.qteRes != null && formRef.current.values.puRes != null ) {
                console.log(formRef.current.values);
                console.log(Number(formRef.current.values.qteRes));
                console.log(Number(formRef.current.values.puRes));
                qte = qte != null ? qte : Number(formRef.current.values.qteRes);
                prix = prix != null ? prix : Number(formRef.current.values.puRes);     
                // Number(formRef.current.values.qteRes) * Number(formRef.current.values.puRes)
                formRef.current.values.montant = Number(prix) * Number(qte);
            } else {
                formRef.current.values.montant = null;
            }
        }
    }

    const columns = [
        {
            name: t('Libellé'),
            selector: (row: any) => row.ressource.libMat,
        },
        {
            name: t('Montant'),
            selector: (row: any) => row.amount,
        }, 
        {
            name: "Options", 
            selector: (row: any) => (
                <div className='btn-list'> 
                    <button type="button" onClick={event => getRessourceDetails(row)}
                        className="btn waves-effect waves-light btn-primary">
                            {
                                selectedRessourceId == row?.ressource?.id && ressourceLoading ?
                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> :
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-eye"></i></span>
                            }
                    </button>
                </div>
            )
        }
    ];

    const paymentsListColumns = [
        {
            name: t('Quantité'),
            selector: (row: any) => row.qte,
        },
        {
            name: t('Prix unitaire'),
            selector: (row: any) => row.pu,
        },
        {
            name: t('Agent'),
            selector: (row: any) => row.agent.prenomAgent + ' ' + row.agent.nomAgent,
        },
        {
            name: 'Date',
            selector: (row: any) => <Moment format="YYYY/MM/DD hh:mm:ss">
                                        {row.dateOperation}
                                    </Moment>
        },
        {
            name: "Options", 
            selector: (row: any) => (
                <div className='btn-list'> 
                    <button type="button" onClick={event =>  {
                        if (formRef.current != null) { 
                            formRef.current.values.id = row.id;
                            formRef.current.values.idRes =  selectedRessource.id;
                            formRef.current.values.qteRes =  row.qte;
                            formRef.current.values.puRes = row.pu;
                            formRef.current.values.montant = row.montant;
                            formRef.current.values.agent = user?.id;
                        }
                        getRessourceAmount();
                        setPaymentsModal(false);
                    }}
                        className="btn waves-effect waves-light btn-primary">
                            { 
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-pencil"></i></span>
                            }
                    </button>
                    <button type="button" onClick={async (event) => { 

                        // var response =  confirm('Vous confirmez la suppression de ce payement ?');

                        if (window.confirm('Vous confirmez la suppression de ce payement ?')) {

                            setSelectedPaymentId(row?.id);
                                                setPaymentLoading(true);

                                                await  ressourceService.deleteRessourcePayment( Number( row?.id ) ).then(async function (response: any) {
                                                    confirmAlert({
                                                        overlayClassName: "alert-overlay",
                                                        customUI: ({ onClose }) => {
                                                        return (
                                                                <Alert 
                                                                    alert={{
                                                                        title: 'Succès',
                                                                        message: 'Payement supprimé avec succès.',
                                                                        actionText: 'Ok',
                                                                        action: () => {
                                                                            onClose();
                                                                        }
                                                                    }}
                                                                />
                                                        );
                                                        }
                                                    });  
                                                    setPaymentLoading(false);
                                                })
                                                .catch(function (error: any) {
                                                    alert("Une erreur s'est produite.");
                                                    setPaymentLoading(false); 
                                                });

                        }
                    }}
                    className="btn waves-effect waves-light btn-danger">
                            {
                                selectedPaymentId == row?.id && paymentLoading ?
                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> :
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-trash"></i></span>
                            }
                    </button>
                </div>
            )
        }
    ];

    const getRessourceDetails = async (row: any) => {

        setSelectedRessource(row.ressource);
        setSelectedRessourceId(row?.ressource?.id);
        setRessourceLoading(true);
        
        await  ressourceService.getRessourceDetails( Number( row?.ressource?.id ) ).then(async function (response: any) {
            // setSelectedRessource(row?.ressource);
            setPayments(response?.data?.payments)
            setRessourceLoading(false);
            setPaymentsModal(true);
        })
        .catch(function (error: any) {
            setRessourceLoading(false); 
        });
    }


    const cancel = () => {

        setSelectedRessource(null);

        if ( formRef.current != null) {
            formRef.current.values.id = null;
            formRef.current.values.idRes =  null;
            formRef.current.values.qteRes =  null;
            formRef.current.values.puRes = null;
            formRef.current.values.montant = null;
            formRef.current.values.agent = user?.id;
        } 
    }


    const addPaymentToRessource = async (ressource: Ressource) => {
        if (ressource.idRes != null && ressource.montant != null ) {
            setLoading(true); 
            await  ressourceService.checkResRemainingAmount(ressource.idRes).then(async function (response: any) {
                if (ressource.montant != null) {
                    if (ressource.montant <= Number(response.data.montant)) {
                        await  ressourceService.postRessourcePayment( ressource ).then(async function (response: any) {
                            confirmAlert({
                                overlayClassName: "alert-overlay",
                                customUI: ({ onClose }) => {
                                  return (
                                        <Alert 
                                            alert={{
                                                title: 'Succès',
                                                message: 'Payement ajouté avec succès.',
                                                actionText: 'Ok',
                                                action: () => {
                                                    onClose();
                                                }
                                            }}
                                        />
                                  );
                                }
                            });
                            cancel();
                            getRessources();
                            setLoading(false);
                        })
                        .catch(function (error: any) {
                            setLoading(false); 
                        });
                    } else {
                        setLoading(false); 
                        confirmAlert({
                            overlayClassName: "alert-overlay",
                            customUI: ({ onClose }) => {
                              return (
                                    <Alert 
                                        alert={{
                                            type: 'error',
                                            title: 'Erreur',
                                            message: 'Le montant entré est incorrect, nous ne pouvons pas donné de suite à votre requête.',
                                            actionText: 'Ok',
                                            action: () => {
                                                onClose();
                                            }
                                        }}
                                    />
                              );
                            }
                        });
                    }
                }    
            })
            .catch(function (error: any) {
                setLoading(false); 
            });
        }
       
    }

    const updateRessourcePayment = async (ressource : Ressource ) => {
        setLoading(true); 
        await  ressourceService.updateRessourcePayment( ressource ).then(async function (response: any) {
            confirmAlert({
                overlayClassName: "alert-overlay",
                customUI: ({ onClose }) => {
                  return (
                        <Alert 
                            alert={{
                                title: 'Succès',
                                message: 'Payement mis à jour avec succès.',
                                actionText: 'Ok',
                                action: () => {
                                    onClose();
                                }
                            }}
                        />
                  );
                }
            });
            cancel();
            getRessources();
            setLoading(false);
        })
        .catch(function (error: any) {
            setLoading(false); 
        });
    }

    useEffect(() => {
        getRessources();
    }, []);


    const exportToPdf = () => {
        JsToPdf( payments , false);
    }
    
    const JsToPdf = (approDataContent: Array<any>, includeImge=false) => {
        console.log(approDataContent);
            if (approDataContent) {
              if (approDataContent.length) {
              const reportData = [
                  {
                    field: 'Ressource',
                    value: selectedRessource.libMat
                  }
              ];
              setPaymentLoading(true);
              let content = '';
                //   '<!Doctype html>'+
                //     '<html>'+
                //     '<head>'+
                //     '<meta charset="utf-8">';
              content = content + '<style>' + 'table tr:nth-child(2n){background-color: rgba(240,240,240,1)} ' +
                /*'table tr:nth-child(16){border-top: solid 1px #000000}' +*/
                'table tr td:last-child{border-right: solid 1px #000000 !important;}' +
                'table thead th:last-child{border-right: solid 1px #000000 !important;}' +
                '.total-table tr:first-child td{border-top: solid 1px #000000 !important;}' +
                /*table thead th:nth-child(1){width: 130px !important;}' +
                'table thead th:nth-child(5){width: 110px !important;}' +*/
                '</style>' +
                // '</head>' +
                // '<body>' +
                '<div class="print-header-content" style="width: 100%; padding: 0; margin: 0; float: left;">\n' +
                '    <div class="print-logo" style="margin: 0px 0; padding: 0; float: left;">\n' +
                '      <img src="assets/images/logo.jpg" alt="logo-chronocash" style="width: 60px;">\n' +
                '    </div>\n' +
                '    <div class="print-header-text" style="margin: 0; padding: 0; float: right;">\n' +
                '      <ul style="padding: 0; margin: 0 20px 0 0; float: left;">\n' +
                '        <li style="display: inline-block; list-style-type: none; color: #000000;"><b>Tél :</b></li>\n' +
                '        <li style="display: inline-block; list-style-type: none; color: #000000;">22 22 21 21</li>\n' +
                '      </ul>\n' +
                '      <ul style="padding: 0; margin: 0 20px 0 0; float: left;">\n' +
                '        <li style="display: inline-block; list-style-type: none; color: #000000;"><b>Site web :</b></li>\n' +
                '        <li style="display: inline-block; list-style-type: none; color: #000000;">boutique.com</li>\n' +
                '      </ul>\n' +
                '      <ul style="padding: 0; margin: 0; float: left;">\n' +
                '        <li style="display: inline-block; list-style-type: none; color: #000000;"><b>Email :</b></li>\n' +
                '        <li style="display: inline-block; list-style-type: none; color: #000000;">contact@boutique.com</li>\n' +
                '      </ul>\n' +
                '    </div>\n' +
                '</div>';
    
                content = content + '<table class="total-table" style="border-collapse: collapse; width: 49%; margin: 8px 2% 30px 0; float: left;">';
              
                reportData.forEach(
                  (line, index) => {
                      content = content + '<tr>' + '<td style="width: 200px; border: solid 1px #000000; padding: 5px 8px; ' +
                      'text-align: left; border-top: none; border-right: none; font-weight: 600; background-color: rgba(240, 240, 240, 1);">' +
                      line.field + '</td>' + '<td style="background-color: #ffffff; border: solid 1px #000000; padding: 5px 8px; text-align: left;' +
                      'border-top: none; border-right: none;">' + line.value + '</td>' + '</tr>';
                  }
                ); 
            
                content = content + '</table>';
                
                // '<div style="width: 100%; padding: 0; margin: 0;">' + '<h1 style="width: 100%; text-align: center; font-size: 1.6em;' +
                // ' float: left; color: #000000; ' + 'font-weight: normal; margin: 10.5px 0; padding: 0;">' +
                // '<b>LISTE DES VENTES</b></h1>'+
                // '</div>';
        
              content = content + '<table style="border-collapse: collapse; width: 97%; margin-top: 0px; float: left;">';
        
              let page = 0;
        
              content = content + '<tbody>';
              approDataContent.forEach(
                  (line, index) => {
                    content = content + '<tr>' + '<td style="border: solid 1px #000000; padding: 3px 5px; text-align: left;' +
                                'border-top: none; border-right: none;">'
                                + line.qte + '</td>' + 
                                '<td style="border: solid 1px #000000; padding: 3px 5px; text-align: left;' +
                                'border-top: none; border-right: none;">'
                                + line.pu + '</td>' + 
                                '<td style="border: solid 1px #000000; padding: 3px 5px; text-align: left;' +
                                'border-top: none; border-right: none;">'
                                + line.montant + '</td>' + 
                                '<td style="border: solid 1px #000000; padding: 3px 5px; text-align: left;' +
                                'border-top: none; border-right: none;">'
                                + line.agent.prenomAgent + ' ' + line.agent.nomAgent + '</td>'+ 
                                '<td style="border: solid 1px #000000; padding: 3px 5px; text-align: left;' +
                                'border-top: none; border-right: none;">'
                                + line.dateOperation + '</td>';
                    content = content + '</tr>';
    
                    const fisrtPageCount = 25;
                    const restPageCount = 25;
                    const k = 29;
            
                    if ( (index + 1) === fisrtPageCount) {
                      page = page + 1;
                      content = content + '<tr style="background-color: transparent; border-bottom: solid 1px #000000;"><td colspan="100" ' +
                        'class="html2pdf__page-break" style="background-color: transparent; border-right: none !important;"></td></tr>';
                    }
                    if ( ((index + 1) % k === restPageCount) && (index > restPageCount) ) {
                      page = page + 1;
                      content = content + '<tr style="background-color: transparent; border-bottom: solid 1px #000000;"><td colspan="100" ' +
                        'class="html2pdf__page-break" style="background-color: transparent; border-right: none !important;"></td></tr>';
                    }
                  }
                );
              
              content = content + '</tbody>';
        
              content = content + '<thead>';
        
              content = content + '<tr><th style="background-color: rgba(240, 240, 240, 1); border: solid 1px #000000; border-right: none; ' +
                ' text-align: center;' +
                'padding: 2px 4px; text-align: left;">' + 'Quantité' + '</th>' + 
                '<th style="background-color: rgba(240, 240, 240, 1); border: solid 1px #000000; border-right: none; ' +
                ' text-align: center;' +
                ' padding: 2px 4px; text-align: left;">' + 'Prix unitaire' + '</th>' +
              
                '<th style="background-color: rgba(240, 240, 240, 1); border: solid 1px #000000; border-right: none; ' +
                ' text-align: center;' +
                ' padding: 2px 4px; text-align: left;">' + 'Montant' + '</th>' +
    
                '<th style="background-color: rgba(240, 240, 240, 1); border: solid 1px #000000; border-right: none; ' +
                ' text-align: center;' +
                ' padding: 2px 4px; text-align: left;">' + 'Agent' + '</th>'+
                
                '<th style="background-color: rgba(240, 240, 240, 1); border: solid 1px #000000; border-right: none; ' +
                ' text-align: center;' +
                ' padding: 2px 4px; text-align: left;">' + 'Date' + '</th>';
              
              content = content + '</tr>';
        
              content = content + '</thead>';  
        
              content = content + '</table>';
        
            //   content = content + '</body></html>';

            //   console.log(content);

              html2pdf().from(content).set({
                margin:       1,
                filename:     'ressource_' + selectedRessource?.libMat  + '_payements_' + Date.now() + '.pdf',
                image:        { type: 'jpeg', quality: 1 },
                html2canvas:  { dpi: 192 },
                jsPDF:        { unit: 'cm', format: 'a4', orientation: 'portrait' }
              }).save();

              
            // HTMLtoPDF(content, {
            //     margin:       1,
            //     filename:     'ressource_' + selectedRessource?.libMat  + '_payements_' + Date.now() + '.pdf',
            //     image:        { type: 'jpeg', quality: 1 },
            //     html2canvas:  { dpi: 192 },
            //     jsPDF:        { unit: 'cm', format: 'a4', orientation: 'portrait' }
            // });
    
            //   html2pdf(content, {
            //     margin:       1,
            //     filename:     'ressource_' + selectedRessource?.libMat  + '_payements_' + Date.now() + '.pdf',
            //     image:        { type: 'jpeg', quality: 1 },
            //     html2canvas:  { dpi: 192 },
            //     jsPDF:        { unit: 'cm', format: 'a4', orientation: 'portrait' }
            //   });

        
              setPaymentLoading(false);
              } else {
                setPaymentLoading(false);
                alert("Pas de payements disponibles.");
              }
              } else {
                setPaymentLoading(false);
                alert("Pas de payements disponibles.");
              }
      }



    return ( 
        <>  
            <Breadcrumb />

            <div className="container-fluid">

                <div className="row">
                    <div className="col-md-5">
                        <div className="card">
                            <div className="card-body">

                                <h4 className="card-title">
                                    {
                                        selectedRessource == null ? 
                                        t("Ajouter une ressource") :
                                        t("Mettre à jour ressource")
                                    }
                                </h4>

                                <Formik
                                    initialValues={
                                        {
                                        id: null,
                                        idRes: null,
                                        qteRes: null,
                                        puRes: null,
                                        montant: null,
                                        agent: user?.id 
                                    }}
                                    validationSchema={
                                        yup.object().shape({ 
                                            idRes: yup
                                                .string()
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            qteRes: yup
                                                .number()
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            puRes: yup
                                                .number()
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            montant: yup
                                                .number()
                                        })
                                    }
                                    onSubmit={async (
                                        values 
                                    ) => { 
                                        console.log(formRef.current?.values);
                                        if (values.id != null && values.id != undefined) {
                                            updateRessourcePayment( Ressource.fromJson(values) );
                                        } else {
                                            addPaymentToRessource( Ressource.fromJson(values) );
                                        }
                                    }} 
                                    innerRef={formRef}
                                    >
                                        {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                                    <Form>
                                                        <div className="form-body">

                                                        <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                            
                                                        <label>
                                                            { t('Ressource') }
                                                        </label>
                                                        {
                                                            loading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <select  
                                                                className={`form-control custom-select ${ errors.idRes &&
                                                                    touched.idRes ? "is-invalid" : "" }`}
                                                                id="idRes" 
                                                                name="idRes" 
                                                                onChange={handleChange('idRes')}
                                                                onBlur={handleBlur('idRes')}
                                                                value={values.idRes == null ? '' : values.idRes}
                                                                placeholder={ t('Ressource') ?? ''} >
                                                                <option selected>{ t('Ressource') }..</option>
                                                                {
                                                                    ressources.map(
                                                                        (res: any, id) => <option 
                                                                         key={id} value={ res.ressource.id }>
                                                                            {
                                                                               res.ressource.libMat
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </select> 
                                                        }
                                                        </div>
                                                        </div>
                                                        </div>
                                                           
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="form-group">

                                                                        <label>
                                                                            { t('Quantité ressource') } </label>
                                                                        <Field type="number"
                                                                            className={`form-control 
                                                                            ${ errors.qteRes && touched.qteRes ? "is-invalid":""}`}
                                                                            id="qteRes" 
                                                                            name="qteRes"  
                                                                            onChange={(event: any) => {
                                                                                handleChange('qteRes')(event);
                                                                                getRessourceAmount(event.target.value);
                                                                            }}
                                                                            onBlur={handleBlur('qteRes')}
                                                                            value={values.qteRes == null ? '' : values.qteRes }
                                                                            placeholder={ t('Quantité ressource') } />
                                                                        { errors.qteRes && touched.qteRes && errors.qteRes && 
                                                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                { errors.qteRes && touched.qteRes && errors.qteRes }
                                                                            </div> }

                                                                    </div>
                                                                </div>
                                                            </div> 


                                                            
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="form-group">

                                                                        <label> { t('Prix unitaire') } </label>
                                                                        <Field type="number"
                                                                            className={`form-control 
                                                                            ${ errors.puRes && touched.puRes ? "is-invalid":""}`}
                                                                            id="puRes" 
                                                                            name="puRes" 
                                                                            onChange={(event: any) => { 
                                                                                handleChange('puRes')(event);
                                                                                getRessourceAmount(null, event.target.value);
                                                                            }}
                                                                            onBlur={handleBlur('puRes')}
                                                                            value={values.puRes == null ? '' : values.puRes }
                                                                            placeholder={ t('Prix unitaire') } />
                                                                        { errors.puRes && touched.puRes && errors.puRes && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                            { errors.puRes && touched.puRes && errors.puRes }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <br />

                                                            {
                                                                formRef.current != null && formRef.current.values.montant != null
                                                                 && formRef.current.values.montant != 0 &&
                                                                <p>
                                                                    <b>
                                                                        {t('Montant')}: 
                                                                    </b> 
                                                                    {
                                                                       " " + formRef.current.values.montant
                                                                    }
                                                                </p>
                                                            } 

                                                        </div>

                                                        <div className="form-actions">
                                                            <div className="text-right">
                                                                <button disabled={!(isValid && dirty)} type="submit" className="btn btn-info">
                                                                    { t('Valider') }
                                                                    {
                                                                        loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    }
                                                                </button>
                                                                <button type="button" onClick={event => cancel()}
                                                                className="btn btn-dark">
                                                                    { t('Annuler') }
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </Form>
                                            )}
                                    </Formik>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">
                                    { t('Liste ressources') }
                                </h4>
                                <div className="btn-list">
                                    <button type="button" onClick={ event => getRessources() }
                                        className="btn waves-effect waves-light btn-success">
                                            { t('Actualiser') }
                                        </button>
                                </div> 
                                <div className="table-responsive">
                                    <DataTable
                                        responsive={true}
                                        className="table table-striped table-bordered"
                                        columns={columns}
                                        data={ressources}
                                        progressPending={loading}
                                        pagination
                                    />
                                </div>
                            </div>
                        </div> 
                    </div> 
                </div>

            </div>   

            {/* Payments list Modal */}

            <Modal 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={ paymentsModal }
                onHide={() => setPaymentsModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('Liste payements') } {">"} <b>{ selectedRessource?.libMat }</b>
                        
                    </Modal.Title> 
                </Modal.Header>
                <div style={{ width: "100%", paddingTop: "8px", paddingRight: "8px" }}>
                                    <button style={{float: 'right'}} type="button" onClick={ event => exportToPdf() }
                                        className="btn waves-effect waves-light btn-success">
                                            { t('Imprimer') }
                                            {
                                                paymentLoading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            }
                                    </button>
                </div> 
                <Modal.Body>
                    <div className="table-responsive">
                                        <DataTable
                                            responsive={true}
                                            className="table table-striped table-bordered"
                                            columns={paymentsListColumns}
                                            data={payments}
                                            progressPending={loading}
                                            pagination
                                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                {/* <Button onClick={props.onHide}>Close</Button> */}
                </Modal.Footer>
            </Modal>

        </>                   
    );

}

export default RessourcePage