import {
  Menu, MenuItem
} from '../Models/Menu';

const menu: Menu = new Menu([
  {
    id: 1,
    name: "ACCUEIL",
    link: "/",
    icon: "home",
    subMenu: []
  },
  {
    id: 22,
    name: "MESSAGERIE",
    link: "",
    icon: "mail",
    subMenu: [
      {
        id: 22,
        name: "Liste",
        link: "/message/list",
        icon: "",
        subMenu: []
      }, 
      {
        id: 22,
        name: "Ajouter",
        link: "/message/create",
        icon: "",
        subMenu: []
      },
    ]
  },
  {
    id: 5,
    name: "PRODUIT",
    link: "",
    icon: "layers",
    subMenu: [
      {
        id: 5,
        name: "Liste des produits",
        link: "/product/list",
        icon: "",
        subMenu: []
      },
      {
        id: 5,
        name: "Ajouter un produit",
        link: "/product/create",
        icon: "",
        subMenu: []
      },
      {
        id: 5,
        name: "Kit produit",
        link: "/product/kit/list",
        icon: "",
        subMenu: []
      }
    ]
  },
  {
    id: 5,
    name: "BOUTIQUE",
    link: "",
    icon: "home",
    subMenu: [
      {
        id: 5,
        name: "Liste des boutiques",
        link: "/boutique/list",
        icon: "",
        subMenu: []
      },
      {
        id: 5,
        name: "Ajouter une boutique",
        link: "/boutique/create",
        icon: "",
        subMenu: []
      }
    ]
  },
  {
    id: 2,
    name: "AGENT",
    link: "",
    icon: "users",
    subMenu: [
      {
        id: 2,
        name: "Liste des agents",
        link: "/agent/list",
        icon: "",
        subMenu: []
      },
      {
        id: 2,
        name: "Ajouter un agent",
        link: "/agent/create",
        icon: "",
        subMenu: []
      }
    ]
  },
  {
    id: 6,
    name: "CLIENT",
    link: "",
    icon: "users",
    subMenu: [
      {
        id: 6,
        name: "Liste des clients",
        link: "/client/list",
        icon: "",
        subMenu: []
      },
      {
        id: 6,
        name: "Ajouter un client",
        link: "/client/create",
        icon: "",
        subMenu: []
      }
    ]
  },
  {
    id: 7,
    name: "APPROVISONNEMENT",
    link: "",
    icon: "shopping-cart",
    subMenu: [
      {
        id: 7,
        name: "Liste des approvisonnements",
        link: "/approvisionnement/list",
        icon: "",
        subMenu: []
      },
      {
        id: 7,
        name: "Ajouter un approvisonnement",
        link: "/approvisionnement/create",
        icon: "",
        subMenu: []
      }
    ]
  },
  {
    id: 8,
    name: "INVENTAIRE",
    link: "",
    icon: "shopping-cart",
    subMenu: [
      {
        id: 8,
        name: "Liste des inventaires",
        link: "/inventaire/list",
        icon: "",
        subMenu: []
      },
      {
        id: 8,
        name: "Ajouter un inventaire manuel",
        link: "/inventaire/create/manuel",
        icon: "",
        subMenu: []
      },
      {
        id: 8,
        name: "Ajouter un inventaire automtique",
        link: "/inventaire/create/automatique",
        icon: "",
        subMenu: []
      }
    ]
  },
  {
    id: 9,
    name: "VENTE",
    link: "",
    icon: "shopping-cart",
    subMenu: [
      {
        id: 9,
        name: "Liste des ventes",
        link: "/vente/list",
        icon: "",
        subMenu: []
      },
      {
        id: 9,
        name: "Liste des ventes du site",
        link: "/vente/web",
        icon: "",
        subMenu: []
      },
      {
        id: 8,
        name: "Ajouter une vente manuelle",
        link: "/vente/create/manuel",
        icon: "",
        subMenu: []
      },
      {
        id: 8,
        name: "Ajouter une vente automtique",
        link: "/vente/create/automatique",
        icon: "",
        subMenu: []
      }
    ]
  },
  {
    id: 3,
    name: "CONFIGURATION",
    link: "",
    icon: "settings",
    subMenu: [
      {
        id: 3,
        name: "Ajouter gamme",
        link: "/configuration/gamme",
        icon: "",
        subMenu: []
      },
      {
        id: 3,
        name: "Ajouter type",
        link: "/configuration/type",
        icon: "",
        subMenu: []
      },
      {
        id: 3,
        name: "Ajouter catégorie",
        link: "/configuration/category",
        icon: "",
        subMenu: []
      },
      {
        id: 3,
        name: "Ajouter droit",
        link: "/configuration/droit",
        icon: "",
        subMenu: []
      },
      {
        id: 3,
        name: "Attribuer droit",
        link: "/configuration/attribuer-droit",
        icon: "",
        subMenu: []
      }
    ]
  },
  {
    id: 10,
    name: "SALAIRES",
    link: "",
    icon: "dollar-sign",
    subMenu: [
      {
        id: 10,
        name: "Types de salaire",
        link: "/salaire/type",
        icon: "",
        subMenu: []
      },
      {
        id: 10,
        name: "Payer salaire",
        link: "/salaire/payer",
        icon: "",
        subMenu: []
      },
      {
        id: 10,
        name: "Salaires supervisés",
        link: "/salaire/supervises",
        icon: "",
        subMenu: []
      } 
    ]
  },
  {
    id: 11,
    name: "DÉPENSES",
    link: "",
    icon: "dollar-sign",
    subMenu: [
      {
        id: 11,
        name: "Types de dépenses",
        link: "/depense/type",
        icon: "",
        subMenu: []
      },
      {
        id: 11,
        name: "Liste dépense",
        link: "/depense/list",
        icon: "",
        subMenu: []
      },
      {
        id: 11,
        name: "Ajouter une dépense",
        link: "/depense/create",
        icon: "",
        subMenu: []
      },
      {
        id: 11,
        name: "Facture",
        link: "/depense/bill",
        icon: "",
        subMenu: []
      } 
    ]
  },
  {
    id: 12,
    name: "COMPTABILITÉ",
    link: "/compta/ventes",
    icon: "file-text",
    subMenu: []
  },
  {
    id: 13,
    name: "HISTORIQUE",
    link: "/historique",
    icon: "file-text",
    subMenu: []
  },
  {
    id: 14,
    name: "HORAIRES",
    link: "/horaires",
    icon: "file-text",
    subMenu: []
  },
  {
    id: 15,
    name: "REPARTITION INTERÊT",
    link: "",
    icon: "dollar-sign",
    subMenu: [
      {
        id: 15,
        name: "Associés",
        link: "/repartition-interet/associes",
        icon: "",
        subMenu: []
      },
      {
        id: 15,
        name: "Retrait",
        link: "/repartition-interet/retrait",
        icon: "",
        subMenu: []
      },
      {
        id: 15,
        name: "Retrait supervisés",
        link: "/repartition-interet/retrait-supervises",
        icon: "",
        subMenu: []
      }
    ]
  },
  {
    id: 16,
    name: "IMPÔTS",
    link: "",
    icon: "dollar-sign",
    subMenu: [
      {
        id: 16,
        name: "Payement",
        link: "/impots/payement",
        icon: "",
        subMenu: []
      },
      {
        id: 16,
        name: "Rapport",
        link: "/impots/rapport",
        icon: "",
        subMenu: []
      },
    ]
  },
  {
    id: 17,
    name: "PROMOTION",
    link: "",
    icon: "gift",
    subMenu: [
      {
        id: 17,
        name: "Liste",
        link: "/promotion/list",
        icon: "",
        subMenu: []
      },
      {
        id: 17,
        name: "Ajouter",
        link: "/promotion/create",
        icon: "",
        subMenu: []
      } 
    ]
  },
  {
    id: 18,
    name: "REDUCTION",
    link: "",
    icon: "gift",
    subMenu: [
      {
        id: 18,
        name: "Liste",
        link: "/reduction/list",
        icon: "",
        subMenu: []
      },
      {
        id: 18,
        name: "Ajouter",
        link: "/reduction/create",
        icon: "",
        subMenu: []
      } 
    ]
  },
  {
    id: 19,
    name: "TEMPLATE",
    link: "",
    icon: "file-text",
    subMenu: [
      {
        id: 19,
        name: "Liste",
        link: "/template/list",
        icon: "",
        subMenu: []
      },
      {
        id: 19,
        name: "Ajouter",
        link: "/template/create",
        icon: "",
        subMenu: []
      },
      {
        id: 19,
        name: "Envoyer mail",
        link: "/template/send-mail",
        icon: "",
        subMenu: []
      },
      {
        id: 19,
        name: "Imprimer flyers",
        link: "/template/imprimer-flyers",
        icon: "",
        subMenu: []
      } 
    ]
  },
  {
    id: 20,
    name: "AMORTISSEMENT",
    link: "",
    icon: "file-text",
    subMenu: [
      {
        id: 20,
        name: "Liste",
        link: "/amortissement/list",
        icon: "",
        subMenu: []
      }, 
      {
        id: 20,
        name: "Ajouter",
        link: "/amortissement/create",
        icon: "",
        subMenu: []
      },
    ]
  },
  {
    id: 21,
    name: "TRANSPORT",
    link: "",
    icon: "truck",
    subMenu: [
      {
        id: 21,
        name: "Liste",
        link: "/transport/list",
        icon: "",
        subMenu: []
      }, 
      {
        id: 21,
        name: "Ajouter",
        link: "/transport/create",
        icon: "",
        subMenu: []
      },
    ]
  },
  {
    id: 4,
    name: "RESSOURCE",
    link: "/ressource",
    icon: "shopping-cart",
    subMenu: []
  },
  {
    id: 22,
    name: "ANALYTICS",
    link: "",
    icon: "sliders",
    subMenu: [
      {
        id: 22,
        name: "Apercu global",
        link: "/analytics/overview",
        icon: "",
        subMenu: []
      },
      {
        id: 22,
        name: "Produits",
        link: "/analytics/products",
        icon: "",
        subMenu: []
      },
      {
        id: 22,
        name: "Revenue",
        link: "/analytics/revenue",
        icon: "",
        subMenu: []
      },
      {
        id: 22,
        name: "Commandes",
        link: "/analytics/orders",
        icon: "",
        subMenu: []
      },
      {
        id: 22,
        name: "Catégories",
        link: "/analytics/categories",
        icon: "",
        subMenu: []
      },
      {
        id: 22,
        name: "Coupons",
        link: "/analytics/coupons",
        icon: "",
        subMenu: []
      },
      {
        id: 22,
        name: "Stocks",
        link: "/analytics/stocks",
        icon: "",
        subMenu: []
      }  
    ]
  },
  {
    id: 23,
    name: "INVESTISSEMENTS",
    link: "/investissements",
    icon: "dollar-sign",
    subMenu: []
  },
  {
    id: 24,
    name: "CONCENTRATION INGREDIENT",
    link: "/concentration&ingredient",
    icon: "shopping-cart",
    subMenu: []
  },
  {
    id: 25,
    name: "DECLARATION & ASSURANCES",
    link: "/declaration&assurances",
    icon: "book",
    subMenu: []
  },
  {
    id: 26,
    name: "CAMERA & SURVEILLANCE",
    link: "/camera",
    icon: "camera",
    subMenu: []
  },
  {
    id: 27,
    name: "REPERTOIRE DE CONTACT",
    link: "/repertoire",
    icon: "book-open",
    subMenu: []
  },
  {
    id: 28,
    name: "REFERENCEMENT",
    link: "/reference",
    icon: "code",
    subMenu: []
  },
  {
    id: 29,
    name: "AIDE & ACTION",
    link: "/aide&action",
    icon: "help-circle",
    subMenu: []
  }
]);

export default menu