import User from '../../Models/User';
import axiosInstance from '../../Utils/axios_manager';

export default class InventaireService {
  
    constructor( ) {
    }

    public postClient =  (user: User) => {
        return axiosInstance.post('/client/add', User.toJson(user));
    }

    public getInventaires =  async () => {
        return await axiosInstance.get('/inv/list');
    }

    public getClient =  async (id: Number) => {
        return await axiosInstance.get('/client/details' + `?id=${id.toString()}`);
    }

    public putClient =  (user: User) => {
        return axiosInstance.post('/client/update', User.toJson(user));
    }

    public saveInventaire =  (approv: Record<string, any>) => {
        return axiosInstance.post('/inv/create', approv);
    }

    public getProductByBarcode = (params: Record<string, any>) => {
        return axiosInstance.post('/produit/list/barcode/filtred/simple', {
            params: params
        });
    }

    
  

}