export default class SpentType {

    public id?: number | null;
    public libelleDep?: string;
    public descDep?: string;


    static fromJson(parsedJson: Record<string, any>): SpentType {
        return new SpentType(
            Number(parsedJson['id']),
            parsedJson['libelleDep'],
            parsedJson['descDep']
        );
    }

    static toJson(spentType: SpentType): Record<string, any>  {
        return  {
            "id": spentType.id,
            "libelleDep": spentType.libelleDep, 
            "descDep": spentType.descDep
        };
    }


    constructor(id: number, libelle: string, description: string) {
        this.id = id;
        this.libelleDep = libelle;
        this.descDep = description;
    }
}