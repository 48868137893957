import { FC, useEffect, useRef, useState } from 'react'
 

//Alert
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';  

import AgentService from '../Agent/service'; 


import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 


import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import ApprovService from './service'; 

//Moment react
import Moment from 'react-moment';

import {
    Link, useNavigate
} from "react-router-dom";

import InterestRepartitionService from './service';

import Alert from '../../Components/Alert';
import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
import { useTranslation } from 'react-i18next';

interface WithdrawalFormProps {
    id?: string | null ;
    idAss: string; 
    dteDeb: Date | null ;
    dteFin: Date | null ;
    montantTotal: string;
    montantRet: string;
}



const WithdrawalPage: FC = () => {  

    const formRef = useRef< FormikProps<WithdrawalFormProps>>(null);
    const { t, i18n } = useTranslation();

    let idToModInit: any = null;
    const [ withdrawals, setWithdrawals ] = useState( [] );
    const [ idToMod, setIdToMod ] = useState(idToModInit);
    const [ loading, setLoading ] = useState(false);
    const [ associatesLoading, setAssociatesLoading ] = useState(false);

    const [ totalAmountDisabled, setTotalAmountDisabled ] = useState(true);

    const initialSelectedGamme: any = null

    const [
        deleteLoading, setDeleteLoading
    ] = useState(false);

    const [
        selectedWithdrawal,
        setSelectedWithdrawal
    ] = useState(initialSelectedGamme);

    const id: Number = 0;
    const [
        deleteId, setDeleteId
    ] = useState(id);

    const [ associes, setAssociates ] = useState( [] ); 

    const [
        interestLoading, setInterestLoading
    ] = useState(false); 

    const interestRepartitionService = new InterestRepartitionService();

    const navigate = useNavigate();

    let editAgent = (id: Number): void => {
        setIdToMod(id);
        setLoading(true);
        navigate('/agent/edit/'+id.toString());
        setLoading(false);
    }

    const getUnsupervisedWithdrawalList = () => {
        setLoading(true);
        interestRepartitionService.getUnsupervisedWithdrawalList().then(async function (response: any) {
            console.log(response);
            setWithdrawals(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    const getAssociates = () => {
        setAssociatesLoading(true);
        interestRepartitionService.getAssociates().then(async function (response: any) {
            console.log(response);
            setAssociates(response.data);
            setAssociatesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setAssociatesLoading(false); 
        });
    }

    useEffect( function() { 
        getUnsupervisedWithdrawalList();
        getAssociates();
    }, []);

    const columns = [
        {
            name: t('Associé'),
            selector: (row: any) => row.agent.prenomAgent + ' ' + row.agent.nomAgent,
        }, 
        {
            name: t("Pourcentage"),
            selector: (row: any) => row.pourcentage,
        },
        {
            name: t("Date de début"),
            selector: (row: any) => <Moment format="YYYY/MM/DD">
                                        {row.dateDeb}
                                    </Moment>
        },
        {
            name: t("Date de fin"),
            selector: (row: any) => <Moment format="YYYY/MM/DD">
                                        {row.dateFin}
                                    </Moment>
        }, 
        {
            name: t('Date retrait'),
            selector: (row: any) => <Moment format="YYYY/MM/DD">
                                        {row.dateRet}
                                    </Moment>
        },
        {
            name: "Options", 
            selector: (row: any) => (
                <div className='btn-list'> 
                    <button type="button" onClick={event => {    }}
                        className="btn waves-effect waves-light btn-danger">
                            {
                                deleteId == row.id && deleteLoading ?
                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> :
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-trash"></i></span>
                            }
                    </button>
                </div>
            )
        }
    ];

    const cancel = () => {

        setSelectedWithdrawal(null);

        if ( formRef.current != null) {
            formRef.current.values.id = null;
            formRef.current.values.idAss =  '';
            formRef.current.values.dteDeb = null;
            formRef.current.values.dteFin = null;
            formRef.current.values.montantTotal =  '';
            formRef.current.values.montantRet =  '';
        } 
    }

    const getInterest = (data: Record<string, any>) => {
        setInterestLoading(true);
        interestRepartitionService.getInterest(data).then(async function (response: any) {
            console.log(response); 
            setInterestLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setInterestLoading(false); 
        });
    }
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-md-5">
                            <div className="card">
                                <div className="card-body">

                                    <h4 className="card-title">
                                        {
                                            selectedWithdrawal == null ? 
                                            t("Ajouter un retrait") :
                                            t("Mettre à jour retrait")
                                        }
                                    </h4>

                                    <Formik
                                        initialValues={
                                            {
                                                id: null,
                                                idAss: '', 
                                                dteDeb: null,
                                                dteFin: null, 
                                                montantTotal: '',
                                                montantRet: ''
                                        }}
                                        validationSchema={
                                            yup.object().shape({
                                                idAss: yup
                                                    .string() 
                                                    .required(`${t('Ce champ est obligatoire')}`),
                                                dteDeb: yup
                                                    .date() 
                                                    .required(`${t('Ce champ est obligatoire')}`),
                                                dteFin: yup
                                                    .date() 
                                                    .required(`${t('Ce champ est obligatoire')}`),
                                                montantTotal: yup
                                                    .string() 
                                                    .required(`${t('Ce champ est obligatoire')}`),
                                                montantRet: yup
                                                    .string() 
                                                    .required(`${t('Ce champ est obligatoire')}`),
                                            })
                                        }
                                        onSubmit={async (
                                            values 
                                        ) => {

                                            setLoading(true);

                                            if(selectedWithdrawal == null) {

                                                await interestRepartitionService.postAssociates( values ).then(async function (response: any) {
                                                    console.log(response);

                                                    confirmAlert({
                                                        overlayClassName: "alert-overlay",
                                                        customUI: ({ onClose }) => {
                                                        return (
                                                                <Alert 
                                                                    alert={{
                                                                        title: 'Succès',
                                                                        message: 'Associé créé avec succès',
                                                                        actionText: 'Ok',
                                                                        action: () => {
                                                                            onClose();
                                                                        }
                                                                    }}
                                                                />
                                                        );
                                                        }
                                                    });
    
                                                    if ( formRef.current != null) {
                                                        formRef.current.values.id = null;
                                                        formRef.current.values.idAss =  '';
                                                        formRef.current.values.dteDeb = null;
                                                        formRef.current.values.dteFin = null;
                                                        formRef.current.values.montantTotal =  '';
                                                        formRef.current.values.montantRet =  '';
                                                    } 

                                                    getAssociates();
                                                    setLoading(false); 

                                                })
                                                .catch(function (error: any) {
                                                    setLoading(false); 
                                                });

                                            }  else {

                                                await interestRepartitionService.putAssociate( values ).then(async function (response: any) {
                                                    console.log(response); 
                                                    confirmAlert({
                                                        overlayClassName: "alert-overlay",
                                                        customUI: ({ onClose }) => {
                                                        return (
                                                                <Alert 
                                                                    alert={{
                                                                        title: 'Succès',
                                                                        message: 'Associé mis à jour avec succès',
                                                                        actionText: 'Ok',
                                                                        action: () => {
                                                                            onClose();
                                                                        }
                                                                    }}
                                                                />
                                                        );
                                                        }
                                                    }); 

                                                    if ( formRef.current != null) {
                                                        formRef.current.values.id = null;
                                                        formRef.current.values.idAss =  '';
                                                        formRef.current.values.dteDeb = null;
                                                        formRef.current.values.dteFin = null;
                                                        formRef.current.values.montantTotal =  '';
                                                        formRef.current.values.montantRet =  '';
                                                    } 

                                                    setSelectedWithdrawal(null);

                                                    getAssociates();
                                                    setLoading(false); 

                                                })
                                                .catch(function (error: any) {
                                                    setLoading(false); 
                                                });

                                            }

                                            
            
                                        }} 
                                        innerRef={formRef}
                                        >
                                            {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                                        <Form>
                                                            <div className="form-body">
                                                                
                                                            <div className="row"> 
                                                                    <div className="col-md-12">
                                                                        
                                                                        <div className="form-group">
                                                                            <label>
                                                                                { t('Associés') }
                                                                            </label>
                                                                            {
                                                                                associatesLoading ? 
                                                                                <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                                                <span className="sr-only">Loading...</span>
                                                                                </div> :
                                                                                <select  
                                                                                    className={`form-control custom-select 
                                                                                    ${ errors.idAss &&
                                                                                        touched.idAss ? "is-invalid" : "" }`}
                                                                                    id="idAss" 
                                                                                    name="idAss"
                                                                                    onChange={event => { 
                                                                                        handleChange('idAss')(event); 
                                                                                    }}
                                                                                    onBlur={handleBlur('idAss')}
                                                                                    value={values.idAss }
                                                                                    placeholder={ t('Associés') ?? ''} >
                                                                                    <option selected>{ t('Associés') }..</option>
                                                                                    {
                                                                                        associes.map(
                                                                                            (asso: any, id) => <option 
                                                                                            key={id} value={asso.id}>
                                                                                                {
                                                                                                    asso.agent.prenomAgent + ' ' + asso.agent.nomAgent
                                                                                                }
                                                                                            </option>
                                                                                        )
                                                                                    }
                                                                                </select> 
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> 

                                                                
                                                                <div className="row">
                                                                    
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                                <label>
                                                                                    { t('Date de début') }
                                                                                </label>
                                                                                <Field type="date"
                                                                                    className={`form-control ${ errors.dteDeb && touched.dteDeb ? "is-invalid":""}`}
                                                                                    id="dteDeb" 
                                                                                    name="dteDeb"
                                                                                    onChange={handleChange('dteDeb')}
                                                                                    onBlur={handleBlur('dteDeb')}
                                                                                    value={values.dteDeb == null ? new Date()  : values.dteDeb }
                                                                                    placeholder={ t('Date de début') ?? '' } />
                                                                                { errors.dteDeb && touched.dteDeb && errors.dteDeb && 
                                                                                <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                    { errors.dteDeb && touched.dteDeb && errors.dteDeb }
                                                                                </div> }
                                                                        </div>
                                                                    </div>
                                                                </div> 


                                                                <div className="row">
                                                                    
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                                <label>
                                                                                    { t('Date de fin') }
                                                                                </label>
                                                                                <Field type="date"
                                                                                    className={`form-control ${ errors.dteFin
                                                                                         && touched.dteFin ? "is-invalid":""}`}
                                                                                    id="dteFin" 
                                                                                    name="dteFin"
                                                                                    onChange={handleChange('dteFin')}
                                                                                    onBlur={handleBlur('dteFin')}
                                                                                    value={values.dteFin == null ? 
                                                                                        new Date()  : values.dteFin }
                                                                                    placeholder={ t('Date de fin') ?? '' } />
                                                                                { errors.dteFin && touched.dteFin &&
                                                                                 errors.dteFin && 
                                                                                <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                    { errors.dteFin && touched.dteFin &&
                                                                                     errors.dteFin }
                                                                                </div> }
                                                                        </div>
                                                                    </div>
                                                                </div> 

                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <button onClick={event  => getInterest(values)} disabled={!values.idAss || !values.dteDeb || !values.dteFin} type="button" className="btn btn-info">
                                                                            { t('Calculer interêt') }
                                                                            {
                                                                                interestLoading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                                    <span className="sr-only">Loading...</span>
                                                                                </div>
                                                                            }
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                
                                                                <br/>

                                                                <div className="row">
                                                                    
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                                <label>
                                                                                    { t('Montant total') }
                                                                                </label>
                                                                                <input type="text"
                                                                                    disabled={totalAmountDisabled}
                                                                                    className={`form-control ${ errors.montantTotal && touched.montantTotal ? "is-invalid":""}`}
                                                                                    id="montantTotal" 
                                                                                    name="montantTotal"
                                                                                    onChange={handleChange('montantTotal')}
                                                                                    onBlur={handleBlur('montantTotal')}
                                                                                    value={values.montantTotal == null ? ''  : values.montantTotal }
                                                                                    placeholder={ t('Montant total') ?? ''} />
                                                                                { errors.montantTotal && touched.montantTotal && errors.montantTotal && 
                                                                                <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                    { errors.montantTotal && touched.montantTotal && errors.montantTotal }
                                                                                </div> }
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                                <div className="row">
                                                                    
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                                <label>
                                                                                    {
                                                                                        t('Montant à retirer')
                                                                                    }
                                                                                </label>
                                                                                <Field type="text"
                                                                                    className={`form-control ${ errors.montantRet && touched.montantRet ? "is-invalid":""}`}
                                                                                    id="montantRet" 
                                                                                    name="montantRet"
                                                                                    onChange={handleChange('montantRet')}
                                                                                    onBlur={handleBlur('montantRet')}
                                                                                    value={values.montantRet == null ? ''  : values.montantRet }
                                                                                    placeholder={ t('Montant à retirer') ?? '' } />
                                                                                { errors.montantRet && touched.montantRet && errors.montantRet && 
                                                                                <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                    { errors.montantRet && touched.montantRet && errors.montantRet }
                                                                                </div> }
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div> 

                                                            </div>

                                                            <div className="form-actions">
                                                                <div className="text-right">
                                                                    <button  disabled={!isValid} type="submit" className="btn btn-info">
                                                                        { t("Valider") }
                                                                        {
                                                                            loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                                <span className="sr-only">Loading...</span>
                                                                            </div>
                                                                        }
                                                                    </button>
                                                                    <button type="button" onClick={event => cancel()}
                                                                    className="btn btn-dark">
                                                                        {  t('Annuler') }
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </Form>
                                                )}
                                        </Formik>

                                </div>
                            </div>
                        </div>
                        <div className="col-7">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-list">
                                    <button type="button" onClick={ event => getUnsupervisedWithdrawalList() }
                                        className="btn waves-effect waves-light btn-danger">
                                            { t('Actualiser') }
                                        </button>
                                        {/* <Link type="button" to="/approvisionnement/create"
                                            className="btn waves-effect waves-light btn-primary">
                                                Ajouter un approvisonnement
                                        </Link> */}
                                    </div>
                                    
                                    <br />
                                    <br />
                                    {/* <h4 className="card-title">Zero Configuration</h4>
                                    <h6 className="card-subtitle">DataTables has most features enabled by default, so all you
                                        need to do to use it with your own tables is to call the construction
                                        function:<code> $().DataTable();</code>. You can refer full documentation from here
                                        <a href="https://datatables.net/">Datatables</a></h6> */}
                                    <div className="table-responsive">
                                        <DataTable
                                            responsive={true}
                                            className="table table-striped table-bordered"
                                            columns={columns}
                                            data={ withdrawals }
                                            progressPending={loading}
                                            pagination
                                        />
                                        </div>
                                    </div>
                                </div> 
                        </div> 
                    </div>

                </div>   
            </>                   

    );
}

export default WithdrawalPage