import Salary from '../../Models/Salary';
import SalaryType from '../../Models/SalaryType';
import Shop from '../../Models/Shop';
import Spent from '../../Models/Spent';
import SpentType from '../../Models/SpentType';
import axiosInstance from '../../Utils/axios_manager';

export default class SpentService {
  
    constructor() { }

    public postSpentType =  (spentType: SpentType ) => {
        return axiosInstance.post('/dep/create',  SpentType.toJson(spentType) );
    }

    public getSpentTypes =  async () => {
        return await axiosInstance.get('/dep/list');
    }

    public getShop =  async (id: Number) => {
        return await axiosInstance.get('/boutique/details' + `?id=${id.toString()}`);
    }

    public putSalaryType =  (salaryType: SalaryType ) => {
        return axiosInstance.post('/salaire/update', SalaryType.toJson(salaryType));
    }

    public deleteSpentType  = ( salaryId: Number ) => {
        return axiosInstance.delete('/dep/delete' + `?id=${salaryId}`);
    }

    ///// 

    public getSpent =  async (id: Number) => {
        return await axiosInstance.get('/dep/details' + `?id=${id.toString()}`);
    }

    public getSpents =  async () => {
        return await axiosInstance.get('/deps/list');
    }

    public postSpents =  async (spent: Spent ) => {
        return axiosInstance.post('/dep/add',  Spent.toJson(spent) );
    }

    public deleteSalary  = ( salaryId: Number ) => {
        return axiosInstance.delete('/salaires/delete' + `?id=${salaryId}`);
    }

    public filterSalaries =  async (data: Record<string, any>) => {
        return await axiosInstance.post('/salaires/list/periode', data);
    }

    public supervizeSalary =  async (data: Record<string, any>) => {
        return await axiosInstance.post('/salaires/supervise', data);
    }

    public getSupervizeSalaries =  async () => {
        return await axiosInstance.get('/salaires/list/supervised');
    }

}