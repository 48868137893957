import { FC, useEffect, useState } from 'react'
import {
    Link, useLocation, useNavigate, useParams
} from "react-router-dom";
import Breadcrumb from '../../Components/Breadcrumb';
import MessageForm from './form';
import MessageService from './service';
import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
import { RootState } from '../../Redux/store';

import { setMessages } from '../../Redux/Reducers/chatReducer';

//Moment react
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';

const MessageChat: FC = () => {  

    const location: any = useLocation();
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation ();

    const user = useAppSelector((state: RootState ) => state.users.user );

    const messages = useAppSelector((state: RootState ) => state.chats.messages );

    const loadMessages = useAppSelector((state: RootState ) => state.chats.loadMessages );

    const messageService = new MessageService();

    let { idReceiver, idChat } = useParams();

    // const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);

    const  isAgentIsSender = (discussion: any) => {
        console.log(discussion);
        return Number(discussion.expediteur.id) === Number(user?.id);
    }

    const getMessages = (showLoading: boolean = true) => {
        if (showLoading) setLoading(true) ;
        messageService.getChatDetail(Number(idChat)).then(async function (response: any) {
            console.log(response); 
            if (response.data.length > 0) {
                dispatch( setMessages(response.data) );
            }
            
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    const downloadFiles = (disc: any) => {
        disc.files.forEach(
          (file: any) => {
            var a : any = document.createElement('A');
            a.href = file;
            a.target = '_blank';
            a.download = file.substr(file.lastIndexOf('/') + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        )
      }

    useEffect(() => {
        getMessages();
    }, []);


    useEffect(() => {
        getMessages(false);
    }, [ loadMessages ]);

    return ( 
        <> 
            <Breadcrumb />

            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <button type="button" onClick={() => { navigate(`/message`); }}
                                    className="btn waves-effect waves-light btn-danger">
                                    { t('Retour') }
                                </button>
                                <button type="button" onClick={() => getMessages()}
                                    className="btn waves-effect waves-light btn-primary">
                                    { t('Actualiser') }
                                </button>
                                <br />
                                <br />

                                { !loading  ?
                                    messages.length === 0 ? 
                                    <h1 style={{ textAlign: "center" }}>
                                        {
                                            t('Pas de message')
                                        }
                                    </h1> :
                                    <div className="col-lg-12  col-xl-12">
                                        <div className="chat-box scrollable position-relative"
                                            style={{ height: "calc(100vh - 111px)" }} >
                                            <ul className="chat-list list-style-none px-3 pt-3">

                                                {

                                                    messages.map(
                                                        (message: any) => {
                                                            if (!isAgentIsSender(message))  {
                                                                return <li className="chat-item list-style-none mt-3">
                                                                    {/* <div className="chat-img d-inline-block"><img
                                                                            src="assets/images/users/1.jpg" alt="user"
                                                                            className="rounded-circle" width="45" />
                                                                        </div> */}
                                                                    <div className="chat-content d-inline-block pl-3">
                                                                        {/* <h6 className="font-weight-medium">James Anderson</h6> */}
                                                                        <div className="msg p-2 d-inline-block mb-1">
                                                                            {
                                                                                message.texteContent
                                                                            }
                                                                            {   message?.files ?
                                                                                message.files.length > 0 ?
                                                                                <>
                                                                                    <ul className="list-inline">
                                                                                        <li className="list-inline-item">
                                                                                        <span onClick={evt => downloadFiles(message)}
                                                                                            style={{
                                                                                                color: 'red', cursor: 'pointer',
                                                                                                marginLeft: '5px' }}
                                                                                                className="fas fa-file"></span>
                                                                                        </li>
                                                                                    </ul> 
                                                                                </>
                                                                                : <></> : <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ float: 'left' }} className="chat-time d-block font-10 mt-1 mr-0 mb-3">
                                                                        <Moment format="YYYY/MM/DD hh:mm:ss">
                                                                            {message.dateredaction}
                                                                        </Moment>
                                                                    </div>
                                                                </li>
                                                            } else {
                                                                return <li className="chat-item odd list-style-none mt-3">
                                                                    <div className="chat-content text-right d-inline-block pl-3">
                                                                        <div className="msg p-2 d-inline-block mb-1">
                                                                            {
                                                                                message.texteContent
                                                                            }
                                                                            {   message?.files ?
                                                                                message.files.length > 0 ?
                                                                                <>
                                                                                    <ul className="list-inline">
                                                                                        <li className="list-inline-item">
                                                                                        <span 
                                                                                            style={{
                                                                                                color: 'red', cursor: 'pointer',
                                                                                                marginLeft: '5px' }}
                                                                                                className="fas fa-file"></span>
                                                                                        </li>
                                                                                    </ul> 
                                                                                </>
                                                                                : <></> : <></> 
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ float: 'right' }} className="chat-time d-block font-10 mt-1 mr-0 mb-3">
                                                                        <Moment format="YYYY/MM/DD hh:mm:ss">
                                                                            {message.dateredaction}
                                                                        </Moment>
                                                                    </div>
                                                                </li>
                                                            }
                                                        }
                                                    )

                                                }
                                            </ul>
                                        </div>
                                        <div className="card-body border-top">
                                            <div className="row">
                                                <div className="col-12">
                                                    <MessageForm state='edit' receiver={ idReceiver } />
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                    :
                                    <div className="spinner-border text-primary spinner-border-lg" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }     
                                 
                                </div>
                            </div> 
                    </div> 
                </div>

            </div>   
        </>                   
    );
}

export default MessageChat