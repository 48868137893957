import { FC, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import MessageService from './service';

import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
import { RootState } from '../../Redux/store';

import {
    Link, useNavigate
} from "react-router-dom";


//Moment react
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';

const MessageList: FC = () => {  

    let idToModInit: any = null;
    const user = useAppSelector((state: RootState ) => state.users.user );
    const { t, i18n } = useTranslation( );
    const [ messages, setMessages ] = useState( [] );
    const [ idToMod, setIdToMod ] = useState(idToModInit);
    const [ loading, setLoading ] = useState(false);
    const messageService = new MessageService();

    const navigate = useNavigate();

    let editAgent = (id: Number): void => {
        setIdToMod(id);
        setLoading(true);
        navigate('/agent/edit/'+id.toString());
        setLoading(false);
    }

    const getMessages = () => {
        setLoading(true);
        messageService.getMessages(user?.id ?? 1).then(async function (response: any) {
            console.log(response);
            setMessages(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    const  isAgentIsSender = (discussion: any) => {
        return Number(discussion.agent1.id) === Number(user?.id);
      }

    useEffect( function() { 
        getMessages();
    }, []);

    const columns = [
        {
            name: `${t('Libellé')}`,
            selector: (row: any) => row.libDiscussion,
        },
        {
            name: `${t('Correspondant')}`,
            selector: (row: any) => isAgentIsSender(row) ? row.agent2.prenomAgent + ' ' + row.agent2.nomAgent
            :  row.agent1.prenomAgent + ' ' + row.agent1.nomAgent ,
        },
        {
            name: `${t("Date de création")}`,
            selector: (row: any) => <Moment format="YYYY/MM/DD hh:mm:ss">
                                        {row.dateCreaction}
                                    </Moment>
        }, 
        {
            name: `${t("Options")}`, 
            selector: (row: any) => (
                <>
                    <button type="button" onClick={event => {
                            navigate(`/message/chat/${isAgentIsSender(row) ? row.agent2.id  : row.agent1.id }/${row.id}`);
                        }}
                            className="btn waves-effect waves-light btn-success">
                                <span className="" style={{ fontSize: "1em" }} >
                                    <i className="icon-eye"></i></span>
                    </button>
                </>
            )
        }
    ];
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-list">
                                    <button type="button" onClick={ event => getMessages() }
                                        className="btn waves-effect waves-light btn-danger">
                                            {
                                                t('Actualiser')
                                            }
                                        </button>
                                        <Link type="button" to="/message/create"
                                            className="btn waves-effect waves-light btn-primary">
                                                {
                                                    t('Ajouter une discussion')
                                                }
                                        </Link>
                                    </div>
                                    
                                    <br />
                                    <br />
                                    {/* <h4 className="card-title">Zero Configuration</h4>
                                    <h6 className="card-subtitle">DataTables has most features enabled by default, so all you
                                        need to do to use it with your own tables is to call the construction
                                        function:<code> $().DataTable();</code>. You can refer full documentation from here
                                        <a href="https://datatables.net/">Datatables</a></h6> */}
                                    <div className="table-responsive">
                                        <DataTable
                                            responsive={true}
                                            className="table table-striped table-bordered"
                                            columns={columns}
                                            data={messages}
                                            progressPending={loading}
                                            pagination
                                        />
                                        </div>
                                    </div>
                                </div> 
                        </div> 
                    </div>

                </div>   
            </>                   

    );
}

export default MessageList