import { FC, useEffect, useState, useRef } from 'react'
import {
    Link, useNavigate 
} from "react-router-dom";

import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 

import PromotionService from './service';
import User from '../../Models/User';
import ShopService from '../Shop/service';

import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
import { RootState } from '../../Redux/store';

//Alert
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Alert from '../../Components/Alert';
import { useTranslation } from 'react-i18next';

interface PromotionProps {
    idPromo?: string;
    libPromo: string; 
    type: string;
    idShop: string;
    idAgent?: number  ;
    pourcentagePromo: string;
    montantPromo: string;
    montantDebPromo: string;
    dateDebut: Date | null;
    dateFin: Date | null;
    nbreCode: string;
    nbreUsage: string;
    sendingMethod: string;
    boutique?: any | null;
    agent?: any | null
}

interface PromotionFormProps {
    state: string;
    promotion?: PromotionProps | null; 
}




const PromotionForm: FC<PromotionFormProps> = ( {  state, promotion } ) => {
   

    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const user = useAppSelector((state: RootState ) => state.users.user );

    let [image, setImage] = useState('');

    let [typePromo, setTypePromo] = useState('');

    let [loading, setLoading] = useState(false);

    let [ shopsLoading, setShopsLoading] = useState(false);

    const [ shops, setShops ] = useState( [] );

    let [promotionTypes] = useState([
        {
            label: t('Pourcentage'),
            value: 'percentage'
        },
        {
            label: t('Montant fixe'),
            value: 'fixedAmount'
        }
      ]);

    let [sendingMethods] = useState([
        {
            label: t('Imprimer'),
            value: 'print'
        },
        {
            label: t('E-mail'),
            value: 'email'
        }
      ]);

    const promotionService = new PromotionService();
    const shopService = new ShopService();
    
    const readThis = (inputValue: any): void => {
        var file: File = inputValue.files[0];
        var lecteur: FileReader = new FileReader();
    
        lecteur.onloadend = (e) => {
            setImage( lecteur.result as string );
            // if (Object.prototype.toString.call(lecteur.result) === "[object String]") {
                
            // }
            
            console.log(lecteur.result);
        }
        lecteur.readAsDataURL(file);
    }

    const changeListener = (event: any)  => {
        readThis(event.target);
    }

    const getShops = () => {
        setShopsLoading(true);
        shopService.getShops().then(async function (response: any) {
            console.log(response);
            setShops(response.data);
            setShopsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setShopsLoading(false); 
        });
    }

    const formRef = useRef<FormikProps< PromotionProps >>(null);

    useEffect(() => {

        getShops();

        if (promotion != null  && promotion != undefined) {
            // setImage(client.profil ?? '');
        }

    }, []);

 


    return (
        <Formik
        
        initialValues={
            promotion != null  && promotion != undefined ? 
            {
                ...promotion,
                idAgent: user?.id , 
                idShop: promotion?.boutique?.id.toString(),
            }:
            {

                idPromo: '',
                libPromo: '',
                type: '',
                idShop: '',
                idAgent: user?.id , 
                pourcentagePromo: '',
                montantPromo: '',
                montantDebPromo: '',
                dateDebut: null,
                dateFin: null,
                nbreCode: '',
                nbreUsage: '',
                sendingMethod: '',
                
        }}
        validationSchema={
            yup.object().shape({
                libPromo: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                type: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                idShop: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                idAgent: yup
                    .number()
                    .required(`${t('Ce champ est obligatoire')}`),
                pourcentagePromo: yup
                    .number()
                    .min(0, 'Minimum is 0')
                    .max(100, "Maximum is 100"),
                montantPromo: yup
                    .string(),
                montantDebPromo: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                dateDebut: yup
                    .date()
                    .required(`${t('Ce champ est obligatoire')}`),
                dateFin: yup
                    .date()
                    .required(`${t('Ce champ est obligatoire')}`),
                nbreCode: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                nbreUsage: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                sendingMethod: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`)
            })
        }
        innerRef={formRef}
        onSubmit={async (
            values 
          ) => {
                    console.log(values);
                    setLoading(true);

                    // if (image != null && image != '' ) {
                    //     values.photoProfil = image;
                    // }

                    if (state == 'create') {
                        promotionService.postPromotion( values ).then(async function (response: any) {
                            console.log(response);

                            confirmAlert({
                                overlayClassName: "alert-overlay",
                                customUI: ({ onClose }) => {
                                  return (
                                        <Alert 
                                            alert={{
                                                title: 'Succès',
                                                message: 'Promotion ajoutée avec succès',
                                                actionText: 'Ok',
                                                action: () => {
                                                    onClose();
                                                }
                                            }}
                                        />
                                  );
                                }
                            });

                            navigate('/promotion/list');
                            setLoading(false); 
                        })
                          .catch(function (error: any) {
                            console.log(error);
                            alert("Une erreur s'est produite réesseyez s'il vous plaît.")
                            setLoading(false); 
                        });
                    } else if (state == 'edit') {
                        promotionService.putPromotion( values ).then(async function (response: any) {
                            console.log(response);

                            confirmAlert({
                                overlayClassName: "alert-overlay",
                                customUI: ({ onClose }) => {
                                  return (
                                        <Alert 
                                            alert={{
                                                title: 'Succès',
                                                message: 'Promotion mis à jour avec succès',
                                                actionText: 'Ok',
                                                action: () => {
                                                    onClose();
                                                }
                                            }}
                                        />
                                  );
                                }
                            });
  
                            navigate('/promotion/list');
                            setLoading(false); 
                        })
                          .catch(function (error: any) {
                            console.log(error);
                            alert("Une erreur s'est produite réesseyez s'il vous plaît.")
                            setLoading(false); 
                        });
                    }
                    
                }}
                >
                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                        <Form>
                                    <div className="form-body"> 

                                        <div className="row">
                                                    <div className="form-group col-md-6">
                                                                        <label>
                                                                            { t('Boutique') }
                                                                        </label>
                                                                        {
                                                                            shopsLoading ? 
                                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                                            <span className="sr-only">Loading...</span>
                                                                            </div> :
                                                                            <select  
                                                                                className={`form-control custom-select ${ errors.idShop &&
                                                                                    touched.idShop ? "is-invalid" : "" }`}
                                                                                id="idShop" 
                                                                                name="idShop"
                                                                                onChange={event => { 
                                                                                    handleChange('idShop')(event); 
                                                                                }}
                                                                                onBlur={handleBlur('idShop')}
                                                                                value={values.idShop == null ? '' : values.idShop}
                                                                                placeholder={ t('Boutique') ?? '' } >
                                                                                <option selected>{ t('Boutique') }..</option>
                                                                                {
                                                                                    shops.map(
                                                                                        (shop: any, id) => <option 
                                                                                        key={id} value={shop.id}>
                                                                                            {
                                                                                                shop.libBoutique
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                }
                                                                            </select> 
                                                                        }
                                                    </div>
                      
                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Libellé promo') }
                                                        </label>
                                                        <input 
                                                            className={`form-control ${ errors.libPromo && touched.libPromo ? "is-invalid":""}`}
                                                            id="libPromo"
                                                            type="text"
                                                            name="libPromo"
                                                            onChange={handleChange('libPromo')}
                                                            onBlur={handleBlur('libPromo')}
                                                            value={values.libPromo}
                                                            placeholder={ t('Libellé promo') ?? ''} />
                                                        { errors.libPromo && touched.libPromo && errors.libPromo && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.libPromo && touched.libPromo && errors.libPromo }
                                                        </div> }
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                                        <label>
                                                                            { t('Type promo') }
                                                                        </label>
                                                                        
                                                                            <select  
                                                                                className={`form-control custom-select
                                                                                 ${ errors.type &&
                                                                                    touched.type ? "is-invalid" : "" }`}
                                                                                id="type" 
                                                                                name="type"
                                                                                onChange={event => { 
                                                                                    handleChange('type')(event); 
                                                                                    setTypePromo(event.target.value);
                                                                                }}
                                                                                onBlur={handleBlur('type')}
                                                                                value={values.type == null ? '' : values.type}
                                                                                placeholder={ t('Type promo') ?? '' } >
                                                                                <option selected>{ t('Type promo') }</option>
                                                                                {
                                                                                    promotionTypes.map(
                                                                                        (prom: any, id) => <option 
                                                                                        key={id} value={prom.value}>
                                                                                            {
                                                                                                prom.label
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                }
                                                                            </select> 
                                                                        
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Montant début promo') }
                                                        </label>
                                                        <input 
                                                            className={`form-control ${ errors.montantDebPromo && touched.montantDebPromo
                                                                 ? "is-invalid":""}`}
                                                            id="montantDebPromo"
                                                            type="number"
                                                            name="montantDebPromo"
                                                            onChange={handleChange('montantDebPromo')}
                                                            onBlur={handleBlur('montantDebPromo')}
                                                            value={values.montantDebPromo}
                                                            placeholder={ t('Montant début promo') ?? '' } />
                                                        { errors.montantDebPromo && touched.montantDebPromo && errors.montantDebPromo && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.montantDebPromo && touched.montantDebPromo && errors.montantDebPromo }
                                                        </div> }
                                                    </div>


                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Pourcentage') }
                                                        </label>
                                                        <input  disabled={ typePromo === 'fixedAmount' }
                                                            className={`form-control ${ errors.pourcentagePromo && touched.pourcentagePromo
                                                                 ? "is-invalid":""}`}
                                                            id="pourcentagePromo"
                                                            type="number" max={100}
                                                            name="pourcentagePromo"
                                                            onChange={handleChange('pourcentagePromo')}
                                                            onBlur={handleBlur('pourcentagePromo')}
                                                            value={values.pourcentagePromo}
                                                            placeholder={ t('Pourcentage') ?? ''} />
                                                        { errors.pourcentagePromo && touched.pourcentagePromo && errors.pourcentagePromo && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.pourcentagePromo && touched.pourcentagePromo && errors.pourcentagePromo }
                                                        </div> }
                                                    </div>


                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Montant fixe promo') }
                                                        </label>
                                                        <input  disabled={ typePromo === 'percentage' }
                                                            className={`form-control ${ errors.montantPromo && touched.montantPromo
                                                                 ? "is-invalid":""}`}
                                                            id="montantPromo"
                                                            type="number"
                                                            name="montantPromo"
                                                            onChange={handleChange('montantPromo')}
                                                            onBlur={handleBlur('montantPromo')}
                                                            value={values.montantPromo}
                                                            placeholder={ t('Montant fixe promo') ?? ''} />
                                                        { errors.montantPromo && touched.montantPromo && errors.montantPromo && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.montantPromo && touched.montantPromo && errors.montantPromo }
                                                        </div> }
                                                    </div>


                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Date de début') }
                                                        </label>
                                                        <Field type="date"
                                                                                className={`form-control ${ errors.dateDebut
                                                                                     && touched.dateDebut ? "is-invalid":""}`}
                                                                                id="dateDebut" 
                                                                                name="dateDebut"
                                                                                onChange={handleChange('dateDebut')}
                                                                                onBlur={handleBlur('dateDebut')}
                                                                                value={values.dateDebut == null ? new Date()  :
                                                                                     values.dateDebut }
                                                                                placeholder={ t('Date de début') ?? ''} />
                                                                            { errors.dateDebut && touched.dateDebut &&
                                                                             errors.dateDebut && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                { errors.dateDebut && touched.dateDebut &&
                                                                                 errors.dateDebut }
                                                                        </div> }
                                                    </div>


                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Date de fin') }
                                                        </label>
                                                        <Field type="date"
                                                                                className={`form-control ${ errors.dateFin
                                                                                     && touched.dateFin ? "is-invalid":""}`}
                                                                                id="dateFin" 
                                                                                name="dateFin"
                                                                                onChange={handleChange('dateFin')}
                                                                                onBlur={handleBlur('dateFin')}
                                                                                value={values.dateFin == null ? new Date()  :
                                                                                     values.dateFin }
                                                                                placeholder={ t('Date de fin') } />
                                                                            { errors.dateFin && touched.dateFin &&
                                                                             errors.dateFin && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                { errors.dateFin && touched.dateFin &&
                                                                                 errors.dateFin }
                                                                        </div> }
                                                    </div>


                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Nombre de code') }
                                                        </label>
                                                        <input 
                                                            className={`form-control ${ errors.nbreCode && touched.nbreCode
                                                                 ? "is-invalid":""}`}
                                                            id="nbreCode"
                                                            type="number"
                                                            name="nbreCode"
                                                            onChange={handleChange('nbreCode')}
                                                            onBlur={handleBlur('nbreCode')}
                                                            value={values.nbreCode}
                                                            placeholder={ t('Nombre de code') ?? '' } />
                                                        { errors.nbreCode && touched.nbreCode && errors.nbreCode && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.nbreCode && touched.nbreCode && errors.nbreCode }
                                                        </div> }
                                                    </div>


                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Nombre d\'usage') }
                                                        </label>
                                                        <input 
                                                            className={`form-control ${ errors.nbreUsage && touched.nbreUsage
                                                                 ? "is-invalid":""}`}
                                                            id="nbreUsage"
                                                            type="number"
                                                            name="nbreUsage"
                                                            onChange={handleChange('nbreUsage')}
                                                            onBlur={handleBlur('nbreUsage')}
                                                            value={values.nbreUsage}
                                                            placeholder={ t('Nombre d\'usage') ?? '' } />
                                                        { errors.nbreUsage && touched.nbreUsage && errors.nbreUsage && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.nbreUsage && touched.nbreUsage && errors.nbreUsage }
                                                        </div> }
                                                    </div>


                                                    <div className="form-group col-md-6">
                                                                        <label>
                                                                            { t("Méthode d'envoie des codes") }
                                                                        </label>
                                                                        
                                                                            <select  
                                                                                className={`form-control custom-select
                                                                                 ${ errors.sendingMethod &&
                                                                                    touched.sendingMethod ? "is-invalid" : "" }`}
                                                                                id="sendingMethod" 
                                                                                name="sendingMethod"
                                                                                onChange={event => { 
                                                                                    handleChange('sendingMethod')(event); 
                                                                                }}
                                                                                onBlur={handleBlur('sendingMethod')}
                                                                                value={values.sendingMethod == null ? '' :
                                                                                 values.sendingMethod}
                                                                                placeholder="Méthode" >
                                                                                <option selected>Méthode</option>
                                                                                {
                                                                                    sendingMethods.map(
                                                                                        (meth: any, id) => <option 
                                                                                        key={id} value={meth.value}>
                                                                                            {
                                                                                                meth.label
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                }
                                                                            </select> 
                                                                        
                                                    </div>


                                            </div>
                                        </div>


                                    <div className="form-actions">
                                        <div className="text-right">
                                            <button  disabled={!dirty && !isValid } type="submit" className="btn btn-info">
                                                { t('Valider') }
                                                {
                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                            <button type="reset" className="btn btn-dark">
                                                { t('Annuler') }
                                            </button>
                                        </div>
                                    </div>
                        </Form>
                    )}
                </Formik>
    );
}

export default PromotionForm