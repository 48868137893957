import { FC } from 'react'
import { useTranslation } from 'react-i18next';
import {
    Link
} from "react-router-dom";
import Breadcrumb from '../../Components/Breadcrumb';
import SpentForm from './form';


const SpentCreate: FC = () => {  
    const { t } = useTranslation();
    return ( 
        <> 
            <Breadcrumb />

            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                
                                <h4 className="card-title">
                                    { t('Ajouter une dépense') }
                                </h4>

                                <Link type="button" to="/depense/list"
                                    className="btn waves-effect waves-light btn-primary">
                                        { t('Liste des dépenses') } 
                                </Link>

                                <br />
                                <br />

                                <SpentForm state='create' spent={null} />  

                            </div>
                        </div> 
                    </div> 
                </div>

            </div>   
        </>                   
    );
}

export default SpentCreate