import Ressource from '../../Models/Ressource';
import axiosInstance from '../../Utils/axios_manager';

export default class  ProductService {
  
    constructor() {  }

    public getKitTypes =  async () => {
        return await axiosInstance.get('/product/groupType/list' );
    }

    public getProducts =  async () => {
        return await axiosInstance.get('/produit/list' );
    }

    public getKits =  async () => {
        return await axiosInstance.get('/product/group/list' );
    } 

    public getKitDetails =  async (id: Number) => {
        return await axiosInstance.get('/product/group/details'  + `?id=${id.toString()}`);
    } 

    public addKit =  async (kit: Record<string, any>) => {
        return await axiosInstance.post('/product/group/add', kit );
    } 

    public updateKit =  async (kit: Record<string, any>) => {
        return await axiosInstance.post('/product/group/update', kit );
    }

    public getKitProducts =  async (id: Number) => {
        return await axiosInstance.get('/product/group/listcontent'  + `?id=${id.toString()}`);
    } 


    public addKitContent =  async (kitContent: Record<string, any>) => {
        return await axiosInstance.post('/product/group/addcontent', kitContent );
    }
    
    
    public updateKitContent =  async (kitContent: Record<string, any>) => {
        return await axiosInstance.post('/product/group/addcontent', kitContent );
    }
 

    public deleteKitContent =  async (id: Number) => {
        return await axiosInstance.get('/product/group/delcontent' + `?id=${id.toString()}`);
    }

    public getProduct =  async (id: Number) => {
        return await axiosInstance.get('/produit/details' + `?id=${id.toString()}`);
    }

    public searchByBarcodeOrName =  async (searchStr: string) => {
        return await axiosInstance.get('/produit/list/barcodeandnamefiltred/simple' + `?serachStr=${searchStr}`);
    }

    public getFilteredSimpleProduct = (params: Record<string, any>) => {
        return axiosInstance.get('/produit/list/filtred/simple', {
            params: params
        });
    }

    public uploadProductFile = (file: any) => {
        const body = new FormData();
        body.append('file', file); 

        return axiosInstance.post('/images/products/', body);
    }

    public uploadProductGaleryFiles = (file: any, title: string = "edr", gallID: string = '0') => {
        // const body = new FormData();
        // body.append('filecontent', file);
        // body.append('title', title);
        // body.append('gallID', gallID);

        const body = {
            "filecontent": file,
            "title": title,
            "galID": gallID
        }

        return axiosInstance.post('/galerie/addcontent', body);
    }

    public addProduct =  async (product: Record<string, any>) => {
        return axiosInstance.post('/produit/add', product);
    }

    public updateProduct =  async (product: Record<string, any>) => {
        return axiosInstance.put('/produit/update', product);
    }

    public deleteGaleryContent =  async (fileName: string) => {
        return axiosInstance.post('/galerie/delcontent', {
            id: fileName
        });

    }

}