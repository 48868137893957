// Redux
import { useAppDispatch, useAppSelector } from '../Hooks/customSelector';
import { RootState } from '../Redux/store';
 
 export default class UserRight {
    public user = useAppSelector((state: RootState) => state.users.user );
    constructor() {  }

    public hasRight = (permissions: string[]) => {
        if (this.user != null && this.user != undefined) {
            return permissions.includes(this.user.droit?.libDroit);
        }

        return false;
        
    }
}