import { FC, useEffect, useState, useRef } from 'react'
import {
    Link, useNavigate 
} from "react-router-dom";


import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 


import InventaireService from '../../Inventaire/service';
import User from '../../../Models/User';

// React - Bootstrap
import { Tabs, Tab } from 'react-bootstrap';
import ShopService from '../../Shop/service';

//Alert
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 


import Alert from '../../../Components/Alert';


// React - Bootstrap
import { Modal } from 'react-bootstrap';

//Redux 
import { useAppDispatch, useAppSelector } from '../../../Hooks/customSelector';
import { RootState } from '../../../Redux/store';
import ConfigurationService from '../../Configuration/service';
import Gamme from '../../../Models/Gamme';
import Type from '../../../Models/Type';
import Category from '../../../Models/Category';
import Product from '../../../Models/Product';

import MarketManForm from '../../Inventaire/market-man';
import MarketAutoForm from '../../Inventaire/market-auto';
import ClientService from '../../Client/service';

import ProductService from '../service';

import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';

interface KitFormProps {
    state: string; 
    kit?: any | null; 
}

interface ExemplaireForm {
    prodBarcodeValue: string | null,
    gamme:  string | null ,
    type: string | null,
    categorie: string | null,
    product: string | null,
    qteInventaire: string,
    datefabrication: Date | null,
    dateperemption: Date | null
}


interface KitData {
    data: any | null,
    products: Array<any>
    idContent: Number | null
}

const KitForm: FC<KitFormProps> = ( { state, kit } ) => {
   
    let navigate = useNavigate();
    const { t, i18n } = useTranslation(); 
    let [image, setImage] = useState('');

    const exemplaireFormRef = useRef< FormikProps< ExemplaireForm >>(null);

    let kitDataInit: KitData = {data: null, products: [], idContent: null};

    const [kitData, setKitData] = useState<KitData>(kitDataInit);

    let [key, setKey] = useState('init');

    const [ formInitValue, setFormInitValue ] = useState({
        grpType: '',
                                            grpName: '',
                                            grpImage: '',
                                            grpDisplayName: '',
                                            grpSysPrice: '',
                                            grpSellPrice: '',
                                            grpReduction: '',
                                            grpNbreProduct: ''
    });

    const [ products, setProducts ] = useState( [] );

    const [ selectedProducts, setSelectedProducts ] = useState(null);

    const [
        gammes,
        setGammes
    ] = useState([]);

    const [
        clients,
        setClients
    ] = useState([]);

    const [
        kitTypes,
        setKitTypes
    ] = useState([]);

    let [loading, setLoading] = useState(false);

    let [initTabDisable, setInitTabDisable] = useState(false);

    let [ finalTabDisable, setFinalTabDisable ] = useState(true);

    let [kitTypesLoading, setKitTypesLoading] = useState(false);


    let [ productsLoading, setProductsLoading ] = useState(false)

    let [activeExpl, setActiveExpl] = useState(false);
    let [activeTab, setActiveTab] = useState(false);

    const [ productListModal, setProductListModal ] = useState(false);

    const [ tempProducts, setTempProducts ] = useState( [] ); 

    const [ searchStr, setSearchStr ] = useState('');
    const [ searchLoading, setSearchLoading ] = useState(false);

    const [
        totalkit, setTotalKit
    ] = useState(0);

    const user = useAppSelector((state: RootState) => state.users.user );

    const inventaireService = new InventaireService();
    const clientService = new ClientService();
    const configService = new ConfigurationService();
    const shopService = new ShopService();

    const productService = new ProductService();
    
    const getKitTypes = () => {
        setKitTypesLoading(true);
        productService.getKitTypes().then(async function (response: any) {
            console.log(response);
            setKitTypes(response.data?.data);
            setKitTypesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setKitTypesLoading(false); 
        });
    }

    const getKitProducts = (id: Number) => { 
        productService.getKitProducts(id).then(async function (response: any) {
            console.log(response); 
            setKitData({
                ...kitData, 
                products: response.data.data.map(( ligne: any ) => {
                    return {
                        ...ligne,
                        nbre: Number(ligne.qte),
                        idContent: Number(ligne.id)
                    }
                })
            })
        })
          .catch(function (error: any) {
            console.log(error);
            setKitTypesLoading(false); 
        });
    }



    const readThis = (inputValue: any): void => {
        var file: File = inputValue.files[0];
        var lecteur: FileReader = new FileReader();
    
        lecteur.onloadend = (e) => {
            setImage( lecteur.result as string );
            // if (Object.prototype.toString.call(lecteur.result) === "[object String]") {
                
            // }
            
            console.log(lecteur.result);
        }
        lecteur.readAsDataURL(file);
    }

    const changeListener = (event: any)  => {
        readThis(event.target);
    }

    const getProducts = () => {
        setProductsLoading(true);
        productService.getFilteredSimpleProduct({nomProd: null, idType: 0, idGamme: 0}).then(async function (response: any) {
            console.log(response); 
            setProducts(response.data);
            setTempProducts(response.data);
            setProductsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setProductsLoading(false); 
        });
    }

    const productAlreadyAdd = (row: any) => {
        let checkProduct: Array<any> = [];
            checkProduct = kitData.products.filter(
                (prd) => {
                                            return prd.product.id === row?.id;
                                        }
                );
        return checkProduct.length > 0;
    }

    const addKitContent  = (row: any) => {
        let nbre = prompt( `${t('Entrez le nombre de ce produit')}`);
                        if ( Number(nbre) > 0) {

                            setSelectedProducts(row?.id);

                            setLoading(true);
                            productService.addKitContent({
                                grpId: kitData.data?.id,
                                prodId: row.id,
                                prodQte: Number(nbre)
                            }).then(
                                (res: any) => {


                                    let checkProduct: Array<any> = [];
                                    checkProduct = kitData.products.filter(
                                        (prd) => {
                                            return prd.id === row?.id;
                                        }
                                    );

                            if (checkProduct.length > 0) {

                                checkProduct[0].nbre = Number(nbre)

                                let prdts = kitData.products.filter(
                                    (prd) => {
                                        return prd.id !== row?.id;
                                    }
                                );
    
                                prdts.push(checkProduct[0]);
    
                                setKitData({
                                    ...kitData,
                                    products: prdts
                                });

                                confirmAlert({     
                                    overlayClassName: "alert-overlay",
                                    customUI: ({ onClose }) => {
                                      return (
                                        
                                            <Alert 
                                                alert={{
                                                    title: 'Succès',
                                                    message: `${t('Produit mis à jour avec succès')}`,
                                                    actionText: 'Ok',
                                                    action: () => { 
                                                        onClose();
                                                    }
                                                }}
                                            />
                                      );
                                    }
                                }); 

                                setLoading(false);
    
                            } else {

                                getKitProducts(Number(kitData.data.id));

                                // let prdts = kitData.products || [];
    
                                // prdts.push({
                                //     ...row,
                                //     nbre: Number(nbre)
                                // });
    
                                // setKitData({
                                //     ...kitData,
                                //     products: prdts
                                // });

                                confirmAlert({     
                                    overlayClassName: "alert-overlay",
                                    customUI: ({ onClose }) => {
                                      return (
                                        
                                            <Alert 
                                                alert={{
                                                    title: 'Succès',
                                                    message: `${t('Produit ajouté avec succès')}`,
                                                    actionText: 'Ok',
                                                    action: () => { 
                                                        onClose();
                                                    }
                                                }}
                                            />
                                      );
                                    }
                                }); 

                                setLoading(false);

                            }
                                     
                                    
                                    
                                }, (error: any) => {
                                    console.log(error);
                                    alert('Erreur lors de l\'ajout')
                                }
                            );
                            

                        }  else {
                            confirmAlert({
                                overlayClassName: "alert-overlay",
                                customUI: ({ onClose }) => {
                                  return (
                                    
                                        <Alert 
                                            alert={{
                                                type: 'error',
                                                title: 'Erreur',
                                                message: `${t('Le nombre est incorrect')}` ,
                                                actionText: 'Ok',
                                                action: () =>   {
                                                    onClose();
                                                }
                                            }}
                                        />
                                  );
                                }
                            }); 
                            setLoading(false);
                        }
    }

    const columns = [
        {   
            name: `${t('Nom du produit')}`,
            selector: (row: any) => row.gammeDetails?.libGamme + ' - ' + row.libProduit + ' - ' + row.typeDetails?.libType,
            wrap: true, 
        }, 
        
        {
            name: `${t('Nombre')}`,
            selector: (row: any) => row.qteStock
        }, 
        {
            name: `${t('Options')}`, 
            selector: (row: any) => (
                <>
                    <button disabled={ (kitData.products.filter( (prd) => { return prd.product.id === row?.id;  }
                )).length  > 0} type="button" onClick={event =>  addKitContent(row) }
                        className="btn waves-effect waves-light btn-danger"> 
                        Ajouter
                        {
                            loading && selectedProducts === row?.id && <div className="spinner-border text-light spinner-border-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        }
                    </button> 
                </>
            )
        }
    ];


    const productListColumns = [
        {
            name: `${t('Nom du produit')}`,
            selector: (row: any) => row.product.gamme?.libGamme + ' ' + row.product.libProduit + ' ' + row.product.type?.libType,
            wrap: true
        }, 
        {
            name: `${t('Prix unitaire')}`,
            selector: (row: any) => (Number(row.product?.capitalUnitaireProduit) + Number(row.product?.interetUnitaireProduit))
        },
        {
            name: `${t("Stock disponible")}` ,
            selector: (row: any) => row.product.qteStock,
        },  
        {
            name: `${t('Nombre')}`,
            selector: (row: any) => row.nbre
        }, 
        {
            name: `${t('Options')}`, 
            selector: (row: any) => (
                <>
                    <button type="button" onClick={event => { 

                        productService.deleteKitContent(Number(row.idContent)).then(async function (response: any) {
                            console.log(response); 

                            setSelectedProducts(row.id);

                            getKitProducts(Number(kitData.data.id));

                            // let filteredProducts = kitData.products.filter(
                            //     (prd) => row.id !== prd.id
                            // );

                            // setKitData({
                            //         ...kitData,
                            //         products: filteredProducts
                            //     });
                                    
                        })
                          .catch(function (error: any) {
                            console.log(error);
                            
                        });
                       

                     }}
                        className="btn waves-effect waves-light btn-danger"> 
                         { t('Supprimer') }
                        {
                            loading && selectedProducts === row?.id && <div className="spinner-border text-light spinner-border-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        }
                    </button> 
                </>
            )
        }
    ];

    

    useEffect( function() { 
        console.log('kit')
        console.log(kit)
        getProducts();
        getKitTypes();
        if (kit !== null && kit !== undefined) {
            setFormInitValue({
                grpType: kit.grpType.id,
                                            grpName: kit.name,
                                            grpImage: '',
                                            grpDisplayName: kit.displayName,
                                            grpSysPrice: kit.systemPrice,
                                            grpSellPrice: kit.sellPrice,
                                            grpReduction: kit.reduction,
                                            grpNbreProduct: kit.nbreProd.toString()
            }); 
            setImage(kit.fullImage);
            getKitProducts(Number(kit.id));
            console.log(formInitValue);
        }
    }, []);

    useEffect(() => {
        console.log(kitData);
        let total = 0;
        kitData.products.forEach(
            (prd) => {
                total = total + Number( Number(prd.nbre) * Number( (Number(prd.product?.capitalUnitaireProduit) + Number(prd.product?.interetUnitaireProduit))  ) );
            }
        );
        setTotalKit(total);
    }, [kitData]);

    const [
        kitContentsLoading,
        setKitContentLoading
    ] = useState(false);

    const addKitContents = async () => {
        return await new Promise(
          (resolve: any, reject) => {
            if (kitData.products.length > 0) {
                kitData.products.map(
                    (prd, id) => {

                            productService.addKitContent({
                                grpId: kitData.data?.id,
                                prodId: prd.id,
                                prodQte: prd.nbre
                            }).then(
                                (res: any) => {
                                     
                                    if(id === (kitData.products.length - 1)) {
                                        resolve();
                                    }
                                    
                                }, (error: any) => {
                                    reject();
                                }
                            );
                        
                    }
              );
            } else {
              resolve();
            }  
          }
        )
    }
    
    

    return (
        <>
        <Tabs  id="uncontrolled-tab-example" 
            activeKey={key}  transition={false}
            onSelect={(k: any) => setKey(k)} >
            
            <Tab style={{ padding: 20 }} eventKey="init" title={t("Création kit" )}
            disabled={initTabDisable}>
               
                <br />
                <div className="card card-body">
                    <Formik
                                    initialValues= { kit !== null && kit !== undefined ? {
                                        grpType: kit.grpType.id,
                                        grpName: kit.name,
                                        grpImage: '',
                                        grpDisplayName: kit.displayName,
                                        grpSysPrice: kit.systemPrice,
                                        grpSellPrice: kit.sellPrice,
                                        grpReduction: kit.reduction,
                                        grpNbreProduct: kit.nbreProd.toString()
                                    } : {
                                        grpType: '',
                                        grpName: '',
                                        grpImage: '',
                                        grpDisplayName: '',
                                        grpSysPrice: '',
                                        grpSellPrice: '',
                                        grpReduction: '',
                                        grpNbreProduct: ''
                                    }}
                                    validationSchema={
                                        yup.object().shape({
                                            grpType: yup
                                                .string() 
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            grpName: yup
                                                .string() 
                                                .required(`${t('Ce champ est obligatoire')}` ),
                                            grpDisplayName: yup
                                                .string() 
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            grpSysPrice: yup
                                                .string() 
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            grpSellPrice: yup
                                                .string() 
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            grpReduction: yup
                                                .string() 
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            grpNbreProduct: yup
                                                .string() 
                                                .required(`${t('Ce champ est obligatoire')}`)
                                        })
                                    }
                                    onSubmit={async (
                                        values 
                                    ) => { 

                                         values.grpImage = image;

                                         setLoading(true); 
                                        if (kit !== null) {


                                            productService.updateKit( values ).then(async function (response: any) {
                                                console.log(response);
                                                confirmAlert({
                                                    overlayClassName: "alert-overlay",
                                                    customUI: ({ onClose }) => {
                                                      return (
                                                        
                                                            <Alert 
                                                                alert={{
                                                                    title: `${t('Succès')}`,
                                                                    message: `${t('Kit mis à jour avec succès')}`,
                                                                    actionText: 'Ok',
                                                                    action: () => {
                                                                        onClose();
                                                                    }
                                                                }}
                                                            />
                                                      );
                                                    }
                                                });  
                                                 
                                                setKitData({
                                                    ...kitData,
                                                    data: { ...values, id: kit.id }
                                                });
                                                
                                                setKey('products');
                                                setInitTabDisable(true);
                                                setLoading(false); 
    
                                            })
                                              .catch(function (error: any) {
                                                console.log(error);
                                                confirmAlert({
                                                    overlayClassName: "alert-overlay",
                                                    customUI: ({ onClose }) => {
                                                      return (
                                                        
                                                            <Alert 
                                                                alert={{
                                                                    type: 'error',
                                                                    title: 'Erreur',
                                                                    message:  `${t('Une erreur réesseyez')}`,
                                                                    actionText: 'Ok',
                                                                    action: () => {
                                                                        onClose();
                                                                    }
                                                                }}
                                                            />
                                                      );
                                                    }
                                                }); 
                                                setLoading(false); 
                                            });

                                        } else {
                                         productService.addKit( values ).then(async function (response: any) {
                                            console.log(response);
                                            confirmAlert({
                                                overlayClassName: "alert-overlay",
                                                customUI: ({ onClose }) => {
                                                  return (
                                                    
                                                        <Alert 
                                                            alert={{
                                                                title: `${t('Succès')}`,
                                                                message: `${t('Kit ajouté avec succès')}`,
                                                                actionText: 'Ok',
                                                                action: () => {
                                                                    onClose();
                                                                }
                                                            }}
                                                        />
                                                  );
                                                }
                                            });  
                                             
                                            setKitData({
                                                ...kitData,
                                                data: { ...values, id: response.data?.data.idGrp }
                                            });
                                            
                                            setKey('products');
                                            setInitTabDisable(true);
                                            setLoading(false); 

                                        })
                                          .catch(function (error: any) {
                                            console.log(error);
                                            confirmAlert({
                                                overlayClassName: "alert-overlay",
                                                customUI: ({ onClose }) => {
                                                  return (
                                                    
                                                        <Alert 
                                                            alert={{
                                                                type: 'error',
                                                                title: 'Erreur',
                                                                message:  `${t('Une erreur réesseyez')}`,
                                                                actionText: 'Ok',
                                                                action: () => {
                                                                    onClose();
                                                                }
                                                            }}
                                                        />
                                                  );
                                                }
                                            }); 
                                            setLoading(false); 
                                        });
                                        }
                                    }}  
                                >

                                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                <Form>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>
                                                    { t('Type') }
                                                </label>
                                                        {
                                                            kitTypesLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <select  
                                                                className={`form-control custom-select ${ errors.grpType &&
                                                                    touched.grpType ? "is-invalid" : "" }`}
                                                                id="grpType" 
                                                                name="grpType"
                                                                onChange={event => { 
                                                                    handleChange('grpType')(event); 
                                                                }}
                                                                onBlur={handleBlur('grpType')}
                                                                value={values.grpType}
                                                                placeholder={ t("Type") ?? '' } >
                                                                <option selected>{ t('Type') }..</option>
                                                                {
                                                                    kitTypes.map(
                                                                        (kit: any, id) => <option 
                                                                         key={id} value={kit.id}>
                                                                            {
                                                                                kit.libelle
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </select> 
                                                        }
                                            </div>

                                            <div className="form-group">
                                                <label>
                                                    { t('Nom du kit') }
                                                </label>
                                                                        <input type="text"
                                                                        className={`form-control ${ errors.grpName &&
                                                                             touched.grpName ? "is-invalid":""}`}
                                                                            id="grpName" 
                                                                            name="grpName" 
                                                                            onChange={handleChange('grpName')}
                                                                            onBlur={handleBlur('grpName')}
                                                                            value={values.grpName}
                                                                            placeholder={ t("Nom du kit") ?? ''} />
                                                                        { errors.grpName && touched.grpName && errors.grpName && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                            { errors.grpName.toString() }
                                                                        </div> }
                                            </div>

                                            <div className="form-group">
                                                <label>
                                                    {t('Nom du kit sur le site')}
                                                </label>
                                                                        <input type="text"
                                                                        className={`form-control ${ errors.grpDisplayName &&
                                                                             touched.grpDisplayName ? "is-invalid":""}`}
                                                                            id="grpDisplayName" 
                                                                            name="grpDisplayName" 
                                                                            onChange={handleChange('grpDisplayName')}
                                                                            onBlur={handleBlur('grpDisplayName')}
                                                                            value={values.grpDisplayName}
                                                                            placeholder={t("Nom du kit sur le site") ?? ''} />
                                                                        { errors.grpDisplayName && touched.grpDisplayName &&
                                                                         errors.grpDisplayName && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                            { errors.grpDisplayName.toString() }
                                                                        </div> }
                                            </div>

                                            <div className='form-group'>
                                                <label>
                                                    {
                                                        t('Prix système')
                                                    }
                                                </label>
                                                <Field type="text"
                                                    className={`form-control 
                                                    ${ errors.grpSysPrice && touched.grpSysPrice ? "is-invalid":""}`}
                                                    id="grpSysPrice" 
                                                    name="grpSysPrice"  
                                                    onChange={handleChange('grpSysPrice')}
                                                    onBlur={handleBlur('grpSysPrice')}
                                                    value={ values.grpSysPrice }
                                                    placeholder={t("Prix système")} />
                                                    { 
                                                        errors.grpSysPrice && touched.grpSysPrice && errors.grpSysPrice && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.grpSysPrice.toString() }
                                                        </div> 
                                                    } 
                                            </div>

                                            <div className='form-group'>
                                                <label>
                                                    {t('Prix de vente')}
                                                </label>
                                                    <Field type="text"
                                                        className={`form-control 
                                                        ${ errors.grpSellPrice && touched.grpSellPrice ? "is-invalid":""}`}
                                                        id="grpSellPrice" 
                                                        name="grpSellPrice"  
                                                        onChange={handleChange('grpSellPrice')}
                                                        onBlur={handleBlur('grpSellPrice')}
                                                        value={ values.grpSellPrice }
                                                        placeholder={t('Prix de vente')} />
                                                        { 
                                                            errors.grpSellPrice && touched.grpSellPrice && errors.grpSellPrice && 
                                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                                { errors.grpSellPrice.toString() }
                                                            </div> 
                                                        } 
                                            </div>
                                            
                                            <div className='form-group'>
                                                <label>
                                                    {t('Réduction')}
                                                </label>
                                                    <Field type="text"
                                                        className={`form-control 
                                                        ${ errors.grpReduction && touched.grpReduction ? "is-invalid":""}`}
                                                        id="grpReduction" 
                                                        name="grpReduction"  
                                                        onChange={handleChange('grpReduction')}
                                                        onBlur={handleBlur('grpReduction')}
                                                        value={ values.grpReduction }
                                                        placeholder={t('Réduction')} />
                                                        { 
                                                            errors.grpReduction && touched.grpReduction && errors.grpReduction && 
                                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                                { errors.grpReduction.toString() }
                                                            </div> 
                                                        } 
                                            </div>

                                            <div className='form-group'>
                                                <label>
                                                    {t('Nombre de produit')}
                                                </label>
                                                    <Field type="text"
                                                        className={`form-control 
                                                        ${ errors.grpNbreProduct && touched.grpNbreProduct ? "is-invalid":""}`}
                                                        id="grpNbreProduct" 
                                                        name="grpNbreProduct"  
                                                        onChange={handleChange('grpNbreProduct')}
                                                        onBlur={handleBlur('grpNbreProduct')}
                                                        value={ values.grpNbreProduct }
                                                        placeholder={t('Nombre de produit')} />
                                                        { 
                                                            errors.grpNbreProduct && touched.grpNbreProduct && errors.grpNbreProduct && 
                                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                                { errors.grpNbreProduct.toString() }
                                                            </div> 
                                                        } 
                                            </div>

                                           

                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>
                                                {t('Image du kit')}
                                            </label>
                                            <div className='form-group'>
                                                {/* <div className="input-group-prepend">
                                                                    <span className="input-group-text">Upload</span>
                                                                </div> */}
                                                                <div className="custom-file">
                                                                    <input type="file" 
                                                                    className={`form-control custom-file-input `} 
                                                                        id="photoProfil" 
                                                                        onChange={changeListener} 
                                                                        placeholder="Photo" />
                                                                    <label className="custom-file-label" 
                                                                    htmlFor="photoProfil">{ t('Choisir un fichier') }</label>
                                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <img src={ image } 
                                                        className="img-responsive" width="304" height="236" alt="" />
                                        </div>
                                    </div> 
                                    <br/>
                                    <div className="form-actions">
                                        <div className="text-right">
                                            <button  disabled={!isValid} type="submit" 
                                                className="btn btn-info">
                                                {
                                                    t('Valider')
                                                }
                                                {
                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                            {/* <button type="button"
                                                onClick={event => cancel() }
                                            className="btn btn-dark">Annuler</button> */}
                                        </div>
                                    </div>

                                    </Form>
                                    )}
                </Formik>
                </div>
            </Tab>
            <Tab style={{ padding: 20 }}  eventKey="products" title={t("Choix des produits")} disabled={activeExpl}>
 
                 {/* <div className='row'> 
                    <div className='col-md-12' > */}
                      
                            <div className='row'>
                                <div className='col-md-12' >
                                    <div className="card card-body">
                                    {

                                      kitData.products.length > 0 ?
                                        <div  className="btn-list">
                                            <button type="button" onClick={ event => { setProductListModal(true); } }
                                                className="btn waves-effect waves-light btn-danger">
                                                    { t('Liste') }
                                                </button>
                                            
                                            <button type="button" onClick={ event => {} }
                                                className="btn waves-effect waves-light btn-primary">
                                                    TOTAL: {totalkit}
                                            </button>

                                            <button type="button" style={{ float: 'right' }} onClick={ async (event) => {
                                                setKitContentLoading(true);
                                                setKitData({
                                                    ...kitData,
                                                    data: {
                                                        ...kitData.data,
                                                        total: totalkit
                                                    }
                                                })
                                                setKitContentLoading(false);
                                                setFinalTabDisable(false);
                                                setKey("final");

                                            } }
                                                className="btn waves-effect waves-light btn-success">
                                                    { t('Valider') }
                                                    { 
                                                        kitContentsLoading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    }
                                            </button>

                                        </div>
                                        : <></>
                                      
                                    }
                                    <br/>
                                    <div className='input-group'>
                                         
                                         <input type="text"
                                             className={`form-control`}
                                             id="searchStr"  
                                             onChange={event => { 
                                                 setSearchLoading(true);
                                                 setLoading(true);
                                                 if (event.target.value == null || event.target.value == undefined ||
                                                     event.target.value == '') {
                                                         setProducts(tempProducts);
                                                 } else {
                                                     productService.searchByBarcodeOrName(event.target.value).then(async function (response: any) {
                                                         console.log(response);
                                                         if (response.data.length > 0) {
                                                             setProducts(response.data);
                                                         } 
                                                         setSearchLoading(false); 
                                                         setLoading(false);
                                                     })
                                                     .catch(function (error: any) {
                                                         console.log(error);
                                                         setSearchLoading(false);
                                                         setLoading(false);
                                                     });
                                                 }
                                             }} 
                                             placeholder={ `${ t("Chercher un produit (nom ou barcode)") }` }/>
                                             {
                                                 searchLoading && <div className="input-group-append">
                                                     <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                         <span className="sr-only">Loading...</span>
                                                     </div>
                                                 </div>
                                             }
                                             
                                     </div>

                                        <div className="table-responsive">
                                                <DataTable
                                                    responsive={true}
                                                    className="table table-striped table-bordered"
                                                    columns={columns}
                                                    data={ products }
                                                    progressPending={ productsLoading } 
                                                    pagination
                                                />
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                    {/* </div>
                 </div> */}
            </Tab>
            <Tab disabled={finalTabDisable} eventKey="final" title={ t("Tableau de finalisation") } >
                        <div className='row'>
                                <div className='col-md-12' >
                                    <div className="card card-body">
                                    <div  className="btn-list"> 
                                            <button style={{ float: "right" }} type="button" onClick={ event => {

                                                confirmAlert({     
                                                    overlayClassName: "alert-overlay",
                                                    customUI: ({ onClose }) => {
                                                      return (
                                                        
                                                            <Alert 
                                                                alert={{
                                                                    title: `${t('Succès')}`,
                                                                    message: `${t('Votre KIT a été configuré avec succès')}`,
                                                                    actionText: 'Ok',
                                                                    action: () => { 
                                                                        onClose();
                                                                    }
                                                                }}
                                                            />
                                                      );
                                                    }
                                                }); 

                                                navigate('/product/kit/list');
                
                                            } }
                                                className="btn waves-effect waves-light btn-primary">
                                                   {
                                                    t('Valider')
                                                   }
                                            </button>
                                    <br/>
                                    <br/>
                                    </div>
                                            { kitData.data !== null && finalTabDisable === false ? <div className="list-group"> 
                                                    <a href="javascript:void(0)"
                                                            className="list-group-item active">
                                                            {t('Détails KIT')}
                                                            </a> 
                                                             
                                                            <a href="javascript:void(0)" className="list-group-item">
                                                                <b>{ t('Type') }:</b> { kitData.data.grpType }
                                                            </a> 
                                                            <a href="javascript:void(0)" className="list-group-item">
                                                                <b>{ t('Nom du kit') }:</b> { kitData.data.grpName }
                                                            </a>
                                                            <a href="javascript:void(0)" className="list-group-item">
                                                                <b>{ t('Nom du kit sur le site') }:</b> { kitData.data.grpDisplayName }
                                                            </a>
                                                            <a href="javascript:void(0)" className="list-group-item">
                                                                <b> {t('Réduction')}:</b> { kitData.data.grpReduction }
                                                            </a>
                                                            <a href="javascript:void(0)" className="list-group-item">
                                                                <b> { t('Total') }:</b> { kitData.data.total }
                                                            </a>
                                                            {/* <a href="javascript:void(0)" className="list-group-item">
                                                            </a>  */}
                                            </div> : <></> }
                                            <div className="table-responsive">
                                            { kitData.products.length > 0 && finalTabDisable === false ? <DataTable
                                                                responsive={true}
                                                                className="table table-striped table-bordered"
                                                                columns={productListColumns}
                                                                data={kitData.products}
                                                                progressPending={loading}
                                                                pagination
                                                            /> : <></> }
                                            </div>

                                    </div>
                                </div>
                        </div>
            </Tab>
        </Tabs>
        <Modal 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={ productListModal }
                onHide={() => setProductListModal(false) }
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        { t('Produits du kit') }
                    </Modal.Title> 
                </Modal.Header>
                {/* <div style={{ width: "100%", paddingTop: "8px", paddingRight: "8px" }}>
                                    <button style={{float: 'right'}} type="button" onClick={ event => exportToPdf() }
                                        className="btn waves-effect waves-light btn-success">
                                            Imprimer
                                            {
                                                paymentLoading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            }
                                    </button>
                </div>  */}
                <Modal.Body>
                    {

                        <div className="table-responsive">
                                        <DataTable
                                            responsive={true}
                                            className="table table-striped table-bordered"
                                            columns={productListColumns}
                                            data={kitData.products}
                                            progressPending={loading}
                                            pagination
                                        />
                        </div>

                    }
                    
                </Modal.Body>
                <Modal.Footer>
                {/* <Button onClick={props.onHide}>Close</Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default KitForm