import User from '../../Models/User';
import axiosInstance from '../../Utils/axios_manager';

export default class AgentService {
  
    constructor() {  }

    public postAgent =  (user: User) => {
        return axiosInstance.post('/agent/add', User.toJson(user));
    }

    public getAgents =  async () => {
        return await axiosInstance.get('/agent/list');
    }

    public getAgent =  async (id: Number) => {
        return await axiosInstance.get('/agent/details' + `?id=${id.toString()}`);
    }

    public putAgent =  (user: User) => {
        return axiosInstance.post('/agent/update', User.toJson(user));
    }

    public getAgentTypeList =  async () => {
        return await axiosInstance.get('/agent/type/list');
    }

    public getTransactionsMethods =  async () => {
        return await axiosInstance.get('/transanctions/methods/list');
    }

    public uploadAgentFile =  (file: any) => {
        const body = {'fileContent': file}; 

        return axiosInstance.post('/uploads/agent', body);
    }

    public uploadAgentId =  (file: any) => {
        const body = {'fileContent': file}; 

        return axiosInstance.post('/uploads/agent', body);
    }

    public disableAgent =  async (data: Record<string, any>) => {
        return axiosInstance.post('/agent/desable', data);
    }

    public fireAgent =  async (data: Record<string, any>) => {
        return axiosInstance.post('/agent/fired', data);
    }

    public getPieceTypes =  async () => {
        return await axiosInstance.get('/pieces/type/list');
    } 

    public getFullTimeAgents =  async () => {
        return await axiosInstance.get('/agent/list/filtered?idType=1');
    }  

    public getSeasonAgents =  async () => {
        return await axiosInstance.get('/agent/list/filtered?idType=2');
    } 
    
    public getContractAgents =  async () => {
        return await axiosInstance.get('/agent/list/filtered?idType=3');
    }

    public getStageAgents =  async () => {
        return await axiosInstance.get('/agent/list/filtered?idType=4');
    }
  

}