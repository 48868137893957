import { FC, useEffect, useState, useRef } from 'react'
import {
    Link, useNavigate 
} from "react-router-dom";

import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 

import AmortizmentService from './service';
import User from '../../Models/User';
import ShopService from '../Shop/service';

import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
import { RootState } from '../../Redux/store';

//Alert
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Alert from '../../Components/Alert';
import { useTranslation } from 'react-i18next';

interface AmortizmentProps {
    idAmortz?: string;
    libAmort: string; 
    montantAmort: string;
    dureeAmort: string;
    creator?: number ;
    typeAmort: string;
    brandCar: string;
    modelCar: string;
    colorCar: string;
    vinCar: string;
    yearPurchase: string;
    yearConcep: string;
    montantAssur: string;
    indexPurchase: string;
    indexRelease: string
}

interface AmortizmentFormProps {
    state: string;
    amortizment?: AmortizmentProps | null; 
}

const AmortizmentForm: FC<AmortizmentFormProps> = ( {  state, amortizment } ) => {
   

    let navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const user = useAppSelector((state: RootState ) => state.users.user );

    const formRef = useRef<FormikProps< AmortizmentProps >>(null);

    let [image, setImage] = useState('');

    let [typePromo, setTypePromo] = useState('');

    let [loading, setLoading] = useState(false);

    let [ shopsLoading, setShopsLoading] = useState(false);

    const [ shops, setShops ] = useState( [] );

    let [types] = useState([
        {
            label: t('Voiture'),
            value: 'car'
        },
        {
            label: t('Autre'),
            value: 'other'
        }
      ]);

    let [sendingMethods] = useState([
        {
            label: t('Imprimer'),
            value: 'print'
        },
        {
            label: 'E-mail',
            value: 'email'
        }
      ]);

    const amortizmentService = new AmortizmentService();
    const shopService = new ShopService();
    
    const readThis = (inputValue: any): void => {
        var file: File = inputValue.files[0];
        var lecteur: FileReader = new FileReader();
    
        lecteur.onloadend = (e) => {
            setImage( lecteur.result as string );
            // if (Object.prototype.toString.call(lecteur.result) === "[object String]") {
                
            // }
            
            console.log(lecteur.result);
        }
        lecteur.readAsDataURL(file);
    }

    const changeListener = (event: any)  => {
        readThis(event.target);
    }

    const getShops = () => {
        setShopsLoading(true);
        shopService.getShops().then(async function (response: any) {
            console.log(response);
            setShops(response.data);
            setShopsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setShopsLoading(false); 
        });
    }

    

    useEffect(() => {

        getShops();

        if (amortizment != null  && amortizment != undefined) {
            // setImage(client.profil ?? '');
        }

    }, []);

 


    return (
        <Formik
        
        initialValues={
            amortizment != null  && amortizment != undefined ? 
            {
                ...amortizment,
                creator: user?.id ,  
            }:
            {

                idAmortz: '',
                libAmort: '', 
                montantAmort: '',
                dureeAmort: '',
                creator: user?.id,
                typeAmort: 'other',
                brandCar: '',
                modelCar: '',
                colorCar: '',
                vinCar: '',
                yearPurchase: '',
                yearConcep: '',
                montantAssur: '',
                indexPurchase: '',
                indexRelease: ''
                
        }}
        validationSchema={
            yup.object().shape({
                libAmort: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                montantAmort: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                dureeAmort: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                typeAmort: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`), 
                brandCar: yup
                    .string(),
                modelCar: yup
                    .string(),
                colorCar: yup
                    .string(),
                vinCar: yup
                    .string(),
                yearPurchase: yup
                    .string(), 
                yearConcep: yup
                    .string(),
                montantAssur: yup
                    .string(),
                indexPurchase: yup
                    .string(),
                indexRelease: yup
                    .string()
            })
        }
        innerRef={formRef}
        onSubmit={async (
            values 
          ) => {
                    console.log(values);
                    setLoading(true);

                    // if (image != null && image != '' ) {
                    //     values.photoProfil = image;
                    // }

                    if (state == 'create') {
                        amortizmentService.postAmortizment( values ).then(async function (response: any) {
                            console.log(response);

                            confirmAlert({
                                overlayClassName: "alert-overlay",
                                customUI: ({ onClose }) => {
                                  return (
                                        <Alert 
                                            alert={{
                                                title: 'Succès',
                                                message: 'Amortissement ajoutée avec succès',
                                                actionText: 'Ok',
                                                action: () => {
                                                    onClose();
                                                }
                                            }}
                                        />
                                  );
                                }
                            });

                            navigate('/amortissement/list');
                            setLoading(false); 
                        })
                          .catch(function (error: any) {
                            console.log(error);
                            alert("Une erreur s'est produite réesseyez s'il vous plaît.")
                            setLoading(false); 
                        });
                    } else if (state == 'edit') {
                        amortizmentService.putAmortizment( values ).then(async function (response: any) {
                            console.log(response);

                            confirmAlert({
                                overlayClassName: "alert-overlay",
                                customUI: ({ onClose }) => {
                                  return (
                                        <Alert 
                                            alert={{
                                                title: 'Succès',
                                                message: 'Amortissement mis à jour avec succès',
                                                actionText: 'Ok',
                                                action: () => {
                                                    onClose();
                                                }
                                            }}
                                        />
                                  );
                                }
                            });
  
                            navigate('/amortissement/list');
                            setLoading(false); 
                        })
                          .catch(function (error: any) {
                            console.log(error);
                            alert("Une erreur s'est produite réesseyez s'il vous plaît.")
                            setLoading(false); 
                        });
                    }
                    
                }}
                >
                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                        <Form>
                                    <div className="form-body"> 

                                        <div className="row">
                                                        <div className="form-group col-md-6">
                                                                        <label>
                                                                            { t('Type') }
                                                                        </label>
                                                                        
                                                                            <select  
                                                                                className={`form-control custom-select
                                                                                 ${ errors.typeAmort &&
                                                                                    touched.typeAmort ? "is-invalid" : "" }`}
                                                                                id="typeAmort" 
                                                                                name="typeAmort"
                                                                                onChange={event => { 
                                                                                    handleChange('typeAmort')(event); 
                                                                                    setTypePromo(event.target.value);
                                                                                }}
                                                                                onBlur={handleBlur('typeAmort')}
                                                                                value={values.typeAmort == null ? '' : values.typeAmort}
                                                                                placeholder={ t('Type') ?? ''} >
                                                                                <option selected>{ t('Type') }</option>
                                                                                {
                                                                                    types.map(
                                                                                        (tp: any, id) => <option 
                                                                                        key={id} value={tp.value}>
                                                                                            {
                                                                                                tp.label
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                }
                                                                            </select> 
                                                                        
                                                    </div>
                      
                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Libellé') }
                                                        </label>
                                                        <input 
                                                            className={`form-control ${ errors.libAmort && touched.libAmort ? "is-invalid":""}`}
                                                            id="libAmort"
                                                            type="text"
                                                            name="libAmort"
                                                            onChange={handleChange('libAmort')}
                                                            onBlur={handleBlur('libAmort')}
                                                            value={values.libAmort}
                                                            placeholder={ t('Libellé') ?? ''} />
                                                        { errors.libAmort && touched.libAmort && errors.libAmort && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.libAmort && touched.libAmort && errors.libAmort }
                                                        </div> }
                                                    </div>

                                                    

                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Montant amortissement') }
                                                        </label>
                                                        <input 
                                                            className={`form-control ${ errors.montantAmort && touched.montantAmort
                                                                 ? "is-invalid":""}`}
                                                            id="montantAmort"
                                                            type="number"
                                                            name="montantAmort"
                                                            onChange={handleChange('montantAmort')}
                                                            onBlur={handleBlur('montantAmort')}
                                                            value={values.montantAmort}
                                                            placeholder={ t('Montant amortissement') ?? ''} />
                                                        { errors.montantAmort && touched.montantAmort && errors.montantAmort && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.montantAmort && touched.montantAmort && errors.montantAmort }
                                                        </div> }
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                        <label>{ t('Durée amortissement(mois)') }</label>
                                                        <input 
                                                            className={`form-control ${ errors.dureeAmort && touched.dureeAmort
                                                                 ? "is-invalid":""}`}
                                                            id="dureeAmort"
                                                            type="number"
                                                            name="dureeAmort"
                                                            onChange={handleChange('dureeAmort')}
                                                            onBlur={handleBlur('dureeAmort')}
                                                            value={values.dureeAmort}
                                                            placeholder={ t('Durée amortissement(mois)') ?? '' } />
                                                        { errors.dureeAmort && touched.dureeAmort && errors.dureeAmort && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.dureeAmort && touched.dureeAmort && errors.dureeAmort }
                                                        </div> }
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                        <label>{ t('Marque') }  </label>
                                                        <input disabled={ values?.typeAmort === 'other' }
                                                            className={`form-control ${ errors.brandCar && touched.brandCar ? "is-invalid":""}`}
                                                            id="brandCar"
                                                            type="text"
                                                            name="brandCar"
                                                            onChange={handleChange('brandCar')}
                                                            onBlur={handleBlur('brandCar')}
                                                            value={values.brandCar}
                                                            placeholder={ t('Marque') ?? ''} />
                                                        { errors.brandCar && touched.brandCar && errors.brandCar && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.brandCar && touched.brandCar && errors.brandCar }
                                                        </div> }
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                        <label> { t('Modèle') } </label>
                                                        <input disabled={ values?.typeAmort === 'other' }
                                                            className={`form-control ${ errors.modelCar && touched.modelCar ? "is-invalid":""}`}
                                                            id="modelCar"
                                                            type="text"
                                                            name="modelCar"
                                                            onChange={handleChange('modelCar')}
                                                            onBlur={handleBlur('modelCar')}
                                                            value={values.modelCar}
                                                            placeholder={ t('Modèle') ?? ''}/>
                                                        { errors.modelCar && touched.modelCar && errors.modelCar && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.modelCar && touched.modelCar && errors.modelCar }
                                                        </div> }
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                        <label>{ t('Couleur') }</label>
                                                        <input disabled={ values?.typeAmort === 'other' }
                                                            className={`form-control ${ errors.colorCar && touched.colorCar ? "is-invalid":""}`}
                                                            id="colorCar"
                                                            type="text"
                                                            name="colorCar"
                                                            onChange={handleChange('colorCar')}
                                                            onBlur={handleBlur('colorCar')}
                                                            value={values.colorCar}
                                                            placeholder={ t('Couleur') ?? ''} />
                                                        { errors.colorCar && touched.colorCar && errors.colorCar && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.colorCar && touched.colorCar && errors.colorCar }
                                                        </div> }
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                        <label> { t('Vin ou chassis') } </label>
                                                        <input disabled={ values?.typeAmort === 'other' }
                                                            className={`form-control ${ errors.vinCar && touched.vinCar ? "is-invalid":""}`}
                                                            id="vinCar"
                                                            type="text"
                                                            name="vinCar"
                                                            onChange={handleChange('vinCar')}
                                                            onBlur={handleBlur('vinCar')}
                                                            value={values.vinCar}
                                                            placeholder={ t('Vin ou chassis') ?? ''} />
                                                        { errors.vinCar && touched.vinCar && errors.vinCar && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.vinCar && touched.vinCar && errors.vinCar }
                                                        </div> }
                                                    </div>


                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t("Année d'achat") }
                                                        </label>
                                                        <input disabled={ values?.typeAmort === 'other' }
                                                            className={`form-control ${ errors.yearPurchase && touched.yearPurchase ? "is-invalid":""}`}
                                                            id="yearPurchase"
                                                            type="text"
                                                            name="yearPurchase"
                                                            onChange={handleChange('yearPurchase')}
                                                            onBlur={handleBlur('yearPurchase')}
                                                            value={values.yearPurchase}
                                                            placeholder={ t("Année d'achat") ?? ''} />
                                                        { errors.yearPurchase && touched.yearPurchase && errors.yearPurchase && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.yearPurchase && touched.yearPurchase && errors.yearPurchase }
                                                        </div> }
                                                    </div>


                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Année de conception') }
                                                        </label>
                                                        <input disabled={ values?.typeAmort === 'other' }
                                                            className={`form-control ${ errors.yearConcep && touched.yearConcep ? "is-invalid":""}`}
                                                            id="yearConcep"
                                                            type="text"
                                                            name="yearConcep"
                                                            onChange={handleChange('yearConcep')}
                                                            onBlur={handleBlur('yearConcep')}
                                                            value={values.yearConcep}
                                                            placeholder={ t('Année de conception') ?? ''} />
                                                        { errors.yearConcep && touched.yearConcep && errors.yearConcep && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.yearConcep && touched.yearConcep && errors.yearConcep }
                                                        </div> }
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Montant assurance') }
                                                        </label>
                                                        <input disabled={ values?.typeAmort === 'other' }
                                                            className={`form-control ${ errors.montantAssur && touched.montantAssur ? "is-invalid":""}`}
                                                            id="montantAssur"
                                                            type="number"
                                                            name="montantAssur"
                                                            onChange={handleChange('montantAssur')}
                                                            onBlur={handleBlur('montantAssur')}
                                                            value={values.montantAssur}
                                                            placeholder={ t('Montant assurance') ?? ''} />
                                                        { errors.montantAssur && touched.montantAssur && errors.montantAssur && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.montantAssur && touched.montantAssur && errors.montantAssur }
                                                        </div> }
                                                    </div>


                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Index Tableau de bord à l\'achat') }
                                                        </label>
                                                        <input disabled={ values?.typeAmort === 'other' }
                                                            className={`form-control ${ errors.indexPurchase && touched.indexPurchase ? "is-invalid":""}`}
                                                            id="indexPurchase"
                                                            type="text"
                                                            name="indexPurchase"
                                                            onChange={handleChange('indexPurchase')}
                                                            onBlur={handleBlur('indexPurchase')}
                                                            value={values.indexPurchase}
                                                            placeholder={ t('Index Tableau de bord à l\'achat') ?? ''}/>
                                                        { errors.indexPurchase && touched.indexPurchase && errors.indexPurchase && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.indexPurchase && touched.indexPurchase && errors.indexPurchase }
                                                        </div> }
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Index Tableau de bord à la release') }
                                                        </label>
                                                        <input disabled={ values?.typeAmort === 'other' }
                                                            className={`form-control ${ errors.indexRelease && touched.indexRelease ? "is-invalid":""}`}
                                                            id="indexRelease"
                                                            type="text"
                                                            name="indexRelease"
                                                            onChange={handleChange('indexRelease')}
                                                            onBlur={handleBlur('indexRelease')}
                                                            value={values.indexRelease}
                                                            placeholder={ t('Index Tableau de bord à la release') ?? '' } />
                                                        { errors.indexRelease && touched.indexRelease && errors.indexRelease && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.indexRelease && touched.indexRelease && errors.indexRelease }
                                                        </div> }
                                                    </div>
  
                                        </div>
 
                                    </div>
                                    <div className="form-actions">
                                        <div className="text-right">
                                            <button  disabled={!dirty && !isValid } type="submit" className="btn btn-info">
                                                { t('Valider') }
                                                {
                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                            <button type="reset" className="btn btn-dark">
                                                { t('Annuler') }
                                            </button>
                                        </div>
                                    </div>
                        </Form>
                    )}
                </Formik>
    );
}

export default AmortizmentForm