import { FC, useEffect, useState, useRef } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import AnalyticService from './service';

import {
    Link, useNavigate
} from "react-router-dom";
import UserRight from '../../Utils/userRight';

// Moment react
import Moment from 'react-moment';
import moment from 'moment';

// Formik
import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 
import { useTranslation } from 'react-i18next';

import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import ConfigurationService from '../Configuration/service';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);
interface AnalyticsForm {
    dteDeb: string | null;
    dteFin: string | null;
    period: string | null;
    type_id: string | null;
}

interface AnalyticsGraphData {
    labels: Array< String >;
    datasets: Array< any >;
}


const AnalyticStockPage: FC = () => {  

    let idToModInit: any = null; 
    const formRef = useRef< FormikProps< AnalyticsForm >>(null);

    const analyticService = new AnalyticService();
    const configService = new ConfigurationService();

    const [
        types,
        setTypes
    ] = useState([]);

    let [
        typesLoading,
        setTypesLoading
    ] = useState(false);

    const { t, i18n } = useTranslation();
    const analDataInit: any = null;
    const [analData, setAnalData] = useState(analDataInit);
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Analytics nombre de vente.',
          },
        },
    };

      const options2 = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Analytics total des ventes.',
          },
        },
      };


      const options3 = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Nombre d\'article par vente',
          },
        },
      };


      const options4 = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Analytics moyenne des porduits par commande.',
          },
        },
      };

      const options5 = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Analytics total des shipping.',
          },
        },
      };

      const options6 = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Analytics total des taxes.',
          },
        },
      };
      
    let labelsInit: Array<String> = [];
    
    let [labels, setLabels] = useState(labelsInit); 

      const monthLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October',
      'November', 'December'];
      const weekLabels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ];
      const dayLabels = [ '1', '2', '3', '4', '5', '6', '7', '8', 
      '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27',
      '28', '29', '30', '31' ];

      const graphDataInit: AnalyticsGraphData = {
        labels,
        datasets: [] };

    let [data, setData] = useState(graphDataInit);

    let [data2, setData2] = useState(graphDataInit);

    let [data3, setData3] = useState(graphDataInit);

    let [data4, setData4] = useState(graphDataInit);

    let [data5, setData5] = useState(graphDataInit);

    let [data6, setData6] = useState(graphDataInit);
 
    const navigate = useNavigate();

    const periods = [
        'day',
        "month",
        'year'
    ];

    const [ loading, setLoading ] = useState(false);

    const getAnalyticsData = (data: Record<string, any>) => {
        setLoading(true); 
        analyticService.getAnalyticsStocksData(data).then(async function (response: any) {
            console.log(response); 

            setAnalData(response.data.data);

            // const dataSet = response.data.data.graphicsData.map(
            //     (d: any) => d.order_number
            // );

            // const data2Set = response.data.data.graphicsData.map(
            //     (d: any) => d.total_ventes
            // );

            // const data3Set = response.data.data.graphicsData.map(
            //     (d: any) => d.nbre_article_vente
            // );
 
            // const labelsSet = response.data.data.graphicsData.map(
            //     (d: any) => d.date
            // );

            // setData(
            //     {
            //         labels: labelsSet,
            //         datasets: [
            //             {
            //                 label: 'Nombre de vente',
            //                 data: dataSet,
            //                 borderColor: 'rgb(53, 162, 235)',
            //                 backgroundColor: 'rgba(53, 162, 235, 0.5)',
            //             },
            //         ],
            //       }
            // );


            // setData2(
            //     {
            //         labels: labelsSet,
            //         datasets: [
            //             {
            //                 label: 'Total des ventes',
            //                 data: data2Set,
            //                 borderColor: 'rgb(255, 99, 132)',
            //                 backgroundColor: 'rgba(255, 99, 132, 0.5)',
            //             },
            //         ],
            //       }
            // );

            // setData3(
            //     {
            //         labels: labelsSet,
            //         datasets: [
            //             {
            //                 label: 'Nombre d\'article par vente',
            //                 data: data3Set,
            //                 borderColor: 'rgb(199, 255, 142)',
            //                 backgroundColor: 'rgba(199, 255, 142, 0.5)',
            //             },
            //         ],
            //       }
            // );
 
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    } 

    const columns = [
        {   
            name: `${t('Produits')}`,
            selector: (row: any) => row.nom,
            wrap: true, 
        },  
        {
            name: `${t('Stock')}`,
            selector: (row: any) => row.quantite,
        } 
    ];

    const conditionalRowStyles = [
        {
          when: (row: any) => row.quantite === 0,
          style: {
            backgroundColor: '#f26752',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        }
    ];

    useEffect( function() { 

        setLoading(true);

        const dateTo = moment().format('YYYY-MM-DD');
        const dateFrom = moment().subtract(7,'d').format('YYYY-MM-DD');


        getAnalyticsData({
            "dtedebut": dateTo,
            "dtefin": dateFrom,
            "periodicite":"journalier",
            "produit_id":0,
            "type_id": null
        });

        if ( formRef.current != null ) {
            formRef.current.values.dteDeb = dateFrom;
            formRef.current.values.dteFin = dateTo;
            formRef.current.values.period = 'day';
        }

        // getTypes();

    }, []);

    const getPeriod = (period: string | null) => {
        switch (period) {
            case 'day':
                return 'journalier';
                break;

            case 'month':
                return 'mensuel';
                break;
            
            case 'year':
                return 'annuel';
                break;
        
            default:
                return 'journalier';
                break;
        }
    }
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">

                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">
                                    {
                                        t('Stock')
                                    }
                                </h4> 
                            </div>
                        </div> 


                            <div className="card">
                                <div className="card-body">
                                    
                                    <div className="row"> 
                                        <div className="col-md-12">

                                        {/* <Formik
                                            innerRef={formRef}
                                            initialValues={
                                                {
                                                    dteDeb: null,
                                                    dteFin: null ,
                                                    period: '',
                                                    type_id: null
                                                }
                                            }
                                            validationSchema={
                                                yup.object().shape({
                                                    dteDeb: yup
                                                        .date()
                                                        .required(`${t('Ce champ est obligatoire')}`),
                                                    dteFin: yup
                                                        .date() 
                                                        .required(`${t('Ce champ est obligatoire')}`),
                                                    period: yup
                                                        .string()
                                                        .required(`${t('Ce champ est obligatoire')}`),
                                                    type_id: yup
                                                        .string()
                                                        .required(`${t('Ce champ est obligatoire')}`)
                                                })
                                            }
                                            onSubmit={async (
                                                values 
                                            ) => {   

                                                getAnalyticsData({
                                                    "dtedebut": values.dteDeb,
                                                    "dtefin": values.dteFin,
                                                    "periodicite": getPeriod(values.period),
                                                    "produit_id":0,
                                                    "type_id": values.type_id
                                                });
                                                 
                                            }}  
                                            >
                                                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                                <Form>
                                                        <div className="form-body"> 
                                                            <div className="row">
                                                                <div className='col-md-3'>
                                                                    <div className="form-group"> 
                                                                            <label> { t('Date de début') } </label>
                                                                            <Field type="date"
                                                                                className={`form-control ${ errors.dteDeb
                                                                                     && touched.dteDeb ? "is-invalid":""}`}
                                                                                id="dteDeb" 
                                                                                name="dteDeb"
                                                                                onChange={handleChange('dteDeb')}
                                                                                onBlur={handleBlur('dteDeb')}
                                                                                value={values.dteDeb == null ? new Date()  : values.dteDeb }
                                                                                placeholder={ t('Date de début') ?? '' } />
                                                                            { errors.dteDeb && touched.dteDeb && errors.dteDeb && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                { errors.dteDeb && touched.dteDeb && errors.dteDeb }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-3'>
                                                                    <div className="form-group"> 
                                                                            <label>{ t('Date de fin') ?? '' } </label>
                                                                            <Field type="date"
                                                                                className={`form-control ${ errors.dteFin
                                                                                     && touched.dteFin ? "is-invalid":""}`}
                                                                                id="dteFin" 
                                                                                name="dteFin"
                                                                                onChange={handleChange('dteFin')}
                                                                                onBlur={handleBlur('dteFin')}
                                                                                value={values.dteFin == null ? new Date()  : values.dteFin }
                                                                                placeholder={ t('Date de fin') ?? '' } />
                                                                            { errors.dteFin && touched.dteFin && errors.dteFin && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                { errors.dteFin && touched.dteFin && errors.dteFin }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-3'>
                                                                    <div className="form-group"> 
                                                                            <label>{ t('Période') ?? '' } </label>
                                                                            <Field as="select"  
                                                                                className={`form-control custom-select ${ errors.period &&
                                                                                    touched.period ? "is-invalid" : "" }`}
                                                                                id="period" 
                                                                                name="period"
                                                                                onChange={(event: any) => { 
                                                                                    handleChange('period')(event); 
                                                                                }}
                                                                                onBlur={handleBlur('period')}
                                                                                value={ values.period }
                                                                                placeholder={ t('Période') ?? '' } >
                                                                                <option selected>{ t('Période') ?? '' }...</option>
                                                                                {
                                                                                    periods.map(
                                                                                        (prd: any, id) => <option 
                                                                                        key={id} value={prd} style={ { textTransform: "capitalize" } } >
                                                                                            {
                                                                                               t(t('analyticPeriod', { analyticPeriodName: prd } ) ?? '')
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                }
                                                                            </Field> 
                                                                            { errors.period && touched.period && errors.period && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                { errors.period && touched.period && errors.period }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-3'>
                                                                <div className="form-group"> 
                                                                <label>{ t('Catégorie') ?? '' } </label>
                                                                {
                                                            typesLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" 
                                                                role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <Field as="select"  
                                                                className={`form-control custom-select ${ errors.type_id &&
                                                                    touched.type_id ? "is-invalid" : "" }`}
                                                                id="type_id" 
                                                                name="type_id"
                                                                onChange={(event: any) => { 
                                                                    handleChange('type_id')(event); 
                                                                }}
                                                                onBlur={handleBlur('type_id')}
                                                                value={values.type_id}
                                                                placeholder="Type produit" >
                                                                <option selected>{t('Type produit')}...</option>
                                                                {
                                                                   types.map(
                                                                        (type: any, id) => <option 
                                                                        key={id} value={type.id}>
                                                                            {
                                                                                type.libType
                                                                            }
                                                                            </option>
                                                                    )
                                                                }
                                                            </Field> 
                                                        }
                                                        { errors.type_id && touched.type_id && errors.type_id && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.type_id && touched.type_id && errors.type_id }
                                                        </div> } </div>
                                                                </div>
                                                            </div>
                                                         </div>   

                                                        <div className="form-actions">
                                                            <div className="text-right">
                                                                <button  disabled={!isValid} type="submit" className="btn btn-info">
                                                                    { t('Valider') }
                                                                    {
                                                                        loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    }
                                                                </button>

                                                            </div>
                                                        </div>

                                                    </Form>
                                                )}
                                            </Formik> */}


                                        </div>
                                        </div>

                                        <br />

                                        { loading || analData === null ? 
                                        <div className="spinner-border text-light spinner-border-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div> : 
                                        <> 
                                    <div className="row"> 
                                        <div className="col-md-12">

                                           

                                        {/* <div className="card-group">
                                            
                                            <div className="card border-right">
                                                <div className="card-body">
                                                    <div className="d-flex d-lg-flex d-md-block align-items-center">
                                                        <div> 
                                                            <div className="d-inline-flex align-items-center">
                                                                <h4 className="text-dark mb-1 font-weight-medium">
                                                                    { t('Total articles') }
                                                                </h4> 
                                                            </div>
                                                            <h6 className="font-weight-normal mb-0 w-100 text-truncate">
                                                                {
                                                                    analData?.total_articles 
                                                                }
                                                            </h6>
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="card border-right">
                                                <div className="card-body">
                                                    <div className="d-flex d-lg-flex d-md-block align-items-center">
                                                        <div>
                                                            <div className="d-inline-flex align-items-center">
                                                                <h4 className="text-dark mb-1 font-weight-medium">
                                                                    { t('Nombre de commande') }
                                                                </h4>
                                                                
                                                            </div>
                                                            <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                                                                {
                                                                  analData?.total_order_number
                                                                }
                                                            </h6>
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div> 

                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="d-flex d-lg-flex d-md-block align-items-center">
                                                        <div>
                                                            <h4 className="text-dark mb-1 font-weight-medium">
                                                                { t('Vente totale') }
                                                            </h4>
                                                            <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                                                                {
                                                                    analData?.total_ventes_montant
                                                                }
                                                            </h6>
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  */}


                                        <br />
                                            
                            
                                            </div>
                                        </div> 


                                        <div className="row"> 
                                            <div className="col-md-12">
                                                <DataTable
                                                    responsive={true}
                                                    className="table table-striped table-bordered"
                                                    columns={columns}
                                                    conditionalRowStyles={conditionalRowStyles}
                                                    data={analData}
                                                    progressPending={loading}
                                                    pagination
                                                    paginationPerPage={30}
                                                />
                                            </div>
                                            {/* <div className="col-md-12">
                                                { 
                                                    !loading && <Line options={options2} data={data2} />
                                                } 
                                                
                                            </div>
                                            <div className="col-md-12">
                                                { 
                                                    !loading && <Line options={options3} data={data3} />
                                                } 
                                            </div> */}

                                        
                                        </div>
                                    </>
                                    }

                                </div>
                            </div> 
                        </div> 
                    </div>
                </div>   
            </>                   

    );
}

export default AnalyticStockPage