import { FC, useEffect, useRef, useState } from 'react'
import Breadcrumb from '../../../Components/Breadcrumb';


import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup';
import ConfigurationService from '../service';


import Alert from '../../../Components/Alert';


// type FormValues = Gamme;

//Alert
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 

//Models
import User from '../../../Models/User';
import Right from '../../../Models/Right';
import { useTranslation } from 'react-i18next';

const SetRight: FC = () => {     
    
    const formRef = useRef< FormikProps<any> >(null);
    const { t, i18n } = useTranslation();

    const configService = new ConfigurationService();

    const [ loading, setLoading ] = useState(false);

    const [ agentsLoading, setAgentsLoading ] = useState(false);

    const [ rightsLoading, setRightsLoading ] = useState(false);

    const [
        rights,
        setRights
    ] = useState([]);

    const [
        agents,
        setAgents
    ] = useState([]);

    const initialSelectedAgent: any | null = null;

    const [
        selectedAgent, setSelectedAgent
    ] = useState(initialSelectedAgent);


    const getRights = () => {
        setAgentsLoading(true);
        configService.getRights().then(async function (response: any) {
            console.log(response); 
            setRights(response.data);
            setAgentsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setAgentsLoading(false); 
        });
    }

    const getAgents = () => {
        setRightsLoading(true);
        configService.getAgents().then(async function (response: any) {
            console.log(response); 
            setAgents(response.data);
            setRightsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setRightsLoading(false); 
        });
    }

    const cancel = () => {

        if ( formRef.current != null) { 

            formRef.current.values.agent = null;
            formRef.current.values.droit = null;

        }
    }


    const getAgentById = ( id: string ) => {
        const agent = agents.filter(
            (agent: any) => agent.id == Number(id)
        );
        return agent[0];
    }


    useEffect(
        () => {
            getRights();
            getAgents();
        }, []
    );

    return ( 
        <>  
            <Breadcrumb />
            <div className="container-fluid">
             
                <div className="row">

                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">

                                <h4 className="card-title">
                                    { t('Attribuer droit') }
                                </h4>

                                <Formik
                                    initialValues={
                                        {
                                        agent: null,
                                        droit: null
                                    }}
                                    validationSchema={
                                        yup.object().shape({
                                            agent: yup
                                                .string() 
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            droit: yup
                                                .string() 
                                                .required(`${t('Ce champ est obligatoire')}`),
                                        })
                                    }
                                    onSubmit={async (
                                        values 
                                    ) => {

                                        setLoading(true);

                                        const agent: any = getAgentById(values.agent);

                                        if (agent.droit.id !== Number(values.droit)) {

                                            await configService.updateUserRight({
                                                idAgent: values.agent,
                                                idDroit: values.droit
                                            }).then(async function (response: any) {

                                                confirmAlert({
                                                    overlayClassName: "alert-overlay",
                                                    customUI: ({ onClose }) => {
                                                      return (
                                                            <Alert 
                                                                alert={{
                                                                    title: 'Succès', 
                                                                    message: "Le droit a été attribué avec succès",
                                                                    actionText: 'Ok',
                                                                    action: () => {
                                                                        onClose();
                                                                    }
                                                                }}
                                                            />
                                                      );
                                                    }
                                                });
                                                
                                                if ( formRef.current != null) {
                                                    formRef.current.values.agent = null;
                                                    formRef.current.values.droit = null;
                                                } 
                                            
                                            })
                                            .catch(function (error: any) {
                                                setLoading(false); 
                                            });

                                        } else {
                                            setLoading(false);
                                            confirmAlert({
                                                overlayClassName: "alert-overlay",
                                                customUI: ({ onClose }) => {
                                                  return (
                                                        <Alert 
                                                            alert={{
                                                                title: 'Erreur',
                                                                type: 'error',
                                                                message: "L'utilisateur a déjà ce droit",
                                                                actionText: 'Ok',
                                                                action: () => {
                                                                    onClose();
                                                                }
                                                            }}
                                                        />
                                                  );
                                                }
                                            }); 
                                        }

                                        // setLoading(true);


                                    }} 
                                    innerRef={formRef}
                                >

                                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                <Form>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    { t('Agent') }
                                                </label>
                                                        {
                                                            agentsLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <select  
                                                                className={`form-control custom-select ${ errors.agent &&
                                                                    touched.agent ? "is-invalid" : "" }`}
                                                                id="agent" 
                                                                name="agent"
                                                                onChange={event => {
                                                                    console.log(event);
                                                                    handleChange('agent')(event);
                                                                    const agent = getAgentById(event.target.value);
                                                                    console.log(agent);
                                                                    setSelectedAgent( agent );
                                                                }}
                                                                onBlur={handleBlur('agent')}
                                                                value={values.agent}
                                                                placeholder={ t('Agent') ?? '' } >
                                                                <option selected>{ t('Agent') }..</option>
                                                                {
                                                                    agents.map(
                                                                        (agent: any, id) => <option 
                                                                         key={id} value={agent.id}>
                                                                            {
                                                                                agent.prenomAgent + ' ' + agent.nomAgent
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </select> 
                                                        }
                                            </div>
                                            { selectedAgent != null ?
                                                <div className="card">
                                                    <div className="card-body">
                                                        {/* <h4 className="card-title">Droit de l'agent</h4> */}
                                                        <div className="list-group"> 
                                                            <a href="javascript:void(0)"
                                                            className="list-group-item active">
                                                                { t('Droit de l\'agent') } </a> 
                                                            <a href="javascript:void(0)" className="list-group-item">
                                                                <b>{t('Libellé')}:</b> { selectedAgent.droit.libDroit }
                                                            </a> 
                                                            <a href="javascript:void(0)" className="list-group-item">
                                                                <b>Description:</b> { selectedAgent.droit.descriptionDroit }
                                                            </a>
                                                            {/* <a href="javascript:void(0)" className="list-group-item">
                                                            </a>  */}
                                                        </div>
                                                    </div>
                                                </div> :  <></>
                                            }
                                               
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    { t('Droit') }
                                                </label>
                                                {
                                                    rightsLoading ? 
                                                    <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> :
                                                    <select  
                                                            className={`form-control custom-select ${ errors.droit &&
                                                                touched.droit ? "is-invalid" : "" }`}
                                                            id="droit" 
                                                            name="droit"
                                                            onChange={handleChange('droit')}
                                                            onBlur={handleBlur('droit')}
                                                            value={values.droit}
                                                            placeholder="Droit" >
                                                            <option selected>{ t('Droit') }...</option>
                                                            {
                                                                rights.map(
                                                                    (right: any, id) => <option key={id} value={right.id}>
                                                                        {
                                                                            right.libDroit
                                                                        }
                                                                    </option>
                                                                )
                                                            }
                                                    </select>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-actions">
                                        <div className="text-right">
                                            <button  disabled={!isValid} type="submit" 
                                                className="btn btn-info">
                                                { t('Valider') }
                                                {
                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                            <button type="button"
                                                onClick={event => cancel() }
                                            className="btn btn-dark">
                                                { t('Annuler') }
                                            </button>
                                        </div>
                                    </div>

                                    </Form>
                                    )}
                                </Formik>
                            
                            </div>
                        </div>
                    </div>

                </div>
            </div>                
        </>
    )  
}

export default SetRight