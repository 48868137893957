import User from '../../Models/User';
import axiosInstance from '../../Utils/axios_manager';

export default class ClientService {
  
    constructor( ) {
    }

    public postClient =  (data: Record<string, any>) => {
        return axiosInstance.post('/client/add', data);
    }

    public getClients =  async () => {
        return await axiosInstance.get('/client/list');
    }

    public getClient =  async (id: Number) => {
        return await axiosInstance.get('/client/details' + `?id=${id.toString()}`);
    }

    public putClient =  (data: Record<string, any>) => {
        return axiosInstance.post('/client/update', data);
    }
  

}