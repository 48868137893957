import { FC, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import HistoryService from './service';

//Moment react
import Moment from 'react-moment';

import {
    Link, useNavigate
} from "react-router-dom";
import UserRight from '../../Utils/userRight';
import { useTranslation } from 'react-i18next';

const HistoryPage: FC = () => {  

    let idToModInit: any = null;

    const { t } = useTranslation();

    const [ histories, setHistories ] = useState( [] );
    const [ tempProducts, setTempProducts ] = useState( [] );
    const [ idToMod, setIdToMod ] = useState(idToModInit);
    const [ loading, setLoading ] = useState(false);

    const [ searchStr, setSearchStr ] = useState('');
    const [ searchLoading, setSearchLoading ] = useState(false);

    const historyService = new HistoryService();

    const userRight = new UserRight();

    const navigate = useNavigate();

    // let editAgent = (id: Number): void => {
    //     setIdToMod(id);
    //     setLoading(true);
    //     navigate('/agent/edit/'+id.toString());
    //     setLoading(false);
    // }

    const getHistories = () => {
        setLoading(true);
        historyService.getHistories().then(async function (response: any) {
            console.log(response);
            setHistories(response.data); 
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    useEffect( function() { 
        getHistories();
    }, []);

    const columns = [
        {
            name: t('Libellé'),
            selector: (row: any) => row.evennement?.libelleEvent,
        },
        {
            name: 'Agent',
            selector: (row: any) => row.agent?.prenomAgent + ' ' + row.agent?.nomAgent,
        },
        {
            name: 'Description',
            selector: (row: any) => row.evennement?.descEvent,
        },
        {
            name: "Date",
            selector: (row: any) => <Moment format="YYYY/MM/DD hh:mm:ss">
                                        {row.dateEvent}
                                    </Moment>
        } 
    ];
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-list">
                                        <button type="button" onClick={ event => getHistories() }
                                            className="btn waves-effect waves-light btn-danger">
                                                { t('Actualiser') } </button> 
                                    </div> 
                                    
                                    <div className="table-responsive">
                                        <DataTable
                                            responsive={true}
                                            className="table table-striped table-bordered"
                                            columns={columns}
                                            data={histories}
                                            progressPending={loading}
                                            pagination
                                        />
                                    </div>
                                </div>
                            </div> 
                        </div> 
                    </div>
                </div>   
            </>                   

    );
}

export default HistoryPage