import { FC, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import HistoryService from './service';

//Moment react
import Moment from 'react-moment';

import {
    Link, useNavigate
} from "react-router-dom";
import UserRight from '../../Utils/userRight';
import { useTranslation } from 'react-i18next';

const SchedulePage: FC = () => {  

    let idToModInit: any = null;

    const { t } = useTranslation();

    const [ schedules, setSchedules ] = useState( [] );
    const [ tempProducts, setTempProducts ] = useState( [] );
    const [ idToMod, setIdToMod ] = useState(idToModInit);
    const [ loading, setLoading ] = useState(false);

    const [ searchStr, setSearchStr ] = useState('');
    const [ searchLoading, setSearchLoading ] = useState(false);

    const historyService = new HistoryService();

    const userRight = new UserRight();

    const navigate = useNavigate();

    // let editAgent = (id: Number): void => {
    //     setIdToMod(id);
    //     setLoading(true);
    //     navigate('/agent/edit/'+id.toString());
    //     setLoading(false);
    // }

    const getSchedules = () => {
        setLoading(true);
        historyService.getSchedules().then(async function (response: any) {
            console.log(response);
            setSchedules(response.data); 
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    useEffect( function() { 
        getSchedules();
    }, []);

    const columns = [
        {
            name: 'Agent',
            selector: (row: any) => row.agent?.prenomAgent + ' ' + row.agent?.nomAgent,
        },
        {
            name: 'Type',
            selector: (row: any) => row.workEvent?.libelle,
        },
        {
            name: "Date",
            selector: (row: any) => <Moment format="YYYY/MM/DD hh:mm:ss">
                                        {row.dateEventHor}
                                    </Moment>
        } 
    ];
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-list">
                                        <button type="button" onClick={ event => getSchedules() }
                                            className="btn waves-effect waves-light btn-danger">
                                            { t('Actualiser') }  </button> 
                                    </div> 
                                    
                                    <div className="table-responsive">
                                        <DataTable
                                            responsive={true}
                                            className="table table-striped table-bordered"
                                            columns={columns}
                                            data={schedules}
                                            progressPending={loading}
                                            pagination
                                        />
                                    </div>
                                </div>
                            </div> 
                        </div> 
                    </div>
                </div>   
            </>                   

    );
}

export default SchedulePage