import Category from '../../Models/Category';
import Gamme from '../../Models/Gamme';
import Right from '../../Models/Right';
import Type from '../../Models/Type';
import User from '../../Models/User';
import axiosInstance from '../../Utils/axios_manager';

export default class ConfigurationService {
  
    constructor( ) {
    }


    public getGammes =  async () => {
        return await axiosInstance.get('/gamme/list');
    }

    public postGamme =  ( gamme: Gamme ) => {
        return axiosInstance.post('/gamme/add', Gamme.toJson(gamme));
    }

    public updateGamme =  ( gamme: Gamme ) => {
        return axiosInstance.put('/gamme/update', Gamme.toJson(gamme));
    }

    public deleteGamme = ( gammeId: Number ) => {
        return axiosInstance.delete('/gamme/delete' + `?id=${gammeId}`);
    }



    public getTypes =  async () => {
        return await axiosInstance.get('/type/list');
    }

    public postType =  ( type: Type ) => {
        return axiosInstance.post('/type/add', Type.toJson(type));
    }

    public updateType =  ( type: Type ) => {
        return axiosInstance.put('/type/update', Type.toJson(type));
    }

    public deleteType = ( typeId: Number ) => {
        return axiosInstance.delete('/type/delete' + `?id=${typeId}`);
    }


    public getCategories =  async () => {
        return await axiosInstance.get('/cat/list');
    }

    public postCategory =  ( category:  Category ) => {
        return axiosInstance.post('/cat/add', Category.toJson(category));
    }

    public updateCategory =  ( category:  Category ) => {
        return axiosInstance.put('/cat/update', Category.toJson(category));
    }

    public deleteCategory  = ( categoryId: Number ) => {
        return axiosInstance.delete('/cat/delete' + `?id=${categoryId}`);
    }


    public getRights =  async () => {
        return await axiosInstance.get('/droit/list');
    }

    public postRight =  ( right: Right ) => {
        return axiosInstance.post('/droit/add', Right.toJson(right));
    }

    public updateRight =  ( right: Right ) => {
        return axiosInstance.put('/droit/update', Right.toJson(right));
    }

    public deleteRight  = ( rightId: Number ) => {
        return axiosInstance.delete('/droit/delete' + `?id=${rightId}`);
    }

    public updateUserRight =  ( data: any ) => {
        return axiosInstance.post('/agent/right/set', data);
    }

    public getAgents =  async () => {
        return await axiosInstance.get('/agent/list');
    }
  

}