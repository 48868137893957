import { FC, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import AnalyticService from './service';

import {
    Link, useNavigate
} from "react-router-dom";
import UserRight from '../../Utils/userRight';

// Moment react
import Moment from 'react-moment';

// Formik
import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 
import { useTranslation } from 'react-i18next';

const AnalyticFormPage: FC = () => {  

    let idToModInit: any = null;

    const { t, i18n } = useTranslation();

    const [ spents, setSpents ] = useState( [] );
    const [ tempProducts, setTempProducts ] = useState( [] );
    const [ idToMod, setIdToMod ] = useState(idToModInit);
    const [ loading, setLoading ] = useState(false);

    const [ searchStr, setSearchStr ] = useState('');
    const [ searchLoading, setSearchLoading ] = useState(false);

    const analyticService = new AnalyticService();

    const userRight = new UserRight();

    const navigate = useNavigate();

    const periods = [
        'day',
        "month",
        'year'
    ]

    useEffect( function() { 
        // getSpents();
    }, []);
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <p>
                                {
                                    t("Ce ménu vous permet d'avoir des rapports détaillés sur le système, les visites, les ventes, les produits")
                                }...
                            </p>
                            <div className="card">
                                <div className="card-body">
                                    
                                    <div className="row"> 
                                        <div className="col-md-12">
                                        <Formik
                                            initialValues={
                                                {
                                                    dteDeb: null,
                                                    dteFin: null ,
                                                    period: ''
                                                }
                                            }
                                            validationSchema={
                                                yup.object().shape({
                                                    dteDeb: yup
                                                        .date()
                                                        .required(`${t('Ce champ est obligatoire')}`),
                                                    dteFin: yup
                                                        .date() 
                                                        .required(`${t('Ce champ est obligatoire')}`),
                                                    period: yup
                                                        .string()
                                                        .required(`${t('Ce champ est obligatoire')}`),
                                                })
                                            }
                                            onSubmit={async (
                                                values 
                                            ) => {  
                                                
                                                window.localStorage.setItem('analData', JSON.stringify(values));
                                                navigate('/analytics/results');
                                                 
                                            }}  
                                            >
                                                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                                <Form>
                                                        <div className="form-body"> 
                                                            <div className="row">
                                                                <div className='col-md-4'>
                                                                    <div className="form-group"> 
                                                                            <label> { t('Date de début') } </label>
                                                                            <Field type="date"
                                                                                className={`form-control ${ errors.dteDeb
                                                                                     && touched.dteDeb ? "is-invalid":""}`}
                                                                                id="dteDeb" 
                                                                                name="dteDeb"
                                                                                onChange={handleChange('dteDeb')}
                                                                                onBlur={handleBlur('dteDeb')}
                                                                                value={values.dteDeb == null ? new Date()  : values.dteDeb }
                                                                                placeholder={ t('Date de début') ?? '' } />
                                                                            { errors.dteDeb && touched.dteDeb && errors.dteDeb && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                { errors.dteDeb && touched.dteDeb && errors.dteDeb }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <div className="form-group"> 
                                                                            <label>{ t('Date de fin') ?? '' } </label>
                                                                            <Field type="date"
                                                                                className={`form-control ${ errors.dteFin
                                                                                     && touched.dteFin ? "is-invalid":""}`}
                                                                                id="dteFin" 
                                                                                name="dteFin"
                                                                                onChange={handleChange('dteFin')}
                                                                                onBlur={handleBlur('dteFin')}
                                                                                value={values.dteFin == null ? new Date()  : values.dteFin }
                                                                                placeholder={ t('Date de fin') ?? '' } />
                                                                            { errors.dteFin && touched.dteFin && errors.dteFin && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                { errors.dteFin && touched.dteFin && errors.dteFin }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <div className="form-group"> 
                                                                            <label>{ t('Période') ?? '' } </label>
                                                                            <Field as="select"  
                                                                                className={`form-control custom-select ${ errors.period &&
                                                                                    touched.period ? "is-invalid" : "" }`}
                                                                                id="period" 
                                                                                name="period"
                                                                                onChange={(event: any) => {
                                                                                    // console.log(event);
                                                                                    handleChange('period')(event);
                                                                                    // const agent = getAgentById(event.target.value);
                                                                                    // console.log(agent);
                                                                                    // setSelectedAgent( agent );
                                                                                }}
                                                                                onBlur={handleBlur('period')}
                                                                                value={ values.period }
                                                                                placeholder={ t('Période') ?? '' } >
                                                                                <option selected>{ t('Période') ?? '' }...</option>
                                                                                {
                                                                                    periods.map(
                                                                                        (prd: any, id) => <option 
                                                                                        key={id} value={prd} style={ { textTransform: "capitalize" } } >
                                                                                            {
                                                                                               t(t('analyticPeriod', { analyticPeriodName: prd } ) ?? '')
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                }
                                                                            </Field> 
                                                                            { errors.period && touched.period && errors.period && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                { errors.period && touched.period && errors.period }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                         </div>   

                                                        <div className="form-actions">
                                                            <div className="text-right">
                                                                <button  disabled={!isValid} type="submit" className="btn btn-info">
                                                                    { t('Valider') }
                                                                    {
                                                                        loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    }
                                                                </button>

                                                            </div>
                                                        </div>

                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div> 
                    </div>
                </div>   
            </>                   

    );
}

export default AnalyticFormPage