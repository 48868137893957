import { FC, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../../Components/Breadcrumb';
import ProductService from '../service';

import {
    Link, useNavigate
} from "react-router-dom";
import UserRight from '../../../Utils/userRight';
import { useTranslation } from 'react-i18next';


const ProductKitList : FC = () => {  

    let idToModInit: any = null;

    const { t } = useTranslation();

    const [ products, setKits ] = useState( [] );
    const [ tempKits, setTempKits ] = useState( [] );
    const [ idToMod, setIdToMod ] = useState(idToModInit);
    const [ loading, setLoading ] = useState(false);

    const [ searchStr, setSearchStr ] = useState('');
    const [ searchLoading, setSearchLoading ] = useState(false);

    const productService = new ProductService();

    const userRight = new UserRight();

    const navigate = useNavigate();

    // let editAgent = (id: Number): void => {
    //     setIdToMod(id);
    //     setLoading(true);
    //     navigate('/agent/edit/'+id.toString());
    //     setLoading(false);
    // }

    const getKits = () => {
        setLoading(true);
        productService.getKits().then(async function (response: any) {
            console.log(response);
            setKits(response.data.data);
            setTempKits(response.data.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    useEffect( function() { 
        getKits();
    }, []);

    const columns = [
        {
            name: `${ t('Nom du kit') }`,
            selector: (row: any) => row.name,
        },
        {
            name: `${t('Nom sur le site')}`,
            selector: (row: any) => row.displayName,
        },
        {
            name: `${t('Type')}`,
            selector: (row: any) => row.grpType?.libelle,
        }, 
        {
            name: `${t('Nombre de produit')}`,
            selector: (row: any) => row.nbreProd
        },
        {
            name: `${t('Réduction')}`,
            selector: (row: any) => row.reduction,
        },
        {
            name: `${t('Prix de vente')}`, 
            selector: (row: any) => row.sellPrice,
        },
        {
            name: `${t('Prix système')}`, 
            selector: (row: any) => row.systemPrice,
        },
        {
            name: `${t('Options')}`,
            // omit: !userRight.hasRight(['administrateur']),
            selector: (row: any) => (
                <div className='btn-list'>
                    <button type="button" onClick={event => {
                        navigate('/product/kit/edit/'+row.id.toString());
                     }}
                        className="btn waves-effect waves-light btn-success">
                            <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-pencil"></i></span>
                    </button>
                    {/* <button type="button" onClick={event => {
                        navigate('/product/edit/'+row.id.toString());
                     }}
                        className="btn waves-effect waves-light btn-success">
                            <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-pencil"></i></span>
                    </button>
                    <button type="button" onClick={event => {  }}
                        className="btn waves-effect waves-light btn-danger"> 
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-trash"></i></span>
                             
                    </button> */}
                </div>
            )
        }
    ];
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-list">
                                        <button type="button" onClick={ event => getKits() }
                                            className="btn waves-effect waves-light btn-danger">
                                                {
                                                    t('Actualiser')
                                                }
                                            </button>
                                        <Link type="button" to="/product/kit/create"
                                            className="btn waves-effect waves-light btn-primary">
                                                {
                                                    t('Ajouter un kit produit')
                                                }
                                        </Link>
                                    </div> 
                                     
                                    <br />
                                    
                                    <div className="table-responsive">
                                        <DataTable
                                            responsive={true}
                                            className="table table-striped table-bordered"
                                            columns={columns}
                                            data={products}
                                            progressPending={loading}
                                            pagination
                                        />
                                    </div>
                                </div>
                            </div> 
                        </div> 
                    </div>
                </div>   
            </>                   

    );
}

export default ProductKitList