import { FC, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import AgentService from './service';

import {
    Link, useNavigate
} from "react-router-dom";

// React - Bootstrap
import { Tabs, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const AgentList: FC = () => {  
    let idToModInit: any = null;
    const { t } = useTranslation();
    const [ agents, setAgents ] = useState( [] );
    const [key, setKey] = useState('all');
    const [ filteredAgents, setFilteredAgents ] = useState( [] );
    const [ idToMod, setIdToMod ] = useState(idToModInit);
    const [ loading, setLoading ] = useState(false);
    const agentService = new AgentService();

    const [keys, setKeys] = useState([
        {
            key: 'all',
            label: `${t('Tout')}` 
        },
        {
            key: 'fulltime',
            label: `${t('Temps plein')}`
        },
        {
            key: 'season',
            label: `${t('Saisonières')}`
        },
        {
            key: 'contract',
            label: `${t('Contractuels')}`
        },
        {
            key: 'stage',
            label: `${t('Stagiaires')}`
        }
    ]);

    const navigate = useNavigate();

    let editAgent = (id: Number): void => {
        setIdToMod(id);
        setLoading(true);
        navigate('/agent/edit/'+id.toString());
        setLoading(false);
    }

    const getAgents = () => {
        setLoading(true);
        agentService.getAgents().then(async function (response: any) {
            console.log(response);
            setAgents(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    const getFullTimeAgents = () => {
        setLoading(true);
        agentService.getFullTimeAgents().then(async function (response: any) {
            console.log(response);
            setAgents(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    const getSeasonAgents = () => {
        setLoading(true);
        agentService.getSeasonAgents().then(async function (response: any) {
            console.log(response);
            setAgents(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    const getContractAgents = () => {
        setLoading(true);
        agentService.getContractAgents().then(async function (response: any) {
            console.log(response);
            setAgents(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    const getStageAgents = () => {
        setLoading(true);
        agentService.getStageAgents().then(async function (response: any) {
            console.log(response);
            setAgents(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    const conditionalRowStyles = [
        {
          when: (row: any) => row.actif === false,
          style: {
            backgroundColor: '#f2eded',
            color: 'black',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        }
    ];
        

    useEffect( function() { 
        getAgents();
    }, []);

    const columns = [
        {
            name: `${t('Photo')}`,
            selector: (row: any) => row?.profil != '' ? (
                <img src={`${row.profil}`} className="img-thumbnail" width="35" height="20" alt="" />
            ) :  ""
        },
        {
            name: `${t('Prénom')}`,
            selector: (row: any) => row.prenomAgent,
        },
        {
            name: `${t('Nom')}`,
            selector: (row: any) => row.nomAgent,
        },
        {
            name: `${t('Date de naissance')}`,
            selector: (row: any) => row.dateNaisAgent,
        },
        {
            name: `${t('Adresse')}`,
            selector: (row: any) => row.adresseAgent,
        },
        {
            name: `${t('Contact')}`,
            selector: (row: any) => row.contactAgent,
        },
        {
            name: `${t('Pseudo')}`,
            selector: (row: any) => row.pseudoAgent,
        },
        {
            name: "Options", 
            selector: (row: any) => (
                <button type="button" onClick={event => editAgent(Number(row.id))}
                    className="btn waves-effect waves-light btn-success">
                        { t('Modifier') }
                        {
                            loading && idToMod == Number(row.id) && <div className="spinner-border text-light spinner-border-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        }
                </button>
            )
        }
    ];
    
    const reload = () => {

        switch (key) {
            case 'all':
                getAgents();
                break;
            case 'fulltime':
                getFullTimeAgents();
                break;
            case 'season':
                getSeasonAgents();
                break;
            case 'contract':
                getContractAgents();
                break;
            case 'stage':
                getStageAgents();
                break;
            default:
                break;
        }
          
    }
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-list">
                                    <button type="button" onClick={ event => reload() }
                                        className="btn waves-effect waves-light btn-danger">
                                            { t('Actualiser') }
                                        </button>
                                        <Link type="button" to="/agent/create"
                                            className="btn waves-effect waves-light btn-primary">
                                            { t('Ajouter un agent') }    
                                        </Link>
                                    </div>
                                    
                                    <br />
                                    <br />
                                    <Tabs  id="uncontrolled-tab-example" 
                                        activeKey={key}
                                        onSelect={(k: any) => {
                                            console.log(k);
                                            setKey(k);
                                            switch (k) {
                                                case 'all':
                                                    getAgents();
                                                    break;
                                                case 'fulltime':
                                                    getFullTimeAgents();
                                                    break;
                                                case 'season':
                                                    getSeasonAgents();
                                                    break;
                                                case 'contract':
                                                    getContractAgents();
                                                    break;
                                                case 'stage':
                                                    getStageAgents();
                                                    break;
                                                default:
                                                    break;
                                            }

                                            

                                        }} >
                                        
                                        {
                                            keys.map(
                                                (k: any) => <Tab style={{ padding: 20 }} 
                                                eventKey={k.key} title={ t(t('agentTabLabel', { agentTabLabelName: k.label } ) ?? '') }>
                                                    <div className="table-responsive">
                                                        <DataTable
                                                            responsive={true}
                                                            className="table table-striped table-bordered"
                                                            columns={columns}
                                                            data={agents}
                                                            progressPending={loading}
                                                            pagination
                                                            conditionalRowStyles={conditionalRowStyles}
                                                        />
                                                    </div>
                                                </Tab>
                                            )
                                        }

                                    </Tabs>
                                    
                                    </div>
                                </div> 
                        </div> 
                    </div>

                </div>   
            </>                   

    );
}

export default AgentList