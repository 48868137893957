export default class Spent {

    public id?: number | null;
    public idDep?: string | null;
    public idBoutique?: string | null;
    public idAgent?: number | null;
    public montantDep?: string | null ;
    public dateDep?: Date | null;
    public descriptionDep?: string | null;


    static fromJson(parsedJson: Record<string, any>): Spent {
        return new Spent(
            Number(parsedJson['id']),
            parsedJson['idDep'],
            parsedJson['idBoutique'],
            parsedJson['idAgent'],
            parsedJson['dateDep'],
            parsedJson['montantDep'], 
            parsedJson['descriptionDep']
        );
    }

    static toJson(sal: Spent): Record<string, any>  {
        return  {
            "id": sal.id,
            "idDep": sal.idDep,
            "idBoutique": sal.idBoutique,
            "idAgent": sal.idAgent,
            "montantDep": sal.montantDep,
            "dateDep": sal.dateDep,
            "descriptionDep": sal.descriptionDep
        };
    }


    constructor(id: number, idSal: string, idBoutique: string, idAgent: number,
        dateDep: Date, montantDep: string, descriptionDep: string) {
        this.id = id;
        this.idDep = idSal;
        this.idBoutique = idBoutique;
        this.idAgent = idAgent;
        this.montantDep = montantDep;
        this.dateDep = dateDep;
        this.descriptionDep = descriptionDep;
    }
}