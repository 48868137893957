import { FC, useEffect, useState, useRef } from 'react'
import {
    Link, useNavigate 
} from "react-router-dom";

import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 

import ReductionService from './service';
import User from '../../Models/User';
import ShopService from '../Shop/service';

import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
import { RootState } from '../../Redux/store';

//Alert
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Alert from '../../Components/Alert';
import { useTranslation } from 'react-i18next';

interface ReductionProps {
    lidRed?: string;
    libReduction: string; 
    barcode: string;
    idShop: string;
    idAgent?: number  ;
    pourcentageReduction: string;
    dateDebut: Date | null;
    dateFin: Date | null;
    boutique?: any | null;
    agent?: any | null
}

interface ReductionFormProps {
    state: string;
    reduction?: ReductionProps | null; 
}




const ReductionForm: FC<ReductionFormProps> = ( {  state, reduction } ) => {
   

    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const user = useAppSelector((state: RootState ) => state.users.user );

    let [image, setImage] = useState('');

    let initialProduct: any  = null;
    let [product, setProduct ] = useState(initialProduct);

    let [typePromo, setTypePromo] = useState('');

    let [loading, setLoading] = useState(false);

    let [barcodeLoading, setBarcodeLoading] = useState(false);

    let [ shopsLoading, setShopsLoading] = useState(false);

    const [ shops, setShops ] = useState( [] );

    let [reductionTypes] = useState([
        {
            label: 'Pourcentage',
            value: 'percentage'
        },
        {
            label: 'Montant fixe',
            value: 'fixedAmount'
        }
      ]);

    let [sendingMethods] = useState([
        {
            label: 'Imprimer',
            value: 'print'
        },
        {
            label: 'E-mail',
            value: 'email'
        }
      ]);

    const reductionService = new ReductionService();
    const shopService = new ShopService();
    
    const readThis = (inputValue: any): void => {
        var file: File = inputValue.files[0];
        var lecteur: FileReader = new FileReader();
    
        lecteur.onloadend = (e) => {
            setImage( lecteur.result as string );
            // if (Object.prototype.toString.call(lecteur.result) === "[object String]") {
                
            // }
            
            console.log(lecteur.result);
        }
        lecteur.readAsDataURL(file);
    }

    const changeListener = (event: any)  => {
        readThis(event.target);
    }

    const getShops = () => {
        setShopsLoading(true);
        shopService.getShops().then(async function (response: any) {
            console.log(response);
            setShops(response.data);
            setShopsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setShopsLoading(false); 
        });
    }

    const formRef = useRef<FormikProps< ReductionProps >>(null);

    useEffect(() => {

        getShops();

        if (reduction != null  && reduction != undefined) {
            // setImage(client.profil ?? '');
        }

    }, []);

 


    return (
        <>
        <Formik
        
        initialValues={ 
        {
            barcode: ''
                
        }}
        validationSchema={
            yup.object().shape({
                barcode: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`)
            })
        } 
        onSubmit={async (
            values 
          ) => {  

            setBarcodeLoading(true);

            reductionService.getProductByBarcode( values.barcode  ).then(async function (response: any) {
                console.log(response);

                if (response.data !== 'failure' && response.data.length) { 
                    setProduct(response.data[0]);
                    // this.form.controls['barcode'].setValue(this.formBarcode.value.barcode);
                    if (formRef.current !== null) {
                        formRef.current.values.barcode = values.barcode;
                    }
                    
                    
                  } else {

                    setProduct(null);

                    confirmAlert({
                        overlayClassName: "alert-overlay",
                        customUI: ({ onClose }) => {
                          return (
                                <Alert 
                                    alert={{
                                        type: 'error',
                                        title: 'Erreur',
                                        message: 'Aucun produit correspondant.',
                                        actionText: 'Ok',
                                        action: () => {
                                            onClose();
                                        }
                                    }}
                                />
                          );
                        }
                    }); 
                  }
                
                setBarcodeLoading(false); 
            })
              .catch(function (error: any) {
                console.log(error);
                alert("Une erreur s'est produite réesseyez s'il vous plaît.")
                setBarcodeLoading(false); 
            });

          }}
        >
                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                        <Form>
                                    <div className="form-body"> 

                                        <div className="row">
                                            <div className="col-md-8 offset-md-2">
                                                <div className="form-group">
                                                        <label>Barcode</label>
                                                        <input 
                                                            className={`form-control ${ errors.barcode && touched.barcode
                                                                 ? "is-invalid":""}`}
                                                            id="barcode"
                                                            type="text"
                                                            name="barcode"
                                                            onChange={handleChange('barcode')}
                                                            onBlur={handleBlur('barcode')}
                                                            value={values.barcode == null ? '' : values.barcode}
                                                            placeholder="Barcode" />
                                                        { errors.barcode && touched.barcode && errors.barcode && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.barcode && touched.barcode && errors.barcode }
                                                        </div> }
                                                </div>
                                            </div>
                                        </div>

                                    </div>            
                                    <div className="form-actions">
                                        <div className="text-right">
                                            <button  disabled={!dirty && !isValid } type="submit" className="btn btn-danger">
                                                Valider
                                                {
                                                    barcodeLoading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </button> 
                                        </div>
                                    </div>
                        </Form>
                    )}
        </Formik>
        <hr />
        { product !== null && <div className="text-center">
            <h4>
                <b>Produit trouvé</b>
            </h4>
            <ul style={{listStyle: 'none' }}>
                <li>
                    <strong>Produit:</strong> {product.libProduit}
                </li>
                <li>
                    <strong>Quantité en stock:</strong> {product.qteStock}
                </li>
                <li>
                    <strong>Prix unitaire:</strong> {product.prixProd}
                </li>
            </ul>
        
        <hr />

        </div> }
        <Formik
        
        initialValues={
            reduction != null  && reduction != undefined ? 
            {
                ...reduction,
                idAgent: user?.id , 
                idShop: reduction?.boutique?.id.toString(),
            }:
            {

                lidRed: '',
                libReduction: '',
                idShop: '',
                idAgent: user?.id , 
                pourcentageReduction: '',
                dateDebut: null,
                dateFin: null, 
                barcode: ''
                
        }}
        validationSchema={
            yup.object().shape({
                libReduction: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                barcode: yup
                    .string(),
                idShop: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                idAgent: yup
                    .number()
                    .required(`${t('Ce champ est obligatoire')}`),
                pourcentageReduction: yup
                    .number()
                    .min(0, 'Minimum is 0')
                    .max(100, "Maximum is 100"),
                dateDebut: yup
                    .date()
                    .required(`${t('Ce champ est obligatoire')}`),
                dateFin: yup
                    .date()
                    .required(`${t('Ce champ est obligatoire')}`)
            })
        }
        innerRef={formRef}
        onSubmit={async (
            values 
          ) => {
                    console.log(values);
                    setLoading(true);

                    // if (image != null && image != '' ) {
                    //     values.photoProfil = image;
                    // }

                    if (state == 'create') {
                        reductionService.postReduction( values ).then(async function (response: any) {
                            console.log(response);

                            confirmAlert({
                                overlayClassName: "alert-overlay",
                                customUI: ({ onClose }) => {
                                  return (
                                        <Alert 
                                            alert={{
                                                title: 'Succès',
                                                message: 'Reduction ajoutée avec succès',
                                                actionText: 'Ok',
                                                action: () => {
                                                    onClose();
                                                }
                                            }}
                                        />
                                  );
                                }
                            });

                            navigate('/reduction/list');
                            setLoading(false); 
                        })
                          .catch(function (error: any) {
                            console.log(error);
                            alert("Une erreur s'est produite réesseyez s'il vous plaît.")
                            setLoading(false); 
                        });
                    } else if (state == 'edit') {
                        reductionService.putReduction( values ).then(async function (response: any) {
                            console.log(response);

                            confirmAlert({
                                overlayClassName: "alert-overlay",
                                customUI: ({ onClose }) => {
                                  return (
                                        <Alert 
                                            alert={{
                                                title: 'Succès',
                                                message: 'Reduction mis à jour avec succès',
                                                actionText: 'Ok',
                                                action: () => {
                                                    onClose();
                                                }
                                            }}
                                        />
                                  );
                                }
                            });
  
                            navigate('/client/list');
                            setLoading(false); 
                        })
                          .catch(function (error: any) {
                            console.log(error);
                            alert("Une erreur s'est produite réesseyez s'il vous plaît.")
                            setLoading(false); 
                        });
                    }
                    
                }}
                >
                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                        <Form>
                                    <div className="form-body"> 

                                        <div className="row">
                                            <div className="col-md-8 offset-md-2">
                                                    <div className="form-group">
                                                                        <label>
                                                                            { t('Boutique') }
                                                                        </label>
                                                                        {
                                                                            shopsLoading ? 
                                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                                            <span className="sr-only">Loading...</span>
                                                                            </div> :
                                                                            <select  
                                                                                className={`form-control custom-select ${ errors.idShop &&
                                                                                    touched.idShop ? "is-invalid" : "" }`}
                                                                                id="idShop" 
                                                                                name="idShop"
                                                                                onChange={event => { 
                                                                                    handleChange('idShop')(event); 
                                                                                }}
                                                                                onBlur={handleBlur('idShop')}
                                                                                value={values.idShop == null ? '' : values.idShop}
                                                                                placeholder={ t('Boutique') ?? ''} >
                                                                                <option selected>{ t('Boutique') }..</option>
                                                                                {
                                                                                    shops.map(
                                                                                        (shop: any, id) => <option 
                                                                                        key={id} value={shop.id}>
                                                                                            {
                                                                                                shop.libBoutique
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                }
                                                                            </select> 
                                                                        }
                                                    </div>
                      
                                                    <div className="form-group">
                                                        <label>
                                                            { t('Libellé réduction') }
                                                        </label>
                                                        <input 
                                                            className={`form-control ${ errors.libReduction && touched.libReduction ? "is-invalid":""}`}
                                                            id="libReduction"
                                                            type="text"
                                                            name="libReduction"
                                                            onChange={handleChange('libReduction')}
                                                            onBlur={handleBlur('libReduction')}
                                                            value={values.libReduction}
                                                            placeholder={ t('Libellé réduction') ?? ''} />
                                                        { errors.libReduction && touched.libReduction && errors.libReduction && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.libReduction && touched.libReduction && errors.libReduction }
                                                        </div> }
                                                    </div>


                                                    <div className="form-group">
                                                        <label>
                                                            { t('Pourcentage réduction') }
                                                        </label>
                                                        <input  disabled={ typePromo === 'fixedAmount' }
                                                            className={`form-control ${ errors.pourcentageReduction && touched.pourcentageReduction
                                                                 ? "is-invalid":""}`}
                                                            id="pourcentageReduction"
                                                            type="number" max={100}
                                                            name="pourcentageReduction"
                                                            onChange={handleChange('pourcentageReduction')}
                                                            onBlur={handleBlur('pourcentageReduction')}
                                                            value={values.pourcentageReduction}
                                                            placeholder="Pourcentage promo" />
                                                        { errors.pourcentageReduction && touched.pourcentageReduction && errors.pourcentageReduction && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.pourcentageReduction && touched.pourcentageReduction && errors.pourcentageReduction }
                                                        </div> }
                                                    </div>


                                                    <div className="form-group">
                                                        <label>
                                                            { t('Date de début') }
                                                        </label>
                                                        <Field type="date"
                                                                                className={`form-control ${ errors.dateDebut
                                                                                     && touched.dateDebut ? "is-invalid":""}`}
                                                                                id="dateDebut" 
                                                                                name="dateDebut"
                                                                                onChange={handleChange('dateDebut')}
                                                                                onBlur={handleBlur('dateDebut')}
                                                                                value={values.dateDebut == null ? new Date()  :
                                                                                     values.dateDebut }
                                                                                placeholder={ t('Date de début') ?? '' } />
                                                                            { errors.dateDebut && touched.dateDebut &&
                                                                             errors.dateDebut && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                { errors.dateDebut && touched.dateDebut &&
                                                                                 errors.dateDebut }
                                                                        </div> }
                                                    </div>


                                                    <div className="form-group">
                                                        <label>
                                                            { t('Date de fin') }
                                                        </label>
                                                        <Field type="date"
                                                                                className={`form-control ${ errors.dateFin
                                                                                     && touched.dateFin ? "is-invalid":""}`}
                                                                                id="dateFin" 
                                                                                name="dateFin"
                                                                                onChange={handleChange('dateFin')}
                                                                                onBlur={handleBlur('dateFin')}
                                                                                value={values.dateFin == null ? new Date()  :
                                                                                     values.dateFin }
                                                                                placeholder={ t('Date de fin') ?? '' } />
                                                                            { errors.dateFin && touched.dateFin &&
                                                                             errors.dateFin && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                { errors.dateFin && touched.dateFin &&
                                                                                 errors.dateFin }
                                                                        </div> }
                                                    </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="form-actions">
                                        <div className="text-right">
                                            <button  disabled={!dirty && !isValid } type="submit" className="btn btn-info">
                                                { t('Valider') }
                                                {
                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                            <button type="reset" className="btn btn-dark">
                                                { t('Annuler') }
                                            </button>
                                        </div>
                                    </div>
                        </Form>
                    )}
                </Formik>
                </>
    );
}

export default ReductionForm