import Shop from '../../Models/Shop';
import axiosInstance from '../../Utils/axios_manager';

export default class ShopService {
  
    constructor( ) {
    }

    public postShop =  (shop: Shop) => { 
        return axiosInstance.post('/boutique/add', Shop.toJson(shop));
    }

    public getShops =  async () => {
        return await axiosInstance.get('/boutique/list');
    }

    public getShop =  async (id: Number) => {
        return await axiosInstance.get('/boutique/details' + `?id=${id.toString()}`);
    }

    public putShop =  (shop: Shop) => {
        return axiosInstance.post('/boutique/update', Shop.toJson(shop));
    }

    public getCountries =  async () => {
        return await axiosInstance.get('/countries/list');
    }

    public getFournisseurs =  async () => {
        return await axiosInstance.get('/fornisseur/list');
    }
    
    public uploadShopFile =  (file: any) => {
        const body = {'fileContent': file}; 

        return axiosInstance.post('/uploads/shopfiles', body);
    }

    public uploadAdminFiles = (file: any, libelle: string = "edr", gallID: string = '0') => {
        // const body = new FormData();
        // body.append('filecontent', file);
        // body.append('title', title);
        // body.append('gallID', gallID);

        const body = {
            "filecontent": file,
            "title": libelle,
            "galID": gallID
        }

        return axiosInstance.post('/galerie/addcontent', body);
    }

}