import { FC, useEffect, useState } from 'react';
import Breadcrumb from '../../Components/Breadcrumb';

//Redux 
import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
 
import { RootState } from '../../Redux/store';
import DashboardService from './service';

// the hook
import { useTranslation, withTranslation } from 'react-i18next';
import i18next from 'i18next';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar, Line } from 'react-chartjs-2';
  import { faker } from '@faker-js/faker';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  );
  

interface DashboardMonthSell { 
    dates?: any;
    totals?: any; 
}

interface DashboardData { 
    nbreVente?: any;
    nbreProduit?: any; 
    nbreTotalProduit?: any;
    ventesTotal?: any;
    capitalTotal?: any;
    interetTotal?: any;
}
 
const Dashboard: FC = () => {  

    const { t, i18n } = useTranslation();


    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: `${t('Vente du mois')}`,
          },
        },
    };

    const dashboardService = new DashboardService();
    const user = useAppSelector((state: RootState) => state.users.user );

    const localStorage = window.localStorage;

    const [loading, setLoading] = useState(false);
    

    let dashboardDatainitial: DashboardData = { 
        nbreVente: null,
        nbreProduit: null, 
        nbreTotalProduit: null,
        ventesTotal: null,
        capitalTotal: null,
        interetTotal: null  }

    const dashboardMonthSellsInit = {
        dates: Array(),
        totals: Array()
    };

    const [ dashboardMonthSells, setDashboardMonthSells ] = useState(dashboardMonthSellsInit);

    const [ dashboardData, setDashboardData ] = useState(dashboardDatainitial);

    const [ dates, setDates ] = useState([]);

    const [ totals, setTotals ] = useState([]);

    let labels: Array<String> = [ ];
 
    let init_data: Array<String> = [ ];  
    let data = {
        labels,
        datasets: [
          {
            label: `${t('Ventes par jour')}`,
            data: init_data,
            backgroundColor: 'rgba(210, 212, 98, 1)',
          }, 
        ],
    };

    const [debutTravail, setDebutTravail] = useState(false);
    const [debutPause, setDebutPause] = useState(false);
    const [finPause, setFinPause] = useState(false);
    const [finTravail, setfinTravail ] = useState(false);

    const workState = () => {
        setDebutTravail(localStorage.getItem('debutTravail') == null ? false : true );
        setDebutPause(localStorage.getItem('debutPause')  == null ? false : true );
        setFinPause(localStorage.getItem('finPause')  == null ? false : true);
        setfinTravail(localStorage.getItem('finTravail') == null ? false : true);
    }

    const onDebutTravail = async () => {

        setDebutTravail( false );
        setDebutPause( false );
        setFinPause( false );
        setfinTravail( false );

        await dashboardService.setDebutTravail({
          "idAgent": user?.id ,
          idEvenement: 1
        }).then(
          (response: any) => {
            localStorage.setItem('debutTravail', 'Ok');
             workState();
          }
        );
    }
  
    const onDebutPause = async () => {

        setDebutTravail( false );
        setDebutPause( false );
        setFinPause( false );
        setfinTravail( false );

        await dashboardService.setDebutPause({
            "idAgent": user?.id ,
            idEvenement: 3
          }).then(
            (response: any) => {
              localStorage.setItem('debutPause', 'Ok');
               workState();
            }
          );
    }
  
    const onFinPause = async () => {

        setDebutTravail( false );
        setDebutPause( false );
        setFinPause( false );
        setfinTravail( false );

        await dashboardService.setFinPause({
        idAgent: user?.id,
        idEvenement: 4
      }).then(
        (res:any) => {
          localStorage.setItem('finPause', 'Ok');
          workState();
        }
      );
    }
  
    const onFinTravail = async () => {

        setDebutTravail( false );
        setDebutPause( false );
        setFinPause( false );
        setfinTravail( false );
        
        await dashboardService.setFinTravail({
            idAgent: user?.id,
            idEvenement: 2
        }).then(
            (res: any) => {
                localStorage.setItem('finTravail', 'Ok');
                workState();
            }
        );
    }

    const getDashboardInfo = () => {
            setLoading(true);
            dashboardService.getDashboardInfo().then(async function (response: any) {
                console.log(response);
                setDashboardData(response.data);
                setLoading(false); 
            })
              .catch(function (error: any) {
                console.log(error);
                setLoading(false); 
             });
    }

    const getDashboardMonthSells = () => {
        setLoading(true);
            dashboardService.getDashboardMonthSells().then(function (response: any) { 
                setLoading(true);
                setDashboardMonthSells({
                    totals: [],
                    dates: []
                }); 
                response.data.forEach((element: any) => {
                    let dates = dashboardMonthSells.dates;
                    dates.push(element.date);
                    let totals = dashboardMonthSells.totals;
                    totals.push(element.total ? element.total : faker.datatype.number({ min: 0, max: 1000 }));
                    setDashboardMonthSells({
                        totals: totals,
                        dates: dates
                    }); 
                    
                });
                console.log(dashboardMonthSells);

                labels = dashboardMonthSells.dates;
                data.datasets[0].data = dashboardMonthSells.totals;
                setLoading(false); 
            })
              .catch(function (error: any) {
                console.log(error);
                setLoading(false); 
             });
    }
    
    useEffect(() => {

        workState();

        getDashboardInfo();

        getDashboardMonthSells();

    }, []);

    return (
        <> 
            <Breadcrumb />
            {/* Work buttons */}
            
            <div className="container-fluid"> 
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">
                            {
                                t('Gestion horaire')
                            }
                        </h4>
                        <div className="btn-list">
                            <button type="button" disabled={debutTravail}
                                onClick={event => onDebutTravail()}
                                className="btn waves-effect waves-light btn-outline-success">
                                    {
                                        t('Début travail')
                                    }
                            </button>
                            <button type="button" disabled={!debutPause && !debutTravail}
                                onClick={event => onDebutPause()}
                                className="btn waves-effect waves-light btn-outline-info">
                                    {
                                        t('Début pause')
                                    }
                                </button>
                            <button type="button" disabled={!finPause && !debutPause}
                                onClick={event => onFinPause()}
                                className="btn waves-effect waves-light btn-outline-warning">
                                    {
                                        t('Fin pause')
                                    }
                                </button>
                            <button type="button" disabled={!finTravail && !finPause}
                                onClick={event => onFinTravail()}
                                className="btn waves-effect waves-light btn-outline-danger">
                                    {
                                        t('Fin Travail')
                                    }
                            </button>  
                        </div>
                    </div>
                </div>        

                                 
                <div className="card-group">
                    <div className="card border-right">
                        <div className="card-body">
                            <div className="d-flex d-lg-flex d-md-block align-items-center">
                                <div>
                                    {}
                                    <div className="d-inline-flex align-items-center">
                                        <h2 className="text-dark mb-1 font-weight-medium">
                                            {dashboardData.nbreVente}
                                        </h2>
                                        {/* <span
                                            className="badge bg-primary font-12 text-white font-weight-medium
                                             badge-pill ml-2 d-lg-block d-md-none">+18.33%</span> */}
                                    </div>
                                    <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                                        {
                                            t('Nombre de ventes')
                                        }
                                    </h6>
                                </div>
                                <div className="ml-auto mt-md-3 mt-lg-0">
                                    <span className="opacity-7 text-muted fs-20" style={{ fontSize: "2em" }} >
                                        <i className="icon-basket text-success"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card border-right">
                        <div className="card-body">
                            <div className="d-flex d-lg-flex d-md-block align-items-center">
                                <div>
                                    <h2 className="text-dark mb-1 w-100 text-truncate font-weight-medium">
                                        {dashboardData.nbreProduit}/{dashboardData.nbreTotalProduit}
                                    </h2>
                                    <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                                        {
                                            t('Nombre de produits')
                                        }
                                    </h6>
                                </div>
                                <div className="ml-auto mt-md-3 mt-lg-0">
                                    <span className="opacity-7 text-muted fs-20" style={{ fontSize: "2em" }} >
                                        <i className="icon-list text-danger"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card border-right">
                        <div className="card-body">
                            <div className="d-flex d-lg-flex d-md-block align-items-center">
                                <div>
                                    <div className="d-inline-flex align-items-center">
                                        <h2 className="text-dark mb-1 font-weight-medium">
                                            {dashboardData.ventesTotal}
                                        </h2>
                                        {/* <span
                                            className="badge bg-danger font-12 text-white font-weight-medium 
                                            badge-pill ml-2 d-md-none d-lg-block">-18.33%</span> */}
                                    </div>
                                    <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                                        {
                                            t('Ventes totale')
                                        }
                                    </h6>
                                </div>
                                <div className="ml-auto mt-md-3 mt-lg-0">
                                    <span className="opacity-7 text-muted fs-20" style={{ fontSize: "2em" }} >
                                        <i className="icon-wallet"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card border-right">
                        <div className="card-body">
                            <div className="d-flex d-lg-flex d-md-block align-items-center">
                                <div>
                                    <div className="d-inline-flex align-items-center">
                                        <h2 className="text-dark mb-1 font-weight-medium">
                                            {dashboardData.capitalTotal}
                                        </h2>
                                        {/* <span
                                            className="badge bg-danger font-12 text-white font-weight-medium badge-pill 
                                            ml-2 d-md-none d-lg-block">-18.33%</span> */}
                                    </div>
                                    <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                                        {
                                            t('Capital total')
                                        }
                                    </h6>
                                </div>
                                <div className="ml-auto mt-md-3 mt-lg-0">
                                    <span className="opacity-7 text-muted fs-20" style={{ fontSize: "2em" }} >
                                        <i className="icon-wallet text-info"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex d-lg-flex d-md-block align-items-center">
                                <div>
                                    <h2 className="text-dark mb-1 font-weight-medium">
                                        {dashboardData.interetTotal}
                                    </h2>
                                    <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                                        {
                                            t('Interêt total')
                                        }
                                    </h6>
                                </div>
                                <div className="ml-auto mt-md-3 mt-lg-0">
                                <span className="opacity-7 text-muted fs-20" style={{ fontSize: "2em" }} >
                                        <i className="icon-graph text-warning"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="row">
                    {/* <div className="col-lg-4 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Total Sales</h4>
                                <div id="campaign-v2" className="mt-2" style={{ height:"283px", width:"100%" }}></div>
                                <ul className="list-style-none mb-0">
                                    <li>
                                        <i className="fas fa-circle text-primary font-10 mr-2"></i>
                                        <span className="text-muted">Direct Sales</span>
                                        <span className="text-dark float-right font-weight-medium">$2346</span>
                                    </li>
                                    <li className="mt-3">
                                        <i className="fas fa-circle text-danger font-10 mr-2"></i>
                                        <span className="text-muted">Referral Sales</span>
                                        <span className="text-dark float-right font-weight-medium">$2108</span>
                                    </li>
                                    <li className="mt-3">
                                        <i className="fas fa-circle text-cyan font-10 mr-2"></i>
                                        <span className="text-muted">Affiliate Sales</span>
                                        <span className="text-dark float-right font-weight-medium">$1204</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">{
                                    t('Graphiques des ventes du mois')
                                }</h4>

                                {
                                    loading ?
                                    <div className="net-income mt-4 position-relative" style={{ height: "294px" }} ></div> :
                                    <Bar options={options} data={data} />
                                }
                                
                                <ul className="list-inline text-center mt-5 mb-2">
                                    <li className="list-inline-item text-muted font-italic">
                                        {
                                            t('Ventes du mois')
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title mb-4">Earning by Location</h4>
                                <div className="" style={{ height: "180px" }}>
                                    <div id="visitbylocate" style={{ height: "100%" }}></div>
                                </div>
                                <div className="row mb-3 align-items-center mt-1 mt-5">
                                    <div className="col-4 text-right">
                                        <span className="text-muted font-14">India</span>
                                    </div>
                                    <div className="col-5">
                                        <div className="progress" style={{height: "5px" }}>
                                            <div className="progress-bar bg-primary" role="progressbar" style={{ width: "100%" }}
                                                aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                                        </div>
                                    </div>
                                    <div className="col-3 text-right">
                                        <span className="mb-0 font-14 text-dark font-weight-medium">28%</span>
                                    </div>
                                </div>
                                <div className="row mb-3 align-items-center">
                                    <div className="col-4 text-right">
                                        <span className="text-muted font-14">UK</span>
                                    </div>
                                    <div className="col-5">
                                        <div className="progress" style={{ height: "5px" }}>
                                            <div className="progress-bar bg-danger" role="progressbar" style={{ width: "74%" }}
                                                aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                                        </div>
                                    </div>
                                    <div className="col-3 text-right">
                                        <span className="mb-0 font-14 text-dark font-weight-medium">21%</span>
                                    </div>
                                </div>
                                <div className="row mb-3 align-items-center">
                                    <div className="col-4 text-right">
                                        <span className="text-muted font-14">USA</span>
                                    </div>
                                    <div className="col-5">
                                        <div className="progress" style={{ height: "5px" }}>
                                            <div className="progress-bar bg-cyan" role="progressbar" style={{ width: "60%" }}
                                                aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                                        </div>
                                    </div>
                                    <div className="col-3 text-right">
                                        <span className="mb-0 font-14 text-dark font-weight-medium">18%</span>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-4 text-right">
                                        <span className="text-muted font-14">China</span>
                                    </div>
                                    <div className="col-5">
                                        <div className="progress" style={{ height: "5px" }}>
                                            <div className="progress-bar bg-success" role="progressbar" style={{ width: "50%" }}
                                                aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                                        </div>
                                    </div>
                                    <div className="col-3 text-right">
                                        <span className="mb-0 font-14 text-dark font-weight-medium">12%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div> 
                <div className="row">
                    {/* <div className="col-md-12 col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-start">
                                    <h4 className="card-title mb-0">Earning Statistics</h4>
                                    <div className="ml-auto">
                                        <div className="dropdown sub-dropdown">
                                            <button className="btn btn-link text-muted dropdown-toggle" type="button"
                                                id="dd1" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false">
                                                <i data-feather="more-vertical"></i>
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dd1">
                                                <a className="dropdown-item" href="#">Insert</a>
                                                <a className="dropdown-item" href="#">Update</a>
                                                <a className="dropdown-item" href="#">Delete</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pl-4 mb-5">
                                    <div className="stats ct-charts position-relative" style={{ height: "315px" }}></div>
                                </div>
                                <ul className="list-inline text-center mt-4 mb-0">
                                    <li className="list-inline-item text-muted font-italic">Earnings for this month</li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="col-md-6 col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Recent Activity</h4>
                                <div className="mt-4 activity">
                                    <div className="d-flex align-items-start border-left-line pb-3">
                                        <div>
                                            <a href="javascript:void(0)" className="btn btn-info btn-circle mb-2 btn-item">
                                                <i data-feather="shopping-cart"></i>
                                            </a>
                                        </div>
                                        <div className="ml-3 mt-2">
                                            <h5 className="text-dark font-weight-medium mb-2">New Product Sold!</h5>
                                            <p className="font-14 mb-2 text-muted">John Musa just purchased <br /> Cannon 5M
                                                Camera.
                                            </p>
                                            <span className="font-weight-light font-14 text-muted">10 Minutes Ago</span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-start border-left-line pb-3">
                                        <div>
                                            <a href="javascript:void(0)"
                                                className="btn btn-danger btn-circle mb-2 btn-item">
                                                <i data-feather="message-square"></i>
                                            </a>
                                        </div>
                                        <div className="ml-3 mt-2">
                                            <h5 className="text-dark font-weight-medium mb-2">New Support Ticket</h5>
                                            <p className="font-14 mb-2 text-muted">Richardson just create support <br />
                                                ticket</p>
                                            <span className="font-weight-light font-14 text-muted">25 Minutes Ago</span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-start border-left-line">
                                        <div>
                                            <a href="javascript:void(0)" className="btn btn-cyan btn-circle mb-2 btn-item">
                                                <i data-feather="bell"></i>
                                            </a>
                                        </div>
                                        <div className="ml-3 mt-2">
                                            <h5 className="text-dark font-weight-medium mb-2">Notification Pending Order!
                                            </h5>
                                            <p className="font-14 mb-2 text-muted">One Pending order from Ryne <br /> Doe</p>
                                            <span className="font-weight-light font-14 mb-1 d-block text-muted">2 Hours
                                                Ago</span>
                                            <a href="javascript:void(0)" className="font-14 border-bottom pb-1 border-info">Load More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                {/* <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex align-items-center mb-4">
                                    <h4 className="card-title">Top Leaders</h4>
                                    <div className="ml-auto">
                                        <div className="dropdown sub-dropdown">
                                            <button className="btn btn-link text-muted dropdown-toggle" type="button"
                                                id="dd1" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false">
                                                <i data-feather="more-vertical"></i>
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dd1">
                                                <a className="dropdown-item" href="#">Insert</a>
                                                <a className="dropdown-item" href="#">Update</a>
                                                <a className="dropdown-item" href="#">Delete</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table no-wrap v-middle mb-0">
                                        <thead>
                                            <tr className="border-0">
                                                <th className="border-0 font-14 font-weight-medium text-muted">Team Lead
                                                </th>
                                                <th className="border-0 font-14 font-weight-medium text-muted px-2">Project
                                                </th>
                                                <th className="border-0 font-14 font-weight-medium text-muted">Team</th>
                                                <th className="border-0 font-14 font-weight-medium text-muted text-center">
                                                    Status
                                                </th>
                                                <th className="border-0 font-14 font-weight-medium text-muted text-center">
                                                    Weeks
                                                </th>
                                                <th className="border-0 font-14 font-weight-medium text-muted">Budget</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="border-top-0 px-2 py-4">
                                                    <div className="d-flex no-block align-items-center">
                                                        <div className="mr-3"><img
                                                                src="assets/images/users/widget-table-pic1.jpg"
                                                                alt="user" className="rounded-circle" width="45"
                                                                height="45" /></div>
                                                        <div className="">
                                                            <h5 className="text-dark mb-0 font-16 font-weight-medium">Hanna
                                                                Gover</h5>
                                                            <span className="text-muted font-14">hgover@gmail.com</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="border-top-0 text-muted px-2 py-4 font-14">Elite Admin</td>
                                                <td className="border-top-0 px-2 py-4">
                                                    <div className="popover-icon">
                                                        <a className="btn btn-primary rounded-circle btn-circle font-12"
                                                            href="javascript:void(0)">DS</a>
                                                        <a className="btn btn-danger rounded-circle btn-circle font-12 popover-item"
                                                            href="javascript:void(0)">SS</a>
                                                        <a className="btn btn-cyan rounded-circle btn-circle font-12 popover-item"
                                                            href="javascript:void(0)">RP</a>
                                                        <a className="btn btn-success text-white rounded-circle btn-circle font-20"
                                                            href="javascript:void(0)">+</a>
                                                    </div>
                                                </td>
                                                <td className="border-top-0 text-center px-2 py-4"><i
                                                        className="fa fa-circle text-primary font-12" data-toggle="tooltip"
                                                        data-placement="top" title="In Testing"></i></td>
                                                <td
                                                    className="border-top-0 text-center font-weight-medium text-muted px-2 py-4">
                                                    35
                                                </td>
                                                <td className="font-weight-medium text-dark border-top-0 px-2 py-4">$96K
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="px-2 py-4">
                                                    <div className="d-flex no-block align-items-center">
                                                        <div className="mr-3"><img
                                                                src="assets/images/users/widget-table-pic2.jpg"
                                                                alt="user" className="rounded-circle" width="45"
                                                                height="45" /></div>
                                                        <div className="">
                                                            <h5 className="text-dark mb-0 font-16 font-weight-medium">Daniel
                                                                Kristeen
                                                            </h5>
                                                            <span className="text-muted font-14">Kristeen@gmail.com</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-muted px-2 py-4 font-14">Real Homes WP Theme</td>
                                                <td className="px-2 py-4">
                                                    <div className="popover-icon">
                                                        <a className="btn btn-primary rounded-circle btn-circle font-12"
                                                            href="javascript:void(0)">DS</a>
                                                        <a className="btn btn-danger rounded-circle btn-circle font-12 popover-item"
                                                            href="javascript:void(0)">SS</a>
                                                        <a className="btn btn-success text-white rounded-circle btn-circle font-20"
                                                            href="javascript:void(0)">+</a>
                                                    </div>
                                                </td>
                                                <td className="text-center px-2 py-4"><i
                                                        className="fa fa-circle text-success font-12" data-toggle="tooltip"
                                                        data-placement="top" title="Done"></i>
                                                </td>
                                                <td className="text-center text-muted font-weight-medium px-2 py-4">32</td>
                                                <td className="font-weight-medium text-dark px-2 py-4">$85K</td>
                                            </tr>
                                            <tr>
                                                <td className="px-2 py-4">
                                                    <div className="d-flex no-block align-items-center">
                                                        <div className="mr-3"><img
                                                                src="assets/images/users/widget-table-pic3.jpg"
                                                                alt="user" className="rounded-circle" width="45"
                                                                height="45" /></div>
                                                        <div className="">
                                                            <h5 className="text-dark mb-0 font-16 font-weight-medium">Julian
                                                                Josephs
                                                            </h5>
                                                            <span className="text-muted font-14">Josephs@gmail.com</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-muted px-2 py-4 font-14">MedicalPro WP Theme</td>
                                                <td className="px-2 py-4">
                                                    <div className="popover-icon">
                                                        <a className="btn btn-primary rounded-circle btn-circle font-12"
                                                            href="javascript:void(0)">DS</a>
                                                        <a className="btn btn-danger rounded-circle btn-circle font-12 popover-item"
                                                            href="javascript:void(0)">SS</a>
                                                        <a className="btn btn-cyan rounded-circle btn-circle font-12 popover-item"
                                                            href="javascript:void(0)">RP</a>
                                                        <a className="btn btn-success text-white rounded-circle btn-circle font-20"
                                                            href="javascript:void(0)">+</a>
                                                    </div>
                                                </td>
                                                <td className="text-center px-2 py-4"><i
                                                        className="fa fa-circle text-primary font-12" data-toggle="tooltip"
                                                        data-placement="top" title="Done"></i>
                                                </td>
                                                <td className="text-center text-muted font-weight-medium px-2 py-4">29</td>
                                                <td className="font-weight-medium text-dark px-2 py-4">$81K</td>
                                            </tr>
                                            <tr>
                                                <td className="px-2 py-4">
                                                    <div className="d-flex no-block align-items-center">
                                                        <div className="mr-3"><img
                                                                src="assets/images/users/widget-table-pic4.jpg"
                                                                alt="user" className="rounded-circle" width="45"
                                                                height="45" /></div>
                                                        <div className="">
                                                            <h5 className="text-dark mb-0 font-16 font-weight-medium">Jan
                                                                Petrovic
                                                            </h5>
                                                            <span className="text-muted font-14">hgover@gmail.com</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-muted px-2 py-4 font-14">Hosting Press HTML</td>
                                                <td className="px-2 py-4">
                                                    <div className="popover-icon">
                                                        <a className="btn btn-primary rounded-circle btn-circle font-12"
                                                            href="javascript:void(0)">DS</a>
                                                        <a className="btn btn-success text-white font-20 rounded-circle btn-circle"
                                                            href="javascript:void(0)">+</a>
                                                    </div>
                                                </td>
                                                <td className="text-center px-2 py-4"><i
                                                        className="fa fa-circle text-danger font-12" data-toggle="tooltip"
                                                        data-placement="top" title="In Progress"></i></td>
                                                <td className="text-center text-muted font-weight-medium px-2 py-4">23</td>
                                                <td className="font-weight-medium text-dark px-2 py-4">$80K</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  */}
            </div>
        </>
    );
}

export default Dashboard