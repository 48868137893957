import { FC, useEffect, useState } from 'react';
import menu from '../Utils/menu'
import { Link } from "react-router-dom";

//Redux 
import { useAppDispatch, useAppSelector } from '../Hooks/customSelector';
 
import { RootState } from '../Redux/store';
import { setUser } from '../Redux/Reducers/userReducer';
import MessageService from '../Pages/Message/service';
import NotificationService from '../Pages/Notification/service';

//Moment react
import Moment from 'react-moment';

// the hook
import { useTranslation, withTranslation } from 'react-i18next';
import { setLang } from '../Redux/Reducers/langReducer';

const Header: FC<{ activeMenu?: String, activeSubMenu?: string }> = ( {activeMenu, activeSubMenu = ''} ) => { 
        const dispatch = useAppDispatch();
        const user = useAppSelector((state: RootState) => state.users.user );
        const lang = useAppSelector((state: RootState) => state.lang.lang );
        const { t, i18n } = useTranslation();
        const [ messages, setMessages ] = useState( [] );

        const [ notifications, setNotifications ] = useState( [] );

        const messageService = new MessageService();

        const notificationService = new NotificationService();

        const getUserLanguage = () => window.navigator.language;

        const getLastMessageList = () => {
                messageService.getMessages(user?.id ?? 1).then(async function (response: any) {
                    console.log(response);
                    setMessages(response.data);
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }

        const getLastNotificationList = () => {
            notificationService.getNotifications(user?.id ?? 1).then(async function (response: any) {
                console.log(response);
                setNotifications(response.data);
            })
            .catch(function (error: any) {
                console.log(error);
            });
        }

        const  isAgentIsSender = (discussion: any) => {
            return Number(discussion.agent1.id) === Number(user?.id);
        }

        useEffect( function() { 
            console.log('user language');
            console.log(getUserLanguage());
            getLastMessageList();
            getLastNotificationList();
        }, []);
        
        return (  
          <header className="topbar" data-navbarbg="skin6">
          <nav className="navbar top-navbar navbar-expand-md">
              <div className="navbar-header" data-logobg="skin6"> 
                  <a className="nav-toggler waves-effect waves-light d-block d-md-none" href="javascript:void(0)"><i
                          className="ti-menu ti-close"></i></a> 
                  <div className="navbar-brand"> 
                      <Link to="/">
                          <b className="logo-icon"> 
                              <img style={{
                                height: "40px",
                                width: "40px",
                                boxSizing: "border-box",
                                margin: "0 auto"
                              }} src="assets/images/logo.jpg" alt="homepage" className="dark-logo" /> 
                              <img style={{
                                height: "40px",
                                width: "40px",
                                boxSizing: "border-box",
                                margin: "0 auto"
                              }} src="assets/images/logo.jpg" alt="homepage" className="light-logo" />
                          </b> 
                          <span style={{color: "#1c2d41" }} className="logo-text"> 
                            LaPotencielle
                              {/* <img style={ {
                                height: "50px",
                                width: "50px",
                                boxSizing: "border-box",
                                margin: "0 auto"
                              } } src="assets/images/logo.jpg" alt="homepage" className="dark-logo img-responsive" /> 
                              <img style={ {
                                height: "50px",
                                width: "50px",
                                boxSizing: "border-box",
                                margin: "0 auto"
                              } }  src="assets/images/logo.jpg" className="light-logo img-responsive" alt="homepage" /> */}
                          </span>
                      </Link>
                  </div> 
                  <a className="topbartoggler d-block d-md-none waves-effect waves-light" href="javascript:void(0)"
                      data-toggle="collapse" data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><i
                          className="ti-more"></i></a>
              </div> 
              <div className="navbar-collapse collapse" id="navbarSupportedContent"> 
                  <ul className="navbar-nav float-left mr-auto ml-3 pl-1"> 
                      <li className="nav-item dropdown">
                          <a className="nav-link dropdown-toggle pl-md-3 position-relative" href="javascript:void(0)"
                              id="bell" role="button" data-toggle="dropdown" aria-haspopup="true"
                              aria-expanded="false">
                              <span><i data-feather="bell" className="svg-icon"></i></span>
                              <span className={`badge ${ notifications.length > 0 ? 'badge-danger' : 'badge-primary'} notify-no rounded-circle`}>
                                { notifications.length }
                              </span>
                          </a>
                          <div className="dropdown-menu dropdown-menu-left mailbox animated bounceInDown">
                              <ul className="list-style-none">
                                  <li>
                                      <div className="message-center notifications position-relative"> 
                                        {   
                                            notifications.length > 0 ?
                                            notifications.map(
                                                (row: any) => <Link to="/notification"
                                                className="message-item d-flex align-items-center border-bottom px-3 py-2">
                                                {/* <div className="btn btn-danger rounded-circle btn-circle"><i
                                                        data-feather="airplay" className="text-white"></i></div> */}
                                                <div className="w-75 d-inline-block v-middle pl-2">
                                                    <h6 className="message-title mb-0 mt-1">
                                                        {
                                                            isAgentIsSender(row) ? row.agent2.prenomAgent + ' ' + row.agent2.nomAgent
                                                            :  row.agent1.prenomAgent + ' ' + row.agent1.nomAgent 
                                                        }
                                                    </h6>
                                                    <span className="font-12 text-nowrap d-block text-muted">
                                                        {
                                                            row.libDiscussion
                                                        }
                                                    </span>
                                                    <span className="font-12 text-nowrap d-block text-muted">
                                                        <Moment format="YYYY/MM/DD hh:mm:ss">
                                                            {row.dateCreaction}
                                                        </Moment>
                                                    </span>
                                                </div>
                                            </Link> 
                                            ) : <></>
                                        }
                                      </div>
                                  </li>
                                  <li>
                                      <Link className="nav-link pt-3 text-center text-dark"  to="/notification" >
                                          <strong>
                                            { t('Voir les notifications') }
                                          </strong>
                                          <i className="fa fa-angle-right"></i>
                                      </Link>
                                  </li>
                              </ul>
                          </div>
                      </li> 


                      <li className="nav-item dropdown">
                          <a className="nav-link dropdown-toggle pl-md-3 position-relative" href="javascript:void(0)"
                              id="bell" role="button" data-toggle="dropdown" aria-haspopup="true"
                              aria-expanded="false">
                              <span><i data-feather="mail" className="svg-icon"></i></span>
                              <span className={`badge ${ messages.length > 0 ? 'badge-danger' : 'badge-primary'} notify-no rounded-circle`}>
                                { messages.length }
                              </span>
                          </a>
                          <div className="dropdown-menu dropdown-menu-left mailbox animated bounceInDown">
                              <ul className="list-style-none">
                                  <li>
                                      <div className="message-center notifications position-relative"> 
                                        {   
                                            messages.length > 0 ?
                                            messages.map(
                                                (row: any) => <Link to="/message"
                                                className="message-item d-flex align-items-center border-bottom px-3 py-2">
                                                {/* <div className="btn btn-danger rounded-circle btn-circle"><i
                                                        data-feather="airplay" className="text-white"></i></div> */}
                                                <div className="w-75 d-inline-block v-middle pl-2">
                                                    <h6 className="message-title mb-0 mt-1">
                                                        {
                                                            isAgentIsSender(row) ? row.agent2.nomAgent
                                                            :  row.agent1.nomAgent 
                                                        }
                                                    </h6>
                                                    <span className="font-12 text-nowrap d-block text-muted">
                                                        {
                                                            row.libDiscussion
                                                        }
                                                    </span>
                                                    <span className="font-12 text-nowrap d-block text-muted">
                                                        <Moment format="YYYY/MM/DD hh:mm:ss">
                                                            {row.dateCreaction}
                                                        </Moment>
                                                    </span>
                                                </div>
                                            </Link> 
                                            ) : <></>
                                        }
                                           
                                      </div>
                                  </li>
                                  <li>
                                      <Link className="nav-link pt-3 text-center text-dark"  to="/message" >
                                          <strong>
                                            { t('Voir les messages') }
                                          </strong>
                                          <i className="fa fa-angle-right"></i>
                                      </Link>
                                  </li>
                              </ul>
                          </div>
                      </li>


                      {/* <li className="nav-item dropdown">
                          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i data-feather="settings" className="svg-icon"></i>
                          </a>
                          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                              <a className="dropdown-item" href="#">Action</a>
                              <a className="dropdown-item" href="#">Another action</a>
                              <div className="dropdown-divider"></div>
                              <a className="dropdown-item" href="#">Something else here</a>
                          </div>
                      </li> */}
                      <li className="nav-item d-none d-md-block">
                          <a className="nav-link" href="javascript:void(0)">
                              <div className="customize-input">
                                  <select value={lang} onChange={(event) => { 
                                    const lang = event.target.value; 
                                    i18n.changeLanguage(lang).then(
                                        (res) => {
                                            dispatch( setLang(lang)  ); 
                                        }
                                    );
                                  }}
                                    className="custom-select form-control bg-white custom-radius custom-shadow border-0">
                                        <option value="fr" selected> FR</option>
                                        <option value="en" > EN</option> 
                                  </select>
                              </div>
                          </a>
                      </li>
                  </ul> 
                  <ul className="navbar-nav float-right"> 
                      {/* <li className="nav-item d-none d-md-block">
                          <a className="nav-link" href="javascript:void(0)">
                              <form>
                                  <div className="customize-input">
                                      <input className="form-control custom-shadow custom-radius border-0 bg-white"
                                          type="search" placeholder="Search" aria-label="Search" />
                                      <i className="form-control-icon" data-feather="search"></i>
                                  </div>
                              </form>
                          </a>
                      </li>  */}
                      <li className="nav-item dropdown">
                          <a className="nav-link dropdown-toggle" href="javascript:void(0)" data-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="false">
                              <img style={{ width: "30px", height: "30px", borderRadius: "50%" }}  src={user?.profil} alt="user" className="rounded-circle"
                                  width="40" />
                              <span className="ml-2 d-none d-lg-inline-block"><span>Hello,</span> <span
                                      className="text-dark">  { user?.prenomAgent + " " + user?.nomAgent } </span> <i data-feather="chevron-down"
                                      className="svg-icon"></i></span>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right user-dd animated flipInY">
                              <Link className="dropdown-item" to="/notification"><i data-feather="bell"
                                      className="svg-icon mr-2 ml-1"></i>
                                  Notifications</Link>
                              <Link className="dropdown-item" to="/message"><i data-feather="mail"
                                      className="svg-icon mr-2 ml-1"></i>
                                  Message</Link> 
                              <div className="dropdown-divider"></div>
                              <div className="pl-4 p-3"><a onClick={ () => {
                                window.localStorage.removeItem("app_user");
                                dispatch( setUser( null ) );
                                window.location.href = "/auth";
                              }}  className="btn btn-sm btn-info"> 
                                Déconnexion  </a></div>
                          </div>
                      </li> 
                  </ul>
              </div>
          </nav>
      </header>
        )   
}

export default Header