import { FC, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import NotificationService from './service';

import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
import { RootState } from '../../Redux/store';

import {
    Link, useNavigate
} from "react-router-dom";


//Moment react
import Moment from 'react-moment';

const NotificationList: FC = () => {  

    let idToModInit: any = null;

    const user = useAppSelector((state: RootState ) => state.users.user );

    const [ notifications, setNotifications ] = useState( [] );
    const [ idToMod, setIdToMod ] = useState(idToModInit);
    const [ loading, setLoading ] = useState(false);
    const notificationService = new NotificationService();

    const navigate = useNavigate();

    let editAgent = (id: Number): void => {
        setIdToMod(id);
        setLoading(true);
        navigate('/agent/edit/'+id.toString());
        setLoading(false);
    }

    const getNotifications = () => {
        setLoading(true);
        notificationService.getNotifications(user?.id ?? 1).then(async function (response: any) {
            console.log(response);
            setNotifications(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    const  isAgentIsSender = (discussion: any) => {
        return Number(discussion.agent1.id) === Number(user?.id);
      }

    useEffect( function() { 
        getNotifications();
    }, []);

    const columns = [
        {
            name: 'Contenu',
            selector: (row: any) => row.notificationContent,
        }, 
        {
            name: "Date",
            selector: (row: any) => <Moment format="YYYY/MM/DD hh:mm:ss">
                                        {row.dateNotification}
                                    </Moment>
        }
    ];
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-list">
                                    <button type="button" onClick={ event => getNotifications() }
                                        className="btn waves-effect waves-light btn-primary">Actualiser</button> 
                                    </div> 
                                    <br />
                                    <br /> 
                                    <div className="table-responsive">
                                        <DataTable
                                            responsive={true}
                                            className="table table-striped table-bordered"
                                            columns={columns}
                                            data={notifications}
                                            progressPending={loading}
                                            pagination
                                        />
                                        </div>
                                    </div>
                                </div> 
                        </div> 
                    </div>

                </div>   
            </>                   

    );
}

export default NotificationList