import User from '../../Models/User';
import axiosInstance from '../../Utils/axios_manager';

export default class TransportService {
  
    constructor( ) {
    }

    public postTransport =  (data: Record<string, any>) => {
        return axiosInstance.post('/shipingService/add', data);
    }

    public getTransports =  async () => {
        return await axiosInstance.get('/shipingService/list');
    }

    public getTransport =  async (id: Number) => {
        return await axiosInstance.get('/shipingService/details' + `?idSp=${id.toString()}`);
    }

    public putTransport =  (data: Record<string, any>) => {
        return axiosInstance.post('/shipingService/update',data);
    }

    public getProductByBarcode =  async (barcode: string) => {
        return await axiosInstance.get('/produit/list/barcode/filtred/simple' + `?barcode=${barcode}`);
    }

    public getShippingSpentBillsList  =  async (id: Number) => {
        return await axiosInstance.get('/shipingService/bills/list' + `?idSp=${id.toString()}`);
    }
    
    public getShippingEntryBillsList  =  async (id: Number) => {
        return await axiosInstance.get('/shipingService/entries/list' + `?idSp=${id.toString()}`);
    }

    public postShippingBill =  (data: Record<string, any>) => {
        return axiosInstance.post('/shipingService/bills/add', data);
    }

}