import { FC, useRef, useState, useEffect } from 'react'
import {
    Outlet
} from "react-router-dom";
import DataTable from 'react-data-table-component';

//Moment react
import Moment from 'react-moment';

import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 
import ConfigurationService from '../Configuration/service';
import ProductService from '../Product/service';
import { useTranslation } from 'react-i18next';

interface MarketAutoFormProps {
    qteVente: number | null,
    barcode:  string | null 
}

//Props interface
interface MarketManProps {
    onProductAdd: React.MouseEventHandler<HTMLButtonElement>;
    onProductDelete: React.MouseEventHandler<HTMLButtonElement>;
}

const MarketAutoForm: FC<MarketManProps> = ( {  onProductAdd, onProductDelete } ) => {  

    const marketAutoFormRef = useRef< FormikProps< MarketAutoFormProps >>(null);
    const { t, i18n } = useTranslation();
    let [loading, setLoading ] = useState(false);
    let [gammesLoading, setGammesLoading] = useState(false);
    const [
        gammes,
        setGammes
    ] = useState([]);

    let [typesLoading, setTypesLoading] = useState(false);
    const [
        types,
        setTypes
    ] = useState([]);

    let [productsLoading, setProductsLoading] = useState(false);
    const [
        products,
        setProducts
    ] = useState([]);

    const configService = new ConfigurationService();
    const productService = new ProductService();

    const getGammes = () => {
        setGammesLoading(true);
        configService.getGammes().then(async function (response: any) {
            console.log(response); 
            setGammes(response.data);
            setGammesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setGammesLoading(false); 
        });
    }


    const getTypes = () => {
        setTypesLoading(true);
        configService.getTypes().then(async function (response: any) {
            console.log(response); 
            setTypes(response.data);
            setTypesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setTypesLoading(false); 
        });
    }

    const getProducts = () => {
        setProductsLoading(true);
        productService.getFilteredSimpleProduct({nomProd: null, idType: 0, idGamme: 0}).then(async function (response: any) {
            console.log(response); 
            setProducts(response.data);
            setProductsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setProductsLoading(false); 
        });
    }



    useEffect( function() {  
        getGammes();
        getTypes();
        getProducts();
    }, []);



    const columns = [
        {
            name: `${t('Nom du produit')}`,
            selector: (row: any) => row.libProduit,
        },
        {
            name: `${t('Prix unitaire')}`,
            selector: (row: any) => row.prixProd
        },
        {
            name: `${t('Stock')}`,
            selector: (row: any) => row.qteStock
        },
        // {
        //     name: 'Date',
        //     selector: (row: any) => <Moment format="YYYY/MM/DD hh:mm:ss">
        //                                 {row.dateInventaire}
        //                             </Moment>
        // },
        {
            name: "Options", 
            selector: (row: any) => (
                <>
                    <button type="button" onClick={event => {  }}
                        className="btn waves-effect waves-light btn-danger"> 
                        {
                            t('Ajouter')
                        }
                    </button> 
                </>
            )
        }
    ];


    return (
        <Formik
        initialValues={
            { 
                qteVente: 1,
                barcode: null
        }}
        validationSchema={
            yup.object().shape({ 
                qteVente: yup
                    .number() 
                    .required(`${t('Ce champ est obligatoire')}`),
                barcode: yup
                    .string() 
                    .required(`${t('Ce champ est obligatoire')}`)
            })
        }
        innerRef={marketAutoFormRef} 
        onSubmit={async (
            values 
        ) => {  }}  
    >
    {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
        <Form>
            <div className="form-group">
                <label>
                    { t('Quantité') }
                </label>
                <input type="number"
                                                        className={`form-control 
                                                        ${ errors.qteVente  && touched.qteVente ? "is-invalid":""}`}
                                                        id="qteVente" 
                                                        name="qteVente"  
                                                        onChange={handleChange('qteVente')}
                                                        onBlur={handleBlur('qteVente')}
                                                        value={  values.qteVente == null ? '' : values.qteVente }
                                                        placeholder="Nom du produit" />
                                                        { errors.qteVente && touched.qteVente && errors.qteVente && 
                                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                                { errors.qteVente && touched.qteVente && errors.qteVente }
                                                            </div> 
                                                        }
            </div>

            <div className="form-group">
                        <label>Barcode</label>
                        <input type="text"
                                                        className={`form-control 
                                                        ${ errors.barcode  && touched.barcode ? "is-invalid":""}`}
                                                        id="barcode" 
                                                        name="barcode"  
                                                        onChange={handleChange('barcode')}
                                                        onBlur={handleBlur('barcode')}
                                                        value={  values.barcode == null ? '' : values.barcode }
                                                        placeholder="Barcode" />
                                                        { errors.barcode && touched.barcode && errors.barcode && 
                                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                                { errors.barcode && touched.barcode && errors.barcode }
                                                            </div> 
                                                        }
            </div>

            <div className="form-actions">
                            <div className="text-right">
                                <button  disabled={!dirty && !isValid } type="submit" className="btn btn-info">
                                    { t('Valider') }
                                    {
                                        loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    }
                                </button>
                                {/* <button type="reset" className="btn btn-dark">Annuler</button> */}
                            </div>
            </div>
          
  
        </Form>
    )}
    </Formik>
    );
}

export default MarketAutoForm