import { FC, useEffect, useState, useRef, useCallback } from 'react'
import {
    Link, useNavigate 
} from "react-router-dom";

import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 

import MessageService from './service';
import User from '../../Models/User';
import ConfigurationService from '../Configuration/service';

import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
import { RootState } from '../../Redux/store';

import { toggleLoadMessages } from '../../Redux/Reducers/chatReducer';
import { useTranslation } from 'react-i18next';

interface MessageFormProps {
    state: string;
    receiver?: any | null; 
}

interface MessageProps {
    idSender?: number;
    idReceiver: string;
    textContent: string;
    fileContent?: string; 
}

const MessageForm: FC<MessageFormProps> = ( {  state, receiver } ) => {
   

    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const initState:any = null;
    const [, updateState] = useState(initState);
    const forceUpdate = useCallback(() => updateState({}), []);


    const user = useAppSelector((state: RootState ) => state.users.user );

    const formRef = useRef<FormikProps< MessageProps >>(null);

    let [image, setImage] = useState('');

    let [loading, setLoading] = useState(false);

    const initFileArray: Array<any> = [];

    let [fileArray, setFileArray] = useState(initFileArray);

    const [ agentsLoading, setAgentsLoading ] = useState(false);

    const [
        agents,
        setAgents
    ] = useState([]);

    const messageService = new MessageService();

    const configService = new ConfigurationService();
    
    const readThis = (inputValue: any): void => {
        var file: File = inputValue.files[0];
        var lecteur: FileReader = new FileReader();
    
        lecteur.onloadend = (e) => {
            // setImage( lecteur.result as string );
            // if (formRef.current !== null) {
            //     formRef.current.values.fileContent = ( lecteur.result as string );
            // }
            // console.log(lecteur.result);
        }
        lecteur.readAsDataURL(file);

        let files = fileArray ;
        files.push(file);
        setFileArray(files);

        console.log(fileArray);

        forceUpdate();

    }

    const changeListener = (event: any)  => {
        readThis(event.target);
    }

    const getAgents = () => {
        setAgentsLoading(true);
        configService.getAgents().then(async function (response: any) {
            console.log(response); 
            setAgents(response.data.filter(
                (agent:any) => {
                  return agent.id !== user?.id;
                }
              ));
            setAgentsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setAgentsLoading(false); 
        });
    }

    const onDeleteFile = (ingredientIndex : number) => {  
        let files = fileArray.filter((file, index) => {
            return index !== ingredientIndex;
        });  
        console.log(files);
        setFileArray(files);  
        forceUpdate();
    }


    const uploadFiles = async () => {
        return await new Promise(
          (resolve: any, reject) => {
            if (fileArray.length > 0) {
                fileArray.map(
                    (file, id) => {
                    
                        messageService.uploadFile(file).then(
                            (res: any) => {
                                console.log(res);
                                if (formRef.current !== null) {
                                    let fileArray2: any =  formRef.current.values.fileContent ?  formRef.current.values.fileContent : [];
                                    fileArray2.push(res?.data?.mediaName);
                                    formRef.current.values.fileContent = fileArray2;
                                    if(id === (fileArray.length - 1)) {
                                        resolve();
                                    }
                                } 
                                
                            }, (error: any) => {
                            reject();
                            }
                        );
                    }
              );
            } else {
              resolve();
            }  
          }
        )
      }

    

    useEffect( () => {

        if (receiver != null  && receiver != undefined) {
            
            if (formRef.current != null) {
                formRef.current.values.idReceiver = receiver;
            }

        }

        getAgents();

    }, []);
    
    

    return (
        <Formik
        innerRef={formRef}
        initialValues={ 
            {
                idSender: user?.id,
                idReceiver: '',
                textContent: '',
                fileContent: ''
        }}
        validationSchema={
            yup.object().shape({ 
                idReceiver: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                textContent: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                fileContent: yup
                    .string() 
            })
        }
        onSubmit={async (
            values 
          ) => {
                    console.log(values);
                    setLoading(true);

                    // if (image != null && image != '' ) {
                    //     values.photoProfil = image;
                    // }

                    
                        await uploadFiles().then(
                            () => {

                                console.log("Upload done!");
                                console.log(values.fileContent);

                                if (state == 'create') {

                                    messageService.createDiscussion({
                                        idAgent1: values.idSender,
                                        idAgent2: values.idReceiver
                                    }).then(async function (response: any) {


                                        messageService.mailSend(values).then(async function (response: any) {
                                                // console.log(response);
                                                alert("Message créé avec succès");  
                                                navigate('/message/list');
                                                setLoading(false); 
                                            })
                                            .catch(function (error: any) {
                                                console.log(error);
                                                alert("Une erreur s'est produite réesseyez s'il vous plaît.")
                                                setLoading(false); 
                                            });
                                            
                                    }).catch(function (error: any) {
                                            console.log(error);
                                            alert("Une erreur s'est produite réesseyez s'il vous plaît.")
                                            setLoading(false); 
                                    });

                                } else if (state == 'edit') {

                                    messageService.mailSend(values).then(async function (response: any) {
                                        // console.log(response);
                                        // alert("Message mis à jour avec succès");  
                                        // navigate('/message/list');
                                        if (formRef.current !== null) {
                                            formRef.current.values.textContent = '';
                                            formRef.current.values.fileContent = '';
                                        }
                                        dispatch(toggleLoadMessages(null));
                                        setLoading(false); 
                                        window.scrollTo(0, 0);
                                    })
                                    .catch(function (error: any) {
                                        console.log(error);
                                        alert("Une erreur s'est produite réesseyez s'il vous plaît.")
                                        setLoading(false); 
                                    });

                                }

                            }
                        ); 
                    
                    
                }}
                >
                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                        <Form>
                                    <div className="form-body">
                                        {
                                            receiver != null && receiver != undefined ? 
                                            <></> : 
                                            <div className="form-group">
                                                <label>
                                                    {
                                                        t('Agent')
                                                    }
                                                </label>
                                                        {
                                                            agentsLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <select  
                                                                className={`form-control custom-select ${ errors.idReceiver &&
                                                                    touched.idReceiver ? "is-invalid" : "" }`}
                                                                id="agent" 
                                                                name="agent"
                                                                onChange={event => {
                                                                    // console.log(event);
                                                                    handleChange('idReceiver')(event);
                                                                    // const agent = getAgentById(event.target.value);
                                                                    // console.log(agent);
                                                                    // setSelectedAgent( agent );
                                                                }}
                                                                onBlur={handleBlur('idReceiver')}
                                                                value={values.idReceiver}
                                                                placeholder="Agent" >
                                                                <option selected>{t('Agent')}..</option>
                                                                {
                                                                    agents.map(
                                                                        (agent: any, id) => <option 
                                                                         key={id} value={agent.id}>
                                                                            {
                                                                                agent.prenomAgent + ' ' + agent.nomAgent
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </select> 
                                                        }
                                            </div>

                                        }


                                        <div className="form-group">
                                                    <label>
                                                        {
                                                            t('Message')
                                                        }
                                                    </label>
                                                    <Field as="textarea" rows={3}
                                                        className={`form-control ${ errors.textContent &&
                                                             touched.textContent ? "is-invalid" : "" }`}
                                                        id="textContent"
                                                        type="text"
                                                        name="textContent"
                                                        onChange={handleChange('textContent')}
                                                        onBlur={handleBlur('textContent')}
                                                        value={values.textContent}
                                                        placeholder={`${t('Message')}`} />
                                                    { errors.textContent && touched.textContent && errors.textContent && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.textContent && touched.textContent && errors.textContent }
                                                    </div> }
                                        </div>

                                       
                                        <div className='form-group'>
                                                <label>{t('Attaché des fichiers')}</label>
                                                            <div className="custom-file">
                                                                <input type="file" 
                                                                className={`form-control custom-file-input `} 
                                                                    id="photoProfil" 
                                                                    onChange={changeListener} 
                                                                    placeholder="Photo" />
                                                                <label className="custom-file-label" 
                                                                htmlFor="photoProfil">Choose file</label>
                                                            </div>
                                        </div>

                                        {  fileArray.length > 0 ? 
                                            <ul className="list-group mt-2">
                                                {
                                                    fileArray.map(
                                                        (file, index) =>
                                                            <li key={index} className="list-group-item">
                                                                {file?.name}
                                                                <span onClick={event => onDeleteFile(index)}  style={{ float: 'right', cursor: 'pointer' }}
                                                                    className="badge badge-danger ml-auto">
                                                                    <span className="" style={{ fontSize: "1em" }} >
                                                                    x</span>
                                                                </span>
                                                            </li>
                                                    )
                                                }
                                            </ul>
                                            : null
                                        }           
 

                                    </div>
                                    <br />
                                    <div className="form-actions">
                                        <div className="text-right">
                                            <button  disabled={!dirty && !isValid } type="submit" className="btn btn-info">
                                                { t('Envoyer') }
                                                {
                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                            <button type="reset" className="btn btn-dark">
                                                { t('Annuler') }
                                            </button>
                                        </div>
                                    </div>
                        </Form>
                    )}
                </Formik>
    );
}

export default MessageForm