import { FC, useEffect, useState } from 'react'
import {
    Link, useNavigate 
} from "react-router-dom";

import { Formik, Field, Form } from 'formik'
import * as yup from 'yup'; 

import ClientService from './service';
import User from '../../Models/User';
import ShopService from '../Shop/service';
import { useTranslation } from 'react-i18next';

interface ClientFormProps {
    state: string;
    client?: User | null; 
}

const ClientForm: FC<ClientFormProps> = ( {  state, client } ) => {
   

    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    let [image, setImage] = useState('');

    let [loading, setLoading] = useState(false);

    let [ countriesLoading, setCountriesLoading ] = useState(false);
    let [ countries, setCountries ] = useState([]);

    const shopService = new ShopService();

    const clientService = new ClientService();
    
    const readThis = (inputValue: any): void => {
        var file: File = inputValue.files[0];
        var lecteur: FileReader = new FileReader();
    
        lecteur.onloadend = (e) => {
            setImage( lecteur.result as string );
            // if (Object.prototype.toString.call(lecteur.result) === "[object String]") {
                
            // }
            
            console.log(lecteur.result);
        }
        lecteur.readAsDataURL(file);
    }

    const changeListener = (event: any)  => {
        readThis(event.target);
    }

    const getCountries = () => {
        setCountriesLoading(true);
        shopService.getCountries().then(async function (response: any) {
            console.log(response);
            setCountries(response.data);
            setCountriesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setCountriesLoading(false); 
        });
    }

    const validateConfirmPassword = (pass: any, value: any) => {

        let error = "";
        if (pass && value) {
          if (pass !== value) {
            error = `${t("Les mots de passe ne correspondent pas")}` ;
          }
        }
        return error;
    }


    useEffect( () => {

        getCountries();

        if (client != null  && client != undefined) {
            // setImage(client.profil ?? '');
        }

    }, []);
    
    

    return (
        <Formik
        initialValues={
            client != null  && client != undefined ? 
            {
                ...client
            }:
            {
            // pseudoClient: '',
            // passwordClient: '',
            nomClient: '',
            prenomClient: '',
            email: '',
            username: '',
            password: '',
            passwordConfirm: '',
            '2faActivated': false,
            newsletter: '',
            contactClient: '',
            paysClient: '',
            adresseClient: '',
            town: '',
            street: '',
            bp: '',
            billAddress: ''
            // sexeClient: '',
            // photoProfil: ''
        }}
        validationSchema={
            yup.object().shape({
                nomClient: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                prenomClient: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                email: yup
                    .string()
                    .email('Mail non valide')
                    .required(`${t('Ce champ est obligatoire')}`),
                username: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                password: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`)
                    .matches(
                        /^(?=.*[!@#\$%\^&\*])(?=.{12,})/,
                        `${t("Le mot de passe doit comporter 12 caractères avec l'utilisation d'un caractère spécial")}`
                    ),
                passwordConfirm: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                '2faActivated': yup
                    .boolean(),
                contactClient: yup
                    .string(),
                paysClient: yup
                    .string(),
                newsletter: yup
                    .boolean(),
                town: yup
                    .string(),
                street: yup
                    .string(),
                bp: yup
                    .string(),
                billAddress: yup
                    .string()
            })
        }
        onSubmit={async (
            values 
          ) => {
                    console.log(values);
                    setLoading(true);

                    // if (image != null && image != '' ) {
                    //     values.photoProfil = image;
                    // }

                    values.billAddress = values.paysClient + ',' + values.town + ',' + 
                    values.street + ',' + values.bp;

                    if (state == 'create') {
                        clientService.postClient( values ).then(async function (response: any) {
                            console.log(response);
                            alert( `${t("Client créé avec succès")}` );  
                            navigate('/client/list');
                            setLoading(false); 
                        })
                          .catch(function (error: any) {
                            console.log(error);
                            alert(`${t("Une erreur s'est produite réesseyez s'il vous plaît")}`)
                            setLoading(false); 
                        });
                    } else if (state == 'edit') {
                        clientService.putClient( values ).then(async function (response: any) {
                            console.log(response);
                            alert( `${t("Client mise à jour avec succès")}`);  
                            navigate('/client/list');
                            setLoading(false); 
                        })
                          .catch(function (error: any) {
                            console.log(error);
                            alert(`${t("Une erreur s'est produite réesseyez s'il vous plaît")}`)
                            setLoading(false); 
                        });
                    }
                    
                }}
                >
                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                        <Form>
                                    <div className="form-body row">

                                        <div className="form-group col-md-6">
                                                    <label>
                                                        { t('Nom utilisateur') }
                                                        <span style={{ color: 'red' }}> *</span>
                                                    </label>
                                                    <input 
                                                        className={`form-control ${ errors.username && touched.username ? "is-invalid":""}`}
                                                        id="username"
                                                        type="text"
                                                        name="username"
                                                        onChange={handleChange('username')}
                                                        onBlur={handleBlur('username')}
                                                        value={values.username}
                                                        placeholder={ t('Nom utilisateur') ?? '' } />
                                                    { errors.username && touched.username && errors.username && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.username && touched.username && errors.username }
                                                    </div> }
                                        </div>
                                         
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>
                                                        { t('Prénoms') }
                                                        <span style={{ color: 'red' }}> *</span>
                                                    </label>
                                                    <input 
                                                        className={`form-control ${ errors.prenomClient && touched.prenomClient ? "is-invalid":""}`}
                                                        id="prenomClient"
                                                        type="text"
                                                        name="prenomClient"
                                                        onChange={handleChange('prenomClient')}
                                                        onBlur={handleBlur('prenomClient')}
                                                        value={values.prenomClient}
                                                        placeholder={ t('Prénoms') ?? ''} />
                                                    { errors.prenomClient && touched.prenomClient && errors.prenomClient && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.prenomClient && touched.prenomClient && errors.prenomClient }
                                                    </div> }
                                                </div>
                                            </div> 
                                         
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>
                                                        { t('Nom') }
                                                        <span style={{ color: 'red' }}> *</span>
                                                    </label>
                                                    <input 
                                                        className={`form-control ${ errors.nomClient && touched.nomClient ? "is-invalid":""}`}
                                                        id="nomClient"
                                                        type="text"
                                                        name="nomClient"
                                                        onChange={handleChange('nomClient')}
                                                        onBlur={handleBlur('nomClient')}
                                                        value={values.nomClient}
                                                        placeholder={ t('Nom') ?? '' } />
                                                    { errors.nomClient && touched.nomClient && errors.nomClient && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.nomClient && touched.nomClient && errors.nomClient }
                                                    </div> }
                                                </div>
                                            </div> 

                                        
                                        

                                        

                                        <div className="form-group col-md-6">
                                                    <label>
                                                        { t('Mot de passe') }
                                                        <span style={{ color: 'red' }}> *</span>
                                                    </label>
                                                    <input 
                                                        className={`form-control ${ errors.password && touched.password ? "is-invalid":""}`}
                                                        id="password"
                                                        type="password"
                                                        name="password"
                                                        onChange={handleChange('password')}
                                                        onBlur={handleBlur('password')}
                                                        value={values.password}
                                                        placeholder={ t('Mot de passe') ?? ''} />
                                                    { errors.password && touched.password && errors.password && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.password && touched.password && errors.password }
                                                    </div> }
                                        </div>

                                        <div className="form-group col-md-6">
                                                    <label>
                                                        { t('Mot de passe confirmation') }
                                                        <span style={{ color: 'red' }}> *</span>
                                                    </label>
                                                    <Field 
                                                        validate={(value: any) =>
                                                            validateConfirmPassword(values.password, value)
                                                        }
                                                        className={`form-control ${ 
                                                            errors.passwordConfirm && touched.passwordConfirm ? "is-invalid":""}`}
                                                        id="passwordConfirm"
                                                        type="password"
                                                        name="passwordConfirm"
                                                        onChange={handleChange('passwordConfirm')}
                                                        onBlur={handleBlur('passwordConfirm')}
                                                        value={values.passwordConfirm}
                                                        placeholder={ t('Mot de passe confirmation') ?? '' } />
                                                    { errors.passwordConfirm && touched.passwordConfirm && errors.passwordConfirm && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.passwordConfirm && touched.passwordConfirm && errors.passwordConfirm }
                                                    </div> }
                                        </div>


                                        <div className="form-group col-md-6">
                                                    <label>
                                                        { t('Email') }
                                                        <span style={{ color: 'red' }}> *</span>
                                                    </label>
                                                    <input 
                                                        className={`form-control ${ errors.email && touched.email ? "is-invalid":""}`}
                                                        id="email"
                                                        type="email"
                                                        name="email"
                                                        onChange={handleChange('email')}
                                                        onBlur={handleBlur('email')}
                                                        value={values.email}
                                                        placeholder={ t('Email') ?? ''} />
                                                    { errors.email && touched.email && errors.email && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.email && touched.email && errors.email }
                                                    </div> }
                                        </div>

                                         
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                <label>
                                                    { t('Contact') }
                                                </label>
                                                    <input 
                                                        className={`form-control ${ errors.contactClient && touched.contactClient ? "is-invalid":""}`}
                                                        id="contactClient"
                                                        type="text"
                                                        name="contactClient"
                                                        onChange={handleChange('contactClient')}
                                                        onBlur={handleBlur('contactClient')}
                                                        value={values.contactClient}
                                                        placeholder={ t('Contact') ?? '' } />
                                                    { errors.contactClient && touched.contactClient && errors.contactClient && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.contactClient && touched.contactClient && errors.contactClient }
                                                    </div> }
                                                </div>
                                            </div> 

                                        <div className="form-group col-md-6">
                                                <label> 
                                                    { t('Pays') } </label>
                                                        {
                                                            countriesLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <select  
                                                                className={`form-control custom-select ${ errors.paysClient &&
                                                                    touched.paysClient ? "is-invalid" : "" }`}
                                                                id="paysClient" 
                                                                name="paysClient"
                                                                onChange={event => { 
                                                                    handleChange('paysClient')(event); 
                                                                }}
                                                                onBlur={handleBlur('paysClient')}
                                                                value={values.paysClient}
                                                                placeholder={ t('Pays') ?? '' } >
                                                                <option selected>{ t('Pays') ?? ''}...</option>
                                                                {
                                                                    countries.map(
                                                                        (country: any, id) => <option 
                                                                         key={id} value={country.id}>
                                                                            {
                                                                                country.name
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </select> 
                                                        }
                                        </div>

                                        <div className="form-group col-md-6">
                                                    <label>
                                                        { t('Ville') }
                                                    </label>
                                                    <input 
                                                        className={`form-control ${ errors.town && touched.town ? "is-invalid":""}`}
                                                        id="town"
                                                        type="text"
                                                        name="town"
                                                        onChange={handleChange('town')}
                                                        onBlur={handleBlur('town')}
                                                        value={values.town}
                                                        placeholder={ t('Ville') ?? ''} />
                                                    { errors.town && touched.town && errors.town && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.town && touched.town && errors.town }
                                                    </div> }
                                        </div>
                                        

                                        <div className="form-group col-md-6">
                                                    <label>
                                                        {t('Rue')}
                                                    </label>
                                                    <input 
                                                        className={`form-control ${ errors.street && touched.street ? "is-invalid":""}`}
                                                        id="street"
                                                        type="text"
                                                        name="street"
                                                        onChange={handleChange('street')}
                                                        onBlur={handleBlur('street')}
                                                        value={values.street}
                                                        placeholder={t('Rue') ?? ''} />
                                                    { errors.street && touched.street && errors.street && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.street && touched.street && errors.street }
                                                    </div> }
                                        </div>

                                        <div className="form-group col-md-6">
                                                    <label>
                                                        { t('BP') }
                                                    </label>
                                                    <input 
                                                        className={`form-control ${ errors.bp && touched.bp ? "is-invalid":""}`}
                                                        id="bp"
                                                        type="text"
                                                        name="bp"
                                                        onChange={handleChange('bp')}
                                                        onBlur={handleBlur('bp')}
                                                        value={values.bp}
                                                        placeholder={ t('BP') ?? ''} />
                                                    { errors.bp && touched.bp && errors.bp && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.bp && touched.bp && errors.bp }
                                                    </div> }
                                        </div>
                                        <div className="col-md-6">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='form-group'>
                                                    <label>2 Factors
                                                    <Field type="checkbox"
                                                        className={`form-control 
                                                        ${ errors['2faActivated'] && touched['2faActivated']? "is-invalid":""}`}
                                                        id="2faActivated" 
                                                        name="2faActivated"  
                                                        />
                                                        </label>
                                                        { errors['2faActivated'] && touched['2faActivated'] && errors['2faActivated'] && 
                                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                                { errors['2faActivated'] && touched['2faActivated']&&
                                                                 errors['2faActivated']}
                                                            </div> 
                                                        }
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='form-group'>
                                                    <label>Mails
                                                    <Field type="checkbox"
                                                        className={`form-control 
                                                        ${ errors.newsletter && touched.newsletter ? "is-invalid":""}`}
                                                        id="newsletter" 
                                                        name="newsletter"  
                                                        />
                                                        </label>
                                                        { errors.newsletter && touched.newsletter && errors.newsletter && 
                                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                                { errors.newsletter && touched.newsletter && errors.newsletter }
                                                            </div> 
                                                        }
                                                </div>
                                            </div> 
                                        </div>
                                        </div>

                                        {/* <div className="row">
                                            <div className="col-md-12">
                                                <img src={image} 
                                                className="img-responsive" width="304" height="236" alt="" />
                                            </div>
                                         </div>             */}



                                    </div>
                                    <div className="form-actions">
                                        <div className="text-right">
                                            <button  disabled={!dirty && !isValid } type="submit" className="btn btn-info">
                                                { t('Valider') }
                                                {
                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                            <button type="reset" className="btn btn-dark">
                                                { t('Annuler') }
                                            </button>
                                        </div>
                                    </div>
                        </Form>
                    )}
                </Formik>
    );
}

export default ClientForm