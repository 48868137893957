import { FC, useEffect, useState, useRef } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import TransportService from './service';

import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 

import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
import { RootState } from '../../Redux/store';

import {
    Link, useNavigate
} from "react-router-dom";

//Moment react
import Moment from 'react-moment';

// React - Bootstrap
import { Modal } from 'react-bootstrap';

//Alert
import Alert from '../../Components/Alert'; 
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { useTranslation } from 'react-i18next';

interface BillFormProps {
    idSp: string;
    idAgent?: number;
    montant: string
}

const TransportList: FC = () => {  
    
    let idToModInit: any = null;
    const { t, i18n } = useTranslation();
    const billsFormRef = useRef<FormikProps< BillFormProps >>(null);

    const user = useAppSelector((state: RootState ) => state.users.user );

    const [ transports, setTransports ] = useState( [] );
    const [ idToMod, setIdToMod ] = useState(idToModInit);
    const [ loading, setLoading ] = useState(false);
    const transportService = new TransportService();


    const [ currentTransportBills, setCurrentTransportBills ] = useState([]);
    const [ billsModal, setBillsModal ] = useState(false);
    const [ loadBills, setLoadBills ] = useState(false);

    const [ billsFormModal, setBillsFormModal ] = useState(false);

    const [ billType, setBillType ] = useState('');

    const navigate = useNavigate();

    let editAgent = (id: Number): void => {
        setIdToMod(id);
        setLoading(true);
        navigate('/agent/edit/'+id.toString());
        setLoading(false);
    }

    const getTransports = () => {
        setLoading(true);
        transportService.getTransports().then(async function (response: any) {
            console.log(response);
            setTransports(response.data.services);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    useEffect( function() { 
        getTransports();
    }, []);

    const columns = [ 
        {
            name: t('Libellé'),
            selector: (row: any) => row.libSpService,
        },
        {
            name: t("Balance"),
            selector: (row: any) => row.currentBalance,
        }, 
        {
            name: t("Site web"),
            selector: (row: any) => row.siteSpService,
        }, 
        {
            name: t("Email"),
            selector: (row: any) => row.emailSpService,
        },
        {
            name: "Contact",
            selector: (row: any) => row.contactSpService,
        },
        {
            name: t("Dernière mis à jour"),
            selector: (row: any) => <Moment format="YYYY/MM/DD">
                                        {row.dateLastUpdate}
                                    </Moment>
        },
        {
            name: t("Facture"),
            selector: (row: any) => (
                <>

                    <button type="button" onClick={event => {
                            setIdToMod(row.id);
                            setLoadBills(true);
                            setBillType("Entrées");
                            transportService.getShippingEntryBillsList(Number(row.id)).then(async function (response: any) {
                                console.log(response);
                                setCurrentTransportBills(response.data.entries);
                                setBillsModal(true);
                                setLoadBills(false); 
                            })
                              .catch(function (error: any) {
                                console.log(error);
                                setLoadBills(false); 
                            });
                        }}
                            className="btn waves-effect waves-light btn-warning">
                            { t('Entrée') }
                            {  
                                idToMod == row.id && loadBills &&
                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            }
                    </button>
                    <br />
                    <button type="button" onClick={event => {
                            setIdToMod(row.id);
                            setLoadBills(true);
                            setBillType("Sorties");
                            transportService.getShippingSpentBillsList(Number(row.id)).then(async function (response: any) {
                                console.log(response);
                                setCurrentTransportBills(response.data.payments);
                                setBillsModal(true);
                                setLoadBills(false); 
                            })
                              .catch(function (error: any) {
                                console.log(error);
                                setLoadBills(false); 
                            });
                        }}
                            className="btn waves-effect waves-light btn-success">
                            { t('Sortie') }
                            {  
                                idToMod == row.id && loadBills &&
                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            }
                    </button>
                    <br />
                    <button type="button" onClick={event => { 
                        console.log(row);
                        setIdToMod(row.id);
                        setLoadBills(true);
                         
                         setBillsFormModal(true);

                         setLoadBills(false);
                     }}
                        className="btn waves-effect waves-light btn-danger"> 
                            { t('Ajouter') }
                            {  
                                idToMod == row.id && loadBills &&
                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            }
                    </button> 
                    
                </>
            )
        }, 
        {
            name: "Options", 
            selector: (row: any) => (
                <>
                    <button type="button" onClick={event => {
                            navigate('/transport/edit/'+row.id.toString());
                        }}
                            className="btn waves-effect waves-light btn-success">
                                <span className="" style={{ fontSize: "1em" }} >
                                    <i className="icon-pencil"></i></span>
                    </button>
                    <button type="button" onClick={event => {  }}
                        className="btn waves-effect waves-light btn-danger"> 
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-trash"></i></span>
                            
                    </button> 
                </>
            )
        }
    ];


    const billListColumns  = [ 
        {
            name: t('Montant'),
            selector: (row: any) => row.montantCaisse,
        },
        {
            name: t("Opérateur"),
            selector: (row: any) => row.operator.prenomAgent + ' ' + row.operator.nomAgent,
        },  
        {
            name: "Date",
            selector: (row: any) => <Moment format="YYYY/MM/DD">
                                        {row.dateAction}
                                    </Moment>
        }, 
    ];
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-list">
                                    <button type="button" onClick={ event => getTransports() }
                                        className="btn waves-effect waves-light btn-danger">
                                            { t('Actualiser') }
                                        </button>
                                        <Link type="button" to="/transport/create"
                                            className="btn waves-effect waves-light btn-primary">
                                            { t('Ajouter un service de transport') }
                                        </Link>
                                    </div>
                                    
                                    <br />
                                    <br />
                                    {/* <h4 className="card-title">Zero Configuration</h4>
                                    <h6 className="card-subtitle">DataTables has most features enabled by default, so all you
                                        need to do to use it with your own tables is to call the construction
                                        function:<code> $().DataTable();</code>. You can refer full documentation from here
                                        <a href="https://datatables.net/">Datatables</a></h6> */}
                                    <div className="table-responsive">
                                        <DataTable
                                            responsive={true}
                                            className="table table-striped table-bordered"
                                            columns={columns}
                                            data={transports}
                                            progressPending={loading}
                                            pagination
                                        />
                                        </div>
                                    </div>
                                </div> 
                        </div> 
                    </div>

                </div>   

            {/* Bills list Modal */}

            <Modal 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={ billsModal }
                onHide={() => setBillsModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        { t('Détails factures') } {">"} <b>{ billType }</b>
                    </Modal.Title> 
                </Modal.Header>
                {/* <div style={{ width: "100%", paddingTop: "8px", paddingRight: "8px" }}>
                                    <button style={{float: 'right'}} type="button" onClick={ event => exportToPdf() }
                                        className="btn waves-effect waves-light btn-success">
                                            Imprimer
                                            {
                                                paymentLoading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            }
                                    </button>
                </div>  */}
                <Modal.Body>
                    {
                        !(currentTransportBills.length > 0) ? 
                        <p className="text-center" >
                            <b>
                                { t('Pas de factures') }
                            </b>
                        </p>
                        :

                        <div className="table-responsive">
                                        <DataTable
                                            responsive={true}
                                            className="table table-striped table-bordered"
                                            columns={billListColumns}
                                            data={currentTransportBills}
                                            progressPending={loading}
                                            pagination
                                        />
                        </div>

                    }
                    
                </Modal.Body>
                <Modal.Footer>
                {/* <Button onClick={props.onHide}>Close</Button> */}
                </Modal.Footer>
            </Modal>


             {/* Bill Form Modal */}

             <Modal 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={ billsFormModal }
                onHide={() => setBillsFormModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        { t('Ajouter une facture') }
                    </Modal.Title> 
                </Modal.Header> 
                <Modal.Body>
                     
                <Formik
                    innerRef={billsFormRef}
                    initialValues={ 
                        {
                            idSp: idToMod,
                            idAgent: user?.id,
                            montant: ''
                    }}
                    validationSchema={
                        yup.object().shape({
                            idSp: yup
                                .string()
                                .required(`${t('Ce champ est obligatoire')}`),
                            montant: yup
                                .string()
                                .required(`${t('Ce champ est obligatoire')}`) 
                        })
                    }
                    onSubmit={async (
                        values 
                    ) => {
                                console.log(values);
                                setLoading(true);

                                transportService.postShippingBill(values).then(async function (response: any) {
                                    console.log(response); 

                                    confirmAlert({
                                        overlayClassName: "alert-overlay",
                                        customUI: ({ onClose }) => {
                                          return (
                                                <Alert 
                                                    alert={{
                                                        title: 'Succès',
                                                        message: 'Facture ajoutée avec succès',
                                                        actionText: 'Ok',
                                                        action: () => {
                                                            onClose();
                                                        }
                                                    }}
                                                />
                                          );
                                        }
                                    }); 

                                    setBillsFormModal(false);

                                    setLoading(false); 
                                })
                                  .catch(function (error: any) {
                                    console.log(error);

                                    confirmAlert({
                                        overlayClassName: "alert-overlay",
                                        customUI: ({ onClose }) => {
                                          return (
                                                <Alert 
                                                    alert={{
                                                        type: 'error',
                                                        title: 'Erreur',
                                                        message: 'Une erreur s\'est produite, réesseyez.',
                                                        actionText: 'Ok',
                                                        action: () => {
                                                            onClose();
                                                        }
                                                    }}
                                                />
                                          );
                                        }
                                    }); 

                                    setLoading(false); 
                                });
                                
                            }}
                            >
                            {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                    <Form>
                                                <div className="form-body">
                                                    
                                                    
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>
                                                                    { t('Montant') }
                                                                </label>
                                                                <input 
                                                                    className={`form-control ${ errors.montant &&
                                                                         touched.montant ? "is-invalid":""}`}
                                                                    id="montant"
                                                                    type="number"
                                                                    name="montant"
                                                                    onChange={handleChange('montant')}
                                                                    onBlur={handleBlur('montant')}
                                                                    value={values.montant}
                                                                    placeholder={ t('Montant') ?? ''} />
                                                                { errors.montant && touched.montant && errors.montant && 
                                                                <div id="validationServer05Feedback" className="invalid-feedback">
                                                                    { errors.montant && touched.montant && errors.montant }
                                                                </div> }
                                                            </div>
                                                        </div>
                                                    </div>
 
                                                </div>
                                                <div className="form-actions">
                                                    <div className="text-right">
                                                        <button  disabled={!dirty && !isValid } type="submit" className="btn btn-info">
                                                           { t(' Valider') }
                                                            {
                                                                loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>
                                                            }
                                                        </button>
                                                        <button type="reset" className="btn btn-dark">
                                                            { t('Annuler') }
                                                        </button>
                                                    </div>
                                                </div>
                                    </Form>
                                )}
                </Formik>
                    
                </Modal.Body>
                <Modal.Footer>
                {/* <Button onClick={props.onHide}>Close</Button> */}
                </Modal.Footer>
            </Modal>

            </>                   

    );
}

export default TransportList