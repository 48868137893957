import { FC, useEffect, useState, useRef } from 'react'
import {
    Link, useNavigate 
} from "react-router-dom";

import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 

import ClientService from './service';
import User from '../../Models/User';
import TemplateService from './service';

import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
import { RootState } from '../../Redux/store';

//Alert
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Alert from '../../Components/Alert';
import { useTranslation } from 'react-i18next';


interface TemplateProps {
    idTemp?: string;
    libelle: string; 
    fichier: string;
    description: string;
    idAgent?: number;
}

interface ClientFormProps {
    state: string;
    template?: TemplateProps | null; 
}

const TemplateForm: FC<ClientFormProps> = ( {  state, template } ) => {
   

    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const formRef = useRef<FormikProps< TemplateProps >>(null);

    const user = useAppSelector((state: RootState ) => state.users.user );

    let [image, setImage] = useState('');

    let [loading, setLoading] = useState(false);

    const templateService = new  TemplateService();
    
    const readThis = (inputValue: any): void => {
        var file: File = inputValue.files[0];
        var lecteur: FileReader = new FileReader();
    
        lecteur.onloadend = (e) => {
            setImage( lecteur.result as string );
            console.log(lecteur.result);
        }
        lecteur.readAsDataURL(file);
    }

    const changeListener = (event: any)  => {
        readThis(event.target);
    }

    useEffect( () => {

        if (formRef.current !== null) {
            formRef.current.values.fichier = image;
            console.log(formRef.current.values);
        }
        

    }, [ image ]);
    
    

    return (
        <Formik
        innerRef={formRef}
        initialValues={
            template != null  && template != undefined ? 
            {
                ...template,
                idAgent: user?.id
            }:
            { 
                idTemp: '',
                libelle: '',
                fichier: '',
                description: '',
                idAgent: user?.id
        }}
        validationSchema={
            yup.object().shape({
                libelle: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                fichier: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                description: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`)
            })
        }
        onSubmit={async (
            values 
          ) => {
                    console.log(values);
                    setLoading(true); 

                    if (state == 'create') {
                        templateService.postTemplate( values ).then(async function (response: any) {
                            console.log(response);
                            confirmAlert({
                                overlayClassName: "alert-overlay",
                                customUI: ({ onClose }) => {
                                  return (
                                        <Alert 
                                            alert={{
                                                title: 'Succès',
                                                message: 'Template ajouté avec succès',
                                                actionText: 'Ok',
                                                action: () => {
                                                    onClose();
                                                }
                                            }}
                                        />
                                  );
                                }
                            }); 
                            navigate('/template/list');
                            setLoading(false); 
                        })
                          .catch(function (error: any) {
                            console.log(error);
                            confirmAlert({
                                overlayClassName: "alert-overlay",
                                customUI: ({ onClose }) => {
                                  return (
                                        <Alert 
                                            alert={{
                                                type: 'error',
                                                title: 'Error',
                                                message: 'Une erreur s\'est produite réesseyez s\'il vous plaît.',
                                                actionText: 'Ok',
                                                action: () => {
                                                    onClose();
                                                }
                                            }}
                                        />
                                  );
                                }
                            });  
                            setLoading(false); 
                        });
                    } else if (state == 'edit') {
                        templateService.putTemplate( values ).then(async function (response: any) {
                            console.log(response);
                            confirmAlert({
                                overlayClassName: "alert-overlay",
                                customUI: ({ onClose }) => {
                                  return (
                                        <Alert 
                                            alert={{
                                                title: 'Succès',
                                                message: 'Template mis à jour avec succès',
                                                actionText: 'Ok',
                                                action: () => {
                                                    onClose();
                                                }
                                            }}
                                        />
                                  );
                                }
                            }); 
                            navigate('/template/list');
                            setLoading(false); 
                        })
                          .catch(function (error: any) {
                            console.log(error);
                            confirmAlert({
                                overlayClassName: "alert-overlay",
                                customUI: ({ onClose }) => {
                                  return (
                                        <Alert 
                                            alert={{
                                                type: 'error',
                                                title: 'Error',
                                                message: 'Une erreur s\'est produite réesseyez s\'il vous plaît.',
                                                actionText: 'Ok',
                                                action: () => {
                                                    onClose();
                                                }
                                            }}
                                        />
                                  );
                                }
                            });  
                            setLoading(false); 
                        });
                    }
                    
                }}
                >
                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                        <Form>
                                    <div className="form-body">
                                         
                                        
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>
                                                        { t('Libellé') }
                                                    </label>
                                                    <input 
                                                        className={`form-control ${ errors.libelle && touched.libelle ? "is-invalid":""}`}
                                                        id="libelle"
                                                        type="text"
                                                        name="libelle"
                                                        onChange={handleChange('libelle')}
                                                        onBlur={handleBlur('libelle')}
                                                        value={values.libelle}
                                                        placeholder={ t('Libellé') ?? ''} />
                                                    { errors.libelle && touched.libelle && errors.libelle && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.libelle && touched.libelle && errors.libelle }
                                                    </div> }
                                                </div>
                                            </div>
                                        </div>

                                        
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>
                                                        { t('Description') }
                                                    </label>
                                                    <Field as='textarea' rows={5} 
                                                        className={`form-control ${ errors.description && touched.description ? "is-invalid":""}`}
                                                        id="description" 
                                                        name="description"
                                                        onChange={handleChange('description')}
                                                        onBlur={handleBlur('description')}
                                                        value={values.description}
                                                        placeholder={ t('Description') ?? '' } />
                                                    { errors.description && touched.description && errors.description && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.description && touched.description && errors.description }
                                                    </div> }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>
                                                    { t('Fichier template') }
                                                </label>
                                                <div className='form-group'>
                                                    {/* <div className="input-group-prepend">
                                                                        <span className="input-group-text">Upload</span>
                                                                    </div> */}
                                                                    <div className="custom-file">
                                                                        <input type="file" accept="image/*, .html, .pdf"
                                                                        className={`form-control custom-file-input `} 
                                                                            id="photoProfil" 
                                                                            onChange={changeListener} 
                                                                            placeholder="Photo" />
                                                                        <label className="custom-file-label" 
                                                                        htmlFor="photoProfil">
                                                                            { t('Choisir un fichier') }
                                                                        </label>
                                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='col-md-6'>
                                                <img src={image} 
                                                            className="img-responsive" width="304" height="236" alt="" />
                                            </div> */}
                                        </div>   
 



                                    </div>
                                    <div className="form-actions">
                                        <div className="text-right">
                                            <button  disabled={!dirty && !isValid } type="submit" className="btn btn-info">
                                                { t('Valider') }
                                                {
                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                            <button type="reset" className="btn btn-dark">
                                                { t('Annuler') }
                                            </button>
                                        </div>
                                    </div>
                        </Form>
                    )}
                </Formik>
    );
}

export default TemplateForm