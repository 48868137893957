import Ressource from '../../Models/Ressource';
import axiosInstance from '../../Utils/axios_manager';

export default class ScheduleService {
  
    constructor() {  }

    public getSchedules =  async () => {
        return await axiosInstance.get('/work/time/list' );
    } 
}