import { FC, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import PromotionService from './service';

import {
    Link, useNavigate
} from "react-router-dom";

//Moment react
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';

const PromotionList: FC = () => {  
    let idToModInit: any = null;

    const { t, i18n } = useTranslation();
    const [ promotions, setPromotions ] = useState( [] );

    const [ idToMod, setIdToMod ] = useState(idToModInit);
    const [ loading, setLoading ] = useState(false);
    const promotionService = new PromotionService();

    const navigate = useNavigate();

    let editAgent = (id: Number): void => {
        setIdToMod(id);
        setLoading(true);
        navigate('/agent/edit/'+id.toString());
        setLoading(false);
    }

    const getPromotions = () => {
        setLoading(true);
        promotionService.getPromotions().then(async function (response: any) {
            console.log(response);
            setPromotions(response.data.promos);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    useEffect( function() { 
        getPromotions();
    }, []);

    const columns = [
        {
            name: t('Boutique'),
            selector: (row: any) => row.boutique.libBoutique,
        },
        {
            name: t('Libellé promo'),
            selector: (row: any) => row.libPromo,
        },
        {
            name: t("Type"),
            selector: (row: any) => row.type,
        },
        {
            name: t("Montant début promo"),
            selector: (row: any) => row.montantDebPromo,
        },
        {
            name: t("Pourcentage"),
            selector: (row: any) => row.pourcentagePromo,
        },
        {
            name: t("Montant fixe"),
            selector: (row: any) => row.fixedAmount,
        },
        {
            name: "Agent",
            selector: (row: any) => row.agent.prenomAgent + ' ' + row.agent.nomAgent,
        },
        {
            name: t("Date de début"),
            selector: (row: any) => <Moment format="YYYY/MM/DD">
                                        {row.dateDebut}
                                    </Moment>
        },
        {
            name: t("Date de fin"),
            selector: (row: any) => <Moment format="YYYY/MM/DD">
                                        {row.dateFin}
                                    </Moment>
        },
        {
            name: t("Date de création"),
            selector: (row: any) => <Moment format="YYYY/MM/DD">
                                        {row.dateCreation}
                                    </Moment>
        },
        {
            name: "Options", 
            selector: (row: any) => (
                <>
                    <button type="button" onClick={event => {
                            navigate('/promotion/edit/'+row.id.toString());
                        }}
                            className="btn waves-effect waves-light btn-success">
                                <span className="" style={{ fontSize: "1em" }} >
                                    <i className="icon-pencil"></i></span>
                    </button>
                    <button type="button" onClick={event => {  }}
                        className="btn waves-effect waves-light btn-danger"> 
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-trash"></i></span>
                            
                    </button> 
                </>
            )
        }
    ];
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-list">
                                    <button type="button" onClick={ event => getPromotions() }
                                        className="btn waves-effect waves-light btn-danger">
                                            { t('Actualiser') }
                                        </button>
                                        <Link type="button" to="/promotion/create"
                                            className="btn waves-effect waves-light btn-primary">
                                            { t('Ajouter une promotion') }    
                                        </Link>
                                    </div>
                                    
                                    <br />
                                    <br />
                                    {/* <h4 className="card-title">Zero Configuration</h4>
                                    <h6 className="card-subtitle">DataTables has most features enabled by default, so all you
                                        need to do to use it with your own tables is to call the construction
                                        function:<code> $().DataTable();</code>. You can refer full documentation from here
                                        <a href="https://datatables.net/">Datatables</a></h6> */}
                                    <div className="table-responsive">
                                        <DataTable
                                            responsive={true}
                                            className="table table-striped table-bordered"
                                            columns={columns}
                                            data={promotions}
                                            progressPending={loading}
                                            pagination
                                        />
                                        </div>
                                    </div>
                                </div> 
                        </div> 
                    </div>

                </div>   
            </>                   

    );
}

export default PromotionList