import uuid from 'react-uuid';
import { FC, useEffect, useState, useRef } from 'react'
import {
    Link, useNavigate 
} from "react-router-dom";

import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup';
import { string } from 'yup/lib/locale';
import ShopService from './service';
import Shop from '../../Models/Shop';
import { useTranslation } from 'react-i18next';

interface ShopFormProps {
    state: string;
    shop?: Shop | null; 
}

const ShopForm: FC<ShopFormProps> = ( {  state, shop } ) => {
   

    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    let [image, setImage] = useState('');

    let galeryIdInit: any = null;
    let [galId, setGalId] = useState(galeryIdInit);

    let [loading, setLoading] = useState(false);

    let [countriesLoading, setCountriesLoading] = useState(false);
    let [ countries, setCountries ] = useState([]);

    let [fournisseursLoading, setFournisseursLoading] = useState(false);
    let [ fournisseurs, setFournisseurs ] = useState([]);

    let [ adminFiles, setAdminFiles ] = useState(Array<any>);

    let [adminFileLabel, setAdminFileLabel] = useState('');
    let [adminFile, setAdminFile] = useState('');

    const formRef = useRef< FormikProps< Shop >>(null);

    // let shopInitial = new Shop(0)

    //const formRef = useRef< FormikProps< Shop >>(null) ;

    const shopService = new ShopService();
    
    const readThis = (inputValue: any): void => {
        var file: File = inputValue.files[0];
        var lecteur: FileReader = new FileReader();
    
        lecteur.onloadend = (e) => {
            // setImage( lecteur.result as string );
            // if (Object.prototype.toString.call(lecteur.result) === "[object String]") {
                
            // }

            shopService.uploadShopFile( lecteur.result as string ).then(
                (res: any) => {

                    if (formRef.current !== null) {
                        formRef.current.values.contratBail = res.data?.uploadedFN;
                    }

                }, (error: any) => {
                
                }
            );


            
            console.log(lecteur.result);
        }
        lecteur.readAsDataURL(file);
    }

    const changeListener = (event: any)  => {
        readThis(event.target);
    }

    const readAdminThis = (inputValue: any): void => {
        var file: File = inputValue.files[0];
        var lecteur: FileReader = new FileReader();
    
        lecteur.onloadend = (e) => {

            if (formRef.current !== null) {
               setAdminFile(lecteur.result as string);
            }
            
            console.log(lecteur.result);
        }
        lecteur.readAsDataURL(file);
    }

    const changeAdminFileListener = (event: any)  => {
        readAdminThis(event.target);
    }

    const getCountries = () => {
        setCountriesLoading(true);
        shopService.getCountries().then(async function (response: any) {
            console.log(response);
            setCountries(response.data);
            setCountriesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setCountriesLoading(false); 
        });
    }
    
    const getFournisseurs = () => {
        setFournisseursLoading(true);
        shopService.getFournisseurs().then(async function (response: any) {
            console.log(response);
            setFournisseurs(response.data.fournisseurs);
            setFournisseursLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setFournisseursLoading(false); 
        });
    }

    useEffect( () => {

        if (shop != null  && shop != undefined) {
            // setImage(shop.profil ?? '');
        }

        getCountries();
        getFournisseurs();

    }, []);

    const sendAdminFile = (event: any) => {
        console.log("Add file");
        if (adminFile && adminFileLabel) {

            console.log(adminFiles);
            let files = Array.isArray(adminFiles) ? adminFiles : [];
            files.push({
                'label':  adminFileLabel,
                'file':  adminFile
            });
            setAdminFiles(files);

            setAdminFile('');
            setAdminFileLabel('');

        }  else {
            alert(`${t("Vous devez remplir le champ label et choisir un fichier")}`);
        }
        // if (formRef.current !== null) {
        //     if (formRef.current.values.adminFileLabel !== null 
        //         && formRef.current.values.adminFileLabel !== '' && formRef.current.values.adminFile !== null 
        //         && formRef.current.values.adminFile !== '') {
       
        //         formRef.current.values.adminFileLabel = '';
        //         formRef.current.values.adminFile = '';
        //     } else {
        //         alert("Remplir le champ label et choisir un fichier");
        //     }
        // }   
    }


    const isBase64 = (file: string) => {
        return file.includes('data');
        // if (file ==='' || file.trim() ===''){ return false; }
        // try {
        //     return window.btoa(window.atob(file)) == file;
        // } catch (err) {
        //     return false;
        // }
    }

    const uploadAdminFiles = async () => {
        console.log("Uploading files");
        const galId = state === 'create' ? uuid() : "";
        setGalId(galId);
        return await new Promise(
          (resolve: any, reject) => {
            console.log(adminFiles);
            if (adminFiles.length > 0) {
                adminFiles.map(
                    (file, id) => {

                        if (isBase64(file?.file)) {
                    
                           shopService.uploadAdminFiles(file?.file, file?.label, galId).then(
                                (res: any) => {
                                    console.log(res);
                                    
                                    if (formRef.current !== null) {

                                        // let fileArray2: any =  formRef.current.values.galContent ?
                                        //  formRef.current.values.galContent : [];
                                        // fileArray2.push(res?.data?.uploadedFN);

                                        formRef.current.values.adminDocGalID = res?.data?.galID;

                                        // formRef.current.values.galContent = fileArray2;
                                        
                                        if(id === (adminFiles.length - 1)) {
                                            resolve();
                                        }

                                    } 
                                    
                                }, (error: any) => {
                                    reject();
                                }
                            );
                        } else {
                            // if (formRef.current !== null) {
                            //     let fileArray2: any =  formRef.current.values.galContent ?  formRef.current.values.galContent : [];
                            //     fileArray2.push(file); 

                            //     formRef.current.values.galContent = fileArray2;
                            //     if(id === (galerie.length - 1)) {
                            //         resolve();
                            //     }

                            // }
                        }
                    }
              );
            } else {
              resolve();
            }  
          }
        )
    }

    

    return (
        <Formik
            innerRef={ formRef }
            initialValues={
                shop != null  && shop != undefined ? 
                {
                    ...shop
                } :
                {
                    libBoutique: '',
                    // adresseBoutique: '',
                    // adresseGpsBoutique: '',
                    addresseLineOne: '',
                    addresseLineTwo: '', 
                    city: '' , 
                    stateOrProvice: '', 
                    zipOrPostalCode: '',
                    country: '', 
                    codeBoutique: '',
                    contactBoutique: '',
                    taxeBoutique: '',
                    localCurrency: '',
                    usdEqInLocal: '',
                    contratBail: '',   
                    nbreAnneeService: '',
                    ageLimiteTravail: '',
                    boutiqueDefautPays: false, 
                    montantLoyerMensuel: '',
                    nvlDatePaymentLoyer: '',
                    adminDocGalID: '', 
                    gazFourn: '',
                    eauFourn: '',
                    electricFourn: '',
                    telFourn: '',
                    internetFourn: ''
            }}
        validationSchema={
            yup.object().shape({
                libBoutique: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                addresseLineOne: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`), 
                addresseLineTwo: yup
                    .string(),
                city: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                stateOrProvice: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                zipOrPostalCode: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                country: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                codeBoutique: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                contactBoutique: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                taxeBoutique: yup
                    .number()
                    .required(`${t('Ce champ est obligatoire')}`),
                localCurrency: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                usdEqInLocal: yup
                    .number()
                    .required(`${t('Ce champ est obligatoire')}`),
                contratBail: yup
                    .string(), 
                nbreAnneeService: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`), 
                ageLimiteTravail: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`), 
                boutiqueDefautPays: yup
                    .boolean()
                    .required(`${t('Ce champ est obligatoire')}`),  
                montantLoyerMensuel: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`), 
                nvlDatePaymentLoyer: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                gazFourn: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                eauFourn: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                electricFourn: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                telFourn: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                internetFourn: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
            })
        }
        
        onSubmit={async (
            values 
          ) => {
                    console.log(values);
                    setLoading(true);

                    // if (image != null && image != '' ) {
                    //     values.photoProfil = image;
                    // } 
                    if (state == 'create') {
                        await uploadAdminFiles().then(
                            async () => {
                                shopService.postShop( Shop.fromJson(values) ).then(async function (response: any) {
                                    console.log(response);
                                    alert( `${t("Boutique créée avec succès")}` );  
                                    navigate('/boutique/list');
                                    setLoading(false); 
                                })
                                .catch(function (error: any) {
                                    console.log(error);
                                    alert( `${ t("Une erreur s'est produite réesseyez s'il vous plaît") }` )
                                    setLoading(false); 
                                });
                        }).catch(function(err) {
                            setLoading(false); 
                        }); 
                    } else if (state == 'edit') {
                        shopService.putShop( Shop.fromJson(values)).then(async function (response: any) {
                            console.log(response);
                            alert( `${ t("Shop mise à jour avec succès") }` );  
                            navigate('/boutique/list');
                            setLoading(false); 
                        })
                          .catch(function (error: any) {
                            console.log(error);
                            alert( `${ t("Une erreur s'est produite réesseyez s'il vous plaît") }` )
                            setLoading(false); 
                        });
                    }
                    
                }}

            // innerRef={formRef}

                >
                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                        <Form className=''>
                                    <div className="form-body row">

                                        <div className='form-group col-md-6'>
                                            <label>
                                                {t('Libellé')}
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.libBoutique &&
                                                             touched.libBoutique ? "is-invalid":""}`}
                                                        id="libBoutique"
                                                        type="text"
                                                        name="libBoutique"
                                                        onChange={handleChange('libBoutique')}
                                                        onBlur={handleBlur('libBoutique')}
                                                        value={values.libBoutique}
                                                        placeholder={t('Libellé') ?? ''} />
                                                    { errors.libBoutique && touched.libBoutique && errors.libBoutique && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.libBoutique && touched.libBoutique && errors.libBoutique }
                                                    </div> }
                                        </div> 

                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Ligne Adresse') } 1
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.addresseLineOne &&
                                                             touched.addresseLineOne ? "is-invalid":""}`}
                                                        id="addresseLineOne"
                                                        type="text"
                                                        name="addresseLineOne"
                                                        onChange={handleChange('addresseLineOne')}
                                                        onBlur={handleBlur('addresseLineOne')}
                                                        value={values.addresseLineOne}
                                                        placeholder={ t('Ligne Adresse') + ' 1' ?? '' } />
                                                    { errors.addresseLineOne && touched.addresseLineOne && errors.addresseLineOne && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.addresseLineOne && touched.addresseLineOne && errors.addresseLineOne }
                                                    </div> }
                                        </div> 

                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Ligne Adresse') } 2
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.addresseLineTwo &&
                                                             touched.addresseLineTwo ? "is-invalid":""}`}
                                                        id="addresseLineTwo"
                                                        type="text"
                                                        name="addresseLineTwo"
                                                        onChange={handleChange('addresseLineTwo')}
                                                        onBlur={handleBlur('addresseLineTwo')}
                                                        value={values.addresseLineTwo}
                                                        placeholder={ t('Ligne Adresse') + ' 2' ?? '' } />
                                                    { errors.addresseLineTwo && touched.addresseLineTwo && errors.addresseLineTwo && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.addresseLineTwo && touched.addresseLineTwo && errors.addresseLineTwo }
                                                    </div> }
                                        </div> 


                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Ville') }
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.city &&
                                                             touched.city ? "is-invalid":""}`}
                                                        id="city"
                                                        type="text"
                                                        name="city"
                                                        onChange={handleChange('city')}
                                                        onBlur={handleBlur('city')}
                                                        value={values.city}
                                                        placeholder={ t('Ville') ?? '' } />
                                                    { errors.city && touched.city && errors.city && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.city && touched.city && errors.city }
                                                    </div> }
                                        </div> 

                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Région') }
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.stateOrProvice &&
                                                             touched.stateOrProvice ? "is-invalid":""}`}
                                                        id="stateOrProvice"
                                                        type="text"
                                                        name="stateOrProvice"
                                                        onChange={handleChange('stateOrProvice')}
                                                        onBlur={handleBlur('stateOrProvice')}
                                                        value={values.stateOrProvice}
                                                        placeholder={ t('Région') ?? '' } />
                                                    { errors.stateOrProvice && touched.stateOrProvice && errors.stateOrProvice && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.stateOrProvice && touched.stateOrProvice && errors.stateOrProvice }
                                                    </div> }
                                        </div> 

                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Code postal') }
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.zipOrPostalCode &&
                                                             touched.zipOrPostalCode ? "is-invalid":""}`}
                                                        id="zipOrPostalCode"
                                                        type="text"
                                                        name="zipOrPostalCode"
                                                        onChange={handleChange('zipOrPostalCode')}
                                                        onBlur={handleBlur('zipOrPostalCode')}
                                                        value={values.zipOrPostalCode}
                                                        placeholder={ t('Code postal') ?? '' } />
                                                    { errors.zipOrPostalCode && touched.zipOrPostalCode && errors.zipOrPostalCode && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.zipOrPostalCode && touched.zipOrPostalCode && errors.zipOrPostalCode }
                                                    </div> }
                                        </div> 

                                        <div className="form-group col-md-6">
                                                <label> 
                                                    { t('Pays') } </label>
                                                        {
                                                            countriesLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <select  
                                                                className={`form-control custom-select ${ errors.country &&
                                                                    touched.country ? "is-invalid" : "" }`}
                                                                id="country" 
                                                                name="country"
                                                                onChange={event => { 
                                                                    handleChange('country')(event); 
                                                                }}
                                                                onBlur={handleBlur('country')}
                                                                value={values.country}
                                                                placeholder="Pays" >
                                                                <option selected>{ t('Pays') }..</option>
                                                                {
                                                                    countries.map(
                                                                        (country: any, id) => <option 
                                                                         key={id} value={country.id}>
                                                                            {
                                                                                country.name
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </select> 
                                                        }
                                        </div>


                                        {/* <div className='form-group'>
                                            <label>
                                                {t('Adresse Gps')}
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.adresseGpsBoutique &&
                                                             touched.adresseGpsBoutique ? "is-invalid":""}`}
                                                        id="adresseGpsBoutique"
                                                        type="text"
                                                        name="adresseGpsBoutique"
                                                        onChange={handleChange('adresseGpsBoutique')}
                                                        onBlur={handleBlur('adresseGpsBoutique')}
                                                        value={values.adresseGpsBoutique}
                                                        placeholder={t('Adresse Gps') ?? ''} />
                                                    { errors.adresseGpsBoutique && touched.adresseGpsBoutique &&
                                                     errors.adresseGpsBoutique && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.adresseGpsBoutique && touched.adresseGpsBoutique &&
                                                         errors.adresseGpsBoutique }
                                                    </div> }
                                        </div> */}

                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Code Boutique') }
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.codeBoutique &&
                                                             touched.codeBoutique ? "is-invalid":""}`}
                                                        id="codeBoutique"
                                                        type="text"
                                                        name="codeBoutique"
                                                        onChange={handleChange('codeBoutique')}
                                                        onBlur={handleBlur('codeBoutique')}
                                                        value={values.codeBoutique}
                                                        placeholder={ t('Code Boutique') ?? '' } />
                                                    { errors.codeBoutique && touched.codeBoutique &&
                                                     errors.codeBoutique && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.codeBoutique && touched.codeBoutique &&
                                                         errors.codeBoutique }
                                                    </div> }
                                        </div>


                                        {/* <div className='form-group'>
                                            <label>Code Boutique</label>
                                            <input 
                                                        className={`form-control ${ errors.codeBoutique &&
                                                             touched.codeBoutique ? "is-invalid":""}`}
                                                        id="codeBoutique"
                                                        type="text"
                                                        name="codeBoutique"
                                                        onChange={handleChange('codeBoutique')}
                                                        onBlur={handleBlur('codeBoutique')}
                                                        value={values.codeBoutique}
                                                        placeholder="Code Boutique" />
                                                    { errors.codeBoutique && touched.codeBoutique &&
                                                     errors.codeBoutique && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.codeBoutique && touched.codeBoutique &&
                                                         errors.codeBoutique }
                                                    </div> }
                                        </div> */}

                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Contact Boutique') }
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.contactBoutique &&
                                                             touched.contactBoutique ? "is-invalid":""}`}
                                                        id="contactBoutique"
                                                        type="text"
                                                        name="contactBoutique"
                                                        onChange={handleChange('contactBoutique')}
                                                        onBlur={handleBlur('contactBoutique')}
                                                        value={values.contactBoutique}
                                                        placeholder={ t('Contact Boutique') ?? '' } />
                                                    { errors.contactBoutique && touched.contactBoutique &&
                                                     errors.contactBoutique && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.contactBoutique && touched.contactBoutique &&
                                                         errors.contactBoutique }
                                                    </div> }
                                        </div>


                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Taxe Boutique') }
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.taxeBoutique &&
                                                             touched.taxeBoutique ? "is-invalid":""}`}
                                                        id="taxeBoutique"
                                                        type="number"
                                                        name="taxeBoutique"
                                                        onChange={handleChange('taxeBoutique')}
                                                        onBlur={handleBlur('taxeBoutique')}
                                                        value={values.taxeBoutique}
                                                        placeholder={ t('Taxe Boutique') ?? ''} />
                                                    { errors.taxeBoutique && touched.taxeBoutique &&
                                                     errors.taxeBoutique && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.taxeBoutique && touched.taxeBoutique &&
                                                         errors.taxeBoutique }
                                                    </div> }
                                        </div>


                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Monnaie locale') }
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.localCurrency &&
                                                             touched.localCurrency ? "is-invalid":""}`}
                                                        id="localCurrency"
                                                        type="text"
                                                        name="localCurrency"
                                                        onChange={handleChange('localCurrency')}
                                                        onBlur={handleBlur('localCurrency')}
                                                        value={values.localCurrency}
                                                        placeholder={ t('Monnaie locale') ?? '' } />
                                                    { errors.localCurrency && touched.localCurrency &&
                                                     errors.localCurrency && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.localCurrency && touched.localCurrency &&
                                                         errors.localCurrency }
                                                    </div> }
                                        </div>

                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Taux d\'échange USD vers monnaie locale') }
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.usdEqInLocal &&
                                                             touched.usdEqInLocal ? "is-invalid":""}`}
                                                        id="usdEqInLocal"
                                                        type="number"
                                                        name="usdEqInLocal"
                                                        onChange={handleChange('usdEqInLocal')}
                                                        onBlur={handleBlur('usdEqInLocal')}
                                                        value={values.usdEqInLocal}
                                                        placeholder={ t('Taux d\'échange USD vers monnaie locale') ?? ''} />
                                            { errors.usdEqInLocal && touched.usdEqInLocal &&
                                                     errors.usdEqInLocal && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.usdEqInLocal && touched.usdEqInLocal &&
                                                         errors.usdEqInLocal }
                                            </div> }
                                        </div>

                                        
                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Nombre maximum d\'années de service') }
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.nbreAnneeService &&
                                                             touched.nbreAnneeService ? "is-invalid":""}`}
                                                        id="nbreAnneeService"
                                                        type="number"
                                                        name="nbreAnneeService"
                                                        onChange={handleChange('nbreAnneeService')}
                                                        onBlur={handleBlur('nbreAnneeService')}
                                                        value={values.nbreAnneeService}
                                                        placeholder={ t('Nombre maximum d\'années de service') ?? ''} />
                                            { errors.nbreAnneeService && touched.nbreAnneeService &&
                                                     errors.nbreAnneeService && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.nbreAnneeService && touched.nbreAnneeService &&
                                                         errors.nbreAnneeService }
                                            </div> }
                                        </div>

                                        <div className='form-group col-md-6'>
                                            <label>
                                                {t('Age maximum de travail')}
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.ageLimiteTravail &&
                                                             touched.ageLimiteTravail ? "is-invalid":""}`}
                                                        id="ageLimiteTravail"
                                                        type="number"
                                                        name="ageLimiteTravail"
                                                        onChange={handleChange('ageLimiteTravail')}
                                                        onBlur={handleBlur('ageLimiteTravail')}
                                                        value={values.ageLimiteTravail}
                                                        placeholder={t('Age maximum de travail') ?? ''} />
                                            { errors.ageLimiteTravail && touched.ageLimiteTravail &&
                                                     errors.ageLimiteTravail && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.ageLimiteTravail && touched.ageLimiteTravail &&
                                                         errors.ageLimiteTravail }
                                            </div> }
                                        </div>

                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Montant du loyer mensuel') }
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.montantLoyerMensuel &&
                                                             touched.montantLoyerMensuel ? "is-invalid":""}`}
                                                        id="montantLoyerMensuel"
                                                        type="number"
                                                        name="montantLoyerMensuel"
                                                        onChange={handleChange('montantLoyerMensuel')}
                                                        onBlur={handleBlur('montantLoyerMensuel')}
                                                        value={values.montantLoyerMensuel}
                                                        placeholder={ t('Montant du loyer mensuel') ?? ''} />
                                            { errors.montantLoyerMensuel && touched.montantLoyerMensuel &&
                                                     errors.montantLoyerMensuel && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.montantLoyerMensuel && touched.montantLoyerMensuel &&
                                                         errors.montantLoyerMensuel }
                                            </div> }
                                        </div>


                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Date d\'échéance du loyer') }
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.nvlDatePaymentLoyer &&
                                                             touched.nvlDatePaymentLoyer ? "is-invalid":""}`}
                                                        id="nvlDatePaymentLoyer"
                                                        type="date"
                                                        name="nvlDatePaymentLoyer"
                                                        onChange={handleChange('nvlDatePaymentLoyer')}
                                                        onBlur={handleBlur('nvlDatePaymentLoyer')}
                                                        value={values.nvlDatePaymentLoyer}
                                                        placeholder={ t('Date d\'échéance du loyer') ?? '' } />
                                            { errors.nvlDatePaymentLoyer && touched.nvlDatePaymentLoyer &&
                                                     errors.nvlDatePaymentLoyer && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.nvlDatePaymentLoyer && touched.nvlDatePaymentLoyer &&
                                                         errors.nvlDatePaymentLoyer }
                                            </div> }
                                        </div>

                                        <div className='form-group col-md-6'>
                                            <label>{ t('Définir comme lieu de livraison par défaut') }
                                            <Field type="checkbox"
                                                className={`form-control 
                                                ${ errors.boutiqueDefautPays && touched.boutiqueDefautPays ? "is-invalid":""}`}
                                                id="boutiqueDefautPays" 
                                                name="boutiqueDefautPays"  
                                                />
                                                </label>
                                                { errors.boutiqueDefautPays && touched.boutiqueDefautPays && errors.boutiqueDefautPays && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.boutiqueDefautPays && touched.boutiqueDefautPays && errors.boutiqueDefautPays }
                                                    </div> 
                                                }
                                        </div>


                                        <div className="form-group col-md-6">
                                                <label> 
                                                    {t('Fournisseur Gaz')} </label>
                                                        {
                                                            fournisseursLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <select  
                                                                className={`form-control custom-select ${ errors.gazFourn &&
                                                                    touched.gazFourn ? "is-invalid" : "" }`}
                                                                id="gazFourn" 
                                                                name="gazFourn"
                                                                onChange={event => { 
                                                                    handleChange('gazFourn')(event); 
                                                                }}
                                                                onBlur={handleBlur('gazFourn')}
                                                                value={values.gazFourn}
                                                                placeholder={t('Fournisseur Gaz') ?? ''} >
                                                                <option selected>{t('Fournisseur Gaz')}..</option>
                                                                {
                                                                    fournisseurs.map(
                                                                        (fourn: any, id) => <option 
                                                                         key={id} value={fourn.id}>
                                                                            {
                                                                                fourn.nomFournisseur
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </select> 
                                                        }
                                        </div>


                                        <div className="form-group col-md-6">
                                                <label> {t('Fournisseur Eau')} </label>
                                                        {
                                                            fournisseursLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <select  
                                                                className={`form-control custom-select ${ errors.eauFourn &&
                                                                    touched.eauFourn ? "is-invalid" : "" }`}
                                                                id="eauFourn" 
                                                                name="eauFourn"
                                                                onChange={event => { 
                                                                    handleChange('eauFourn')(event); 
                                                                }}
                                                                onBlur={handleBlur('eauFourn')}
                                                                value={values.eauFourn}
                                                                placeholder={t('Fournisseur Eau') ?? ''} >
                                                                <option selected>{t('Fournisseur Eau')}..</option>
                                                                {
                                                                    fournisseurs.map(
                                                                        (fourn: any, id) => <option 
                                                                         key={id} value={fourn.id}>
                                                                            {
                                                                                fourn.nomFournisseur
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </select> 
                                                        }
                                        </div>


                                        <div className="form-group col-md-6">
                                                <label> {t('Fournisseur Electricité')} </label>
                                                        {
                                                            fournisseursLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <select  
                                                                className={`form-control custom-select ${ errors.electricFourn &&
                                                                    touched.electricFourn ? "is-invalid" : "" }`}
                                                                id="electricFourn" 
                                                                name="electricFourn"
                                                                onChange={event => { 
                                                                    handleChange('electricFourn')(event); 
                                                                }}
                                                                onBlur={handleBlur('electricFourn')}
                                                                value={values.electricFourn}
                                                                placeholder={t('Fournisseur Electricité') ?? ''} >
                                                                <option selected>{t('Fournisseur Electricité')}.</option>
                                                                {
                                                                    fournisseurs.map(
                                                                        (fourn: any, id) => <option 
                                                                         key={id} value={fourn.id}>
                                                                            {
                                                                                fourn.nomFournisseur
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </select> 
                                                        }
                                        </div>


                                        <div className="form-group col-md-6">
                                                <label> {t('Fournisseur Téléphone')} </label>
                                                        {
                                                            fournisseursLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <select  
                                                                className={`form-control custom-select ${ errors.telFourn &&
                                                                    touched.telFourn ? "is-invalid" : "" }`}
                                                                id="telFourn" 
                                                                name="telFourn"
                                                                onChange={event => { 
                                                                    handleChange('telFourn')(event); 
                                                                }}
                                                                onBlur={handleBlur('telFourn')}
                                                                value={values.telFourn}
                                                                placeholder={t('Fournisseur Téléphone') ?? ''}>
                                                                <option selected>{t('Fournisseur Téléphone')}..</option>
                                                                {
                                                                    fournisseurs.map(
                                                                        (fourn: any, id) => <option 
                                                                         key={id} value={fourn.id}>
                                                                            {
                                                                                fourn.nomFournisseur
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </select> 
                                                        }
                                        </div>


                                        <div className="form-group col-md-6">
                                                <label> 
                                                    { t('Fournisseur Internet') } </label>
                                                        {
                                                            fournisseursLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <select  
                                                                className={`form-control custom-select ${ errors.internetFourn &&
                                                                    touched.internetFourn ? "is-invalid" : "" }`}
                                                                id="internetFourn" 
                                                                name="internetFourn"
                                                                onChange={event => { 
                                                                    handleChange('internetFourn')(event); 
                                                                }}
                                                                onBlur={handleBlur('internetFourn')}
                                                                value={values.internetFourn}
                                                                placeholder={ t('Fournisseur Internet') ?? ''} >
                                                                <option selected>Internet..</option>
                                                                {
                                                                    fournisseurs.map(
                                                                        (fourn: any, id) => <option 
                                                                         key={id} value={fourn.id}>
                                                                            {
                                                                                fourn.nomFournisseur
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </select> 
                                                        }
                                        </div>

                                        <div className="col-md-6">
                                            <div className="col-md-12">
                                                <label> { t('Contrat bail') } </label>
                                                <div className='form-group'> 
                                                                    <div className="custom-file">
                                                                        <input type="file" 
                                                                        className={`form-control custom-file-input `} 
                                                                            id="photoProfil" 
                                                                            onChange={changeListener} 
                                                                            placeholder="Photo" />
                                                                        <label className="custom-file-label" 
                                                                        htmlFor="photoProfil">
                                                                            { t('Choisir un fichier') }
                                                                        </label>
                                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div className="col-md-12 row">
                                            <div className="col-md-6">
                                                <hr />
                                                <b>
                                                    { t('Fichiers administratifs') }
                                                </b>
                                                <div className="form-group">
                                                    <label> {t('Libellé du fichier')} </label>
                                                    <Field className='form-control' type="text"
                                                        // className={`form-control 
                                                        // ${ errors.qteAvAlertFinStock && touched.qteAvAlertFinStock ? "is-invalid":""}`}
                                                        // id="qteAvAlertFinStock" 
                                                        // name="qteAvAlertFinStock"  
                                                        onChange={(event: any) => {
                                                            setAdminFileLabel(event.target.value);
                                                        }}
                                                        // onBlur={handleBlur('qteAvAlertFinStock')}
                                                        value={ adminFileLabel == null ? '' : adminFileLabel }
                                                        //onKeyPress={onAddIngredient}
                                                        placeholder={t('Libellé du fichier') ?? '' } />
                                                    {/* <input 
                                                        className={`form-control ${ errors.adminFileLabel &&
                                                             touched.adminFileLabel ? "is-invalid":""}`}
                                                        id="adminFileLabel"
                                                        type="text"
                                                        name="adminFileLabel"
                                                        onChange={handleChange('adminFileLabel')}
                                                        onBlur={handleBlur('adminFileLabel')}
                                                        value={values.adminFileLabel}
                                                        placeholder="Libellé du fichier" />
                                                    { errors.adminFileLabel && touched.adminFileLabel &&
                                                     errors.adminFileLabel && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.adminFileLabel && touched.adminFileLabel &&
                                                         errors.adminFileLabel }
                                                    </div> } */}
                                                </div>

                                                <div className='form-group'>
                                                    <label> { t('Fichier') } </label>
                                                    <div className='form-group'> 
                                                                    <div className="custom-file">
                                                                        <input type="file" 
                                                                        className={`form-control custom-file-input `} 
                                                                            id="adminFile" 
                                                                            onChange={changeAdminFileListener} 
                                                                            placeholder="Photo" />
                                                                        <label className="custom-file-label" 
                                                                        htmlFor="adminFile">
                                                                            { t('Choisir un fichier') }
                                                                        </label>
                                                                    </div>
                                                    </div>
                                                </div>
                                                
                                            </div>

                                            <div className="col-md-6">

                                                <div className="list-group"> 
                                                    <a href="javascript:void(0)"
                                                            className="list-group-item active">
                                                                { t('Fichiers administratifs') } </a> 
                                                            {
                                                                adminFiles.map(
                                                                    (file: any, id) => {
                                                                        return <a key={id} target="_blank" download={file?.label}
                                                                        href={ file?.file } className="list-group-item">
                                                                              {
                                                                                file?.label
                                                                              }
                                                                            </a> 
                                                                    }
                                                                )
                                                            }
                                                            
                                                          
                                                            {/* <a href="javascript:void(0)" className="list-group-item">
                                                            </a>  */}
                                                </div>
                                                
                                            </div>


                                            <div className='col-md-12'>
                                                <button disabled={
                                                    !adminFileLabel || !adminFile
                                                }  onClick={ sendAdminFile } 
                                                    type="button" className="btn btn-info">
                                                    { t('Envoyer') }
                                                    {
                                                        loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    }
                                                </button>
                                            </div>

                                        </div>    


                                    </div>

                                    <div className="form-actions">
                                        <div className="text-right">
                                            <button  disabled={!dirty && !isValid } type="submit" className="btn btn-info">
                                                { t('Valider') }
                                                {
                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                            <button type="reset" className="btn btn-dark">
                                                { t('Annuler') }
                                            </button>
                                        </div>
                                    </div>
                        </Form>
                    )}
                </Formik>
    );
}

export default ShopForm