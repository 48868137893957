import { FC, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import ComptaService from './service';

import {
    Link, useNavigate
} from "react-router-dom";
import UserRight from '../../Utils/userRight';

// Moment react
import Moment from 'react-moment';

// Formik
import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 
import { useTranslation } from 'react-i18next';

const ComptaVentes: FC = () => {  

    let idToModInit: any = null;

    const { t, i18n } = useTranslation();

    const [ spents, setSpents ] = useState( [] );
    const [ tempProducts, setTempProducts ] = useState( [] );
    const [ idToMod, setIdToMod ] = useState(idToModInit);
    const [ loading, setLoading ] = useState(false);

    const [ searchStr, setSearchStr ] = useState('');
    const [ searchLoading, setSearchLoading ] = useState(false);

    const comptaService = new ComptaService();

    const userRight = new UserRight();

    const navigate = useNavigate();

    useEffect( function() { 
        // getSpents();
    }, []);
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">

                                    {/* <div className="btn-list">
                                        <button type="button" onClick={ event => { } }
                                            className="btn waves-effect waves-light btn-danger">Actualiser</button>
                                        <Link type="button" to="/product/create"
                                            className="btn waves-effect waves-light btn-primary">
                                                Ajouter une dépense
                                        </Link>
                                    </div>  */}
                                    
                                    <div className="row"> 
                                        <div className="col-md-12">
                                        <Formik
                                            initialValues={
                                                {
                                                    dtedebut: null,
                                                    dtefin: null  
                                                }
                                            }
                                            validationSchema={
                                                yup.object().shape({
                                                    dtedebut: yup
                                                        .date()
                                                        .required(`${t('Ce champ est obligatoire')}`),
                                                    dtefin: yup
                                                        .date() 
                                                        .required(`${t('Ce champ est obligatoire')}`) 
                                                })
                                            }
                                            onSubmit={async (
                                                values 
                                            ) => {  

                                                console.log(values);
                                                setLoading(true);
                                                await comptaService.comptaDetail(values).then(async function (response: any) {
                                                    console.log(response);
                                                    window.localStorage.setItem(
                                                        'compta',
                                                        JSON.stringify(response.data)
                                                    )
                                                    setLoading(false); 
                                                    navigate('/compta/result');
                                                })
                                                  .catch(function (error: any) {
                                                    console.log(error);
                                                    setLoading(false); 
                                                });
                                                 
                                            }}  
                                            >
                                                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                                <Form>
                                                        <div className="form-body"> 
                                                            <div className="row">
                                                                <div className='col-md-6'>
                                                                    <div className="form-group"> 
                                                                            <label> { t('Date de début') } </label>
                                                                            <Field type="date"
                                                                                className={`form-control ${ errors.dtedebut
                                                                                     && touched.dtedebut ? "is-invalid":""}`}
                                                                                id="dtedebut" 
                                                                                name="dtedebut"
                                                                                onChange={handleChange('dtedebut')}
                                                                                onBlur={handleBlur('dtedebut')}
                                                                                value={values.dtedebut == null ? new Date()  : values.dtedebut }
                                                                                placeholder={ t('Date de début') ?? '' } />
                                                                            { errors.dtedebut && touched.dtedebut && errors.dtedebut && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                { errors.dtedebut && touched.dtedebut && errors.dtedebut }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <div className="form-group"> 
                                                                            <label>{ t('Date de fin') ?? '' } </label>
                                                                            <Field type="date"
                                                                                className={`form-control ${ errors.dtefin
                                                                                     && touched.dtefin ? "is-invalid":""}`}
                                                                                id="dtefin" 
                                                                                name="dtefin"
                                                                                onChange={handleChange('dtefin')}
                                                                                onBlur={handleBlur('dtefin')}
                                                                                value={values.dtefin == null ? new Date()  : values.dtefin }
                                                                                placeholder={ t('Date de fin') ?? '' } />
                                                                            { errors.dtefin && touched.dtefin && errors.dtefin && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                { errors.dtefin && touched.dtefin && errors.dtefin }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                         </div>   

                                                        <div className="form-actions">
                                                            <div className="text-right">
                                                                <button  disabled={!isValid} type="submit" className="btn btn-info">
                                                                    { t('Valider') }
                                                                    {
                                                                        loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    }
                                                                </button>
                                                                {/* <button type="button" onClick={event => cancel()}
                                                                className="btn btn-dark">Annuler</button> */}
                                                            </div>
                                                        </div>

                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div> 
                    </div>
                </div>   
            </>                   

    );
}

export default ComptaVentes