import { FC, useEffect, useRef, useState } from 'react'
import {
    Link
} from "react-router-dom";
import Breadcrumb from '../../Components/Breadcrumb';

//Alert
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';  

import AgentService from '../Agent/service';
import InterestRepartitionService from './service';


import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 
 

import DataTable from 'react-data-table-component';

import Alert from '../../Components/Alert';
import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
import { useTranslation } from 'react-i18next';

interface AssociateFormProps {
    id?: string | null ;
    idAgent: string; 
    pourcentage: string
}



const AssociatesPage: FC = () => {  

    // const alert = useAppSelector((state: RootState ) => state.alerts );
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const formRef = useRef< FormikProps<AssociateFormProps>>(null);
    

    const [
        loading, setLoading
    ] = useState(false);


    const [ agents, setAgents ] = useState( [] ); 

    const [
        agentsLoading, setAgentsLoading
    ] = useState(false);

    const agentService  = new AgentService();

    const interestRepartitionService  = new InterestRepartitionService();


    const [
        associates,
        setAssociates
    ] = useState([]);

    const initialSelectedGamme: any = null;

    const [
        selectedType,
        setSelectedType
    ] = useState(initialSelectedGamme);

    const [
        deleteLoading, setDeleteLoading
    ] = useState(false);

   const id: Number = 0;
    const [
        deleteId, setDeleteId
    ] = useState(id);
 

    const deleteAssociate = async (salaryId: Number) => {
        setDeleteId(salaryId);
        setDeleteLoading(true);

        confirmAlert({
            overlayClassName: "alert-overlay",
            customUI: ({ onClose }) => {
              return (
                    <Alert 
                        alert={{
                            title: 'Confirmer',
                            message: 'Vous confirmez la suppression de cet associé ?',
                            actionText: 'Valider',
                            action: async () => {
                                await interestRepartitionService.deleteAssociate(salaryId).then(async function (response: any) {
                                    console.log(response); 
                                   
                                    getAssociates();
                                    setDeleteLoading(false); 

                                    confirmAlert({
                                        overlayClassName: "alert-overlay",
                                        customUI: ({ onClose }) => {
                                          return (
                                                <Alert 
                                                    alert={{
                                                        title: 'Succès',
                                                        message: 'Associé supprimé avec succès',
                                                        actionText: 'Ok',
                                                        action: () => {
                                                            onClose();
                                                        }
                                                    }}
                                                />
                                          );
                                        }
                                    });

                                })
                                  .catch(function (error: any) {
                                    console.log(error);
                                    setDeleteLoading(false); 
                                });
                                onClose();
                            },
                            cancelText: "Annuler",
                            cancelAction: () => {
                                setDeleteLoading(false); 
                                onClose();
                            }
                        }}
                    />
              );
            }
        });

    }

    const getAgents = () => {
        setAgentsLoading(true);
        agentService.getAgents().then(async function (response: any) {
            console.log(response);
            setAgents(response.data);
            setAgentsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setAgentsLoading(false); 
        });
    }

    const getAssociates = () => {
        setLoading(true);
        interestRepartitionService.getAssociates().then(async function (response: any) {
            console.log(response);
            setAssociates(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row.id
        },
        {
            name: 'Agent',
            selector: (row: any) => row.agent.prenomAgent + ' ' + row.agent.nomAgent ,
        },
        {
            name: `${ t("Pourcentage") }`,
            selector: (row: any) => row.pourcentage,
        }, 
        {
            name: "Options", 
            selector: (row: any) => (
                <div className='btn-list'>
                    <button type="button" onClick={event => {
                        setSelectedType( row );
                        if ( formRef.current != null) {
                            formRef.current.values.id = row.id;
                            formRef.current.values.idAgent =  row.agent.id;
                            formRef.current.values.pourcentage = row.pourcentage;
                        }  
                    }}
                        className="btn waves-effect waves-light btn-success">
                            <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-pencil"></i></span>
                    </button>
                    <button type="button" onClick={event => {  deleteAssociate(row.id)  }}
                        className="btn waves-effect waves-light btn-danger">
                            {
                                deleteId == row.id && deleteLoading ?
                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> :
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-trash"></i></span>
                            }
                    </button>
                </div>
            )
        }
    ];


    const cancel = () => {

        setSelectedType(null);

        if ( formRef.current != null) {
            formRef.current.values.id = null;
            formRef.current.values.idAgent =  '';
            formRef.current.values.pourcentage = '';
        } 
    }

    useEffect(() => {
        getAgents();
        getAssociates();
    }, []);



    return ( 
        <>  
            <Breadcrumb />

            <div className="container-fluid">

                <div className="row">
                    <div className="col-md-5">
                        <div className="card">
                            <div className="card-body">

                                <h4 className="card-title">
                                    {
                                        selectedType == null ? 
                                        t("Ajouter un associé") :
                                        t("Mettre à jour associé")
                                    }
                                </h4>

                                <Formik
                                    initialValues={
                                        {
                                        id: null,
                                        idAgent: '',
                                        pourcentage: ''
                                    }}
                                    validationSchema={
                                        yup.object().shape({
                                            idAgent: yup
                                                .string() 
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            pourcentage: yup
                                                .number()
                                                .max(100, "Can't exceed 100")
                                        })
                                    }
                                    onSubmit={async (
                                        values 
                                    ) => {

                                        setLoading(true);

                                        if(selectedType == null) {

                                            await interestRepartitionService.postAssociates( values ).then(async function (response: any) {
                                                console.log(response);

                                                confirmAlert({
                                                    overlayClassName: "alert-overlay",
                                                    customUI: ({ onClose }) => {
                                                      return (
                                                            <Alert 
                                                                alert={{
                                                                    title: 'Succès',
                                                                    message: 'Associé créé avec succès',
                                                                    actionText: 'Ok',
                                                                    action: () => {
                                                                        onClose();
                                                                    }
                                                                }}
                                                            />
                                                      );
                                                    }
                                                });
 
                                                if ( formRef.current != null) {
                                                    formRef.current.values.id = null;
                                                    formRef.current.values.idAgent =  '';
                                                    formRef.current.values.pourcentage = '';
                                                } 

                                                getAssociates();
                                                setLoading(false); 

                                            })
                                            .catch(function (error: any) {
                                                setLoading(false); 
                                            });

                                        }  else {

                                            await interestRepartitionService.putAssociate( values ).then(async function (response: any) {
                                                console.log(response); 
                                                confirmAlert({
                                                    overlayClassName: "alert-overlay",
                                                    customUI: ({ onClose }) => {
                                                      return (
                                                            <Alert 
                                                                alert={{
                                                                    title: 'Succès',
                                                                    message: 'Associé mis à jour avec succès',
                                                                    actionText: 'Ok',
                                                                    action: () => {
                                                                        onClose();
                                                                    }
                                                                }}
                                                            />
                                                      );
                                                    }
                                                }); 

                                                if ( formRef.current != null) {
                                                    formRef.current.values.id = null;
                                                    formRef.current.values.idAgent =  '';
                                                    formRef.current.values.pourcentage = '';
                                                } 

                                                setSelectedType(null);

                                                getAssociates();
                                                setLoading(false); 

                                            })
                                            .catch(function (error: any) {
                                                setLoading(false); 
                                            });

                                        }

                                        
          
                                    }} 
                                    innerRef={formRef}
                                    >
                                        {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                                    <Form>
                                                        <div className="form-body">
                                                            
                                                        <div className="row"> 
                                                                <div className="col-md-12">
                                                                    
                                                                    <div className="form-group">
                                                                        <label>Agent</label>
                                                                        {
                                                                            agentsLoading ? 
                                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                                            <span className="sr-only">Loading...</span>
                                                                            </div> :
                                                                            <select  
                                                                                className={`form-control custom-select 
                                                                                ${ errors.idAgent &&
                                                                                    touched.idAgent ? "is-invalid" : "" }`}
                                                                                id="idAgent" 
                                                                                name="idAgent"
                                                                                onChange={event => { 
                                                                                    handleChange('idAgent')(event); 
                                                                                }}
                                                                                onBlur={handleBlur('idAgent')}
                                                                                value={values.idAgent }
                                                                                placeholder="Agent" >
                                                                                <option selected>Agent..</option>
                                                                                {
                                                                                    agents.map(
                                                                                        (agent: any, id) => <option 
                                                                                        key={id} value={agent.id}>
                                                                                            {
                                                                                                agent.prenomAgent + ' ' + agent.nomAgent
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                }
                                                                            </select> 
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                            
                                                            <div className="row">
                                                                
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label> { t('Pourcentage') } </label>
                                                                        <input type='number' max={100}
                                                                            className={`form-control ${ errors.pourcentage
                                                                                 && touched.pourcentage ? "is-invalid":""}`}
                                                                            id="pourcentage" 
                                                                            name="pourcentage" 
                                                                            onChange={handleChange('pourcentage')}
                                                                            onBlur={handleBlur('pourcentage')}
                                                                            value={values.pourcentage}
                                                                            placeholder={ t('Pourcentage') ?? '' } />
                                                                        { errors.pourcentage && touched.pourcentage &&
                                                                         errors.pourcentage && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                            { errors.pourcentage && touched.pourcentage &&
                                                                             errors.pourcentage }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                        </div>

                                                        <div className="form-actions">
                                                            <div className="text-right">
                                                                <button  disabled={!isValid} type="submit" className="btn btn-info">
                                                                    { t('Valider') }
                                                                    {
                                                                        loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    }
                                                                </button>
                                                                <button type="button" onClick={event => cancel()}
                                                                 className="btn btn-dark">
                                                                    { t('Annuler') }
                                                                 </button>
                                                            </div>
                                                        </div>

                                                    </Form>
                                              )}
                                    </Formik>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">
                                    {
                                        t('Liste des associés')
                                    }
                                </h4>
                                <div className="btn-list">
                                    <button type="button" onClick={ event => getAssociates() }
                                        className="btn waves-effect waves-light btn-success">
                                            { t('Actualiser') }
                                        </button>
                                </div> 
                                <div className="table-responsive">
                                    <DataTable
                                        responsive={true}
                                        className="table table-striped table-bordered"
                                        columns={columns}
                                        data={associates}
                                        progressPending={loading}
                                        pagination
                                    />
                                </div>
                             </div>
                        </div> 
                    </div> 
                </div>

            </div>   
        </>                   
    );
}

export default AssociatesPage