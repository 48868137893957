import axiosInstance from '../../Utils/axios_manager';

export default class TaxeService {
  
    constructor() { }

 
    public getAssociates =  async () => {
        return await axiosInstance.get('/associe/list');
    }

    public postAssociates =  async (associate: Record<string, any> ) => {
        return axiosInstance.post('/associe/add',  associate );
    }

    public putAssociate =  async (associate: Record<string, any> ) => {
        return axiosInstance.post('/associe/update',  associate );
    }

    public deleteAssociate  = ( salaryId: Number ) => {
        return axiosInstance.delete('/associe/delete' + `?id=${salaryId}`);
    }

    public filterSalaries =  async (data: Record<string, any>) => {
        return await axiosInstance.post('/salaires/list/periode', data);
    }

    public getSupervizeWithdrawalList =  async () => {
        return await axiosInstance.get('/associe/retrait/list/supervised');
    }

    public getUnsupervisedWithdrawalList =  async () => {
        return await axiosInstance.get('/associe/retrait/list/unsupervised');
    }

    public getInterest =  async (data: Record<string, any>) => {
        return await axiosInstance.post('/associe/interet/calcul', data);
    }

}