import User from '../../Models/User';
import axiosInstance from '../../Utils/axios_manager';

export default class PromotionService {
  
    constructor( ) {
    }

    public postPromotion =  (data: Record<string, any>) => {
        return axiosInstance.post('/promo/add', data);
    }

    public getPromotions =  async () => {
        return await axiosInstance.get('/promo/list/all');
    }

    public getPromotion =  async (id: Number) => {
        return await axiosInstance.get('/promo/details' + `?idPromo=${id.toString()}`);
    }

    public putPromotion =  (data: Record<string, any>) => {
        return axiosInstance.post('/promo/update',data);
    }
  

}