import { FC, useEffect, useState, useRef } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import AnalyticService from './service';

import {
    Link, useNavigate
} from "react-router-dom";
import UserRight from '../../Utils/userRight';

// Moment react
import Moment from 'react-moment';
import moment from 'moment';

// Formik
import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 
import { useTranslation } from 'react-i18next';

import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);
interface AnalyticsForm {
    dteDeb: string | null;
    dteFin: string | null;
    period: string | null;
}

interface AnalyticsGraphData {
    labels: Array< String >;
    datasets: Array< any >;
}


const AnalyticProductsPage: FC = () => {  

    let idToModInit: any = null; 

    const formRef = useRef< FormikProps< AnalyticsForm >>(null);

    const analyticService = new AnalyticService();

    const { t, i18n } = useTranslation();
    const analDataInit: any = null;
    const [analData, setAnalData] = useState(analDataInit);
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Analytics nombre de vente.',
          },
        },
    };

      const options2 = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Analytics total des ventes.',
          },
        },
      };


      const options3 = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Analytics total produits distincts.',
          },
        },
      };
      
    let labelsInit: Array<String> = [];
    
    let [labels, setLabels] = useState(labelsInit); 

      const monthLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October',
      'November', 'December'];
      const weekLabels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ];
      const dayLabels = [ '1', '2', '3', '4', '5', '6', '7', '8', 
      '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27',
      '28', '29', '30', '31' ];

      const graphDataInit: AnalyticsGraphData = {
        labels,
        datasets: [] };

    let [data, setData] = useState(graphDataInit);

    let [data2, setData2] = useState(graphDataInit);

    let [data3, setData3] = useState(graphDataInit);

    let [ selectedGraph, setSelectedGraph ] = useState(1);
      
    //   const data = {
    //     labels,
    //     datasets: [
    //         {
    //             label: 'Ventes',
    //             data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
    //             borderColor: 'rgb(53, 162, 235)',
    //             backgroundColor: 'rgba(53, 162, 235, 0.5)',
    //         },
    //     ],
    //   };


    //   const data2 = {
    //     labels,
    //     datasets: [
    //       {
    //         label: 'Visites',
    //         data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
    //         borderColor: 'rgb(255, 99, 132)',
    //         backgroundColor: 'rgba(255, 99, 132, 0.5)',
    //       }, 
    //     ],
    //   };
      

    const navigate = useNavigate();

    const periods = [
        'day',
        "month",
        'year'
    ];

    const [ loading, setLoading ] = useState(false);

    const getAnalyticsData = (data: Record<string, any>) => {
        setLoading(true); 
        analyticService.getAnalyticsProductsData(data).then(async function (response: any) {
            console.log(response); 
            setAnalData(response.data.data);

            const dataSet = response.data.data.graphicsData.map(
                (d: any) => d.order_number
            );

            const data2Set = response.data.data.graphicsData.map(
                (d: any) => d.total_ventes
            );

            const data3Set = response.data.data.graphicsData.map(
                (d: any) => d.total_nbre_produit
            );


            const labelsSet = response.data.data.graphicsData.map(
                (d: any) => d.date
            );

            setData(
                {
                    labels: labelsSet,
                    datasets: [
                        {
                            label: 'Nombre de vente',
                            data: dataSet,
                            borderColor: 'rgb(53, 162, 235)',
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        },
                    ],
                  }
            );


            setData2(
                {
                    labels: labelsSet,
                    datasets: [
                        {
                            label: 'Total des ventes',
                            data: data2Set,
                            borderColor: 'rgb(255, 99, 132)',
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                    ],
                  }
            );

            setData3(
                {
                    labels: labelsSet,
                    datasets: [
                        {
                            label: 'Total produits distincts',
                            data: data3Set,
                            borderColor: 'rgb(199, 255, 142)',
                            backgroundColor: 'rgba(199, 255, 142, 0.5)',
                        },
                    ],
                  }
            );

            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }


    useEffect( function() { 
        setLoading(true);


        const dateTo = moment().format('YYYY-MM-DD');
        const dateFrom = moment().subtract(7,'d').format('YYYY-MM-DD');


        getAnalyticsData({
            "dtedebut": dateTo,
            "dtefin": dateFrom,
            "periodicite":"journalier",
            "produit_id":0
        });

        if ( formRef.current != null ) {
            formRef.current.values.dteDeb = dateFrom;
            formRef.current.values.dteFin = dateTo;
            formRef.current.values.period = 'day';
        }

        // let analData = JSON.parse(window.localStorage.getItem('analData') ?? '');
        // if (analData !== null && analData !== undefined) { 
        //     console.log(analData);
        //     setAnalData(analData);
        //     switch (analData?.period) {
        //         case 'day':
        //             labels = weekLabels;
        //             break;
        //         case 'month':
        //             labels = dayLabels;
        //             break;
        //         case 'year':
        //             labels = monthLabels;
        //             break;
            
        //         default:
        //             break;
        //     }
        // } else {
        //     navigate('/analytics');
        // }
        // getSpents();
    }, []);

    const getPeriod = (period: string | null) => {
        switch (period) {
            case 'day':
                return 'journalier';
                break;

            case 'month':
                return 'mensuel';
                break;
            
            case 'year':
                return 'annuel';
                break;
        
            default:
                return 'journalier';
                break;
        }
    }
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">

                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">
                                    {
                                        t('Produits')
                                    }
                                </h4> 
                            </div>
                        </div> 


                            <div className="card">
                                <div className="card-body">
                                    
                                    <div className="row"> 
                                        <div className="col-md-12">

                                        <Formik
                                            innerRef={formRef}
                                            initialValues={
                                                {
                                                    dteDeb: null,
                                                    dteFin: null ,
                                                    period: ''
                                                }
                                            }
                                            validationSchema={
                                                yup.object().shape({
                                                    dteDeb: yup
                                                        .date()
                                                        .required(`${t('Ce champ est obligatoire')}`),
                                                    dteFin: yup
                                                        .date() 
                                                        .required(`${t('Ce champ est obligatoire')}`),
                                                    period: yup
                                                        .string()
                                                        .required(`${t('Ce champ est obligatoire')}`),
                                                })
                                            }
                                            onSubmit={async (
                                                values 
                                            ) => {  
                                                
                                                // window.localStorage.setItem('analData', JSON.stringify(values));
                                                // navigate('/analytics/results');

                                                getAnalyticsData({
                                                    "dtedebut": values.dteDeb,
                                                    "dtefin": values.dteFin,
                                                    "periodicite": getPeriod(values.period),
                                                    "produit_id":0
                                                });
                                                 
                                            }}  
                                            >
                                                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                                <Form>
                                                        <div className="form-body"> 
                                                            <div className="row">
                                                                <div className='col-md-4'>
                                                                    <div className="form-group"> 
                                                                            <label> { t('Date de début') } </label>
                                                                            <Field type="date"
                                                                                className={`form-control ${ errors.dteDeb
                                                                                     && touched.dteDeb ? "is-invalid":""}`}
                                                                                id="dteDeb" 
                                                                                name="dteDeb"
                                                                                onChange={handleChange('dteDeb')}
                                                                                onBlur={handleBlur('dteDeb')}
                                                                                value={values.dteDeb == null ? new Date()  : values.dteDeb }
                                                                                placeholder={ t('Date de début') ?? '' } />
                                                                            { errors.dteDeb && touched.dteDeb && errors.dteDeb && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                { errors.dteDeb && touched.dteDeb && errors.dteDeb }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <div className="form-group"> 
                                                                            <label>{ t('Date de fin') ?? '' } </label>
                                                                            <Field type="date"
                                                                                className={`form-control ${ errors.dteFin
                                                                                     && touched.dteFin ? "is-invalid":""}`}
                                                                                id="dteFin" 
                                                                                name="dteFin"
                                                                                onChange={handleChange('dteFin')}
                                                                                onBlur={handleBlur('dteFin')}
                                                                                value={values.dteFin == null ? new Date()  : values.dteFin }
                                                                                placeholder={ t('Date de fin') ?? '' } />
                                                                            { errors.dteFin && touched.dteFin && errors.dteFin && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                { errors.dteFin && touched.dteFin && errors.dteFin }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <div className="form-group"> 
                                                                            <label>{ t('Période') ?? '' } </label>
                                                                            <Field as="select"  
                                                                                className={`form-control custom-select ${ errors.period &&
                                                                                    touched.period ? "is-invalid" : "" }`}
                                                                                id="period" 
                                                                                name="period"
                                                                                onChange={(event: any) => {
                                                                                    // console.log(event);
                                                                                    handleChange('period')(event);
                                                                                    // const agent = getAgentById(event.target.value);
                                                                                    // console.log(agent);
                                                                                    // setSelectedAgent( agent );
                                                                                }}
                                                                                onBlur={handleBlur('period')}
                                                                                value={ values.period }
                                                                                placeholder={ t('Période') ?? '' } >
                                                                                <option selected>{ t('Période') ?? '' }...</option>
                                                                                {
                                                                                    periods.map(
                                                                                        (prd: any, id) => <option 
                                                                                        key={id} value={prd} style={ { textTransform: "capitalize" } } >
                                                                                            {
                                                                                               t(t('analyticPeriod', { analyticPeriodName: prd } ) ?? '')
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                }
                                                                            </Field> 
                                                                            { errors.period && touched.period && errors.period && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                { errors.period && touched.period && errors.period }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                         </div>   

                                                        <div className="form-actions">
                                                            <div className="text-right">
                                                                <button  disabled={!isValid} type="submit" className="btn btn-info">
                                                                    { t('Valider') }
                                                                    {
                                                                        loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    }
                                                                </button>

                                                            </div>
                                                        </div>

                                                    </Form>
                                                )}
                                            </Formik>


                                        </div>
                                        </div>

                                        <br />

                                        { loading || analData === null ? 
                                        <div className="spinner-border text-light spinner-border-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div> : 
                                        <> 
                                    <div className="row"> 
                                        <div className="col-md-12">

                                           

                                        <div className="card-group">

                                            <div className={`card border-right ${ selectedGraph === 1
                                                    ? "rounded lpGraphPointer shadow-lg p-3 mb-5":""} `} onClick={() => setSelectedGraph(1)}>
                                                    <div className="card-body">
                                                        <div className="d-flex d-lg-flex d-md-block align-items-center">
                                                            <div>
                                                                <div className="d-inline-flex align-items-center">
                                                                    <h4 className="text-dark mb-1 font-weight-medium">
                                                                        { t('Nombre de commande') }
                                                                    </h4>
                                                                    
                                                                </div>
                                                                <h6 className="  font-weight-normal mb-0 w-100 text-truncate">
                                                                    {
                                                                    analData?.total_order_number
                                                                    }
                                                                </h6>
                                                            </div>
                                                            {/*  t(t('analyticPeriod', { analyticPeriodName: analData?.period } ) ?? '')  
                                                            <div className="ml-auto mt-md-3 mt-lg-0">
                                                                <span className="opacity-7 text-muted fs-20" style={{ fontSize: "2em" }} >
                                                                    <i className="icon-wallet"></i></span>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>


                                            <div className="card border-right">
                                                <div className="card-body">
                                                    <div className="d-flex d-lg-flex d-md-block align-items-center">
                                                        <div>
                                                            {}
                                                            <div className="d-inline-flex align-items-center">
                                                                <h4 className="text-dark mb-1 font-weight-medium">
                                                                    { t('Capital total') }
                                                                </h4>
                                                                {/* <span
                                                                    className="badge bg-primary font-12 text-white font-weight-medium
                                                                    badge-pill ml-2 d-lg-block d-md-none">+18.33%</span> */}
                                                            </div>
                                                            <h6 className="font-weight-normal mb-0 w-100 text-truncate">
                                                                {
                                                                   analData?.total_capital
                                                                }
                                                            </h6>
                                                        </div>
                                                        {/* <div className="ml-auto mt-md-3 mt-lg-0">
                                                            <span className="opacity-7 text-muted fs-20" style={{ fontSize: "2em" }} >
                                                                <i className="icon-basket text-success"></i></span>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>

                                            
                                            <div className={`card border-right ${ selectedGraph === 2
                                                    ? "rounded lpGraphPointer shadow-lg p-3 mb-5":""} `} onClick={() => setSelectedGraph(2)} >
                                                <div className="card-body">
                                                    <div className="d-flex d-lg-flex d-md-block align-items-center">
                                                        <div>
                                                            <div className="d-inline-flex align-items-center">
                                                                <h4 className="text-dark mb-1 font-weight-medium">
                                                                    { t('Nombre de produits') }
                                                                </h4>
                                                                {/* <span
                                                                    className="badge bg-danger font-12 text-white font-weight-medium badge-pill 
                                                                    ml-2 d-md-none d-lg-block">-18.33%</span> */}
                                                            </div>
                                                            <h6 className="  font-weight-normal mb-0 w-100 text-truncate">
                                                                {
                                                                    analData?.total_produit_number
                                                                }
                                                            </h6>
                                                        </div>
                                                        {/* <div className="ml-auto mt-md-3 mt-lg-0">
                                                            <span className="opacity-7 text-muted fs-20" style={{ fontSize: "2em" }} >
                                                                <i className="icon-wallet text-info"></i></span>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="card border-right">
                                                <div className="card-body">
                                                    <div className="d-flex d-lg-flex d-md-block align-items-center">
                                                        <div>
                                                            <h4 className="text-dark mb-1 w-100 text-truncate font-weight-medium">
                                                                { t('Intérêt total') }
                                                            </h4>
                                                            <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                                                                {
                                                                    analData?.total_interet
                                                                }
                                                            </h6>
                                                        </div>
                                                        {/* <div className="ml-auto mt-md-3 mt-lg-0">
                                                            <span className="opacity-7 text-muted fs-20" style={{ fontSize: "2em" }} >
                                                                <i className="icon-list text-danger"></i></span>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                                <div className={`card border-right ${ selectedGraph === 3
                                                        ? "rounded lpGraphPointer shadow-lg p-3 mb-5":""} `} onClick={() => setSelectedGraph(3)}>
                                                    <div className="card-body">
                                                        <div className="d-flex d-lg-flex d-md-block align-items-center">
                                                            <div>
                                                                <h4 className="text-dark mb-1 font-weight-medium">
                                                                    { t('Vente totale') }
                                                                </h4>
                                                                <h6 className="  font-weight-normal mb-0 w-100 text-truncate">
                                                                    {
                                                                        analData?.total_ventes_montant
                                                                    }
                                                                </h6>
                                                            </div>
                                                            {/* 
                                                                <div className="ml-auto mt-md-3 mt-lg-0">
                                                                    <span className="opacity-7 text-muted fs-20" style={{ fontSize: "2em" }} >
                                                                        <i className="icon-graph text-warning"></i></span>
                                                                </div> 
                                                            */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                            <br />
                                            
                            
                                            </div>
                                        </div> 


                                        <div className="row"> 

                                            <div className="col-md-12">
                                                { 
                                                   !loading && selectedGraph === 1 && <Line options={options} data={data} />
                                                } 
                                            </div>

                                            <div className="col-md-12">
                                                { 
                                                    !loading && selectedGraph === 2 && <Line options={options3} data={data3} />
                                                } 
                                            </div> 

                                            <div className="col-md-12">
                                                { 
                                                    !loading && selectedGraph === 3 && <Line options={options2} data={data2} />
                                                } 
                                                
                                            </div>
                                          
                                        </div>
                                    </>
                                    }

                                </div>
                            </div> 
                        </div> 
                    </div>
                </div>   
            </>                   

    );
}

export default AnalyticProductsPage