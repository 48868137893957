import { FC, useEffect, useRef, useState } from 'react'
import {
    Link
} from "react-router-dom";
import Breadcrumb from '../../Components/Breadcrumb';


import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 

import SalaryType from "../../Models/SalaryType"

import DataTable from 'react-data-table-component';

import Alert from '../../Components/Alert';
import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';


// type FormValues = Gamme;

//Alert
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import SalaryService from './service';
import { useTranslation } from 'react-i18next';

const SalaryTypePage: FC = () => {  

    // const alert = useAppSelector((state: RootState ) => state.alerts );
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const formRef = useRef< FormikProps<SalaryType>>(null);

    const [
        loading, setLoading
    ] = useState(false);

    const [
        types,
        setTypes
    ] = useState([]);

    const initialSelectedGamme: any = null

    const [
        selectedType,
        setSelectedType
    ] = useState(initialSelectedGamme);

    const [
        deleteLoading, setDeleteLoading
    ] = useState(false);

   const id: Number = 0;
    const [
        deleteId, setDeleteId
    ] = useState(id);


    const salaryService = new SalaryService();

    const deleteSalaryType = async (salaryId: Number) => {
        setDeleteId(salaryId);
        setDeleteLoading(true);

        confirmAlert({
            overlayClassName: "alert-overlay",
            customUI: ({ onClose }) => {
              return (
                    <Alert 
                        alert={{
                            title: t('Confirmer'),
                            message: t('Vous confirmez la suppression de ce type ?'),
                            actionText: `${t('Valider')}`,
                            action: async () => {
                                await salaryService.deleteSalaryType(salaryId).then(async function (response: any) {
                                    console.log(response); 
                                   
                                    getSalaryTypes();
                                    setDeleteLoading(false); 

                                    confirmAlert({
                                        overlayClassName: "alert-overlay",
                                        customUI: ({ onClose }) => {
                                          return (
                                                <Alert 
                                                    alert={{
                                                        title: t('Succès'),
                                                        message: t('Type de salaire supprimé avec succès'),
                                                        actionText: 'Ok',
                                                        action: () => {
                                                            onClose();
                                                        }
                                                    }}
                                                />
                                          );
                                        }
                                    });

                                })
                                  .catch(function (error: any) {
                                    console.log(error);
                                    setDeleteLoading(false); 
                                });
                                onClose();
                            },
                            cancelText: `${t('Annuler')}`,
                            cancelAction: () => {
                                setDeleteLoading(false); 
                                onClose();
                            }
                        }}
                    />
              );
            }
        });

    }

    const getSalaryTypes = () => {
        setLoading(true);
        salaryService.getSalaryTypes().then(async function (response: any) {
            console.log(response); 
            setTypes(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row.id
        },
        {
            name: t('Libellé'),
            selector: (row: any) => row.libelle,
        },
        {
            name: t('Description'),
            selector: (row: any) => row.description,
        }, 
        {
            name: "Options", 
            selector: (row: any) => (
                <div className='btn-list'>
                    <button type="button" onClick={event => {
                        setSelectedType(SalaryType.fromJson(row) );
                        if ( formRef.current != null) {
                            formRef.current.values.id = row.id;
                            formRef.current.values.libelle =  row.libelle;
                            formRef.current.values.description = row.description;
                        }  
                    }}
                        className="btn waves-effect waves-light btn-success">
                            <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-pencil"></i></span>
                    </button>
                    <button type="button" onClick={event => {  deleteSalaryType(row.id)  }}
                        className="btn waves-effect waves-light btn-danger">
                            {
                                deleteId == row.id && deleteLoading ?
                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> :
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-trash"></i></span>
                            }
                    </button>
                </div>
            )
        }
    ];


    const cancel = () => {

        setSelectedType(null);

        if ( formRef.current != null) {
            formRef.current.values.id = null;
            formRef.current.values.libelle =  '';
            formRef.current.values.description = '';
        } 
    }

    useEffect(() => {
        getSalaryTypes();
    }, []);



    return ( 
        <>  
            <Breadcrumb />

            <div className="container-fluid">

                <div className="row">
                    <div className="col-md-5">
                        <div className="card">
                            <div className="card-body">

                                <h4 className="card-title">
                                    {
                                        selectedType == null ? 
                                        t("Ajouter un type") :
                                        t("Mettre à jour type")
                                    }
                                </h4>

                                <Formik
                                    initialValues={
                                        {
                                        id: null,
                                        libelle: '',
                                        description: ''
                                    }}
                                    validationSchema={
                                        yup.object().shape({
                                            libelle: yup
                                                .string() 
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            description: yup
                                                .string()
                                        })
                                    }
                                    onSubmit={async (
                                        values 
                                    ) => {

                                        setLoading(true);

                                        if(selectedType == null) {

                                            await salaryService.postSalaryType( SalaryType.fromJson(values) ).then(async function (response: any) {
                                                console.log(response);

                                                confirmAlert({
                                                    overlayClassName: "alert-overlay",
                                                    customUI: ({ onClose }) => {
                                                      return (
                                                            <Alert 
                                                                alert={{
                                                                    title: t('Succès'),
                                                                    message: t('Type salaire créé avec succès'),
                                                                    actionText: 'Ok',
                                                                    action: () => {
                                                                        onClose();
                                                                    }
                                                                }}
                                                            />
                                                      );
                                                    }
                                                });
 
                                                if ( formRef.current != null) {
                                                    formRef.current.values.id = null;
                                                    formRef.current.values.libelle =  '';
                                                    formRef.current.values.description = '';
                                                } 
                                                getSalaryTypes();
                                                setLoading(false); 
                                            })
                                            .catch(function (error: any) {
                                                setLoading(false); 
                                            });

                                        }  else {

                                            await salaryService.putSalaryType( SalaryType.fromJson(values) ).then(async function (response: any) {
                                                console.log(response); 
                                                confirmAlert({
                                                    overlayClassName: "alert-overlay",
                                                    customUI: ({ onClose }) => {
                                                      return (
                                                            <Alert 
                                                                alert={{
                                                                    title: t('Succès'),
                                                                    message: t('Type salaire mis à jour avec succès'),
                                                                    actionText: 'Ok',
                                                                    action: () => {
                                                                        onClose();
                                                                    }
                                                                }}
                                                            />
                                                      );
                                                    }
                                                }); 

                                                if ( formRef.current != null) {
                                                    formRef.current.values.id = null;
                                                    formRef.current.values.libelle =  '';
                                                    formRef.current.values.description = '';
                                                } 

                                                setSelectedType(null);

                                                getSalaryTypes();
                                                setLoading(false); 
                                            })
                                            .catch(function (error: any) {
                                                setLoading(false); 
                                            });

                                        }

                                        
          
                                    }} 
                                    innerRef={formRef}
                                    >
                                        {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                                    <Form>
                                                        <div className="form-body">
                                                            
                                                            <div className="row">
                                                                
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label> { t('Libellé') } </label>
                                                                        <input type="text"
                                                                            className={`form-control ${ errors.libelle && touched.libelle ? "is-invalid":""}`}
                                                                            id="libelle" 
                                                                            name="libelle" 
                                                                            onChange={handleChange('libelle')}
                                                                            onBlur={handleBlur('libelle')}
                                                                            value={values.libelle}
                                                                            placeholder={ t('Libellé') ?? '' } />
                                                                        { errors.libelle && touched.libelle && errors.libelle && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                            { errors.libelle && touched.libelle && errors.libelle }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                            
                                                            <div className="row">
                                                                
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label> { t('Description') } </label>
                                                                        <textarea rows={6}
                                                                            className={`form-control ${ errors.description
                                                                                 && touched.description ? "is-invalid":""}`}
                                                                            id="description" 
                                                                            name="description" 
                                                                            onChange={handleChange('description')}
                                                                            onBlur={handleBlur('description')}
                                                                            value={values.description}
                                                                            placeholder={ t('Description') ?? ''} />
                                                                        { errors.description && touched.description && errors.description && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                            { errors.description && touched.description && errors.description }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                        </div>

                                                        <div className="form-actions">
                                                            <div className="text-right">
                                                                <button  disabled={!isValid} type="submit" className="btn btn-info">
                                                                    { t('Valider') }
                                                                    {
                                                                        loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    }
                                                                </button>
                                                                <button type="button" onClick={event => cancel()}
                                                                 className="btn btn-dark">
                                                                    { t('Annuler') }
                                                                 </button>
                                                            </div>
                                                        </div>

                                                    </Form>
                                              )}
                                    </Formik>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">
                                    {
                                        t('Liste types salaire')
                                    }
                                </h4>
                                <div className="btn-list">
                                    <button type="button" onClick={ event => getSalaryTypes() }
                                        className="btn waves-effect waves-light btn-success">
                                            { t('Actualiser') }
                                        </button>
                                </div> 
                                <div className="table-responsive">
                                    <DataTable
                                        responsive={true}
                                        className="table table-striped table-bordered"
                                        columns={columns}
                                        data={types}
                                        progressPending={loading}
                                        pagination
                                    />
                                </div>
                             </div>
                        </div> 
                    </div> 
                </div>

            </div>   
        </>                   
    );
}

export default SalaryTypePage