import { FC, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import AnalyticService from './service';

import {
    Link, useNavigate
} from "react-router-dom";
import UserRight from '../../Utils/userRight';

// Moment react
import Moment from 'react-moment';

// Formik
import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 
import { useTranslation } from 'react-i18next';

import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);




const AnalyticResultPage: FC = () => {  

    let idToModInit: any = null;

    const { t, i18n } = useTranslation();
    const analDataInit: any = null;
    const [analData, setAnalData] = useState(analDataInit);
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Analytics produit, type de produit, gamme.',
          },
        },
    };

      const options2 = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Analytics visites, ventes, interêt, taxes.',
          },
        },
      };
      
      let labels: Array<String> = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October',
      'November', 'December'];

      const monthLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October',
      'November', 'December'];
      const weekLabels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ];
      const dayLabels = [ '1', '2', '3', '4', '5', '6', '7', '8', 
      '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27',
      '28', '29', '30', '31' ];
      
      const data = {
        labels,
        datasets: [
          {
            label: 'Produit',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            label: 'Type de produit',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
          {
              label: 'Gamme',
              data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
              backgroundColor: 'rgba(249, 146, 92, 0.5)',
            },
        ],
      };


      const data2 = {
        labels,
        datasets: [
          {
            label: 'Visites',
            data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            label: 'Ventes',
            data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
          {
            label: 'Interêt',
            data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: 'rgb(249, 146, 92)',
            backgroundColor: 'rgba(249, 146, 92, 0.5)',
          },
          {
            label: 'Taxes',
            data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
            borderColor: 'rgb(54, 221, 51)',
            backgroundColor: 'rgba(54, 221, 51, 0.5)',
          },
        ],
      };
      

    const navigate = useNavigate();

    const periods = [
        'day',
        "month",
        'year'
    ]

    useEffect( function() { 
        let analData = JSON.parse(window.localStorage.getItem('analData') ?? '');
        if (analData !== null && analData !== undefined) { 
            console.log(analData);
            setAnalData(analData);
            switch (analData?.period) {
                case 'day':
                    labels = weekLabels;
                    break;
                case 'month':
                    labels = dayLabels;
                    break;
                case 'year':
                    labels = monthLabels;
                    break;
            
                default:
                    break;
            }
        } else {
            navigate('/analytics');
        }
        // getSpents();
    }, []);
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    
                                    <div className="row"> 
                                        <div className="col-md-12">

                                        <div className="card-group">
                                            <div className="card border-right">
                                                <div className="card-body">
                                                    <div className="d-flex d-lg-flex d-md-block align-items-center">
                                                        <div>
                                                            {}
                                                            <div className="d-inline-flex align-items-center">
                                                                <h2 className="text-dark mb-1 font-weight-medium">
                                                                    { t('Date de début') }
                                                                </h2>
                                                                {/* <span
                                                                    className="badge bg-primary font-12 text-white font-weight-medium
                                                                    badge-pill ml-2 d-lg-block d-md-none">+18.33%</span> */}
                                                            </div>
                                                            <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                                                                {
                                                                   analData?.dteDeb
                                                                }
                                                            </h6>
                                                        </div>
                                                        {/* <div className="ml-auto mt-md-3 mt-lg-0">
                                                            <span className="opacity-7 text-muted fs-20" style={{ fontSize: "2em" }} >
                                                                <i className="icon-basket text-success"></i></span>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card border-right">
                                                <div className="card-body">
                                                    <div className="d-flex d-lg-flex d-md-block align-items-center">
                                                        <div>
                                                            <h2 className="text-dark mb-1 w-100 text-truncate font-weight-medium">
                                                                { t('Date de fin') }
                                                            </h2>
                                                            <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                                                                {
                                                                    analData?.dteFin
                                                                }
                                                            </h6>
                                                        </div>
                                                        {/* <div className="ml-auto mt-md-3 mt-lg-0">
                                                            <span className="opacity-7 text-muted fs-20" style={{ fontSize: "2em" }} >
                                                                <i className="icon-list text-danger"></i></span>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card border-right">
                                                <div className="card-body">
                                                    <div className="d-flex d-lg-flex d-md-block align-items-center">
                                                        <div>
                                                            <div className="d-inline-flex align-items-center">
                                                                <h2 className="text-dark mb-1 font-weight-medium">
                                                                    { t('Période') }
                                                                </h2>
                                                                
                                                            </div>
                                                            <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                                                                {
                                                                   t(t('analyticPeriod', { analyticPeriodName: analData?.period } ) ?? '') 
                                                                }
                                                            </h6>
                                                        </div>
                                                        {/* <div className="ml-auto mt-md-3 mt-lg-0">
                                                            <span className="opacity-7 text-muted fs-20" style={{ fontSize: "2em" }} >
                                                                <i className="icon-wallet"></i></span>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card border-right">
                                                <div className="card-body">
                                                    <div className="d-flex d-lg-flex d-md-block align-items-center">
                                                        <div>
                                                            <div className="d-inline-flex align-items-center">
                                                                <h2 className="text-dark mb-1 font-weight-medium">
                                                                    { t('Visite totale') }
                                                                </h2>
                                                                {/* <span
                                                                    className="badge bg-danger font-12 text-white font-weight-medium badge-pill 
                                                                    ml-2 d-md-none d-lg-block">-18.33%</span> */}
                                                            </div>
                                                            <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                                                                {
                                                                    100
                                                                }
                                                            </h6>
                                                        </div>
                                                        {/* <div className="ml-auto mt-md-3 mt-lg-0">
                                                            <span className="opacity-7 text-muted fs-20" style={{ fontSize: "2em" }} >
                                                                <i className="icon-wallet text-info"></i></span>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="d-flex d-lg-flex d-md-block align-items-center">
                                                        <div>
                                                            <h2 className="text-dark mb-1 font-weight-medium">
                                                                { t('Vente totale') }
                                                            </h2>
                                                            <h6 className="text-muted font-weight-normal mb-0 w-100 text-truncate">
                                                                {
                                                                    '100 000 Fcfa' 
                                                                }
                                                            </h6>
                                                        </div>
                                                        {/* <div className="ml-auto mt-md-3 mt-lg-0">
                                                            <span className="opacity-7 text-muted fs-20" style={{ fontSize: "2em" }} >
                                                                <i className="icon-graph text-warning"></i></span>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                        <br />
                                        <Bar options={options} data={data} />
                                        <br/>
                                        <br/>
                                        <br/>
                                        <Line options={options2} data={data2} />
                          
                                        </div>
                                    </div>

                                </div>
                            </div> 
                        </div> 
                    </div>
                </div>   
            </>                   

    );
}

export default AnalyticResultPage