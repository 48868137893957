import User from '../../Models/User';
import axiosInstance from '../../Utils/axios_manager';

export default class AmortizmentService {
  
    constructor( ) {
    }
 

    public getTemplates =  async () => {
        return await axiosInstance.get('/template/list');
    }

    public getCarAmortizments =  async () => {
        return await axiosInstance.get('/amortization/cars/list');
    }

    public getOtherAmortizments =  async () => {
        return await axiosInstance.get('/amortization/list');
    }

    public postAmortizment =  (data: Record<string, any>) => {
        return axiosInstance.post('/amortization/add', data);
    }

    public putAmortizment =  (data: Record<string, any>) => {
        return axiosInstance.post('/amortization/update',data);
    }

    public getTemplate =  async (id: Number) => {
        return await axiosInstance.get('/template/details' + `?idTemp=${id.toString()}`);
    }

    public sendPromotionMail =  (data: Record<string, any>) => {
        return axiosInstance.post('/template/sendmail/promo', data);
    }

    public getPrintInformation =  async (id: Number) => {
        return await axiosInstance.get('/template/print/informations' + `?idPromo=${id.toString()}`);
    }

    public getAmortizmentContents =  async (id: Number) => {
        return await axiosInstance.get('/amortization/content/list' + `?idAmort=${id.toString()}`);
    }


    public deleteAmortization =  (id: Number) => {
        return axiosInstance.delete('/amortization/delete' + `?idAmort=${id.toString()}`);
    }
  

}