import { FC, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import SpentService from './service';

import {
    Link, useNavigate
} from "react-router-dom";
import UserRight from '../../Utils/userRight';

//Moment react
import Moment from 'react-moment';

import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

const ComptaResult: FC = () => {  

    let anyVar: any = null;

    const { t } = useTranslation();

    const [ comptaDetail, setComptaDetail ] = useState( anyVar );
    const [ tempProducts, setTempProducts ] = useState( [] ); 
    const [ loading, setLoading ] = useState(false);

    const [ searchStr, setSearchStr ] = useState('');
    const [ searchLoading, setSearchLoading ] = useState(false);

    const spentService = new SpentService();

    const navigate = useNavigate();

    const columns = [
        {
            name: t('Produit'),
            selector: (row: any) => row.produitDetails.libProduit,
        },
        {
            name: t('Prix unitaire'),
            selector: (row: any) => row.montantLigne,
        }, 
        {
            name: t('Quantité vendue'),
            selector: (row: any) => row.quantites,
        },
        {
            name: t('Total'),
            selector: (row: any) => row.montant
        }
    ];

    useEffect( function() { 
        let comptaDetail = JSON.parse(window.localStorage.getItem('compta') ?? '');
        if (comptaDetail !== null && comptaDetail !== undefined) {
            setComptaDetail(comptaDetail);
        } else {
            navigate('/compta/ventes');
        }
    }, []);
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-list"> 
                                        <Link type="button" to="/compta/ventes"
                                            className="btn waves-effect waves-light btn-danger">
                                            { t('Retour') }
                                        </Link>
                                    </div> 
                                    <br/>
                                    { comptaDetail != null ?
                                    <div>
                                        <div className="card-group">
                                            <div className="card border-right">
                                                <div className="card-body">
                                                    <div className="d-flex d-lg-flex d-md-block align-items-center">
                                                        <div>
                                                            <div className="d-inline-flex align-items-center">
                                                                <h2 className="text-dark mb-1 font-weight-medium">
                                                                    { t('Capital Total') }
                                                                </h2>
                                                                {/* <span
                                                                    className="badge bg-primary font-12 text-white font-weight-medium
                                                                    badge-pill ml-2 d-lg-block d-md-none">+18.33%</span> */}
                                                        </div>
                                                        <h3 className="text-muted font-weight-normal 
                                                        mb-0 w-100 text-truncate">
                                                            {/* <CountUp start={0} delay={2} end={100} /> */}
                                                        </h3>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ******* */}
                                            <div className="card border-right">
                                                <div className="card-body">
                                                    <div className="d-flex d-lg-flex d-md-block align-items-center">
                                                        <div>
                                                            <div className="d-inline-flex align-items-center">
                                                                <h2 className="text-dark mb-1 font-weight-medium">
                                                                    { t('Vente Totale') }
                                                                </h2>
                                                                {/* <span
                                                                    className="badge bg-primary font-12 text-white font-weight-medium
                                                                    badge-pill ml-2 d-lg-block d-md-none">+18.33%</span> */}
                                                        </div>
                                                        <h3 className="text-muted font-weight-normal 
                                                        mb-0 w-100 text-truncate">
                                                            {/* <CountUp start={0} delay={2} end={100} /> */}
                                                        </h3>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ********* */}
                                            <div className="card border-right">
                                                <div className="card-body">
                                                    <div className="d-flex d-lg-flex d-md-block align-items-center">
                                                        <div>
                                                            <div className="d-inline-flex align-items-center">
                                                                <h2 className="text-dark mb-1 font-weight-medium">
                                                                    { t('Interêt Total') }
                                                                </h2>
                                                                {/* <span
                                                                    className="badge bg-primary font-12 text-white font-weight-medium
                                                                    badge-pill ml-2 d-lg-block d-md-none">+18.33%</span> */}
                                                        </div>
                                                        <h3 className="text-muted font-weight-normal 
                                                        mb-0 w-100 text-truncate">
                                                            {/* <CountUp start={0} delay={2} end={100} /> */}
                                                        </h3>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <DataTable
                                                responsive={true}
                                                className="table table-striped table-bordered"
                                                columns={columns}
                                                data={comptaDetail?.ventes}
                                                progressPending={loading}
                                                pagination
                                            /> 
                                        </div>
                                    </div> : <div className="spinner-border text-light spinner-border-sm" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> }
                                </div>
                            </div> 
                        </div> 
                    </div>
                </div>   
            </>                   

    );
}

export default ComptaResult