import { FC, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import ProductService from './service';

import {
    Link, useNavigate
} from "react-router-dom";
import UserRight from '../../Utils/userRight';
import { useTranslation } from 'react-i18next';

const ProductList: FC = () => {  

    let idToModInit: any = null;

    const [ products, setProducts ] = useState( [] );
    const [ tempProducts, setTempProducts ] = useState( [] );
    const [ idToMod, setIdToMod ] = useState(idToModInit);
    const [ loading, setLoading ] = useState(false);

    const [ searchStr, setSearchStr ] = useState('');
    const [ searchLoading, setSearchLoading ] = useState(false);

    const productService = new ProductService();

    const userRight = new UserRight();

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    // let editAgent = (id: Number): void => {
    //     setIdToMod(id);
    //     setLoading(true);
    //     navigate('/agent/edit/'+id.toString());
    //     setLoading(false);
    // }

    const getProducts = () => {
        setLoading(true);
        productService.getProducts().then(async function (response: any) {
            console.log(response);
            setProducts(response.data);
            setTempProducts(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    useEffect( function() { 
        getProducts();
    }, []);

    const columns = [
        {   
            name: `${t('Libellé')}`,
            selector: (row: any) => row.gamme?.libGamme + ' - ' + row.libProduit + ' - ' + row.type?.libType,
            wrap: true, 
        },  
        {
            name: `${t('Prix unitaire')}`,
            selector: (row: any) => row.prixProd,
        },
        {
            name: `${t("Stock disponible")}` ,
            selector: (row: any) => row.qteStock,
        },
        {
            name: `${t("Capital Unitaire Produit")}`,
            omit: !userRight.hasRight(['administrateur']),
            selector: (row: any) => row.capitalUnitaireProduit,
        },
        {
            name: `${t("Interet Unitaire Produit")}` ,
            omit: !userRight.hasRight(['administrateur']),
            selector: (row: any) => row.interetUnitaireProduit,
        },
        {
            name: `${t("Options")}` ,
            omit: !userRight.hasRight(['administrateur']),
            selector: (row: any) => (
                <div className='btn-list'>
                    <button type="button" onClick={event => {
                        navigate('/product/edit/'+row.id.toString());
                     }}
                        className="btn waves-effect waves-light btn-success">
                            <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-pencil"></i></span>
                    </button>
                    <button type="button" onClick={event => {  }}
                        className="btn waves-effect waves-light btn-danger"> 
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-trash"></i></span>
                             
                    </button>
                </div>
            )
        } 
    ];
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-list">
                                        <button type="button" onClick={ event => getProducts() }
                                            className="btn waves-effect waves-light btn-danger">
                                                { t('Actualiser') }
                                            </button>
                                        <Link type="button" to="/product/create"
                                            className="btn waves-effect waves-light btn-primary">
                                                { t('Ajouter un produit') }
                                        </Link>
                                    </div>
                                    
                                    <br />
                                    
                                    <div className='input-group'>
                                         
                                        <input type="text"
                                            className={`form-control`}
                                            id="searchStr"  
                                            onChange={event => { 
                                                setSearchLoading(true);
                                                setLoading(true);
                                                if (event.target.value == null || event.target.value == undefined ||
                                                    event.target.value == '') {
                                                        setProducts(tempProducts);
                                                } else {
                                                    productService.searchByBarcodeOrName(event.target.value).then(async function (response: any) {
                                                        console.log(response);
                                                        if (response.data.length > 0) {
                                                            setProducts(response.data);
                                                        } 
                                                        setSearchLoading(false); 
                                                        setLoading(false);
                                                    })
                                                    .catch(function (error: any) {
                                                        console.log(error);
                                                        setSearchLoading(false);
                                                        setLoading(false);
                                                    });
                                                }
                                            }} 
                                            placeholder={ `${ t("Chercher un produit (nom ou barcode)") }` }/>
                                            {
                                                searchLoading && <div className="input-group-append">
                                                    <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            }
                                            
                                    </div>

                                    <br />
                                    
                                    <div className="table-responsive">
                                        <DataTable
                                            responsive={true}
                                            className="table table-striped table-bordered"
                                            columns={columns}
                                            data={products}
                                            progressPending={loading}
                                            pagination
                                        />
                                    </div>
                                </div>
                            </div> 
                        </div> 
                    </div>
                </div>   
            </>                   

    );
}

export default ProductList