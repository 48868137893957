export default class Type {
    public id?: number | null;
    public libType: string;
    public descriptionType?: string;


    static fromJson(parsedJson: Record<string, any>): Type {
        return new Type(
            Number(parsedJson['id']),
            parsedJson['libType'],
            parsedJson['descriptionType']
        );
    }

    static toJson(Type: Type): Record<string, any>  {
        return  {
            "id": Type.id,
            "libType": Type.libType,
            "descriptionType": Type.descriptionType
        };
    }
  
    constructor(id: number, libType: string, descriptionType: string  ) {
            this.id = id;
            this.libType = libType;
            this.descriptionType = descriptionType;
    }

}