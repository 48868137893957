import { FC } from 'react'
import { useTranslation } from 'react-i18next';
import {
    Link, useParams
} from "react-router-dom";
import Breadcrumb from '../../Components/Breadcrumb';
import VenteForm from './form';


const VenteCreate: FC = () => {  

    let { type } = useParams();
    const {  t } = useTranslation();
    
    return ( 
        <> 
            <Breadcrumb />

            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">{t("Ajouter une vente")} {type}</h4>
                                <Link type="button" to="/vente/list"
                                    className="btn waves-effect waves-light btn-primary">
                                        {
                                            t("Liste des ventes")
                                        }
                                </Link>
                                <br />
                                <br />

                                <VenteForm  formType={type ? type : ''} state='create' vente={null} /> 

                                {/* <h4 className="card-title">Zero Configuration</h4>
                                <h6 className="card-subtitle">DataTables has most features enabled by default, so all you
                                    need to do to use it with your own tables is to call the construction
                                    function:<code> $().DataTable();</code>. You can refer full documentation from here
                                    <a href="https://datatables.net/">Datatables</a></h6> */}
                                 
                                </div>
                            </div> 
                    </div> 
                </div>

            </div>   
        </>                   
    );
}

export default VenteCreate