import { FC, useRef, useState, useEffect } from 'react'
import {
    Outlet
} from "react-router-dom";
import DataTable from 'react-data-table-component';

//Moment react
import Moment from 'react-moment';

import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 
import ConfigurationService from '../Configuration/service';
import ProductService from '../Product/service';
import { useTranslation } from 'react-i18next';

interface MarketManFormProps {
    nomProd: string | null,
    idGamme:  string | null ,
    idType: string | null
}

//Props interface
interface MarketManProps {
    onProductAdd: React.MouseEventHandler<HTMLButtonElement>;
    onProductDelete: React.MouseEventHandler<HTMLButtonElement>;
}

const MarketManForm: FC<MarketManProps> = ( {  onProductAdd, onProductDelete } ) => {  

    const marketManFormRef = useRef< FormikProps< MarketManFormProps >>(null);
    const { t, i18n } = useTranslation();
    let [gammesLoading, setGammesLoading] = useState(false);
    const [
        gammes,
        setGammes
    ] = useState([]);

    let [typesLoading, setTypesLoading] = useState(false);
    const [
        types,
        setTypes
    ] = useState([]);

    let [productsLoading, setProductsLoading] = useState(false);
    const [
        products,
        setProducts
    ] = useState([]);

    const configService = new ConfigurationService();
    const productService = new ProductService();

    const getGammes = () => {
        setGammesLoading(true);
        configService.getGammes().then(async function (response: any) {
            console.log(response); 
            setGammes(response.data);
            setGammesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setGammesLoading(false); 
        });
    }


    const getTypes = () => {
        setTypesLoading(true);
        configService.getTypes().then(async function (response: any) {
            console.log(response); 
            setTypes(response.data);
            setTypesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setTypesLoading(false); 
        });
    }

    const getProducts = () => {
        setProductsLoading(true);
        productService.getFilteredSimpleProduct({nomProd: null, idType: 0, idGamme: 0}).then(async function (response: any) {
            console.log(response); 
            setProducts(response.data);
            setProductsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setProductsLoading(false); 
        });
    }



    useEffect( function() {  
        getGammes();
        getTypes();
        getProducts();
    }, []);



    const columns = [
        {
            name: `${t('Nom du produit')}` ,
            selector: (row: any) => row.libProduit,
        },
        {
            name: `${t("Prix unitaire")}` ,
            selector: (row: any) => row.prixProd
        },
        {
            name: `${t("Stock")}`,
            selector: (row: any) => row.qteStock
        },
        // {
        //     name: 'Date',
        //     selector: (row: any) => <Moment format="YYYY/MM/DD hh:mm:ss">
        //                                 {row.dateInventaire}
        //                             </Moment>
        // },
        {
            name: "Options", 
            selector: (row: any) => (
                <>
                    <button type="button" onClick={event => {  }}
                        className="btn waves-effect waves-light btn-danger"> 
                        {
                            t('Ajouter')
                        }
                    </button> 
                </>
            )
        }
    ];


    return (
        <Formik
        initialValues={
            { 
                nomProd: null,
                idGamme: null,
                idType: null
        }}
        validationSchema={
            yup.object().shape({ 
                nomProd: yup
                    .string() 
                    .required(`${t('Ce champ est obligatoire')}`),
                idGamme: yup
                    .string() 
                    .required(`${t('Ce champ est obligatoire')}`),
                idType: yup
                    .string() 
                    .required(`${t('Ce champ est obligatoire')}`)
            })
        }
        innerRef={marketManFormRef} 
        onSubmit={async (
            values 
        ) => {  }}  
    >
    {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
        <Form>
             <div className="form-group">
                {/* <label>Nom du produit</label> */}
                <input type="text"
                                                        className={`form-control 
                                                        ${ errors.nomProd  && touched.nomProd ? "is-invalid":""}`}
                                                        id="nomProd" 
                                                        name="nomProd"  
                                                        onChange={handleChange('nomProd')}
                                                        onBlur={handleBlur('nomProd')}
                                                        value={  values.nomProd == null ? '' : values.nomProd }
                                                        placeholder={ t("Nom du produit") ?? ''} />
                                                        { errors.nomProd && touched.nomProd && errors.nomProd && 
                                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                                { errors.nomProd && touched.nomProd && errors.nomProd }
                                                            </div> 
                                                        }
            </div>
            <div className="row">
                <div className='col-md-6'>
                    <div className="form-group">
                                                        {/* <label>Gamme</label> */}
                                                        {
                                                            gammesLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm"
                                                             role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                             <Field as="select"  
                                                                className={`form-control custom-select ${ errors.idGamme &&
                                                                    touched.idGamme ? "is-invalid" : "" }`}
                                                                id="idGamme" 
                                                                name="idGamme"
                                                                onChange={(event: any) => {
                                                                    // console.log(event);
                                                                    handleChange('idGamme')(event);
                                                                    // const agent = getAgentById(event.target.value);
                                                                    // console.log(agent);
                                                                    // setSelectedAgent( agent );
                                                                }}
                                                                onBlur={handleBlur('idGamme')}
                                                                value={ values.idGamme }
                                                                placeholder={ t("Gamme" ) ?? '' }>
                                                                <option selected>{ t("Gamme" ) }...</option>
                                                                {
                                                                    gammes.map(
                                                                        (gamme: any, id) => <option 
                                                                         key={id} value={gamme.id}>
                                                                            {
                                                                                gamme.libGamme
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </Field> 
                                                        }
                                                        { errors.idGamme && touched.idGamme && errors.idGamme && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.idGamme && touched.idGamme && errors.idGamme }
                                                        </div> }
                                                    </div>
                </div>
                <div className='col-md-6'>
                <div className="form-group">
                                                        {/* <label>Gamme</label> */}
                                                        {
                                                            typesLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm"
                                                             role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                             <Field as="select"  
                                                                className={`form-control custom-select ${ errors.idType &&
                                                                    touched.idType ? "is-invalid" : "" }`}
                                                                id="idType" 
                                                                name="idType"
                                                                onChange={(event: any) => {
                                                                    // console.log(event);
                                                                    handleChange('idType')(event);
                                                                    // const agent = getAgentById(event.target.value);
                                                                    // console.log(agent);
                                                                    // setSelectedAgent( agent );
                                                                }}
                                                                onBlur={handleBlur('idType')}
                                                                value={ values.idType }
                                                                placeholder={ t("Type") ?? '' } >
                                                                <option selected>{ t("Type") ?? '' }...</option>
                                                                {
                                                                    types.map(
                                                                        (type: any, id) => <option 
                                                                         key={id} value={type.id}>
                                                                            {
                                                                                type.libType
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </Field> 
                                                        }
                                                        { errors.idType && touched.idType && errors.idType && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.idType && touched.idType && errors.idType }
                                                        </div> }
                                                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="table-responsive">
                                            <DataTable
                                                responsive={true}
                                                className="table table-striped table-bordered"
                                                columns={columns}
                                                data={ products }
                                                progressPending={ productsLoading } 
                                                pagination
                                            />
                    </div>
                </div>
            </div>
        </Form>
    )}
    </Formik>
    );
}

export default MarketManForm