import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import {
    Link, useLocation, useNavigate, useParams
} from "react-router-dom";
import Breadcrumb from '../../Components/Breadcrumb';
import TemplateForm from './form'; 
import TemplateService from './service';


const TemplateEdit: FC = () => {  
    const location: any = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const templateService = new TemplateService();

    let { id } = useParams();

    const [template, setTemplate] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        templateService.getTemplate(Number(id)).then(async function (response: any) {
            console.log(response); 
            setTemplate(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }, []);

    return ( 
        <> 
            <Breadcrumb />

            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">
                                    { t('Modifier un template') }
                                </h4>
                                <Link type="button" to="/template/list"
                                    className="btn waves-effect waves-light btn-primary">
                                    { t('Liste des templates') }    
                                </Link>
                                <br />
                                <br />

                                { !loading && template ?
                                    <TemplateForm state='edit' template={ template } />  
                                    :
                                    <div className="spinner-border text-primary spinner-border-lg" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }     
                                 
                                </div>
                            </div> 
                    </div> 
                </div>

            </div>   
        </>                   
    );
}

export default TemplateEdit