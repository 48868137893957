import { FC, useEffect, useRef, useState } from 'react'
import {
    Link, useNavigate 
} from "react-router-dom";

import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 

import SpentService from './service';
import User from '../../Models/User';
import Spent from '../../Models/Spent';
import ShopService from '../Shop/service';
import AgentService from '../Agent/service';

import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
import { RootState } from '../../Redux/store';

//Alert
import Alert from '../../Components/Alert';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { useTranslation } from 'react-i18next';

interface SpentFormProps {
    state: string;
    spent?: any | null; 
}

const SpentForm: FC<SpentFormProps> = ( {  state, spent } ) => {
   

    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const formRef = useRef<FormikProps< Spent >>(null);

    const user = useAppSelector((state: RootState ) => state.users.user );

    let [image, setImage] = useState('');

    let [loading, setLoading] = useState(false);

    const spentService = new SpentService();

    const [ agents, setAgents ] = useState( [] );

    const [ shops, setShops ] = useState( [] );

    const [ spentTypes, setSpentTypes ] = useState( [] );

    const [
        agentsLoading, setAgentsLoading
    ] = useState(false);

    const [
        shopsLoading, setShopsLoading
    ] = useState(false);

    const [
        spentTypesLoading, setSpentTypesLoading
    ] = useState(false);

    const shopService = new ShopService();
    const agentService  = new AgentService();
    
   
    
    const getShops = () => {
        setShopsLoading(true);
        shopService.getShops().then(async function (response: any) {
            console.log(response);
            setShops(response.data);
            setShopsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setShopsLoading(false); 
        });
    }

    const getAgents = () => {
        setAgentsLoading(true);
        agentService.getAgents().then(async function (response: any) {
            console.log(response);
            setAgents(response.data);
            setAgentsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setAgentsLoading(false); 
        });
    }

    const getSpentTypes = () => {
        setSpentTypesLoading(true);
        spentService.getSpentTypes().then(async function (response: any) {
            console.log(response); 
            setSpentTypes(response.data);
            setSpentTypesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setSpentTypesLoading(false); 
        });
    }



    useEffect( () => {

        if (state === 'view') {
            if (formRef.current !== null ) {
                // formRef.current.di
            }
        }

        getShops();
        getSpentTypes();

    }, []);
    
    

    return (
        <Formik
        initialValues={
            spent != null  && spent != undefined ? 
            Spent.fromJson({   
                id: Number(spent.id),
                idDep: spent.depense.id,
                idBoutique: spent?.boutique.id,
                idAgent: Number(spent.agent.id),
                montantDep: spent.montant,
                dateDep: new Date(spent.dateDepense),
                descriptionDep: spent.descLigneDepense
            }):
            {
                id: null,
                idDep:  "",
                idBoutique: "",
                idAgent: user?.id,
                montantDep: "",
                dateDep: null,
                descriptionDep: ""
        }}
        validationSchema={
            yup.object().shape({
                idDep: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                idBoutique: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                montantDep: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                dateDep: yup
                    .date()
                    .required(`${t('Ce champ est obligatoire')}`),
                descriptionDep: yup
                    .string()
            })
        }
        
        onSubmit={async (
            values 
          ) => {
                    console.log(values);
                    setLoading(true);

                    // if (image != null && image != '' ) {
                    //     values.photoProfil = image;
                    // }

                    if (state == 'create') {
                        spentService.postSpents( Spent.fromJson(values)).then(async function (response: any) {
                            console.log(response); 
                            confirmAlert({
                                overlayClassName: "alert-overlay",
                                customUI: ({ onClose }) => {
                                  return (
                                        <Alert 
                                            alert={{
                                                title: t('Succès'),
                                                message: t('Dépense créé avec succès'),
                                                actionText: 'Ok',
                                                action: () => {
                                                    onClose();
                                                }
                                            }}
                                        />
                                  );
                                }
                            });
                            navigate('/depense/list');
                            setLoading(false); 
                        })
                        .catch(function (error: any) {
                            console.log(error);
                            confirmAlert({
                                overlayClassName: "alert-overlay",
                                customUI: ({ onClose }) => {
                                  return (
                                        <Alert 
                                            alert={{
                                                type: 'error',
                                                title: t('Erreur'),
                                                message: t('Une erreur s\'est produite, réesseyez'),
                                                actionText: 'Ok',
                                                action: () => {
                                                    onClose();
                                                }
                                            }}
                                        />
                                  );
                                }
                            });
                            setLoading(false); 
                        });
                    } 
                    // else if (state == 'edit') {
                    //     clientService.putClient( User.fromJson(values)).then(async function (response: any) {
                    //         console.log(response);
                    //         alert("Client mise à jour avec succès");  
                    //         navigate('/client/list');
                    //         setLoading(false); 
                    //     })
                    //       .catch(function (error: any) {
                    //         console.log(error);
                    //         alert("Une erreur s'est produite réesseyez s'il vous plaît.")
                    //         setLoading(false); 
                    //     });
                    // }
                    
                }}
                innerRef={formRef}
                >
                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                        <Form>
                                    <div className="form-body">
                                         
                                        
                                        <div className="row">
                                            <div className="col-md-12">
                                                    <div className="form-group">
                                                                        <label>
                                                                            { t('Boutique') } 
                                                                        </label>
                                                                        {
                                                                            shopsLoading ? 
                                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                                            <span className="sr-only">Loading...</span>
                                                                            </div> :
                                                                            <select  
                                                                                className={`form-control custom-select ${ errors.idBoutique &&
                                                                                    touched.idBoutique ? "is-invalid" : "" }`}
                                                                                id="idBoutique" 
                                                                                name="idBoutique"
                                                                                onChange={event => { 
                                                                                    handleChange('idBoutique')(event); 
                                                                                }}
                                                                                onBlur={handleBlur('idBoutique')}
                                                                                value={values.idBoutique == null ? '' : values.idBoutique}
                                                                                placeholder={ t('Boutique') ?? '' }  >
                                                                                <option selected>{ t('Boutique') }...</option>
                                                                                {
                                                                                    shops.map(
                                                                                        (shop: any, id) => <option 
                                                                                        key={id} value={shop.id}>
                                                                                            {
                                                                                                shop.libBoutique
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                }
                                                                            </select> 
                                                                        }
                                                    </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label> { t('Type dépense') } </label>
                                                                        {
                                                                            spentTypesLoading ? 
                                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                                            <span className="sr-only">Loading...</span>
                                                                            </div> :
                                                                            <select  
                                                                                className={`form-control custom-select ${ errors.idDep &&
                                                                                    touched.idDep ? "is-invalid" : "" }`}
                                                                                id="idDep" 
                                                                                name="idDep"
                                                                                onChange={event => { 
                                                                                    handleChange('idDep')(event); 
                                                                                }}
                                                                                onBlur={handleBlur('idDep')}
                                                                                value={values.idDep == null ? '' : values.idDep}
                                                                                placeholder="Type dépense" >
                                                                                <option selected>{ t('Type dépense') }...</option>
                                                                                {
                                                                                    spentTypes.map(
                                                                                        (spentType: any, id) => <option 
                                                                                        key={id} value={spentType.id}>
                                                                                            {
                                                                                                spentType.libDepense
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                }
                                                                            </select> 
                                                                        }
                                                </div>
                                            </div>
                                        </div>

                                        
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                        <label> { t('Date de la dépense') } </label>
                                                        <Field type="date"
                                                                                className={`form-control ${ errors.dateDep
                                                                                     && touched.dateDep ? "is-invalid":""}`}
                                                                                id="dateDep" 
                                                                                name="dateDep"
                                                                                onChange={handleChange('dateDep')}
                                                                                onBlur={handleBlur('dateDep')}
                                                                                value={values.dateDep == null ? new Date()  :
                                                                                     values.dateDep }
                                                                                placeholder={ t("Date début") ?? '' } />
                                                                            { errors.dateDep && touched.dateDep && errors.dateDep && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                { errors.dateDep && touched.dateDep && errors.dateDep }
                                                                        </div> }
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                                                
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label> { t('Montant') } </label>
                                                                        <input type="text"
                                                                            className={`form-control ${ errors.montantDep
                                                                                 && touched.montantDep ? "is-invalid":""}`}
                                                                            id="montantDep" 
                                                                            name="montantDep" 
                                                                            onChange={handleChange('montantDep')}
                                                                            onBlur={handleBlur('montantDep')}
                                                                            value={values.montantDep == null ? ' ' : values.montantDep}
                                                                            placeholder={ t('Montant') ?? ''} />
                                                                        { errors.montantDep && touched.montantDep && errors.montantDep && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                            { errors.montantDep && touched.montantDep && errors.montantDep }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                            
                                                            <div className="row">
                                                                
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label>
                                                                            { t('Description') }
                                                                        </label>
                                                                        <textarea rows={6}
                                                                            className={`form-control ${ errors.descriptionDep
                                                                                 && touched.descriptionDep ? "is-invalid":""}`}
                                                                            id="descriptionDep" 
                                                                            name="descriptionDep" 
                                                                            onChange={handleChange('descriptionDep')}
                                                                            onBlur={handleBlur('descriptionDep')}
                                                                            value={values.descriptionDep == null ? '' : values.descriptionDep}
                                                                            placeholder={ t('Description') ?? ''} />
                                                                        { errors.descriptionDep && touched.descriptionDep && 
                                                                        errors.descriptionDep && 
                                                                        <div id="validationServer05Feedback" 
                                                                        className="invalid-feedback">
                                                                            {   errors.descriptionDep && touched.descriptionDep && 
                                                                                errors.descriptionDep }
                                                                        </div> }
                                                                    </div>
                                                                </div>

                                                            </div> 



                                    </div>
                                    <div className="form-actions">
                                        <div className="text-right">
                                            <button  disabled={(!dirty && !isValid) || state === 'view' } type="submit" className="btn btn-info">
                                                { t('Valider') }
                                                {
                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                            <button disabled={ state === 'view' } type="reset" className="btn btn-dark">{ t('Annuler') }</button>
                                        </div>
                                    </div>
                        </Form>
                    )}
                </Formik>
    );
}

export default SpentForm