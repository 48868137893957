import { FC } from 'react'
import {
    Outlet
} from "react-router-dom";


const TaxePage: FC = () => {  
    return (
        <Outlet />
    );
}

export default TaxePage