export default class Gamme {
    public id?: number | null;
    public libGamme: string;
    public descriptionGamme?: string;


    static fromJson(parsedJson: Record<string, any>): Gamme {
        return new Gamme(
            Number(parsedJson['id']),
            parsedJson['libGamme'],
            parsedJson['descriptionGamme']
        );
    }

    static toJson(gamme: Gamme): Record<string, any>  {
        return  {
            "id": gamme.id,
            "libGamme": gamme.libGamme,
            "descriptionGamme": gamme.descriptionGamme
        };
    }
  
    constructor(id: number, libGamme: string, descriptionGamme: string  ) {
            this.id = id;
            this.libGamme = libGamme;
            this.descriptionGamme = descriptionGamme;
    }

}