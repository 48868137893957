export default class Shop {

    public id?: number;
    public libBoutique?: string;
    public adresseBoutique?: string;
    public adresseGpsBoutique?: string;
    public codeBoutique?: string;
    public contactBoutique?: string;
    public taxeBoutique?: string;
    public localCurrency?: string;
    public usdEqInLocal?: string;
    public contratBail?: string;
    public paysID?: string;
    public nbreAnneeService?: string;
    public ageLimiteTravail?: string;
    public boutiqueDefautPays?: boolean;
    public montantLoyerMensuel?: string;
    public nvlDatePaymentLoyer?: string;
    public adminDocGalID?: string;

    public gazFourn?: string;
    public eauFourn?: string;
    public electricFourn?: string;
    public telFourn?: string;
    public internetFourn?: string;

    static fromJson(parsedJson: Record<string, any>): Shop {
        return new Shop(
            Number(parsedJson['id']),
            parsedJson['libBoutique'],
            parsedJson['adresseBoutique'],
            parsedJson['adresseGpsBoutique'],
            parsedJson['codeBoutique'],
            parsedJson['contactBoutique'],
            parsedJson['taxeBoutique'],
            parsedJson['localCurrency'],
            parsedJson['usdEqInLocal'],
            parsedJson['contratBail'],
            parsedJson['paysID'],
            parsedJson['nbreAnneeService'],
            parsedJson['ageLimiteTravail'],
            parsedJson['boutiqueDefautPays'],
            parsedJson['montantLoyerMensuel'],
            parsedJson['nvlDatePaymentLoyer'],
            parsedJson['adminDocGalID'],
            parsedJson['gazFourn'],
            parsedJson['eauFourn'],
            parsedJson['electricFourn'],
            parsedJson['telFourn'],
            parsedJson['internetFourn']
        );
    }

    static toJson(shop: Shop): Record<string, any>  {
        return  {
            "id": shop.id,
            "libBoutique": shop.libBoutique,
            "adresseBoutique": shop.adresseBoutique,
            "adresseGpsBoutique": shop.adresseGpsBoutique,
            "codeBoutique": shop.codeBoutique,
            "contactBoutique": shop.contactBoutique,
            "taxeBoutique": shop.taxeBoutique,
            "localCurrency": shop.localCurrency,
            "usdEqInLocal": shop.usdEqInLocal,
            "contratBail": shop.contratBail,
            "paysID": shop.paysID,
            "nbreAnneeService": shop.nbreAnneeService,
            "ageLimiteTravail": shop.ageLimiteTravail,
            "boutiqueDefautPays": shop.boutiqueDefautPays,
            "montantLoyerMensuel": shop.montantLoyerMensuel,
            "nvlDatePaymentLoyer": shop.nvlDatePaymentLoyer,
            "adminDocGalID": shop.adminDocGalID,
            "gazFourn": shop.gazFourn,
            "eauFourn": shop.eauFourn,
            "electricFourn": shop.electricFourn,
            "telFourn": shop.telFourn,
            "internetFourn": shop.internetFourn
        };
    }


    constructor(id: number, libBoutique?: string, adresseBoutique?: string, adresseGpsBoutique?: string,
        taxeBoutique?: string, localCurrency?: string, usdEqInLocal?: string,
         codeBoutique?: string, contactBoutique?: string, contratBail?: string,   
         paysID?: string, nbreAnneeService?: string, ageLimiteTravail?: string, boutiqueDefautPays?: boolean, 
         montantLoyerMensuel?: string,  nvlDatePaymentLoyer?: string, adminDocGalID?: string, gazFourn?: string, eauFourn?: string, 
         electricFourn?: string, telFourn?: string, internetFourn?: string ) {
        this.id = id;
        this.libBoutique = libBoutique;
        this.adresseBoutique = adresseBoutique;
        this.adresseGpsBoutique = adresseGpsBoutique;
        this.codeBoutique = codeBoutique;
        this.contactBoutique = contactBoutique;
        this.taxeBoutique = taxeBoutique;
        this.localCurrency = localCurrency;
        this.localCurrency = localCurrency;
        this.usdEqInLocal = usdEqInLocal;
        this.contratBail = contratBail;
        this.paysID = paysID;
        this.nbreAnneeService = nbreAnneeService;
        this.ageLimiteTravail = ageLimiteTravail;
        this.boutiqueDefautPays = boutiqueDefautPays;
        this.montantLoyerMensuel = montantLoyerMensuel;
        this.nvlDatePaymentLoyer = nvlDatePaymentLoyer;
        this.adminDocGalID = adminDocGalID; 
        this.gazFourn = gazFourn;
        this.eauFourn = eauFourn;
        this.electricFourn = electricFourn;
        this.telFourn = telFourn;
        this.internetFourn = internetFourn;

    }
}