import { FC, useEffect, useState, useTransition } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import VenteService from './service'; 

//Moment react
import Moment from 'react-moment';

import {
    Link, useNavigate,
    useParams
} from "react-router-dom";
import { useTranslation } from 'react-i18next';

// React - Bootstrap
import { Modal } from 'react-bootstrap';

import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 

const WebVenteDetails: FC = () => {
      
    const { id } = useParams(); 
    let idToModInit: any = null;
    const [ order, setOrder ] = useState( idToModInit );
    const [ idToMod, setIdToMod ] = useState(idToModInit);

    const [ notesModal, setNotesModal ] = useState(false);

    const [ addNote, setAddNote ] = useState(false);

    const [ loading, setLoading ] = useState(false);
    const venteService = new VenteService();

    const notesInit: any = [];

    let [ notes, setNotes ] = useState(notesInit);

    let [ notesLaoding, setNotesLoading ] = useState(notesInit);

    const navigate = useNavigate();

    const { t } = useTranslation();

    let editAgent = (id: Number): void => {
        setIdToMod(id);
        setLoading(true);
        navigate('/agent/edit/'+id.toString());
        setLoading(false);
    }

    const getWebVenteDetails = () => {
        setLoading(true);
        venteService.geWebVenteDetails( Number(id) ).then(async function (response: any) {
            console.log(response);
            setOrder(response.data.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    const getVenteNotes = () => {
        setNotesLoading(true);
        venteService.getVenteNotes( Number(id) ).then(async function (response: any) {
            console.log(response);
            setNotes(response.data.data);
            setNotesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setNotesLoading(false); 
        });
    }

    const toNumber = (val: any) => {
        return Number(val);
    }

    const getOrderStatus = (val: string) => {
        switch(val) {
            case 'paid':
              return 'Payée';
              break;
            case 'processing':
              return 'En cours de traitement';
              break;
            case 'processed':
                return 'En attente d\'expédition';
                break;
            case 'shipped':
               return 'Expédiée en cours de livraison';
               break;
            case 'delivered':
                return 'Livrée';
                break;
            default:
              return val;
          }
    }

 

    const columns = [
        {
            name: 'Produit',
            selector: (row: any) => row.produit.libProduit 
        },
        {
            name: 'Montant',
            selector: (row: any) => row.montant,
        },
        {
            name: 'Prix unitaire',
            selector: (row: any) => row.pu,
        },
        {
            name: 'Quantité',
            selector: (row: any) => row.qte,
        } 
    ];

    const notesColumns = [
        {
            name: '#',
            selector: (row: any) => row.id,
        },
        {
            name: `${ t('Message') }` ,
            selector: (row: any) => row.message,
        } 
    ];

    const updateOrderStatus = (orderStatus: string) => {
       const confirmation = window.confirm("Etes vous sûre de mettre jour la commande ?");
       if (confirmation) {
        setLoading(true);
        if (orderStatus === 'shipped') {
            let tranckingNumber = window.prompt("Entrez le tracking number de la commande");

            if (tranckingNumber !== null && tranckingNumber !== undefined && tranckingNumber !== '')  {
                venteService.updateOrderStatus( Number(order.id), orderStatus, tranckingNumber ).then(async function (response: any) {
                    console.log(response);
                    getWebVenteDetails();
                    setLoading(false); 
                })
                .catch(function (error: any) {
                    console.log(error);
                    setLoading(false); 
                });
            } else {
                setLoading(false);
            }
 
        } else {
            venteService.updateOrderStatus( Number(order.id), orderStatus ).then(async function (response: any) {
                console.log(response);
                getWebVenteDetails();
                setLoading(false); 
            })
            .catch(function (error: any) {
                console.log(error);
                setLoading(false); 
            });
        }
          
       }
    }
    


    useEffect(function() { 
        getWebVenteDetails();
        getVenteNotes();
    }, []); 

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">

                <div className="btn-list">
                                        <button type="button" onClick={ event => navigate('/vente/web') }
                                        className="btn waves-effect waves-light btn-danger">
                                            { t('Retour') }
                                        </button>
                                       
                                    </div>
                                    <br/>
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">
                            {
                                t('Mettre à jour la commande')
                            }
                        </h4>
                        { order !== null && <div className="btn-list">
                            <button type="button" 
                                onClick={event =>  updateOrderStatus('processing')  }
                                className="btn waves-effect waves-light btn-success">
                                    {
                                        t('En traitement')
                                    }
                            </button>
                            <button type="button" disabled={ order.orderStatut.libelle === 'paid' || order.orderStatut.libelle === 'unpaid'}
                                onClick={event => updateOrderStatus('processed') }
                                className="btn waves-effect waves-light btn-info">
                                    {
                                        t('Prêt pour expédition')
                                    }
                                </button>
                            <button type="button"  disabled={ order.orderStatut.libelle === 'paid' || order.orderStatut.libelle === 'unpaid' || order.orderStatut.libelle === 'processing' }
                                onClick={event => updateOrderStatus('shipped') }
                                className="btn waves-effect waves-light btn-warning">
                                    {
                                        t('Expédier')
                                    }
                                </button>
                            <button type="button"  disabled={ order.orderStatut.libelle === 'paid' || order.orderStatut.libelle === 'unpaid' || 
                                order.orderStatut.libelle === 'processing' || order.orderStatut.libelle === 'processed' 
                                
                            }
                                onClick={event => updateOrderStatus('delivered') }
                                className="btn waves-effect waves-light btn-danger">
                                    {
                                        t('Livrer')
                                    }
                            </button>  


                            <button style={{ float: 'right' }} type="button" onClick={event => {
                                setAddNote(false);
                                setNotesModal( !notesModal );
                            }}
                                className="btn waves-effect waves-light btn-info">
                                    {
                                        t('Notes')
                                    }
                            </button>  

                        </div> }
                    </div>
                </div>        
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    
                                    
                                    <br />
                                    <br /> 
                                    {  order !== null && <h3 >
                                        <b style={{ textDecoration: 'underline', color: '#c4c850' }} >ID COMMANDE: </b> <b>{ order.id }</b>
                                    </h3> }
                                    <br/>  
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <h3 style={{ textDecoration: 'underline', color: '#c4c850' }} className="woocommerce-form__label woocommerce-form__label-for-checkbox checkbox"> 
                                                <b>Client</b>
                                            </h3>
                                            <div>
                                            {  order !== null && <ul style={{ listStyleType: 'none', width: '100%' }} >
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Prénoms :</b> 
                                                            { order.vente.client.prenomClient}
                                                        </li>
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Nom :</b> { order.vente.client.nomClient}
                                                        </li>
                                                        <br />
                                                        <br />
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Email :</b> { order.vente.client.emailClient }
                                                        </li>
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Contact :</b> { order.vente.client.contactClient }
                                                        </li>
                                                        <br />
                                                        <br />
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Pays :</b> { order.vente.client.pays.name }
                                                        </li>
                                                    </ul> }  
                                            </div>
                                        </div>
                                        <div className='col-md-6'>

                                        <h3 style={{ textDecoration: 'underline', color: '#c4c850' }} className="woocommerce-form__label woocommerce-form__label-for-checkbox checkbox"> 
                                                <b>Commande</b>
                                            </h3>

                                        {  order !== null && <ul style={{ listStyleType: 'none', width: '100%' }} >
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Statut commande :</b> 
                                                            { ' ' + getOrderStatus(order.orderStatut.libelle) }
                                                        </li>
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Date de la commande :</b>    <Moment format="YYYY/MM/DD hh:mm:ss">
                                                                                                {order.addDate}
                                                                                            </Moment>
                                                        </li>
                                                        <br />
                                                        <br />
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Methode de paiement :</b>  {' ' + order.paymenentMethod.libelle}  
                                                        </li>
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Montant payé :</b>  { toNumber(order.vente.montantVente) +
            toNumber(order.shippingFees) + toNumber(order.taxesFees) - toNumber(order.montantReduction)  } USD 
                                                        </li>
                                                        <br />
                                                        <br />
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Frais de livraison :</b> : { order.shippingFees } USD
                                                        </li>
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Frais taxe boutique :</b> : { order.taxesFees } USD
                                                        </li>
                                                    </ul> }  
                                            
                                        </div>
                                    </div>   
                                    <br/>                            
                                    <div className='row'>
                                        <div className='col-md-6'> 
                                                <h3 style={{ textDecoration: 'underline', color: '#c4c850' }}   className="woocommerce-form__label woocommerce-form__label-for-checkbox checkbox"> 
                                                    <b>Adresse d'expédition</b>
                                                </h3>
                                           

                                            <div className="woocommerce-additional-fields__field-wrapper">
                                            
                                                {  order !== null && <ul style={{ listStyleType: 'none', width: '100%' }} >
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Pays :</b> 
                                                            { order.shippingAddress.country.name }
                                                        </li>
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Région :</b> { order.shippingAddress.stateOrProvince }
                                                        </li>
                                                        <br />
                                                        <br />
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Ville :</b> { order.shippingAddress.city }
                                                        </li>
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Code postale :</b> { order.shippingAddress.zipOrPostalCode }
                                                        </li>
                                                        <br />
                                                        <br />
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Addresse :</b> { order.shippingAddress.addresseLineOne }
                                                        </li>
                                                    </ul> }  
                                            </div>

                                        </div>
                                        <div className='col-md-6'>

                                        <div className="woocommerce-shipping-fields">
	
                                            <h3 style={{ textDecoration: 'underline', color: '#c4c850' }}  id="ship-to-different-address">
                                                <b className="woocommerce-form__label woocommerce-form__label-for-checkbox checkbox"> 
                                                    <span>Adresse de livraion</span>
                                                </b>
                                            </h3> 

                                        </div>

                                        <div className="woocommerce-additional-fields__field-wrapper">
                        
                                        {  order !== null && <ul style={{ listStyleType: 'none', width: '100%' }} >
                                                <li style={{ width: '49%', display: 'inline-block' }} >
                                                    <b>Pays :</b> 
                                                    { order.deliveryAddress.country.name }
                                                </li>
                                                <li style={{ width: '49%', display: 'inline-block' }} >
                                                    <b>Région :</b> { order.deliveryAddress.stateOrProvince }
                                                </li>
                                                <br />
                                                <br />
                                                <li style={{ width: '49%', display: 'inline-block' }} >
                                                    <b>Ville :</b> { order.deliveryAddress.city }
                                                </li>
                                                <li style={{ width: '49%', display: 'inline-block' }} >
                                                    <b>Code postale :</b> { order.deliveryAddress.zipOrPostalCode }
                                                </li>
                                                <br />
                                                <br />
                                                <li style={{ width: '49%', display: 'inline-block' }} >
                                                    <b >Addresse :</b> { order.deliveryAddress.addresseLineOne }
                                                </li>
                                            </ul> }  
                                        </div>

                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <h3 style={{ textDecoration: 'underline', color: '#c4c850' }}  id="ship-to-different-address">
                                                <b className="woocommerce-form__label woocommerce-form__label-for-checkbox checkbox"> 
                                                    <span>Les Produits</span>
                                                </b>
                                            </h3>
                                            <br /> 
                                            <div className="table-responsive">
                                               {
                                                order !== null ? order.content !== null ?
                                                    <DataTable
                                                    responsive={true}
                                                    className="table table-striped table-bordered"
                                                    columns={columns}
                                                    data={ order.content }
                                                    progressPending={loading}
                                                    pagination
                                                />: <></> : <></>
                                               }
                                            </div>
                                        </div>
                                    </div>

                                    </div>
                                </div> 
                        </div> 
                    </div>

                </div>   


                {/* Car details Modal */}

            <Modal 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={ notesModal }
                onHide={() => setNotesModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        { t('Notes') }
                    </Modal.Title> 
                </Modal.Header>
                <Modal.Body>
                 {
                    notesLaoding ? "Chargement..." :
                    <>
                        <div className="container-fluid">

                            <div className="btn-list">
                            { !addNote ? <button type="button" onClick={ event =>  getVenteNotes() }
                                    className="btn waves-effect waves-light btn-danger">
                                    { t('Actualiser') }
                                </button> : <> </>
                            }

                                <button style={{ float: 'right' }} type="button" onClick={ event => 
                                 setAddNote( !addNote ) }
                                className={`btn waves-effect waves-light ${ !addNote ? 'btn-success' : 'btn-danger' }`}>
                                    { !addNote ? t('Ajouter') : t('Annuler') }
                                </button>
                            </div>

                            <br />

                            { !addNote ? <DataTable
                                                responsive={true}
                                                className="table table-striped table-bordered"
                                                columns={notesColumns}
                                                data={ notes }
                                                progressPending={loading}
                                                pagination
                                            />
                                : <div>  <Formik
                                initialValues={
                                    {
                                        orderId: id,
                                        message: '' 
                                }}
                                validationSchema={
                                    yup.object().shape({
                                        orderId: yup
                                            .string() 
                                            .required(`${t('Ce champ est obligatoire')}`),
                                        message: yup
                                            .string() 
                                            .required(`${t('Ce champ est obligatoire')}`),
                                    })
                                }
                                onSubmit={async (
                                    values 
                                ) => { 
                                        setLoading(true);

                                        await venteService.createNote({
                                        ...values  }).then(async function (response: any) {

                                            alert("Note ajouté avec succès");

                                            getVenteNotes();

                                            setLoading(false);

                                            setAddNote(false);
                                         
                                    })
                                    .catch(function (error: any) {
                                        alert("Une erreur s'est produite lors de l'ajout de la note.");
                                        setLoading(false); 
                                    });


                                }}  
                            >

                            {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                            <Form>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>
                                                { t('Message') }
                                            </label>
                                            <textarea  cols={12} rows={3} 
                                            className={`form-control ${
                                                 errors.message &&
                                                                touched.message ? "is-invalid" : "" }`}
                                                            id="message" 
                                                            name="message"
                                                            onChange={event => { 
                                                                handleChange('message')(event); 
                                                            }}
                                                            onBlur={handleBlur('message')}
                                                            value={values.message == null ? '' :
                                                                 values.message}
                                                            placeholder={ t('message') ?? ''}></textarea>
                                        </div>  
                                    </div>
                                </div>

                                <div className="form-actions">
                                    <div className="text-right">
                                        <button  disabled={!isValid} type="submit" 
                                            className="btn btn-primary btn-lg btn-block">
                                            { t('Ajouter') }
                                            {
                                                loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            }
                                        </button> 
                                    </div>
                                </div>

                                </Form>
                                )}
                            </Formik> </div>  }
                        </div>
                    </>
                 }
                </Modal.Body>
                <Modal.Footer>
                {/* <Button onClick={props.onHide}>Close</Button> */}
                </Modal.Footer>
            </Modal>

            </>                   

    );
}

export default WebVenteDetails