import { FC, useEffect, useState, useTransition } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import SpentService from './service';

import {
    Link, useNavigate
} from "react-router-dom";
import UserRight from '../../Utils/userRight';

//Moment react
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';

const SpentList: FC = () => {  

    let idToModInit: any = null;

    const { t } = useTranslation();

    const [ spents, setSpents ] = useState( [] );
    const [ tempProducts, setTempProducts ] = useState( [] );
    const [ idToMod, setIdToMod ] = useState(idToModInit);
    const [ loading, setLoading ] = useState(false);

    const [ searchStr, setSearchStr ] = useState('');
    const [ searchLoading, setSearchLoading ] = useState(false);

    const spentService = new SpentService();

    const userRight = new UserRight();

    const navigate = useNavigate();

    // let editAgent = (id: Number): void => {
    //     setIdToMod(id);
    //     setLoading(true);
    //     navigate('/agent/edit/'+id.toString());
    //     setLoading(false);
    // }

    const getSpents = () => {
        setLoading(true);
        spentService.getSpents().then(async function (response: any) {
            console.log(response);
            setSpents(response.data); 
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    

    const columns = [
        {
            name: t('Libellé'),
            selector: (row: any) => row.depense.libDepense,
        },
        {
            name: t('Agent'),
            selector: (row: any) => row.agent.prenomAgent + ' ' + row.agent.nomAgent,
        }, 
        {
            name: t('Montant'),
            selector: (row: any) => row.montant,
        },
        {
            name: 'Date',
            selector: (row: any) => <Moment format="YYYY/MM/DD hh:mm:ss">
                                        {row.dateDepense}
                                    </Moment>
        }, 
        {
            name: "Options", 
            selector: (row: any) => (
                <div className='btn-list'>
                    <button type="button" onClick={event => {
                        window.localStorage.setItem('depense', JSON.stringify(row));
                        navigate('/depense/details/'+row.id.toString());
                     }}
                        className="btn waves-effect waves-light btn-warning">
                            <span className="light" style={{ fontSize: "1em" }} >
                                <i className="icon-eye"></i></span>
                    </button>
                    {/* <button type="button" onClick={event => {  }}
                        className="btn waves-effect waves-light btn-danger"> 
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-trash"></i></span>
                             
                    </button> */}
                </div>
            )
        } 
    ];

    useEffect( function() { 
        getSpents();
    }, []);
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-list">
                                        <button type="button" onClick={ event => getSpents() }
                                            className="btn waves-effect waves-light btn-danger">
                                                { t('Actualiser') }
                                            </button>
                                        <Link type="button" to="/product/create"
                                            className="btn waves-effect waves-light btn-primary">
                                                { t('Ajouter une dépense') }
                                        </Link>
                                    </div> 
                                    
                                    <div className="table-responsive">
                                        <DataTable
                                            responsive={true}
                                            className="table table-striped table-bordered"
                                            columns={columns}
                                            data={spents}
                                            progressPending={loading}
                                            pagination
                                        />
                                    </div>
                                </div>
                            </div> 
                        </div> 
                    </div>
                </div>   
            </>                   

    );
}

export default SpentList