import { FC, useEffect, useState, useRef } from 'react';
import uuid from 'react-uuid';
import {
    Link, useNavigate 
} from "react-router-dom";

import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup';
import { string } from 'yup/lib/locale';
import AgentService from './service';
import User from '../../Models/User';
import ShopService from '../Shop/service';
import { useTranslation } from 'react-i18next';

interface AgentFormProps {
    state: string;
    agent?: User | null; 
}

const AgentForm: FC<AgentFormProps> = ( {  state, agent } ) => {
   

    let navigate = useNavigate();
    const { t, i18n } = useTranslation( );
    const formRef = useRef< FormikProps< User >>(null);

    let [image, setImage] = useState('');

    let [loading, setLoading] = useState(false);

    let [agentFileLabel, setAgentFileLabel] = useState('');
    let [agentFile, setAgentFile] = useState('');

    let [ agentFiles, setAgentFiles ] = useState(Array<any>);

    let [agentTypesLoading, setAgentTypesLoading] = useState(false);
    let [ transactionMethodsLoading, setTransactionMethodsLoading ] = useState(false);

    let [ countriesLoading, setCountriesLoading ] = useState(false);

    let [ shopsLoading, setShopsLoading ] = useState(false);

    let [ shops, setShops ] = useState([]);

    let [ pieceTypesLoading, setPieceTypesLoading ] = useState(false);
    let [ pieceTypes, setPieceTypes ] = useState([]);

    let [ countries, setCountries ] = useState([]);

    let galeryIdInit: any = null;
    let [galId, setGalId] = useState(galeryIdInit);

    let [
        agentTypes, setAgentTypes
    ] = useState([]);

    let [
        transactionMethods, setTransactionMethods
    ] = useState([]);

    const agentService = new AgentService();
    const shopService = new ShopService();
    
    const readThis = (inputValue: any, filetype: string): void => {
        var file: File = inputValue.files[0];
        var lecteur: FileReader = new FileReader();
    
        lecteur.onloadend = (e) => {
            switch (filetype) {
                case 'profile':
                    setImage( lecteur.result as string );
                    break;
                case 'agent_files':
                    setAgentFile( lecteur.result as string );
                    break;
                case 'casier':
                    agentService.uploadAgentFile( lecteur.result as string ).then(
                        (res: any) => { 
                            console.log(res);
                            if (formRef.current !== null) {
                                formRef.current.values.cassierJudiciaire = res.data?.uploadedFN;
                            }
                         }, (error: any) => {
                            alert( `${ t('Une erreur s\'est produite réesseyez s\'il vous plaît') }` );
                        }
                    );
                    break;
                case 'identite':
                    agentService.uploadAgentId( lecteur.result as string ).then(
                            (res: any) => { 
                                console.log(res);
                                if (formRef.current !== null) {
                                    formRef.current.values.pieceIdentite = res.data?.uploadedFN;
                                }
                             }, (error: any) => {

                                alert( `${ t('Une erreur s\'est produite réesseyez s\'il vous plaît') }` );
                            }
                    );
                    break;
            
                default:
                    break;
            }
            
            
            
            console.log(lecteur.result);
        }
        lecteur.readAsDataURL(file);

    }

    const changeListener = (event: any, filetype: string = 'profile')  => {
        readThis(event.target, filetype);
    }

    const getAgentTypeList = () => {
        setAgentTypesLoading(true);
        agentService.getAgentTypeList().then(async function (response: any) {
            console.log(response);
            setAgentTypes(response.data?.data);
            setAgentTypesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setAgentTypesLoading(false); 
        });
    }

    const getTransactionsMethods = () => {
        setTransactionMethodsLoading(true);
        agentService.getTransactionsMethods().then(async function (response: any) {
            console.log(response);
            setTransactionMethods(response.data?.data);
            setTransactionMethodsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setTransactionMethodsLoading(false); 
        });
    }

    const getCountries = () => {
        setCountriesLoading(true);
        shopService.getCountries().then(async function (response: any) {
            console.log(response);
            setCountries(response.data);
            setCountriesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setCountriesLoading(false); 
        });
    }

    const getShopList = () => {
        setShopsLoading(true);
        shopService.getShops().then(async function (response: any) {
            console.log(response);
            setShops(response.data);
            setShopsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setShopsLoading(false); 
        });
    }

    const sendAgentFile = (event: any) => {
        console.log("Add file");
        if (agentFile && agentFileLabel) {

            console.log(agentFiles);
            let files = Array.isArray(agentFiles) ? agentFiles : [];
            files.push({
                'label':  agentFileLabel,
                'file':  agentFile
            });
            setAgentFiles(files);

            setAgentFile('');
            setAgentFileLabel('');

        }  else {
            alert( `${t("Vous devez remplir le champ label et choisir un fichier")}` );
        }
        // if (formRef.current !== null) {
        //     if (formRef.current.values.adminFileLabel !== null 
        //         && formRef.current.values.adminFileLabel !== '' && formRef.current.values.adminFile !== null 
        //         && formRef.current.values.adminFile !== '') {
       
        //         formRef.current.values.adminFileLabel = '';
        //         formRef.current.values.adminFile = '';
        //     } else {
        //         alert("Remplir le champ label et choisir un fichier");
        //     }
        // }   
    }

    const getPieceTypesList = () => {
        setPieceTypesLoading(true);
        agentService.getPieceTypes().then(async function (response: any) {
            console.log(response);
            setPieceTypes(response.data?.data);
            setPieceTypesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setPieceTypesLoading(false); 
        });
    }


    useEffect( () => {

        getAgentTypeList();
        getTransactionsMethods();
        getCountries();
        getShopList();
        getPieceTypesList();

        if (agent != null  && agent != undefined) {
            setImage(agent.profil ?? '');
        }

    }, []);


    const isBase64 = (file: string) => {
        return file.includes('data');
        // if (file ==='' || file.trim() ===''){ return false; }
        // try {
        //     return window.btoa(window.atob(file)) == file;
        // } catch (err) {
        //     return false;
        // }
    }

    const uploadAdminFiles = async () => {
        console.log("Uploading files");
        const galId = state === 'create' ? uuid() : "";
        setGalId(galId);
        return await new Promise(
          (resolve: any, reject) => {
            console.log(agentFiles);
            if (agentFiles.length > 0) {
                agentFiles.map(
                    (file, id) => {

                        if (isBase64(file?.file)) {
                    
                           shopService.uploadAdminFiles(file?.file, file?.label, galId).then(
                                (res: any) => {
                                    console.log(res);
                                    
                                    if (formRef.current !== null) {

                                        // let fileArray2: any =  formRef.current.values.galContent ?
                                        //  formRef.current.values.galContent : [];
                                        // fileArray2.push(res?.data?.uploadedFN);

                                        formRef.current.values.dossierGalId = res?.data?.galID;

                                        // formRef.current.values.galContent = fileArray2;
                                        
                                        if(id === (agentFiles.length - 1)) {
                                            resolve();
                                        }

                                    } 
                                    
                                }, (error: any) => {
                                    reject();
                                }
                            );
                        } else {
                            // if (formRef.current !== null) {
                            //     let fileArray2: any =  formRef.current.values.galContent ?  formRef.current.values.galContent : [];
                            //     fileArray2.push(file); 

                            //     formRef.current.values.galContent = fileArray2;
                            //     if(id === (galerie.length - 1)) {
                            //         resolve();
                            //     }

                            // }
                        }
                    }
              );
            } else {
              resolve();
            }  
          }
        )
    }
    
    

    return (
        <Formik
        initialValues={
            agent != null  && agent != undefined ? 
            {
                ...agent
            }:
            {
            pseudoAgent: '',
            passwordAgent: '',
            nomAgent: '',
            prenomAgent: '',
            // dateNaisAgent: '',
            contactAgent: '',
            addresseLineOne: '',
            addresseLineTwo: '', 
            city: '' , 
            stateOrProvice: '', 
            zipOrPostalCode: '',
            country: '', 
            sexeAgent: '',
            photoProfil: '', 
            paysAgent: '',
            contactMobile: '',
            contactFixe: '',
            dteNais: '',
            dteDebService: '',
            dteFinService:'',
            idTypeAgent: '',
            actif: false,
            enssured: false,
            newsletter: false,
            methodRecepSalireId: '',
            numComptSalaire: '',
            shopId: '',
            dossierGalId: '',
            cassierJudiciaire: '',
            idTypePieceIdentite: '',
            numPieceIdentite: '',
            pieceIdentite: '',
            fullnamePersPrev: '',
            phonePersPrev: '', 
            addressPersPrev: ''
        }}
        validationSchema={
            yup.object().shape({
                pseudoAgent: yup
                    .string() 
                    .required(`${t('Ce champ est obligatoire')}`),
                passwordAgent: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`)
                    .matches(
                        /^(?=.*[!@#\$%\^&\*])(?=.{12,})/,
                        `${t("Le mot de passe doit comporter 12 caractères avec l'utilisation d'un caractère spécial")}`
                    ),
                nomAgent: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                prenomAgent: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                // dateNaisAgent: yup
                //     .string()
                //     .required(`${t('Ce champ est obligatoire')}`),
                // contactAgent: yup
                //     .string()
                //     .required(`${t('Ce champ est obligatoire')}`),
                addresseLineOne: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`), 
                addresseLineTwo: yup
                    .string(),
                city: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                stateOrProvice: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                zipOrPostalCode: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                country: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                sexeAgent: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                photoProfil: yup
                    .string(), 
                paysAgent: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                contactMobile: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                contactFixe: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                dteNais: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                dteDebService:yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                dteFinService: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                idTypeAgent: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                actif: yup
                    .boolean()
                    .required(`${t('Ce champ est obligatoire')}`),
                enssured: yup
                    .boolean()
                    .required(`${t('Ce champ est obligatoire')}`),
                newsletter: yup
                    .boolean()
                    .required(`${t('Ce champ est obligatoire')}`),
                methodRecepSalireId: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                numComptSalaire: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                shopId: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                dossierGalId: yup
                    .string(),
                cassierJudiciaire: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                idTypePieceIdentite:  yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                numPieceIdentite: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                pieceIdentite: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                fullnamePersPrev: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                phonePersPrev: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                addressPersPrev: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
            })
        }
        onSubmit={async (
            values 
          ) => {
                    console.log(values);
                    setLoading(true);

                    if (image != null && image != '' ) {
                        values.photoProfil = image;
                    }

                    if (state == 'create') {
                        await uploadAdminFiles().then(
                            async () => {
                                agentService.postAgent( User.fromJson(values)).then(async function (response: any) {
                                    console.log(response);
                                    alert( `${t("Agent créé avec succès")}` );  
                                    navigate('/agent/list');
                                    setLoading(false); 
                                })
                                .catch(function (error: any) {
                                    console.log(error);
                                    alert(`${ t('Une erreur s\'est produite réesseyez s\'il vous plaît') }`)
                                    setLoading(false); 
                                });
                        }).catch(function(err) {
                            setLoading(false); 
                        }); 
                    } else if (state == 'edit') {
                        agentService.putAgent( User.fromJson(values)).then(async function (response: any) {
                            console.log(response);
                            alert( `${t("Agent mise à jour avec succès")}`);  
                            navigate('/agent/list');
                            setLoading(false); 
                        })
                          .catch(function (error: any) {
                            console.log(error);
                            alert(`${ t('Une erreur s\'est produite réesseyez s\'il vous plaît') }`)
                            setLoading(false); 
                        });
                    }
                    
                }}
                innerRef={formRef}
                >
                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                        <Form>
                                    <div className="form-body row"> 
                                             
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label> { t("Nom d'utilisateur") } </label>
                                                    <input type="text"
                                                        className={`form-control ${ errors.pseudoAgent && touched.pseudoAgent ? "is-invalid":""}`}
                                                        id="pseudoAgent" 
                                                        name="pseudoAgent" 
                                                        onChange={handleChange('pseudoAgent')}
                                                        onBlur={handleBlur('pseudoAgent')}
                                                        value={values.pseudoAgent}
                                                        placeholder={ t("Nom d'utilisateur") ?? '' } />
                                                    { errors.pseudoAgent && touched.pseudoAgent && errors.pseudoAgent && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.pseudoAgent && touched.pseudoAgent && errors.pseudoAgent }
                                                    </div> }
                                                </div>
                                            </div> 

                                         
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label> { t('Mot de passe') } </label>
                                                    <input 
                                                        className={`form-control ${ errors.passwordAgent && touched.passwordAgent ? "is-invalid":""}`}
                                                        id="passwordAgent"
                                                        type="password"
                                                        name="passwordAgent"
                                                        onChange={handleChange('passwordAgent')}
                                                        onBlur={handleBlur('passwordAgent')}
                                                        value={values.passwordAgent}
                                                        placeholder={ t('Mot de passe') ?? '' } />
                                                    { errors.passwordAgent && touched.passwordAgent && errors.passwordAgent && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.passwordAgent && touched.passwordAgent && errors.passwordAgent }
                                                    </div> }
                                                </div>
                                            </div>

                                         
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>
                                                        { t('Prénoms') }
                                                    </label>
                                                    <input 
                                                        className={`form-control ${ errors.prenomAgent && touched.prenomAgent ? "is-invalid":""}`}
                                                        id="prenomAgent"
                                                        type="text"
                                                        name="prenomAgent"
                                                        onChange={handleChange('prenomAgent')}
                                                        onBlur={handleBlur('prenomAgent')}
                                                        value={values.prenomAgent}
                                                        placeholder={ t('Prénoms') ?? '' } />
                                                    { errors.prenomAgent && touched.prenomAgent && errors.prenomAgent && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.prenomAgent && touched.prenomAgent && errors.prenomAgent }
                                                    </div> }
                                                </div>
                                            </div> 

                                         
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>
                                                        { t('Nom') }
                                                    </label>
                                                    <input 
                                                        className={`form-control ${ errors.nomAgent && touched.nomAgent ? "is-invalid":""}`}
                                                        id="nomAgent"
                                                        type="text"
                                                        name="nomAgent"
                                                        onChange={handleChange('nomAgent')}
                                                        onBlur={handleBlur('nomAgent')}
                                                        value={values.nomAgent}
                                                        placeholder={ t('Nom') ?? '' } />
                                                    { errors.nomAgent && touched.nomAgent && errors.nomAgent && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.nomAgent && touched.nomAgent && errors.nomAgent }
                                                    </div> }
                                                </div>
                                            </div> 

                                        

                                        {/* <label>
                                            {
                                                t('Date de naissance')
                                            }
                                        </label>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input 
                                                        className={`form-control ${ errors.prenomAgent && touched.prenomAgent ? "is-invalid":""}`}
                                                        id="dateNaisAgent"
                                                        type="date"
                                                        name="dateNaisAgent"
                                                        onChange={handleChange('dateNaisAgent')}
                                                        onBlur={handleBlur('dateNaisAgent')}
                                                        value={values.dateNaisAgent}
                                                        placeholder={  t('Date de naissance') ?? '' } />
                                                    { errors.dateNaisAgent && touched.dateNaisAgent && errors.dateNaisAgent && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.dateNaisAgent && touched.dateNaisAgent && errors.dateNaisAgent }
                                                    </div> }
                                                </div>
                                            </div>
                                        </div> */}

                                         
                                            {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>
                                                        {
                                                            t('Contact')
                                                        }
                                                    </label>
                                                    <input 
                                                        className={`form-control ${ errors.contactAgent && touched.contactAgent ? "is-invalid":""}`}
                                                        id="contactAgent"
                                                        type="text"
                                                        name="contactAgent"
                                                        onChange={handleChange('contactAgent')}
                                                        onBlur={handleBlur('contactAgent')}
                                                        value={values.contactAgent}
                                                        placeholder={t('Contact') ?? ''} />
                                                    { errors.contactAgent && touched.contactAgent && errors.contactAgent && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.contactAgent && touched.contactAgent && errors.contactAgent }
                                                    </div> }
                                                </div>
                                            </div> */}


                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Ligne Adresse') } 1
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.addresseLineOne &&
                                                             touched.addresseLineOne ? "is-invalid":""}`}
                                                        id="addresseLineOne"
                                                        type="text"
                                                        name="addresseLineOne"
                                                        onChange={handleChange('addresseLineOne')}
                                                        onBlur={handleBlur('addresseLineOne')}
                                                        value={values.addresseLineOne}
                                                        placeholder={ t('Ligne Adresse') + ' 1' ?? '' } />
                                                    { errors.addresseLineOne && touched.addresseLineOne && errors.addresseLineOne && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.addresseLineOne && touched.addresseLineOne && errors.addresseLineOne }
                                                    </div> }
                                        </div> 

                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Ligne Adresse') } 2
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.addresseLineTwo &&
                                                             touched.addresseLineTwo ? "is-invalid":""}`}
                                                        id="addresseLineTwo"
                                                        type="text"
                                                        name="addresseLineTwo"
                                                        onChange={handleChange('addresseLineTwo')}
                                                        onBlur={handleBlur('addresseLineTwo')}
                                                        value={values.addresseLineTwo}
                                                        placeholder={ t('Ligne Adresse') + ' 2' ?? '' } />
                                                    { errors.addresseLineTwo && touched.addresseLineTwo && errors.addresseLineTwo && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.addresseLineTwo && touched.addresseLineTwo && errors.addresseLineTwo }
                                                    </div> }
                                        </div> 


                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Ville') }
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.city &&
                                                             touched.city ? "is-invalid":""}`}
                                                        id="city"
                                                        type="text"
                                                        name="city"
                                                        onChange={handleChange('city')}
                                                        onBlur={handleBlur('city')}
                                                        value={values.city}
                                                        placeholder={ t('Ville') ?? '' } />
                                                    { errors.city && touched.city && errors.city && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.city && touched.city && errors.city }
                                                    </div> }
                                        </div> 

                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Région') }
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.stateOrProvice &&
                                                             touched.stateOrProvice ? "is-invalid":""}`}
                                                        id="stateOrProvice"
                                                        type="text"
                                                        name="stateOrProvice"
                                                        onChange={handleChange('stateOrProvice')}
                                                        onBlur={handleBlur('stateOrProvice')}
                                                        value={values.stateOrProvice}
                                                        placeholder={ t('Région') ?? '' } />
                                                    { errors.stateOrProvice && touched.stateOrProvice && errors.stateOrProvice && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.stateOrProvice && touched.stateOrProvice && errors.stateOrProvice }
                                                    </div> }
                                        </div> 

                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Code postal') }
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.zipOrPostalCode &&
                                                             touched.zipOrPostalCode ? "is-invalid":""}`}
                                                        id="zipOrPostalCode"
                                                        type="text"
                                                        name="zipOrPostalCode"
                                                        onChange={handleChange('zipOrPostalCode')}
                                                        onBlur={handleBlur('zipOrPostalCode')}
                                                        value={values.zipOrPostalCode}
                                                        placeholder={ t('Code postal') ?? '' } />
                                                    { errors.zipOrPostalCode && touched.zipOrPostalCode && errors.zipOrPostalCode && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.zipOrPostalCode && touched.zipOrPostalCode && errors.zipOrPostalCode }
                                                    </div> }
                                        </div> 

                                        <div className="form-group col-md-6">
                                                <label> 
                                                    { t('Pays') } </label>
                                                        {
                                                            countriesLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <select  
                                                                className={`form-control custom-select ${ errors.country &&
                                                                    touched.country ? "is-invalid" : "" }`}
                                                                id="country" 
                                                                name="country"
                                                                onChange={event => { 
                                                                    handleChange('country')(event); 
                                                                }}
                                                                onBlur={handleBlur('country')}
                                                                value={values.country}
                                                                placeholder="Pays" >
                                                                <option selected>{ t('Pays') }..</option>
                                                                {
                                                                    countries.map(
                                                                        (country: any, id) => <option 
                                                                         key={id} value={country.id}>
                                                                            {
                                                                                country.name
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </select> 
                                                        }
                                        </div>


                                         
                                        
                                         
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>
                                                        { t('Sexe') }
                                                    </label>
                                                    <select  
                                                        className={`form-control custom-select ${ errors.sexeAgent &&
                                                            touched.sexeAgent ? "is-invalid" : "" }`}
                                                        id="sexeAgent" 
                                                        name="sexeAgent"
                                                        onChange={handleChange('sexeAgent')}
                                                        onBlur={handleBlur('sexeAgent')}
                                                        value={values.sexeAgent}
                                                        placeholder={ t('Sexe') ?? '' } >
                                                        <option selected>{ t('Sexe') }...</option>
                                                        <option value="F">{ t('Féminin') }</option>
                                                        <option value="M">{ t('Masculin') }</option> 
                                                    </select>
                                                </div>
                                            </div> 

                                        <div className="form-group col-md-6">
                                                <label> { t('Pays') } </label>
                                                        {
                                                            countriesLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <select  
                                                                className={`form-control custom-select ${ errors.paysAgent &&
                                                                    touched.paysAgent ? "is-invalid" : "" }`}
                                                                id="paysAgent" 
                                                                name="paysAgent"
                                                                onChange={event => { 
                                                                    handleChange('paysAgent')(event); 
                                                                }}
                                                                onBlur={handleBlur('paysAgent')}
                                                                value={values.paysAgent}
                                                                placeholder="Pays" >
                                                                <option selected>{ t('Pays') }..</option>
                                                                {
                                                                    countries.map(
                                                                        (country: any, id) => <option 
                                                                         key={id} value={country.id}>
                                                                            {
                                                                                country.name
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </select> 
                                                        }
                                        </div>


                                        <div className="form-group col-md-6">
                                                    <label> { t('Numéro de téléphone mobile') } </label>
                                                    <input 
                                                        className={`form-control ${ errors.contactMobile &&
                                                             touched.contactMobile ? "is-invalid" : "" }`}
                                                        id="contactMobile"
                                                        type="text"
                                                        name="contactMobile"
                                                        onChange={handleChange('contactMobile')}
                                                        onBlur={handleBlur('contactMobile')}
                                                        value={values.contactMobile}
                                                        placeholder={ t('Contact mobile') ?? '' } />
                                                    { errors.contactMobile && touched.contactMobile && errors.contactMobile && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.contactMobile && touched.contactMobile && errors.contactMobile }
                                                    </div> }
                                        </div>

                                        <div className="form-group col-md-6">
                                                    <label> { t('Numéro de téléphone fixe') } </label>
                                                    <input 
                                                        className={`form-control ${ errors.contactFixe &&
                                                             touched.contactFixe ? "is-invalid" : "" }`}
                                                        id="contactFixe"
                                                        type="text"
                                                        name="contactFixe"
                                                        onChange={handleChange('contactFixe')}
                                                        onBlur={handleBlur('contactFixe')}
                                                        value={values.contactFixe}
                                                        placeholder={ t('Contact fixe') ?? '' } />
                                                    { errors.contactFixe && touched.contactFixe && errors.contactFixe && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.contactFixe && touched.contactFixe && errors.contactFixe }
                                                    </div> }
                                        </div>

                                        <div className='form-group col-md-6'>
                                            <label> { t('Date de naissance') } </label>
                                            <input 
                                                        className={`form-control ${ errors.dteNais &&
                                                             touched.dteNais ? "is-invalid":""}`}
                                                        id="dteNais"
                                                        type="date"
                                                        name="dteNais"
                                                        onChange={handleChange('dteNais')}
                                                        onBlur={handleBlur('dteNais')}
                                                        value={values.dteNais}
                                                        placeholder={ t('Date de naissance') ?? ''} />
                                            { errors.dteNais && touched.dteNais &&
                                                     errors.dteNais && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.dteNais && touched.dteNais &&
                                                         errors.dteNais }
                                            </div> }
                                        </div>


                                        <div className='form-group col-md-6'>
                                            <label> { t('Date début service') } </label>
                                            <input 
                                                        className={`form-control ${ errors.dteDebService &&
                                                             touched.dteDebService ? "is-invalid":""}`}
                                                        id="dteDebService"
                                                        type="date"
                                                        name="dteDebService"
                                                        onChange={handleChange('dteDebService')}
                                                        onBlur={handleBlur('dteDebService')}
                                                        value={values.dteDebService}
                                                        placeholder={ t('Date début service') ?? ''} />
                                            { errors.dteDebService && touched.dteDebService &&
                                                     errors.dteDebService && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.dteDebService && touched.dteDebService &&
                                                         errors.dteDebService }
                                            </div> }
                                        </div>


                                        <div className='form-group col-md-6'>
                                            <label> { t('Date fin service') } </label>
                                            <input 
                                                        className={`form-control ${ errors.dteFinService &&
                                                             touched.dteFinService ? "is-invalid":""}`}
                                                        id="dteFinService"
                                                        type="date"
                                                        name="dteFinService"
                                                        onChange={handleChange('dteFinService')}
                                                        onBlur={handleBlur('dteFinService')}
                                                        value={values.dteFinService}
                                                        placeholder={ t('Date fin service') ?? '' } />
                                            { errors.dteFinService && touched.dteFinService &&
                                                     errors.dteFinService && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.dteFinService && touched.dteFinService &&
                                                         errors.dteFinService }
                                            </div> }
                                        </div>


                                        <div className="form-group col-md-6">
                                                <label> { t('Type agent')  }  </label>
                                                        {
                                                            agentTypesLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <select  
                                                                className={`form-control custom-select ${ errors.idTypeAgent &&
                                                                    touched.idTypeAgent ? "is-invalid" : "" }`}
                                                                id="idTypeAgent" 
                                                                name="idTypeAgent"
                                                                onChange={event => { 
                                                                    handleChange('idTypeAgent')(event); 
                                                                }}
                                                                onBlur={handleBlur('idTypeAgent')}
                                                                value={values.idTypeAgent}
                                                                placeholder={ t('Type agent') ?? ''  } >
                                                                <option selected>{ t('Type agent')  }...</option>
                                                                {
                                                                    agentTypes.map(
                                                                        (agt: any, id) => <option 
                                                                         key={id} value={agt.id}>
                                                                            {
                                                                                agt.libelle
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </select> 
                                                        }
                                        </div>

                                        <div className='col-md-6' >
                                            <div className='row'>
                                            <div className='col-md-4'>
                                                <div className='form-group'>
                                                    <label> { t('Statut agent') }
                                                    <Field type="checkbox"
                                                        className={`form-control 
                                                        ${ errors.actif && touched.actif ? "is-invalid":""}`}
                                                        id="actif" 
                                                        name="actif"  
                                                        />
                                                        </label>
                                                        { errors.actif && touched.actif && errors.actif && 
                                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                                { errors.actif && touched.actif && errors.actif }
                                                            </div> 
                                                        }
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='form-group'>
                                                    <label> { t('Assurance') }
                                                    <Field type="checkbox"
                                                        className={`form-control 
                                                        ${ errors.enssured && touched.enssured ? "is-invalid":""}`}
                                                        id="enssured" 
                                                        name="enssured"  
                                                        />
                                                        </label>
                                                        { errors.enssured && touched.enssured && errors.enssured && 
                                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                                { errors.enssured && touched.enssured && errors.enssured }
                                                            </div> 
                                                        }
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='form-group'>
                                                    <label>Mails
                                                    <Field type="checkbox"
                                                        className={`form-control 
                                                        ${ errors.newsletter && touched.newsletter ? "is-invalid":""}`}
                                                        id="newsletter" 
                                                        name="newsletter"  
                                                        />
                                                        </label>
                                                        { errors.newsletter && touched.newsletter && errors.newsletter && 
                                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                                { errors.newsletter && touched.newsletter && errors.newsletter }
                                                            </div> 
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="form-group col-md-6">
                                                <label> { t('Méthode de transactions') } </label>
                                                        {
                                                            transactionMethodsLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <select  
                                                                className={`form-control custom-select ${ errors.methodRecepSalireId &&
                                                                    touched.methodRecepSalireId ? "is-invalid" : "" }`}
                                                                id="methodRecepSalireId" 
                                                                name="methodRecepSalireId"
                                                                onChange={event => { 
                                                                    handleChange('methodRecepSalireId')(event); 
                                                                }}
                                                                onBlur={handleBlur('methodRecepSalireId')}
                                                                value={values.methodRecepSalireId}
                                                                placeholder={ t('Méthode de transactions') ?? ''} >
                                                                <option selected>{ t('Méthode de transactions') }...</option>
                                                                {
                                                                    transactionMethods.map(
                                                                        (methods: any, id) => <option 
                                                                         key={id} value={methods.id}>
                                                                            {
                                                                                methods.libelle
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </select> 
                                                        }
                                        </div>

                                        <div className="form-group col-md-6">
                                                    <label> { t('Numéro de compte salaire') } </label>
                                                    <input 
                                                        className={`form-control ${ errors.numComptSalaire &&
                                                             touched.numComptSalaire ? "is-invalid" : "" }`}
                                                        id="numComptSalaire"
                                                        type="text"
                                                        name="numComptSalaire"
                                                        onChange={handleChange('numComptSalaire')}
                                                        onBlur={handleBlur('numComptSalaire')}
                                                        value={values.numComptSalaire}
                                                        placeholder={ t('Numéro de compte salaire') ?? ''} />
                                                    { errors.numComptSalaire && touched.numComptSalaire && errors.numComptSalaire && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.numComptSalaire && touched.numComptSalaire && errors.numComptSalaire }
                                                    </div> }
                                        </div>


                                        <div className="form-group col-md-6">
                                                <label> { t('Boutique affiliée') } </label>
                                                        {
                                                            shopsLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <select  
                                                                className={`form-control custom-select ${ errors.shopId &&
                                                                    touched.shopId ? "is-invalid" : "" }`}
                                                                id="shopId" 
                                                                name="shopId"
                                                                onChange={event => { 
                                                                    handleChange('shopId')(event); 
                                                                }}
                                                                onBlur={handleBlur('shopId')}
                                                                value={values.shopId}
                                                                placeholder={ t('Boutique affiliée') ?? ''} >
                                                                <option selected>{ t('Boutique affiliée') }...</option>
                                                                {
                                                                    shops.map(
                                                                        (shop: any, id) => <option 
                                                                         key={id} value={shop.id}>
                                                                            {
                                                                                shop.libBoutique
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </select> 
                                                        }
                                        </div>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                
                                                    <div className='form-group'> 
                                                                    <label>
                                                                        { t('Casier judiciaire') }
                                                                    </label>
                                                                    <div className="custom-file">
                                                                            <input type="file" 
                                                                            className={`form-control custom-file-input `} 
                                                                                id="casier" 
                                                                                onChange={event => changeListener(event, 'casier')} 
                                                                                placeholder="Photo" />
                                                                            <label className="custom-file-label" 
                                                                            htmlFor="casier">
                                                                                { t('Choisir un fichier') }
                                                                            </label>
                                                                    </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    { formRef.current !== null ? formRef.current.values.cassierJudiciaire !== '' ?
                                                        <a target="_blank" download={'Casier judiciaire'}
                                                                            href={ formRef.current.values.cassierJudiciaire } className="list-group-item">
                                                                                {
                                                                                    'Casier judiciaire'
                                                                                }
                                                                                </a> : <></> : <></>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="form-group col-md-6">
                                                <label> { t("Type de pièce d'identité") } </label>
                                                        {
                                                            pieceTypesLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <select  
                                                                className={`form-control custom-select ${ errors.idTypePieceIdentite &&
                                                                    touched.idTypePieceIdentite ? "is-invalid" : "" }`}
                                                                id="idTypePieceIdentite" 
                                                                name="idTypePieceIdentite"
                                                                onChange={event => { 
                                                                    handleChange('idTypePieceIdentite')(event); 
                                                                }}
                                                                onBlur={handleBlur('idTypePieceIdentite')}
                                                                value={values.idTypePieceIdentite}
                                                                placeholder={ t("Type de pièce d'identité") ?? '' } >
                                                                <option selected>{ t("Type de pièce d'identité") }...</option>
                                                                {
                                                                    pieceTypes.map(
                                                                        (piece: any, id) => <option 
                                                                         key={id} value={piece.id}>
                                                                            {
                                                                                piece.libelle
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </select> 
                                                        }
                                        </div>
                                        
                                        <div className="form-group col-md-6" >
                                                    <label> { t("Numéro de la pièce d'identité") } </label>
                                                    <input 
                                                        className={`form-control ${ errors.numPieceIdentite &&
                                                             touched.numPieceIdentite ? "is-invalid" : "" }`}
                                                        id="numPieceIdentite"
                                                        type="text"
                                                        name="numPieceIdentite"
                                                        onChange={handleChange('numPieceIdentite')}
                                                        onBlur={handleBlur('numPieceIdentite')}
                                                        value={values.numPieceIdentite}
                                                        placeholder={ t("Numéro de la pièce d'identité") ?? '' } />
                                                    { errors.numPieceIdentite && touched.numPieceIdentite && errors.numPieceIdentite && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.numPieceIdentite && touched.numPieceIdentite && errors.numPieceIdentite }
                                                    </div> }
                                        </div>

                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label>
                                                        { t("Pièce d'identité") }
                                                    </label>
                                                    <div className='form-group'> 
                                                                        <div className="custom-file">
                                                                            <input type="file" 
                                                                            className={`form-control custom-file-input `} 
                                                                                id="identite" 
                                                                                onChange={event => changeListener(event, 'identite')} 
                                                                                placeholder="Photo" />
                                                                            <label className="custom-file-label" 
                                                                            htmlFor="identite">
                                                                                { t('Choisir un fichier') }
                                                                            </label>
                                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    { formRef.current !== null ? formRef.current.values.pieceIdentite !== '' ?
                                                        <a target="_blank" download={'Pièce d\'identité'}
                                                                            href={ formRef.current.values.pieceIdentite } className="list-group-item">
                                                                                {
                                                                                    'Pièce d\'identité'
                                                                                }
                                                                                </a> : <></> : <></>
                                                    }
                                                </div>
                                            </div>
                                        </div>


                                       
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label> { t('Photo') } </label>
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">
                                                                { t('Envoyer') }
                                                            </span>
                                                        </div>
                                                        <div className="custom-file">
                                                            <input type="file" 
                                                            
                                                                id="photoProfil" 
                                                                onChange={changeListener} 
                                                                placeholder="Photo" />
                                                            <label className="custom-file-label" 
                                                            htmlFor="photoProfil">
                                                                { t('Choisir un fichier') }
                                                            </label>
                                                        </div>
                                                    </div> 
                                                     
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <img src={image} 
                                                className="img-responsive" width="304" height="236" alt="" />
                                            </div>

                                        
                                        
                                            <div className="col-md-6">
                                                <hr />
                                                <b>
                                                    { t('Fichiers agent') }
                                                </b>
                                                <div className="form-group">
                                                    <label>
                                                        { t('Libelle du fichier') }
                                                    </label>
                                                    <Field className='form-control' type="text"
                                                        // className={`form-control 
                                                        // ${ errors.qteAvAlertFinStock && touched.qteAvAlertFinStock ? "is-invalid":""}`}
                                                        // id="qteAvAlertFinStock" 
                                                        // name="qteAvAlertFinStock"  
                                                        onChange={(event: any) => {
                                                            setAgentFileLabel(event.target.value);
                                                        }}
                                                        // onBlur={handleBlur('qteAvAlertFinStock')}
                                                        value={ agentFileLabel == null ? '' : agentFileLabel }
                                                        //onKeyPress={onAddIngredient}
                                                        placeholder={ t('Libelle du fichier') ?? ''} />
                                                    {/* <input 
                                                        className={`form-control ${ errors.agentFileLabel &&
                                                             touched.agentFileLabel ? "is-invalid":""}`}
                                                        id="agentFileLabel"
                                                        type="text"
                                                        name="agentFileLabel"
                                                        onChange={handleChange('agentFileLabel')}
                                                        onBlur={handleBlur('agentFileLabel')}
                                                        value={values.agentFileLabel}
                                                        placeholder="Libellé du fichier" />
                                                    { errors.agentFileLabel && touched.agentFileLabel &&
                                                     errors.agentFileLabel && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.agentFileLabel && touched.agentFileLabel &&
                                                         errors.agentFileLabel }
                                                    </div> } */}
                                                </div>

                                                <div className='form-group'>
                                                    <label> 
                                                        { t('Fichier') } </label>
                                                    <div className='form-group'> 
                                                                    <div className="custom-file">
                                                                        <input type="file" 
                                                                        className={`form-control custom-file-input `} 
                                                                            id="agentFile" 
                                                                            onChange={event => changeListener(event, 'agent_files')} 
                                                                            placeholder="Photo" />
                                                                        <label className="custom-file-label" 
                                                                        htmlFor="agentFile">
                                                                            { t('Choisir un fichier') }
                                                                        </label>
                                                                    </div>
                                                    </div>
                                                </div>
                                                
                                            </div>

                                            <div className="col-md-6">
                                                    <br/>
                                                <div className="list-group"> 
                                                    <a href="javascript:void(0)"
                                                            className="list-group-item active">
                                                                {t('Fichiers agent')} </a> 
                                                            {
                                                                agentFiles.map(
                                                                    (file: any, id) => {
                                                                        return <a key={id} target="_blank" download={file?.label}
                                                                        href={ file?.file } className="list-group-item">
                                                                              {
                                                                                file?.label
                                                                              }
                                                                            </a> 
                                                                    }
                                                                )
                                                            }
                                                            
                                                          
                                                            {/* <a href="javascript:void(0)" className="list-group-item">
                                                            </a>  */}
                                                </div>
                                                
                                            </div>


                                            <div className='col-md-12'>
                                                <button disabled={
                                                    !agentFileLabel || !agentFile
                                                }  onClick={ sendAgentFile } 
                                                    type="button" className="btn btn-info">
                                                    { t('Envoyer') }
                                                    {
                                                        loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    }
                                                </button>
                                            </div>

                                            <div className="col-md-12">
                                                <div style={{ textAlign: 'center' }}>
                                                        <hr style={{ color: 'black' }} />
                                                        <p style={{ color: 'black', fontSize: "1em", fontWeight: "bold" }} >
                                                        { t('PERSONNE A PRÉVENIR') } </p>
                                                        <hr style={{ color: 'black' }} />
                                                </div>
                                            </div>

                                            <div className="form-group col-md-6" >
                                                    <label> { t("Nom complet") } </label>
                                                    <input 
                                                        className={`form-control ${ errors.fullnamePersPrev &&
                                                             touched.fullnamePersPrev ? "is-invalid" : "" }`}
                                                        id="fullnamePersPrev"
                                                        type="text"
                                                        name="fullnamePersPrev"
                                                        onChange={handleChange('fullnamePersPrev')}
                                                        onBlur={handleBlur('fullnamePersPrev')}
                                                        value={values.fullnamePersPrev}
                                                        placeholder={ t("Nom complet") ?? '' } />
                                                    { errors.fullnamePersPrev && touched.fullnamePersPrev && errors.fullnamePersPrev && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.fullnamePersPrev && touched.fullnamePersPrev && errors.fullnamePersPrev }
                                                    </div> }
                                            </div>

                                            <div className="form-group col-md-6" >
                                                    <label> { t("Numéro de téléphone") } </label>
                                                    <input 
                                                        className={`form-control ${ errors.phonePersPrev &&
                                                             touched.phonePersPrev ? "is-invalid" : "" }`}
                                                        id="phonePersPrev"
                                                        type="text"
                                                        name="phonePersPrev"
                                                        onChange={handleChange('phonePersPrev')}
                                                        onBlur={handleBlur('phonePersPrev')}
                                                        value={values.phonePersPrev}
                                                        placeholder={ t("Numéro de téléphone") ?? '' } />
                                                    { errors.phonePersPrev && touched.phonePersPrev && errors.phonePersPrev && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.phonePersPrev && touched.phonePersPrev && errors.phonePersPrev }
                                                    </div> }
                                        </div>

                                        <div className="form-group col-md-6" >
                                                    <label> { t("Adresse") } </label>
                                                    <input 
                                                        className={`form-control ${ errors.addressPersPrev &&
                                                             touched.addressPersPrev ? "is-invalid" : "" }`}
                                                        id="addressPersPrev"
                                                        type="text"
                                                        name="addressPersPrev"
                                                        onChange={handleChange('addressPersPrev')}
                                                        onBlur={handleBlur('addressPersPrev')}
                                                        value={values.addressPersPrev}
                                                        placeholder={ t("Adresse") ?? '' } />
                                                    { errors.addressPersPrev && touched.addressPersPrev && errors.addressPersPrev && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.addressPersPrev && touched.addressPersPrev && errors.addressPersPrev }
                                                    </div> }
                                        </div>




                                    </div>
                                    <div className="form-actions">
                                        <div className="text-right">
                                            <button  disabled={!dirty} type="submit" className="btn btn-info">
                                                { t('Valider') }
                                                {
                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                            <button type="reset" className="btn btn-dark">
                                                { t('Annuler') }
                                            </button>
                                        </div>
                                    </div>
                        </Form>
                    )}
                </Formik>
    );
}

export default AgentForm