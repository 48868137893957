export default class Ressource {
    public id?:  number | null;
    public idRes:  number | null;
    public qteRes:  number | null;
    public puRes:  number | null;
    public montant:  number | null;
    public agent: any | null;


    static fromJson(parsedJson: Record<string, any>): Ressource {
        return new Ressource(
            Number(parsedJson['id']),
            Number(parsedJson['idRes']),
            Number(parsedJson['qteRes']),
            Number(parsedJson['puRes']),
            Number(parsedJson['montant']),
            Number(parsedJson['agent'])
        );
    }

    static toJson(ressource: Ressource): Record<string, any>  {
        return  {
            "id": ressource.id,
            "idRes": ressource.idRes,
            "qteRes": ressource.qteRes,
            "puRes": ressource.puRes,
            "montant": ressource.montant,
            "agent": ressource.agent,
        };
    }


    constructor(id: number, idRes: number, qteRes: number, puRes: number, montant: number, agent: any ) {
        this.id = id;
        this.idRes = idRes;
        this.qteRes = qteRes;
        this.puRes = puRes;
        this.montant = montant;
        this.agent  = agent;
    }
    
}