import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import {
    Link, useLocation, useNavigate, useParams
} from "react-router-dom";
import Breadcrumb from '../../Components/Breadcrumb';
import ClientForm from './form';
import ClientService from './service';


const ClientEdit: FC = () => {  
    const location: any = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const clientService = new ClientService();

    let { id } = useParams();

    const [client, setClient] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        clientService.getClient(Number(id)).then(async function (response: any) {
            console.log(response); 
            setClient(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }, []);

    return ( 
        <> 
            <Breadcrumb />

            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">
                                    { t('Modifier un client') }
                                </h4>
                                <Link type="button" to="/client/list"
                                    className="btn waves-effect waves-light btn-primary">
                                    { t('Liste des clients') }   
                                </Link>
                                <br />
                                <br />

                                { !loading && client ?
                                    <ClientForm state='edit' client={ client } />  
                                    :
                                    <div className="spinner-border text-primary spinner-border-lg" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }     
                                 
                                </div>
                            </div> 
                    </div> 
                </div>

            </div>   
        </>                   
    );
}

export default ClientEdit