import Ressource from '../../Models/Ressource';
import axiosInstance from '../../Utils/axios_manager';

export default class HistoryService {
  
    constructor() {  }

    public getHistories =  async () => {
        return await axiosInstance.get('/event/history/content/list' );
    } 
}