export default class SalaryType {

    public id?: number | null;
    public libelle?: string;
    public description?: string;


    static fromJson(parsedJson: Record<string, any>): SalaryType {
        return new SalaryType(
            Number(parsedJson['id']),
            parsedJson['libelle'],
            parsedJson['description']
        );
    }

    static toJson(salType: SalaryType): Record<string, any>  {
        return  {
            "id": salType.id,
            "libelle": salType.libelle, 
            "description": salType.description
        };
    }


    constructor(id: number, libelle: string, description: string) {
        this.id = id;
        this.libelle = libelle;
        this.description = description;
    }
}