import Salary from '../../Models/Salary';
import SalaryType from '../../Models/SalaryType';
import Shop from '../../Models/Shop';
import Spent from '../../Models/Spent';
import SpentType from '../../Models/SpentType';
import axiosInstance from '../../Utils/axios_manager';

export default class ComptaService {
  
    constructor() { }

 
    public comptaDetail =  async (data: Record<string, any>) => {
        return await axiosInstance.post('/compta/vente/periode', data);
    }

    public supervizeSalary =  async (data: Record<string, any>) => {
        return await axiosInstance.post('/salaires/supervise', data);
    }

    public getSupervizeSalaries =  async () => {
        return await axiosInstance.get('/salaires/list/supervised');
    }

}