import { FC, useEffect, useState } from 'react'
import {
    Link, useLocation, useNavigate, useParams
} from "react-router-dom";
import Breadcrumb from '../../Components/Breadcrumb';
import SpentForm from './form';
import SpentService from './service';


const SpentDetail: FC = () => {  
    const location: any = useLocation();
    const navigate = useNavigate();

    const spentService = new SpentService();

    let { id } = useParams();

    const [spent, setSpent] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const sp = JSON.parse(window.localStorage.getItem('depense') ?? '');
        if (sp !== null) {
            console.log(sp);
            setSpent(sp);
            setLoading(false); 
        } else {
            navigate('/depense/list');
        }
        // spentService.getSpent(Number(id)).then(async function (response: any) {
        //     console.log(response); 
       
        // })
        //   .catch(function (error: any) {
        //     console.log(error);
        //     setLoading(false); 
        // });
    }, []);

    return ( 
        <> 
            <Breadcrumb />

            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Modifier un produit</h4>
                                <Link type="button" to="/depense/list"
                                    className="btn waves-effect waves-light btn-primary">
                                        Liste des dépenses
                                </Link>
                                <br />
                                <br />

                                {   
                                    !loading && spent ?
                                    <SpentForm state='view' spent={ spent } />  
                                    :
                                    <div className="spinner-border text-primary spinner-border-lg" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }     
                                 
                                </div>
                            </div> 
                    </div> 
                </div>

            </div>   
        </>                   
    );
}

export default SpentDetail