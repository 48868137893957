import Ressource from '../../Models/Ressource';
import axiosInstance from '../../Utils/axios_manager';

export default class  RessourceService {
  
    constructor( ) {
    }

    public getRessources =  async () => {
        return await axiosInstance.get('/ressource/list/all' );
    }

    public postRessourcePayment =  ( ressource: Ressource ) => {
        return axiosInstance.post('/ressource/buy', Ressource.toJson(ressource));
    }

    public updateRessourcePayment =  ( ressource: Ressource ) => {
        return axiosInstance.post('/ressource/buy/update', Ressource.toJson(ressource));
    }

    public updateRessource =  ( ressource: Ressource ) => {
        return axiosInstance.put('/droit/update', Ressource.toJson(ressource));
    }

    public checkResRemainingAmount =  async (idRes: Number) => {
        return await axiosInstance.get('/ressource/remaining/amount' + `?idRes=${idRes}` );
    }

    public getRessourceDetails = async (idRes: Number) => {
        return await axiosInstance.get('/ressource/details' + `?id=${idRes}` );
    }

    public deleteRessourcePayment = async (idBuy: Number) => {
        return await axiosInstance.get('/ressource/buy/delete' + `?idBuy=${idBuy}` );
    }

    // public deleteRight  = ( rightId: Number ) => {
    //     return axiosInstance.delete('/droit/delete' + `?id=${rightId}`);
    // }
  

}