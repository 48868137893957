import { FC, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import ApprovService from './service'; 

//Moment react
import Moment from 'react-moment';

import {
    Link, useNavigate
} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const ApprovList: FC = () => {  
    let idToModInit: any = null;
    const [ approvs, setApprovs ] = useState( [] );
    const [ idToMod, setIdToMod ] = useState(idToModInit);
    const [ loading, setLoading ] = useState(false);
    const approvService = new ApprovService();

    const navigate = useNavigate();
    const { t } = useTranslation();

    let editAgent = (id: Number): void => {
        setIdToMod(id);
        setLoading(true);
        navigate('/agent/edit/'+id.toString());
        setLoading(false);
    }

    const getApprovs = () => {
        setLoading(true);
        approvService.getApprovs().then(async function (response: any) {
            console.log(response);
            setApprovs(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    useEffect( function() { 
        getApprovs();
    }, []);

    const columns = [
        {
            name: '#',
            selector: (row: any) => row.id,
        },
        {
            name:  `${ t('Boutique') }` ,
            selector: (row: any) => row.boutique.libBoutique,
        },
        {
            name: `${ t("Agent") }` ,
            selector: (row: any) => row.agent.prenomAgent + ' '+  row.agent.nomAgent,
        },
        {
            name: `${ t("Date") }`,
            selector: (row: any) => <Moment format="YYYY/MM/DD hh:mm:ss">
                                        {row.dateApprovisionnement}
                                    </Moment>
        },
        {
            name: "Options", 
            selector: (row: any) => (
                <>
                    <button type="button" onClick={event => {
                            // navigate('/client/edit/'+row.id.toString());
                        }}
                            className="btn waves-effect waves-light btn-success">
                                <span className="" style={{ fontSize: "1em" }} >
                                    <i className="icon-pencil"></i></span>
                    </button>
                    <button type="button" onClick={event => {  }}
                        className="btn waves-effect waves-light btn-danger"> 
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-trash"></i></span>
                    </button> 
                </>
            )
        }
    ];
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-list">
                                    <button type="button" onClick={ event => getApprovs() }
                                        className="btn waves-effect waves-light btn-danger">
                                            { t('Actualiser') }
                                        </button>
                                        <Link type="button" to="/approvisionnement/create"
                                            className="btn waves-effect waves-light btn-primary">
                                            { t('Ajouter un approvisonnement') }    
                                        </Link>
                                    </div>
                                    
                                    <br />
                                    <br />
                                    {/* <h4 className="card-title">Zero Configuration</h4>
                                    <h6 className="card-subtitle">DataTables has most features enabled by default, so all you
                                        need to do to use it with your own tables is to call the construction
                                        function:<code> $().DataTable();</code>. You can refer full documentation from here
                                        <a href="https://datatables.net/">Datatables</a></h6> */}
                                    <div className="table-responsive">
                                        <DataTable
                                            responsive={true}
                                            className="table table-striped table-bordered"
                                            columns={columns}
                                            data={approvs}
                                            progressPending={loading}
                                            pagination
                                        />
                                        </div>
                                    </div>
                                </div> 
                        </div> 
                    </div>

                </div>   
            </>                   

    );
}

export default ApprovList