import { FC, useEffect, useState, useRef } from 'react'
import {
    Link, useNavigate 
} from "react-router-dom";

import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 

import TransportService from './service';
import User from '../../Models/User';
import ShopService from '../Shop/service';

import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
import { RootState } from '../../Redux/store';

//Alert
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Alert from '../../Components/Alert';
import { useTranslation } from 'react-i18next';

interface TransportProps {
    idSer?: string;
    libSpService: string; 
    siteSpService: string;
    loginSpService: string; 
    passwordSpService: string;
    passwordSpServiceConfirm: string;
    emailSpService: string;
    contactSpService: string;
    ownerEmail: string; 
    ownerLastName: string;
    ownerFirstName: string;
    accountId: string;
    owner_addresseLineOne: string;
    owner_addresseLineTwo: string;
    owner_city: string;
    owner_stateOrProvice: string;
    owner_zipOrPostalCode: string;
    owner_country: string;
}

interface TransportFormProps {
    state: string;
    transport?: TransportProps | null; 
}




const TransportForm: FC<TransportFormProps> = ( {  state, transport } ) => {
   

    let navigate = useNavigate();
    const { t, i18n } = useTranslation();

    let [countriesLoading, setCountriesLoading] = useState(false);
    let [ countries, setCountries ] = useState([]);

    const user = useAppSelector((state: RootState ) => state.users.user );

    let [image, setImage] = useState('');

    let initialProduct: any  = null;
    let [product, setProduct ] = useState(initialProduct);

    let [typePromo, setTypePromo] = useState('');

    let [loading, setLoading] = useState(false);

    let [siteSpServiceLoading, setBarcodeLoading] = useState(false);

    let [ shopsLoading, setShopsLoading] = useState(false);

    const [ shops, setShops ] = useState( [] );

    let [transportTypes] = useState([
        {
            label: 'Pourcentage',
            value: 'percentage'
        },
        {
            label: 'Montant fixe',
            value: 'fixedAmount'
        }
      ]);

    let [sendingMethods] = useState([
        {
            label: 'Imprimer',
            value: 'print'
        },
        {
            label: 'E-mail',
            value: 'email'
        }
      ]);

    const transportService = new TransportService();
    const shopService = new ShopService();
    
    const readThis = (inputValue: any): void => {
        var file: File = inputValue.files[0];
        var lecteur: FileReader = new FileReader();
    
        lecteur.onloadend = (e) => {
            setImage( lecteur.result as string );
            // if (Object.prototype.toString.call(lecteur.result) === "[object String]") {
                
            // }
            
            console.log(lecteur.result);
        }
        lecteur.readAsDataURL(file);
    }

    const changeListener = (event: any)  => {
        readThis(event.target);
    }

    const getShops = () => {
        setShopsLoading(true);
        shopService.getShops().then(async function (response: any) {
            console.log(response);
            setShops(response.data.services);
            setShopsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setShopsLoading(false); 
        });
    }

    const getCountries = () => {
        setCountriesLoading(true);
        shopService.getCountries().then(async function (response: any) {
            console.log(response);
            setCountries(response.data);
            setCountriesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setCountriesLoading(false); 
        });
    }

    const formRef = useRef<FormikProps< TransportProps >>(null);

    useEffect(() => {

        getShops();
        getCountries();

        if (transport != null  && transport != undefined) {
            // setImage(client.profil ?? '');
        }

    }, []);

 


    return (
        <> 
        <Formik
        
        initialValues={
            transport != null  && transport != undefined ? 
            {
                ...transport 
            }:
            {

                idSer: '',
                libSpService: '',
                siteSpService: '',
                loginSpService: '', 
                passwordSpService: '',
                passwordSpServiceConfirm: '',
                emailSpService: '',
                contactSpService: '',
                ownerEmail: '',
                owner_addresseLineOne: '',
                owner_addresseLineTwo: '', 
                owner_city: '' , 
                owner_stateOrProvice: '', 
                owner_zipOrPostalCode: '',
                owner_country: '', 
                ownerLastName: '',
                ownerFirstName: '',
                accountId: '',
                service_addresseLineOne: '',
                service_addresseLineTwo: '', 
                service_city: '' , 
                service_stateOrProvice: '', 
                service_zipOrPostalCode: '',
                service_country: '', 
                 
                
        }}
        validationSchema={
            yup.object().shape({
                libSpService: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                siteSpService: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                loginSpService: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`), 
                passwordSpService: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                passwordSpServiceConfirm: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`)
                    .oneOf([yup.ref('passwordSpService'), null], 'Les mot de passe ne correspondent pas.'),
                emailSpService: yup
                    .string()
                    .email('Email invalide')
                    .required(`${t('Ce champ est obligatoire')}`),
                contactSpService: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                ownerEmail: yup
                    .string()
                    .email('Email invalide')
                    .required(`${t('Ce champ est obligatoire')}`),
                owner_addresseLineOne: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`), 
                owner_addresseLineTwo: yup
                    .string(),
                owner_city: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                owner_stateOrProvice: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                owner_zipOrPostalCode: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                owner_country: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                service_addresseLineOne: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`), 
                service_addresseLineTwo: yup
                    .string(),
                service_city: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                service_stateOrProvice: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                service_zipOrPostalCode: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                service_country: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                ownerLastName: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                ownerFirstName: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                accountId: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
            })
        }
        innerRef={formRef}
        onSubmit={async (
            values 
          ) => {
                    console.log(values);
                    setLoading(true);

                    // if (image != null && image != '' ) {
                    //     values.photoProfil = image;
                    // }

                    if (state == 'create') {
                        transportService.postTransport( values ).then(async function (response: any) {
                            console.log(response);

                            confirmAlert({
                                overlayClassName: "alert-overlay",
                                customUI: ({ onClose }) => {
                                  return (
                                        <Alert 
                                            alert={{
                                                title: 'Succès',
                                                message: 'Transport ajoutée avec succès',
                                                actionText: 'Ok',
                                                action: () => {
                                                    onClose();
                                                }
                                            }}
                                        />
                                  );
                                }
                            });

                            navigate('/transport/list');
                            setLoading(false); 
                        })
                          .catch(function (error: any) {
                            console.log(error);
                            alert("Une erreur s'est produite réesseyez s'il vous plaît.")
                            setLoading(false); 
                        });
                    } else if (state == 'edit') {
                        transportService.putTransport( values ).then(async function (response: any) {
                            console.log(response);

                            confirmAlert({
                                overlayClassName: "alert-overlay",
                                customUI: ({ onClose }) => {
                                  return (
                                        <Alert 
                                            alert={{
                                                title: 'Succès',
                                                message: 'Transport mis à jour avec succès',
                                                actionText: 'Ok',
                                                action: () => {
                                                    onClose();
                                                }
                                            }}
                                        />
                                  );
                                }
                            });
  
                            navigate('/client/list');
                            setLoading(false); 
                        })
                          .catch(function (error: any) {
                            console.log(error);
                            alert("Une erreur s'est produite réesseyez s'il vous plaît.")
                            setLoading(false); 
                        });
                    }
                    
                }}
                >
                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                        <Form>
                                
                                    <div className="form-body "> 

                                    <div style={{ textAlign: 'center' }}>
                                                        <hr style={{ color: 'black' }} />
                                                        <p style={{ color: 'black', fontSize: "1em", fontWeight: "bold" }} >
                                                        { t('SERVICE') } </p>
                                                        <hr style={{ color: 'black' }} />
                                    </div>
                                        <div className='row'>
                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Libellé') }
                                                        </label>
                                                        <input 
                                                            className={`form-control ${ errors.libSpService && touched.libSpService ? "is-invalid":""}`}
                                                            id="libSpService"
                                                            type="text"
                                                            name="libSpService"
                                                            onChange={handleChange('libSpService')}
                                                            onBlur={handleBlur('libSpService')}
                                                            value={values.libSpService}
                                                            placeholder={ t('Libellé') ?? '' } />
                                                        { errors.libSpService && touched.libSpService && errors.libSpService && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.libSpService && touched.libSpService && errors.libSpService }
                                                        </div> }
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Site web') }
                                                        </label>
                                                        <input 
                                                            className={`form-control ${ errors.siteSpService &&
                                                                 touched.siteSpService ? "is-invalid":""}`}
                                                            id="siteSpService"
                                                            type="text"
                                                            name="siteSpService"
                                                            onChange={handleChange('siteSpService')}
                                                            onBlur={handleBlur('siteSpService')}
                                                            value={values.siteSpService}
                                                            placeholder={ t('Site web') ?? ''} />
                                                        { errors.siteSpService && touched.siteSpService && errors.siteSpService && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.siteSpService && touched.siteSpService && errors.siteSpService }
                                                        </div> }
                                                    </div>


                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Email') }
                                                        </label>
                                                        <input 
                                                            className={`form-control ${ errors.emailSpService &&
                                                                 touched.emailSpService ? "is-invalid":""}`}
                                                            id="emailSpService"
                                                            type="email"
                                                            name="emailSpService"
                                                            onChange={handleChange('emailSpService')}
                                                            onBlur={handleBlur('emailSpService')}
                                                            value={values.emailSpService}
                                                            placeholder={ t('Email') ?? '' } />
                                                        { errors.emailSpService && touched.emailSpService && errors.emailSpService && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.emailSpService && touched.emailSpService && errors.emailSpService }
                                                        </div> }
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Contact') }
                                                        </label>
                                                        <input 
                                                            className={`form-control ${ errors.contactSpService &&
                                                                 touched.contactSpService ? "is-invalid":""}`}
                                                            id="contactSpService"
                                                            type="text"
                                                            name="contactSpService"
                                                            onChange={handleChange('contactSpService')}
                                                            onBlur={handleBlur('contactSpService')}
                                                            value={values.contactSpService}
                                                            placeholder={ t('Contact') ?? '' } />
                                                        { errors.contactSpService && touched.contactSpService && errors.contactSpService && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.contactSpService && touched.contactSpService && errors.contactSpService }
                                                        </div> }
                                                    </div>

                                                    <div className='form-group col-md-6'>
                                            <label>
                                                { t('Ligne Adresse') } 1
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.service_addresseLineOne &&
                                                             touched.service_addresseLineOne ? "is-invalid":""}`}
                                                        id="service_addresseLineOne"
                                                        type="text"
                                                        name="service_addresseLineOne"
                                                        onChange={handleChange('service_addresseLineOne')}
                                                        onBlur={handleBlur('service_addresseLineOne')}
                                                        value={values.service_addresseLineOne}
                                                        placeholder={ t('Ligne Adresse') + ' 1' ?? '' } />
                                                    { errors.service_addresseLineOne && touched.service_addresseLineOne && errors.service_addresseLineOne && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.service_addresseLineOne && touched.service_addresseLineOne && errors.service_addresseLineOne }
                                                    </div> }
                                        </div> 

                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Ligne Adresse') } 2
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.service_addresseLineTwo &&
                                                             touched.service_addresseLineTwo ? "is-invalid":""}`}
                                                        id="service_addresseLineTwo"
                                                        type="text"
                                                        name="service_addresseLineTwo"
                                                        onChange={handleChange('service_addresseLineTwo')}
                                                        onBlur={handleBlur('service_addresseLineTwo')}
                                                        value={values.service_addresseLineTwo}
                                                        placeholder={ t('Ligne Adresse') + ' 2' ?? '' } />
                                                    { errors.service_addresseLineTwo && touched.service_addresseLineTwo && errors.service_addresseLineTwo && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.service_addresseLineTwo && touched.service_addresseLineTwo && errors.service_addresseLineTwo }
                                                    </div> }
                                        </div> 


                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Ville') }
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.service_city &&
                                                             touched.service_city ? "is-invalid":""}`}
                                                        id="service_city"
                                                        type="text"
                                                        name="service_city"
                                                        onChange={handleChange('service_city')}
                                                        onBlur={handleBlur('service_city')}
                                                        value={values.service_city}
                                                        placeholder={ t('Ville') ?? '' } />
                                                    { errors.service_city && touched.service_city && errors.service_city && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.service_city && touched.service_city && errors.service_city }
                                                    </div> }
                                        </div> 

                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Région') }
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.service_stateOrProvice &&
                                                             touched.service_stateOrProvice ? "is-invalid":""}`}
                                                        id="service_stateOrProvice"
                                                        type="text"
                                                        name="service_stateOrProvice"
                                                        onChange={handleChange('service_stateOrProvice')}
                                                        onBlur={handleBlur('service_stateOrProvice')}
                                                        value={values.service_stateOrProvice}
                                                        placeholder={ t('Région') ?? '' } />
                                                    { errors.service_stateOrProvice && touched.service_stateOrProvice && errors.service_stateOrProvice && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.service_stateOrProvice && touched.service_stateOrProvice && errors.service_stateOrProvice }
                                                    </div> }
                                        </div> 

                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Code postal') }
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.service_zipOrPostalCode &&
                                                             touched.service_zipOrPostalCode ? "is-invalid":""}`}
                                                        id="service_zipOrPostalCode"
                                                        type="text"
                                                        name="service_zipOrPostalCode"
                                                        onChange={handleChange('service_zipOrPostalCode')}
                                                        onBlur={handleBlur('service_zipOrPostalCode')}
                                                        value={values.service_zipOrPostalCode}
                                                        placeholder={ t('Code postal') ?? '' } />
                                                    { errors.service_zipOrPostalCode && touched.service_zipOrPostalCode && errors.service_zipOrPostalCode && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.service_zipOrPostalCode && touched.service_zipOrPostalCode && errors.service_zipOrPostalCode }
                                                    </div> }
                                        </div> 

                                        <div className="form-group col-md-6">
                                                <label> 
                                                    { t('Pays') } </label>
                                                        {
                                                            countriesLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <select  
                                                                className={`form-control custom-select ${ errors.service_country &&
                                                                    touched.service_country ? "is-invalid" : "" }`}
                                                                id="service_country" 
                                                                name="service_country"
                                                                onChange={event => { 
                                                                    handleChange('service_country')(event); 
                                                                }}
                                                                onBlur={handleBlur('service_country')}
                                                                value={values.service_country}
                                                                placeholder="Pays" >
                                                                <option selected>{ t('Pays') }..</option>
                                                                {
                                                                    countries.map(
                                                                        (country: any, id) => <option 
                                                                         key={id} value={country.id}>
                                                                            {
                                                                                country.name
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </select> 
                                                        }
                                                </div>
                                            </div>

                                                    <div style={{ textAlign: 'center' }}>
                                                        <hr style={{ color: 'black' }} />
                                                        <p style={{ color: 'black', fontSize: "1em", fontWeight: "bold" }} >
                                                            { t('PROPRIÉTAIRE') } </p>
                                                        <hr style={{ color: 'black' }} />
                                                    </div>

                                                    <div className='row'>
                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Email du propriétaire') }
                                                        </label>
                                                        <input 
                                                            className={`form-control ${ errors.ownerEmail &&
                                                                 touched.ownerEmail ? "is-invalid":""}`}
                                                            id="ownerEmail"
                                                            type="email"
                                                            name="ownerEmail"
                                                            onChange={handleChange('ownerEmail')}
                                                            onBlur={handleBlur('ownerEmail')}
                                                            value={values.ownerEmail}
                                                            placeholder={ t('Email du propriétaire') ?? '' }/>
                                                        { errors.ownerEmail && touched.ownerEmail && errors.ownerEmail && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.ownerEmail && touched.ownerEmail && errors.ownerEmail }
                                                        </div> }
                                                    </div>


                                                    <div className='form-group col-md-6'>
                                            <label>
                                                { t('Ligne Adresse') } 1
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.owner_addresseLineOne &&
                                                             touched.owner_addresseLineOne ? "is-invalid":""}`}
                                                        id="owner_addresseLineOne"
                                                        type="text"
                                                        name="owner_addresseLineOne"
                                                        onChange={handleChange('owner_addresseLineOne')}
                                                        onBlur={handleBlur('owner_addresseLineOne')}
                                                        value={values.owner_addresseLineOne}
                                                        placeholder={ t('Ligne Adresse') + ' 1' ?? '' } />
                                                    { errors.owner_addresseLineOne && touched.owner_addresseLineOne && errors.owner_addresseLineOne && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.owner_addresseLineOne && touched.owner_addresseLineOne && errors.owner_addresseLineOne }
                                                    </div> }
                                        </div> 

                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Ligne Adresse') } 2
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.owner_addresseLineTwo &&
                                                             touched.owner_addresseLineTwo ? "is-invalid":""}`}
                                                        id="owner_addresseLineTwo"
                                                        type="text"
                                                        name="owner_addresseLineTwo"
                                                        onChange={handleChange('owner_addresseLineTwo')}
                                                        onBlur={handleBlur('owner_addresseLineTwo')}
                                                        value={values.owner_addresseLineTwo}
                                                        placeholder={ t('Ligne Adresse') + ' 2' ?? '' } />
                                                    { errors.owner_addresseLineTwo && touched.owner_addresseLineTwo && errors.owner_addresseLineTwo && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.owner_addresseLineTwo && touched.owner_addresseLineTwo && errors.owner_addresseLineTwo }
                                                    </div> }
                                        </div> 


                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Ville') }
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.owner_city &&
                                                             touched.owner_city ? "is-invalid":""}`}
                                                        id="owner_city"
                                                        type="text"
                                                        name="owner_city"
                                                        onChange={handleChange('owner_city')}
                                                        onBlur={handleBlur('owner_city')}
                                                        value={values.owner_city}
                                                        placeholder={ t('Ville') ?? '' } />
                                                    { errors.owner_city && touched.owner_city && errors.owner_city && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.owner_city && touched.owner_city && errors.owner_city }
                                                    </div> }
                                        </div> 

                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Région') }
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.owner_stateOrProvice &&
                                                             touched.owner_stateOrProvice ? "is-invalid":""}`}
                                                        id="owner_stateOrProvice"
                                                        type="text"
                                                        name="owner_stateOrProvice"
                                                        onChange={handleChange('owner_stateOrProvice')}
                                                        onBlur={handleBlur('owner_stateOrProvice')}
                                                        value={values.owner_stateOrProvice}
                                                        placeholder={ t('Région') ?? '' } />
                                                    { errors.owner_stateOrProvice && touched.owner_stateOrProvice && errors.owner_stateOrProvice && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.owner_stateOrProvice && touched.owner_stateOrProvice && errors.owner_stateOrProvice }
                                                    </div> }
                                        </div> 

                                        <div className='form-group col-md-6'>
                                            <label>
                                                { t('Code postal') }
                                            </label>
                                            <input 
                                                        className={`form-control ${ errors.owner_zipOrPostalCode &&
                                                             touched.owner_zipOrPostalCode ? "is-invalid":""}`}
                                                        id="owner_zipOrPostalCode"
                                                        type="text"
                                                        name="owner_zipOrPostalCode"
                                                        onChange={handleChange('owner_zipOrPostalCode')}
                                                        onBlur={handleBlur('owner_zipOrPostalCode')}
                                                        value={values.owner_zipOrPostalCode}
                                                        placeholder={ t('Code postal') ?? '' } />
                                                    { errors.owner_zipOrPostalCode && touched.owner_zipOrPostalCode && errors.owner_zipOrPostalCode && 
                                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.owner_zipOrPostalCode && touched.owner_zipOrPostalCode && errors.owner_zipOrPostalCode }
                                                    </div> }
                                        </div> 

                                        <div className="form-group col-md-6">
                                                <label> 
                                                    { t('Pays') } </label>
                                                        {
                                                            countriesLoading ? 
                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                            </div> :
                                                            <select  
                                                                className={`form-control custom-select ${ errors.owner_country &&
                                                                    touched.owner_country ? "is-invalid" : "" }`}
                                                                id="owner_country" 
                                                                name="owner_country"
                                                                onChange={event => { 
                                                                    handleChange('owner_country')(event); 
                                                                }}
                                                                onBlur={handleBlur('owner_country')}
                                                                value={values.owner_country}
                                                                placeholder="Pays" >
                                                                <option selected>{ t('Pays') }..</option>
                                                                {
                                                                    countries.map(
                                                                        (country: any, id) => <option 
                                                                         key={id} value={country.id}>
                                                                            {
                                                                                country.name
                                                                            }
                                                                        </option>
                                                                    )
                                                                }
                                                            </select> 
                                                        }
                                                </div>

                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Prénom du propriétaire') }
                                                        </label>
                                                        <input 
                                                            className={`form-control ${ errors.ownerFirstName &&
                                                                 touched.ownerFirstName ? "is-invalid":""}`}
                                                            id="ownerFirstName"
                                                            type="text"
                                                            name="ownerFirstName"
                                                            onChange={handleChange('ownerFirstName')}
                                                            onBlur={handleBlur('ownerFirstName')}
                                                            value={values.ownerFirstName}
                                                            placeholder={ t('Prénom du propriétaire')  ?? ''} />
                                                        { errors.ownerFirstName && touched.ownerFirstName && errors.ownerFirstName && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.ownerFirstName && touched.ownerFirstName && errors.ownerFirstName }
                                                        </div> }
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Nom du propriétaire') }
                                                        </label>
                                                        <input 
                                                            className={`form-control ${ errors.ownerLastName &&
                                                                 touched.ownerLastName ? "is-invalid":""}`}
                                                            id="ownerLastName"
                                                            type="text"
                                                            name="ownerLastName"
                                                            onChange={handleChange('ownerLastName')}
                                                            onBlur={handleBlur('ownerLastName')}
                                                            value={values.ownerLastName}
                                                            placeholder={ t('Nom du propriétaire') ?? '' } />
                                                        { errors.ownerLastName && touched.ownerLastName && errors.ownerLastName && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.ownerLastName && touched.ownerLastName && errors.ownerLastName }
                                                        </div> }
                                                    </div>


                                                   


                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('ID du compte') }
                                                        </label>
                                                        <input 
                                                            className={`form-control ${ errors.accountId &&
                                                                 touched.accountId ? "is-invalid":""}`}
                                                            id="accountId"
                                                            type="text"
                                                            name="accountId"
                                                            onChange={handleChange('accountId')}
                                                            onBlur={handleBlur('accountId')}
                                                            value={values.accountId}
                                                            placeholder={ t('ID du compte') ?? ''} />
                                                        { errors.accountId && touched.accountId && errors.accountId && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.accountId && touched.accountId && errors.accountId }
                                                        </div> }
                                                    </div>

                                                    
                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t("ID de connexion") ?? '' }
                                                        </label>
                                                        <input 
                                                            className={`form-control ${ errors.loginSpService &&
                                                                 touched.loginSpService ? "is-invalid":""}`}
                                                            id="loginSpService"
                                                            type="text"
                                                            name="loginSpService"
                                                            onChange={handleChange('loginSpService')}
                                                            onBlur={handleBlur('loginSpService')}
                                                            value={values.loginSpService}
                                                            placeholder={ t("ID de connexion") ?? '' } />
                                                        { errors.loginSpService && touched.loginSpService && errors.loginSpService && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.loginSpService && touched.loginSpService && errors.loginSpService }
                                                        </div> }
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Mot de passe') }
                                                        </label>
                                                        <input 
                                                            className={`form-control ${ errors.passwordSpService &&
                                                                 touched.passwordSpService ? "is-invalid":""}`}
                                                            id="passwordSpService"
                                                            type="password"
                                                            name="passwordSpService"
                                                            onChange={handleChange('passwordSpService')}
                                                            onBlur={handleBlur('passwordSpService')}
                                                            value={values.passwordSpService}
                                                            placeholder={ t('Mot de passe') ?? '' }/>
                                                        { errors.passwordSpService && touched.passwordSpService && errors.passwordSpService && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.passwordSpService && touched.passwordSpService && errors.passwordSpService }
                                                        </div> }
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                        <label>
                                                            { t('Mot de passe(confirmation)') }
                                                        </label>
                                                        <input 
                                                            className={`form-control ${ errors.passwordSpServiceConfirm &&
                                                                 touched.passwordSpServiceConfirm ? "is-invalid":""}`}
                                                            id="passwordSpServiceConfirm"
                                                            type="password"
                                                            name="passwordSpServiceConfirm"
                                                            onChange={handleChange('passwordSpServiceConfirm')}
                                                            onBlur={handleBlur('passwordSpServiceConfirm')}
                                                            value={values.passwordSpServiceConfirm}
                                                            placeholder={ t('Mot de passe(confirmation)') ?? ''} />
                                                        { errors.passwordSpServiceConfirm && touched.passwordSpServiceConfirm && errors.passwordSpServiceConfirm && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                            { errors.passwordSpServiceConfirm && touched.passwordSpServiceConfirm && errors.passwordSpServiceConfirm }
                                                        </div> }
                                                    </div>

                                            {/* </div>
                                        </div> */}

                                        


                                        </div>
                                    </div>
                                    <div className="form-actions">
                                        <div className="text-right">
                                            <button  disabled={!dirty && !isValid } type="submit" className="btn btn-info">
                                                { t('Valider') }
                                                {
                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                            <button type="reset" className="btn btn-dark">
                                                { t('Annuler') }
                                            </button>
                                        </div>
                                    </div>
                        </Form>
                    )}
                </Formik>
                </>
    );
}

export default TransportForm