import { FC } from 'react' 
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../Components/Breadcrumb';


const InconstructionPage: FC = () => {  
    const { t } = useTranslation();
    return ( 
        <div className='row'>
            <div className='col-md-12'>
                <Breadcrumb />
                <br />
                <h1 className="page-title text-truncate text-dark font-weight-medium mb-1 text-center">
                    { t('Module en construction') }
                </h1>
            </div>
        </div>
    );
}

export default InconstructionPage