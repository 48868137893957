import User from '../../Models/User';
import axiosInstance from '../../Utils/axios_manager';

export default class TemplateService {
  
    constructor( ) {
    }
 

    public getTemplates =  async () => {
        return await axiosInstance.get('/template/list');
    }

    public getCompletedTemplates =  async () => {
        return await axiosInstance.get('/template/list/completed');
    }

    public getUncompletedTemplates =  async () => {
        return await axiosInstance.get('/template/list/unprocessed');
    }

    public postTemplate =  (data: Record<string, any>) => {
        return axiosInstance.post('/template/add', data);
    }

    public putTemplate =  (data: Record<string, any>) => {
        return axiosInstance.post('/template/update',data);
    }

    public getTemplate =  async (id: Number) => {
        return await axiosInstance.get('/template/details' + `?idTemp=${id.toString()}`);
    }

    public sendPromotionMail =  (data: Record<string, any>) => {
        return axiosInstance.post('/template/sendmail/promo', data);
    }

    public getPrintInformation =  async (id: Number) => {
        return await axiosInstance.get('/template/print/informations' + `?idPromo=${id.toString()}`);
    }

  

}