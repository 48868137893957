import { FC, useEffect, useState, useRef } from 'react'
import {
    Link, useNavigate 
} from "react-router-dom";

import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 

import ClientService from './service';
import User from '../../Models/User';
import TemplateService from './service';

import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
import { RootState } from '../../Redux/store';

//Alert
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Alert from '../../Components/Alert';
import PromotionService from '../Promotion/service';

import Breadcrumb from '../../Components/Breadcrumb';

import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';


interface SenMailFormProps {
    idTemp: string;
    idPromo: string;  
    idSender?: number;
}
 

const PrintFlyersPage: FC = () => {
   

    let navigate = useNavigate();

    const { t, i18n } = useTranslation();

    const formRef = useRef<FormikProps< SenMailFormProps >>(null);

    const user = useAppSelector((state: RootState ) => state.users.user );

    let [ clients , setClients ] = useState([]);

    let [ templates , setTemplates ] = useState([]);
    let [ promotions , setPromotions ] = useState([]);

    let [loading, setLoading] = useState(false);

    let [ templatesLoading, setTemplatesLoading] = useState(false);
    let [ promotionsLoading, setPromotionsLoading] = useState(false);

    const templateService = new  TemplateService();
    const promotionService = new  PromotionService();

    const getTemplates = () => {
        setTemplatesLoading(true);
        templateService.getCompletedTemplates().then(async function (response: any) {
            console.log(response);
            setTemplates(response.data.templates);
            setTemplatesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setTemplatesLoading(false); 
        });
    }

    const getPromotions = () => {
        setPromotionsLoading(true);
        promotionService.getPromotions().then(async function (response: any) {
            console.log(response);
            setPromotions(response.data.promos);
            setPromotionsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setPromotionsLoading(false); 
        });
    }

    const columns = [ 
        {
            name: t('Nom'),
            selector: (row: any) => row.nom,
        },
        {
            name: t("Prénoms"),
            selector: (row: any) => row.prenom,
        }, 
        {
            name: t("Email"),
            selector: (row: any) => row.email,
        }, 
        {
            name: t("Contact"),
            selector: (row: any) => row.contact,
        },
        {
            name: t("Code promo"),
            selector: (row: any) => row.promoCode,
        }
    ];

    useEffect( function() { 
        getTemplates();
        getPromotions();
    }, []);

    const clear = () => {
        if (formRef.current !== null)  {
            formRef.current.values.idTemp = '';
            formRef.current.values.idPromo = '';
        }
    }

 
    

    return ( 
        <> 
            <Breadcrumb />

            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">
                                    { t('Imprimer') } flyers
                                </h4>
                                <Link type="button" to="/template/list"
                                    className="btn waves-effect waves-light btn-primary">
                                    { t('Liste des templates') }
                                </Link>
                                <br />
                                <br />

        <Formik
        innerRef={formRef}
        initialValues={
            { 
                idTemp: '',
                idPromo: '', 
                idSender: user?.id
        }}
        validationSchema={
            yup.object().shape({
                idTemp: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`),
                idPromo: yup
                    .string()
                    .required(`${t('Ce champ est obligatoire')}`)
            })
        }
        onSubmit={async (
            values 
          ) => {
                    console.log(values);
                    setLoading(true); 
 
                        templateService.getPrintInformation( Number( values.idPromo ) ).then(async function (response: any) {
                            console.log(response);
                            setClients(response.data.receiver);
                            setLoading(false); 
                        })
                          .catch(function (error: any) {
                            console.log(error);
                            confirmAlert({
                                overlayClassName: "alert-overlay",
                                customUI: ({ onClose }) => {
                                  return (
                                        <Alert 
                                            alert={{
                                                type: 'error',
                                                title: 'Error',
                                                message: 'Une erreur s\'est produite réesseyez s\'il vous plaît.',
                                                actionText: 'Ok',
                                                action: () => {
                                                    onClose();
                                                }
                                            }}
                                        />
                                  );
                                }
                            });  
                            setLoading(false); 
                        });
                     
                    
                }}
                >
                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                        <Form>
                                    <div className="form-body">
                                         
                                        
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                                        <label> { t('Promotion')} </label>
                                                                        {
                                                                            promotionsLoading ? 
                                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                                            <span className="sr-only">Loading...</span>
                                                                            </div> :
                                                                            <select  
                                                                                className={`form-control custom-select ${
                                                                                     errors.idPromo &&
                                                                                    touched.idPromo ? "is-invalid" : "" }`}
                                                                                id="idPromo" 
                                                                                name="idPromo"
                                                                                onChange={event => { 
                                                                                    handleChange('idPromo')(event); 
                                                                                }}
                                                                                onBlur={handleBlur('idPromo')}
                                                                                value={values.idPromo == null ? '' : values.idPromo}
                                                                                placeholder={ t('Promotion') ?? '' } >
                                                                                <option selected>{ t('Promotion') ?? '' }..</option>
                                                                                {
                                                                                    promotions.map(
                                                                                        (promo: any, id) => <option 
                                                                                        key={id} value={promo.id}>
                                                                                            {
                                                                                                promo.libPromo
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                }
                                                                            </select> 
                                                                        }
                                                    </div>

                                                    <div className="form-group">
                                                                        <label>
                                                                            { t('Template') }
                                                                        </label>
                                                                        {
                                                                            templatesLoading ? 
                                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                                            <span className="sr-only">Loading...</span>
                                                                            </div> :
                                                                            <select  
                                                                                className={`form-control custom-select ${
                                                                                     errors.idTemp &&
                                                                                    touched.idTemp ? "is-invalid" : "" }`}
                                                                                id="idTemp" 
                                                                                name="idTemp"
                                                                                onChange={event => { 
                                                                                    handleChange('idTemp')(event); 
                                                                                }}
                                                                                onBlur={handleBlur('idTemp')}
                                                                                value={values.idTemp == null ? '' : values.idTemp}
                                                                                placeholder={ t('Template') ?? ''} >
                                                                                <option selected>{ t('Template') }..</option>
                                                                                {
                                                                                    templates.map(
                                                                                        (temp: any, id) => <option 
                                                                                        key={id} value={temp.id}>
                                                                                            {
                                                                                                temp.libTemplate
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                }
                                                                            </select> 
                                                                        }
                                                    </div>

                                            </div>
                                        </div>

                                        
                                          
 



                                    </div>
                                    <div className="form-actions">
                                        <div className="text-right">
                                            <button  disabled={!dirty && !isValid } type="submit" className="btn btn-info">
                                                { t('Envoyer') }
                                                {
                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                            <button type="reset" className="btn btn-dark">
                                                { t('Annuler') }
                                            </button>
                                        </div>
                                    </div>
                        </Form>
                    )}
                </Formik>
                <br />
                <br />
                { clients.length > 0 && <div>
                    {
                        loading ? 
                        <div className="spinner-border text-light spinner-border-sm" role="status">
                            <span className="sr-only">Loading...</span>
                        </div> : 
                        <>
                            <div className="text-right">
                                            <button   type="button" className="btn btn-danger">
                                                { t('Imprimer') }
                                            </button>
                                            
                            </div>
                            <div className="table-responsive">
                                <DataTable
                                    responsive={true}
                                    className="table table-striped table-bordered"
                                    columns={columns }
                                    data={ clients }
                                    progressPending={ loading  }
                                    pagination
                                />
                            </div>
                        </>
                    }
                </div> }
                </div>
                            </div> 
                    </div> 
                </div>

            </div>   
        </>                   
    );
}

export default PrintFlyersPage