import User from '../../Models/User';
import axiosInstance from '../../Utils/axios_manager';

export default class ReductionService {
  
    constructor( ) {
    }

    public postReduction =  (data: Record<string, any>) => {
        return axiosInstance.post('/reduction/add', data);
    }

    public getReductions =  async () => {
        return await axiosInstance.get('/reduction/list');
    }

    public getReduction =  async (id: Number) => {
        return await axiosInstance.get('/reduction/details' + `?idPromo=${id.toString()}`);
    }

    public putReduction =  (data: Record<string, any>) => {
        return axiosInstance.post('/reduction/update',data);
    }

    public getProductByBarcode =  async (barcode: string) => {
        return await axiosInstance.get('/produit/list/barcode/filtred/simple' + `?barcode=${barcode}`);
    }
  

}