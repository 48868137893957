import { FC, useEffect, useRef, useState } from 'react'
import {
    Link
} from "react-router-dom";
import Breadcrumb from '../../Components/Breadcrumb';


import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup'; 

import Salary from "../../Models/Salary"

import DataTable from 'react-data-table-component';

import Alert from '../../Components/Alert';
import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';


// type FormValues = Gamme;

//Alert
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 


import SalaryService from './service';
import ShopService from '../Shop/service';
import AgentService from '../Agent/service';
import { RootState } from '../../Redux/store';
import { useTranslation } from 'react-i18next';

const SalaryPayPage: FC = () => {  

    // const alert = useAppSelector((state: RootState ) => state.alerts );
    const dispatch = useAppDispatch();
    const { t, i18n } = useTranslation();
    const user = useAppSelector((state: RootState ) => state.users.user );
    
    const formRef = useRef<FormikProps<Salary>>(null);

    const [
        loading, setLoading
    ] = useState(false);

    const [
        salaries,
        setSalaries
    ] = useState([]);

    const [ agents, setAgents ] = useState( [] );

    const [ shops, setShops ] = useState( [] );

    const [ salaryTypes, setSalaryTypes ] = useState( [] );

    const [
        agentsLoading, setAgentsLoading
    ] = useState(false);

    const [
        shopsLoading, setShopsLoading
    ] = useState(false);

    const [
        salaryTypesLoading, setSalaryTypesLoading
    ] = useState(false);

    const initialSelected: any = null

    const [
        selectedSalary,
        setSelectedSalary
    ] = useState(initialSelected);

    const [
        deleteLoading, setDeleteLoading
    ] = useState(false);

    const [
        supervizeLoading, setSupervizeLoading
    ] = useState(false);

   const id: Number = 0;
    const [
        deleteId, setDeleteId
    ] = useState(id);


    const salaryService = new SalaryService();
    const shopService = new ShopService();
    const agentService  = new AgentService();

    const deleteSalary = async (salaryId: Number) => {
        setDeleteId(salaryId);
        setDeleteLoading(true);

        confirmAlert({
            overlayClassName: "alert-overlay",
            customUI: ({ onClose }) => {
              return (
                    <Alert 
                        alert={{
                            title: t('Confirmer'),
                            message: t('Vous confirmez la suppression de ce salaire ?'),
                            actionText: `${t('Valider')}`,
                            action: async () => {
                                await salaryService.deleteSalaryType(salaryId).then(async function (response: any) {
                                    console.log(response); 
                                   
                                    getSalaries();
                                    setDeleteLoading(false); 

                                    confirmAlert({
                                        overlayClassName: "alert-overlay",
                                        customUI: ({ onClose }) => {
                                          return (
                                                <Alert 
                                                    alert={{
                                                        title: t('Succès'),
                                                        message: t('Type de salaire supprimé avec succès'),
                                                        actionText: 'Ok',
                                                        action: () => {
                                                            onClose();
                                                        }
                                                    }}
                                                />
                                          );
                                        }
                                    });

                                })
                                  .catch(function (error: any) {
                                    console.log(error);
                                    setDeleteLoading(false); 
                                });
                                onClose();
                            },
                            cancelText: `${t('Annuler')}`,
                            cancelAction: () => {
                                setDeleteLoading(false); 
                                onClose();
                            }
                        }}
                    />
              );
            }
        });

    }



    const paySalary = async (salary: Salary ) => {
       setLoading(true);

        confirmAlert({
            overlayClassName: "alert-overlay",
            customUI: ({ onClose }) => {
              return (
                    <Alert 
                        alert={{
                            title: t('Confirmer'),
                            message: t('Vous confirmez le payement ?'),
                            actionText: `${t('Valider')}`,
                            action: async () => {
                                await salaryService.postSalaries(salary).then(async function (response: any) {
                                    console.log(response); 
                                   
                                    getSalaries();
                                    setLoading(false);  

                                    if ( formRef.current != null) {
                                        formRef.current.values.id = null;
                                        formRef.current.values.idSal = '';
                                        formRef.current.values.idBoutique = '';
                                        formRef.current.values.idAgent = '';
                                        formRef.current.values.idPayeur =  user?.id;
                                        formRef.current.values.montantSal = '';
                                        formRef.current.values.description = '';
                                    }

                                    confirmAlert({
                                        overlayClassName: "alert-overlay",
                                        customUI: ({ onClose }) => {
                                          return (
                                                <Alert 
                                                    alert={{
                                                        title: t('Succès'),
                                                        message: t('Salaire payer avec succès'),
                                                        actionText: 'Ok',
                                                        action: () => {
                                                            onClose();
                                                        }
                                                    }}
                                                />
                                          );
                                        }
                                    });

                                })
                                  .catch(function (error: any) {
                                    console.log(error);
                                    setDeleteLoading(false); 
                                });
                                onClose();
                            },
                            cancelText: `${t('Annuler')}`,
                            cancelAction: () => {
                                setDeleteLoading(false); 
                                onClose();
                            }
                        }}
                    />
              );
            }
        });

    }

    const getSalaries = () => {
        setLoading(true);
        salaryService.getSalaries().then(async function (response: any) {
            console.log(response); 
            setSalaries(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }


    const getShops = () => {
        setShopsLoading(true);
        shopService.getShops().then(async function (response: any) {
            console.log(response);
            setShops(response.data);
            setShopsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setShopsLoading(false); 
        });
    }

    const getAgents = () => {
        setAgentsLoading(true);
        agentService.getAgents().then(async function (response: any) {
            console.log(response);
            setAgents(response.data);
            setAgentsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setAgentsLoading(false); 
        });
    }

    const getSalaryTypes = () => {
        setSalaryTypesLoading(true);
        salaryService.getSalaryTypes().then(async function (response: any) {
            console.log(response); 
            setSalaryTypes(response.data);
            setSalaryTypesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setSalaryTypesLoading(false); 
        });
    }


    

    const columns = [
        // {
        //     name: 'Id',
        //     selector: (row: any) => row.id
        // },
        {
            name: 'Salaire',
            selector: (row: any) => row.salaire.libelle,
        },
        {
            name: 'Agent',
            selector: (row: any) => row.agent.prenomAgent + ' ' + row.agent.nomAgent,
        }, 
        {
            name: 'Montant',
            selector: (row: any) => row.montant,
        }, 
        {
            name: "Options", 
            selector: (row: any) => (
                <div className='btn-list'>
                    <button type="button" data-toggle="tooltip" data-placement="top" title="Superviser" onClick={event => {
                        confirmAlert({
                            overlayClassName: "alert-overlay",
                            customUI: ({ onClose }) => {
                              return (
                                    <Alert 
                                        alert={{
                                            title: 'Confirmer',
                                            message: 'Vous confirmez la supervision ?',
                                            actionText: 'Valider',
                                            action: async () => {
                                        setSelectedSalary(Salary.fromJson(row) );
                                        setSupervizeLoading(true);

                                        salaryService.supervizeSalary({
                                            idSal: row.id,
                                            idSup: user?.id
                                        }).then(async function (response: any) {
                                            console.log(response); 
                                            getSalaries();
                                            setSupervizeLoading(false); 
                                        })
                                        .catch(function (error: any) {
                                            console.log(error);
                                            setSupervizeLoading(false); 
                                        }); 
                                        onClose();
                                            },
                                            cancelText: "Annuler",
                                            cancelAction: () => {
                                                setSupervizeLoading(false); 
                                                onClose();
                                            }
                                        }}

                                        />
                                    );
                                 }
                            });
                         
                        }}
                        className="btn waves-effect waves-light btn-success">
                            
                                {
                                    selectedSalary?.id == row.id && supervizeLoading ?
                                    <div className="spinner-border text-light spinner-border-sm" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> :
                                    <span className="" style={{ fontSize: "1em" }} >
                                    <i className="icon-check"></i></span>
                                }

                    </button>
                    <button type="button" onClick={event => {  deleteSalary(row.id)  }}
                        className="btn waves-effect waves-light btn-danger">
                            {
                                deleteId == row.id && deleteLoading ?
                                <div className="spinner-border text-light spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> :
                                <span className="" style={{ fontSize: "1em" }} >
                                <i className="icon-trash"></i></span>
                            }
                    </button>
                </div>
            )
        }
    ];


    const cancel = () => {

        setSelectedSalary(null);

        if ( formRef.current != null) {
            formRef.current.values.id = null;
            formRef.current.values.idSal = '';
            formRef.current.values.idBoutique = '';
            formRef.current.values.idAgent = '';
            formRef.current.values.idPayeur =  user?.id;
            formRef.current.values.montantSal = '';
            formRef.current.values.description = '';
        }

    }

    useEffect(() => {
        getSalaries();
        getShops();
        getAgents();
        getSalaryTypes();
    }, []);



    return ( 
        <>  
            <Breadcrumb />

            <div className="container-fluid">

                <div className="row">
                    <div className="col-md-5">
                        <div className="card">
                            <div className="card-body">

                                <h4 className="card-title">
                                    {
                                        selectedSalary == null ? 
                                        t("Payer") :
                                        t("Mettre à jour type")
                                    }
                                </h4>

                                <Formik
                                    initialValues={
                                        {
                                        id: null,
                                        idSal: '',
                                        idBoutique: '',
                                        idAgent: '',
                                        idPayeur: user?.id,
                                        montantSal: '',
                                        description: ''
                                    }}
                                    validationSchema={
                                        yup.object().shape({
                                            idSal: yup
                                                .string() 
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            idBoutique: yup
                                                .string() 
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            idAgent: yup
                                                .string() 
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            montantSal: yup
                                                .string() 
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            description: yup
                                                .string()
                                        })
                                    }
                                    onSubmit={async (
                                        values 
                                    ) => {

                                         
                                        paySalary( Salary.fromJson(values) );
                                        
          
                                    }} 
                                    innerRef={formRef}
                                    >
                                        {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                                    <Form>
                                                        <div className="form-body"> 
                                                            <div className="row"> 
                                                                <div className="col-md-12">
                                                                    
                                                                    <div className="form-group">
                                                                        <label>
                                                                            { t('Type salaire') }
                                                                        </label>
                                                                        {
                                                                            salaryTypesLoading ? 
                                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                                            <span className="sr-only">Loading...</span>
                                                                            </div> :
                                                                            <select  
                                                                                className={`form-control custom-select 
                                                                                ${ errors.idSal &&
                                                                                    touched.idSal ? "is-invalid" : "" }`}
                                                                                id="idSal" 
                                                                                name="idSal"
                                                                                onChange={event => { 
                                                                                    handleChange('idSal')(event); 
                                                                                }}
                                                                                onBlur={handleBlur('idSal')}
                                                                                value={values.idSal == null ? '' : values.idSal}
                                                                                placeholder={ t('Type salaire')?? ''} >
                                                                                <option selected>{ t('Type salaire') }..</option>
                                                                                {
                                                                                    salaryTypes.map(
                                                                                        (type: any, id) => <option 
                                                                                        key={id} value={type.id}>
                                                                                            {
                                                                                                type.libelle
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                }
                                                                            </select> 
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div> 
                                                            <div className="row"> 
                                                                <div className="col-md-12">

                                                                    <div className="form-group">
                                                                        <label>
                                                                            { t('Boutique') }
                                                                        </label>
                                                                        {
                                                                            shopsLoading ? 
                                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                                            <span className="sr-only">Loading...</span>
                                                                            </div> :
                                                                            <select  
                                                                                className={`form-control custom-select ${ errors.idBoutique &&
                                                                                    touched.idBoutique ? "is-invalid" : "" }`}
                                                                                id="idBoutique" 
                                                                                name="idBoutique"
                                                                                onChange={event => { 
                                                                                    handleChange('idBoutique')(event); 
                                                                                }}
                                                                                onBlur={handleBlur('idBoutique')}
                                                                                value={values.idBoutique == null ? '' : values.idBoutique}
                                                                                placeholder={ t('Boutique') ?? '' } >
                                                                                <option selected>{ t('Boutique') }..</option>
                                                                                {
                                                                                    shops.map(
                                                                                        (shop: any, id) => <option 
                                                                                        key={id} value={shop.id}>
                                                                                            {
                                                                                                shop.libBoutique
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                }
                                                                            </select> 
                                                                        }
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div> 

                                                            <div className="row"> 
                                                                <div className="col-md-12">
                                                                    
                                                                    <div className="form-group">
                                                                        <label>
                                                                            { t('Agent') }
                                                                        </label>
                                                                        {
                                                                            agentsLoading ? 
                                                                            <div className="spinner-border text-primary spinner-border-sm" role="status">
                                                                                            <span className="sr-only">Loading...</span>
                                                                            </div> :
                                                                            <select  
                                                                                className={`form-control custom-select 
                                                                                ${ errors.idAgent &&
                                                                                    touched.idAgent ? "is-invalid" : "" }`}
                                                                                id="idAgent" 
                                                                                name="idAgent"
                                                                                onChange={event => { 
                                                                                    handleChange('idAgent')(event); 
                                                                                }}
                                                                                onBlur={handleBlur('idAgent')}
                                                                                value={values.idAgent }
                                                                                placeholder={ t('Agent') ?? ''} >
                                                                                <option selected>{ t('Agent') }..</option>
                                                                                {
                                                                                    agents.map(
                                                                                        (agent: any, id) => <option 
                                                                                        key={id} value={agent.id}>
                                                                                            {
                                                                                                agent.prenomAgent + ' ' + agent.nomAgent
                                                                                            }
                                                                                        </option>
                                                                                    )
                                                                                }
                                                                            </select> 
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div> 


                                                            <div className="row">
                                                                
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label> { t('Montant') } </label>
                                                                        <input type="text"
                                                                            className={`form-control ${ errors.montantSal
                                                                                 && touched.montantSal ? "is-invalid":""}`}
                                                                            id="montantSal" 
                                                                            name="montantSal" 
                                                                            onChange={handleChange('montantSal')}
                                                                            onBlur={handleBlur('montantSal')}
                                                                            value={values.montantSal}
                                                                            placeholder={ t('Montant') ?? '' } />
                                                                        { errors.montantSal && touched.montantSal && errors.montantSal && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                            { errors.montantSal && touched.montantSal && errors.montantSal }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                            </div> 

                                                            
                                                            <div className="row">
                                                                
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label>
                                                                            { t('Description') }
                                                                        </label>
                                                                        <textarea rows={6}
                                                                            className={`form-control ${ errors.description
                                                                                 && touched.description ? "is-invalid":""}`}
                                                                            id="description" 
                                                                            name="description" 
                                                                            onChange={handleChange('description')}
                                                                            onBlur={handleBlur('description')}
                                                                            value={values.description}
                                                                            placeholder={ t('Description') ?? '' } />
                                                                        { errors.description && touched.description && 
                                                                        errors.description && 
                                                                        <div id="validationServer05Feedback" 
                                                                        className="invalid-feedback">
                                                                            {   errors.description && touched.description && 
                                                                                errors.description }
                                                                        </div> }
                                                                    </div>
                                                                </div>

                                                            </div> 

                                                        </div>

                                                        <div className="form-actions">
                                                            <div className="text-right">
                                                                <button  disabled={!isValid} type="submit" className="btn btn-info">
                                                                    { t('Valider') }
                                                                    {
                                                                        loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    }
                                                                </button>
                                                                <button type="button" onClick={event => cancel()}
                                                                 className="btn btn-dark"> { t('Annuler') } </button>
                                                            </div>
                                                        </div>

                                                    </Form>
                                              )}
                                    </Formik>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">
                                    { t('Liste des salaires') }
                                </h4>
                                <Formik
                                    initialValues={
                                        {
                                            dtedebut: null,
                                            dtefin: null  
                                        }
                                    }
                                    validationSchema={
                                        yup.object().shape({
                                            dtedebut: yup
                                                .date()
                                                .required(`${t('Ce champ est obligatoire')}`),
                                            dtefin: yup
                                                .date() 
                                                .required(`${t('Ce champ est obligatoire')}`) 
                                        })
                                    }
                                    onSubmit={async (
                                        values 
                                    ) => {  
                                        console.log(values);
                                        setLoading(true);
                                        await salaryService.filterSalaries(values).then(async function (response: any) {
                                            console.log(response); 
                                           
                                            setSalaries(response.data);
                                            setLoading(false); 
                                             
                                        })
                                          .catch(function (error: any) {
                                            console.log(error);
                                            setLoading(false); 
                                        });
                                    }}  
                                    >
                                        {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                                    <Form>
                                                        <div className="form-body"> 
                                                            <div className="row">
                                                                <div className='col-md-6'>
                                                                    <div className="form-group"> 
                                                                            <Field type="date"
                                                                                className={`form-control ${ errors.dtedebut
                                                                                     && touched.dtedebut ? "is-invalid":""}`}
                                                                                id="dtedebut" 
                                                                                name="dtedebut"
                                                                                onChange={handleChange('dtedebut')}
                                                                                onBlur={handleBlur('dtedebut')}
                                                                                value={values.dtedebut == null ? new Date()  : values.dtedebut }
                                                                                placeholder={ t("Date début") } />
                                                                            { errors.dtedebut && touched.dtedebut && errors.dtedebut && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                { errors.dtedebut && touched.dtedebut && errors.dtedebut }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <div className="form-group"> 
                                                                            <Field type="date"
                                                                                className={`form-control ${ errors.dtefin
                                                                                     && touched.dtefin ? "is-invalid":""}`}
                                                                                id="dtefin" 
                                                                                name="dtefin"
                                                                                onChange={handleChange('dtefin')}
                                                                                onBlur={handleBlur('dtefin')}
                                                                                value={values.dtefin == null ? new Date()  : values.dtefin }
                                                                                placeholder={ t("Date fin") } />
                                                                            { errors.dtefin && touched.dtefin && errors.dtefin && 
                                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                { errors.dtefin && touched.dtefin && errors.dtefin }
                                                                        </div> }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                         </div>   

                                                        <div className="form-actions">
                                                            <div className="text-right">
                                                                <button  disabled={!isValid} type="submit" className="btn btn-info">
                                                                    { t('Filtrer') }
                                                                    {
                                                                        loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    }
                                                                </button>
                                                                {/* <button type="button" onClick={event => cancel()}
                                                                className="btn btn-dark">Annuler</button> */}
                                                            </div>
                                                        </div>

                                                    </Form>
                                        )}
                                    </Formik>

                                <div className="btn-list">
                                    <button type="button" onClick={ event => getSalaries() }
                                        className="btn waves-effect waves-light btn-success">
                                            { t('Actualiser') }
                                        </button>
                                </div> 
                                <div className="table-responsive">
                                    <DataTable
                                        responsive={true}
                                        className="table table-striped table-bordered"
                                        columns={columns}
                                        data={salaries}
                                        progressPending={loading}
                                        pagination
                                    />
                                </div>
                             </div>
                        </div> 
                    </div> 
                </div>

            </div>   
        </>                   
    );
}

export default SalaryPayPage