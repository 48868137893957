import { FC, useEffect, useState } from 'react'
import {
    Link, useLocation, useNavigate, useParams
} from "react-router-dom";
import Breadcrumb from '../../Components/Breadcrumb';
import AgentForm from './form';
import AgentService from './service';


//Redux 
import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
 
import { RootState } from '../../Redux/store';
import { useTranslation } from 'react-i18next';


const AgentEdit: FC = () => {  
    const location: any = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const agentService = new AgentService();
    const user = useAppSelector((state: RootState) => state.users.user );
    let { id } = useParams();
    let initialAgent: any;
    const [agent, setAgent] = useState(initialAgent);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        agentService.getAgent(Number(id)).then(async function (response: any) {
            console.log(response); 
            setAgent(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }, []);

    const disableAgent = () => {
        const confirmation = window.confirm( `${ t("Vous êtes sûre de continuer ?") }` );
        if (confirmation) {
            setLoading(true);
            const motif = prompt( `${ t("Entrez le motif") }` );
            agentService.disableAgent({
                "idAgent": id,
                "idAdmin": user?.id,
                "motif": motif
            }).then(async function (response: any) {
                console.log(response);  
                setLoading(false); 
            })
              .catch(function (error: any) {
                console.log(error);
                setLoading(false); 
            });
        }
    }

    const fireAgent = () => {
        const confirmation = window.confirm( `${ t("Vous êtes sûre de continuer ?") }` );
        if (confirmation) {
            setLoading(true);
            const motif = prompt( `${ t("Entrez le motif") }` );
            agentService.fireAgent({
                "idAgent": id,
                "idAdmin": user?.id,
                "motif": motif,
                "docLicence": ""
            }).then(async function (response: any) {
                console.log(response);  
                setLoading(false); 
            })
              .catch(function (error: any) {
                console.log(error);
                setLoading(false); 
            });
        }
    }

    return ( 
        <> 
            <Breadcrumb />

            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">
                                    { t("Modifier un agent") }
                                </h4>
                                <div className="btn-list">
                                    <button type="button" onClick={ disableAgent }
                                        className="btn waves-effect waves-light btn-warning">
                                        {  agent !== null && agent !== undefined ? agent.actif === false ? t('Activer') : t('Désactiver') : '' }    
                                    </button>
                                    <button type="button" onClick={ fireAgent }
                                        className="btn waves-effect waves-light btn-danger">
                                        { t("Licencier") }      
                                    </button>
                                    <Link type="button" to="/agent/list"
                                        className="btn waves-effect waves-light btn-primary">
                                        { t("Liste des agents") }    
                                    </Link>
                                </div>
                                <br />
                                <br />

                                { !loading && agent ?
                                    <AgentForm state='edit' agent={ agent } />  
                                    :
                                    <div className="spinner-border text-primary spinner-border-lg" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }     
                                 
                                </div>
                            </div> 
                    </div> 
                </div>

            </div>   
        </>                   
    );
}

export default AgentEdit