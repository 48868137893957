export default class Category {
    public id?: number | null;
    public libcat: string;
    public desccat?: string;


    static fromJson(parsedJson: Record<string, any>): Category {
        return new Category(
            Number(parsedJson['id']),
            parsedJson['libcat'],
            parsedJson['desccat']
        );
    }

    static toJson(cat: Category): Record<string, any>  {
        return  {
            "id": cat.id,
            "libcat": cat.libcat,
            "desccat": cat.desccat
        };
    }
  
    constructor(id: number, libcat: string, desccat: string  ) {
            this.id = id;
            this.libcat = libcat;
            this.desccat = desccat;
    }

}