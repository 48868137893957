export default class Right {
    public id?: number | null;
    public libDroit: string;
    public codeDroit?: string;
    public descriptionDroit?: string;


    static fromJson(parsedJson: Record<string, any>): Right {
        return new Right(
            Number(parsedJson['id']),
            parsedJson['libDroit'],
            parsedJson['codeDroit'],
            parsedJson['descriptionDroit']
        );
    }

    static toJson(right: Right): Record<string, any>  {
        return  {
            "id": right.id,
            "libDroit": right.libDroit,
            "codeDroit": right.codeDroit,
            "descriptionDroit": right.descriptionDroit
        };
    }
  
    constructor(id: number, libDroit: string, codeDroit: string,  descriptionDroit: string  ) {
            this.id = id;
            this.libDroit = libDroit;
            this.codeDroit = codeDroit;
            this.descriptionDroit = descriptionDroit;
    }

}